import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Constants } from '../../../helpers/Constants';
import { formatDate } from '@angular/common';
import { ShiftManagementService } from '../../../services/shift-management.service';
import { ShiftData } from '../../../models/newModels/ShiftData';
import * as moment from 'moment';
import { GetShiftsRequest, ShiftNew } from 'src/app/models/java-models';

@Component({
  selector: 'shifts-to-fill',
  templateUrl: './shifts-to-fill.component.html',
  styleUrls: ['./shifts-to-fill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftsToFillComponent implements OnInit {

  openedShifts: ShiftNew[] = [];

  constructor(private _shiftService: ShiftManagementService,
    private _cdr: ChangeDetectorRef) {
  }


  static _datesToShiftHours(shiftData: ShiftData): string {
    const start = new Date().setHours(Number(shiftData.starttime.split(':')[0]));
    const end = new Date().setHours(Number(shiftData.endtime.split(':')[0]));
    return formatDate(start, 'ha', 'en-US') + '-' + formatDate(end, 'ha', 'en-US');
  }

  private static _getShiftsParams(shiftStatus?: number, statusArray?: number[]) {
    const start = moment();
    const end = start.clone().add(7, 'days');
    const params: GetShiftsRequest = {
      startDate: formatDate(start.toDate(), 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(end.toDate(), 'yyyy-MM-dd', 'en-US')
    };

    if (!!shiftStatus) {
      params.status = shiftStatus;
    }

    if (!!statusArray) {
      params.statusArray = statusArray;
    }

    return params;
  }

  ngOnInit() {
    this._shiftService.getShifts(ShiftsToFillComponent._getShiftsParams(null,
      [Constants.SHIFT_STATUS_DESCRIPTION.open.id, Constants.SHIFT_STATUS_DESCRIPTION.callOut.id])).pipe().subscribe(e => {
        this.openedShifts = e.shifts.sort((e1, e2) => {
          const a = moment(e1.shiftDate + ' ' + e1.startTime).toDate().getTime();
          const b = moment(e2.shiftDate + ' ' + e2.startTime).toDate().getTime();
          return a > b ? 1 : a < b ? -1 : 0;
        });
        this._cdr.detectChanges();
      });
  }
}
