import { Injectable } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseResponseModel } from '../../models/BaseResponseModel';
import { map } from 'rxjs/operators';
import { StaffContact } from '../models/StaffContact';
import { CustomMessage } from '../models/CustomMessage';
import { ParticipantResponse } from 'ng-chat';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  staffListObservable: BehaviorSubject<ParticipantResponse[]> = new BehaviorSubject<ParticipantResponse[]>([]);
  totalUnread: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _baseurl = `${UtilsService.getShiftJavaUri()}/shift/messaging`;

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Retrieve list of staff members to text
   */
  getStaffChatList(): Observable<StaffContact[]> {
    return this._http.get<BaseResponseModel<StaffContact[]>>(`${this._baseurl}/staff`).pipe(
      map(response => response.data)
    );
  }

  /**
   * Get conversation for given staff
   * @param staffId: given staff
   */
  getConversation(staffId: number): Observable<CustomMessage[]> {
    return this._http.get<BaseResponseModel<CustomMessage[]>>(`${this._baseurl}/staff/${staffId}`).pipe(
      map(response => response.data)
    );
  }

  /**
   * Send a new message
   * @param message: message to be sent
   */
  sendMessage(message: CustomMessage): Observable<BaseResponseModel<any>> {
    return this._http.post<BaseResponseModel<any>>(`${this._baseurl}/send`, message);
  }

  /**
   * Update conversation for specific staff to seen
   * @param staffId: staff member to update conversation
   */
  setMessagesAsSeen(staffId: number): Observable<BaseResponseModel<any>> {
    return this._http.put<BaseResponseModel<any>>(`${this._baseurl}/staff/${staffId}/markread`, {}).pipe(
      map(response => response.data)
    );
  }

  /**
   * Update list of unread messages for staff observable
   * @param staffId: given staff
   * @param unread: number of unread messages to update
   */
  updateStaffUnreadMessages(staffId: number, unread: number) {
    const updatedList = this.staffListObservable.value.map(item => {
      if (item.participant.id === staffId) {
        item.metadata.totalUnreadMessages = unread;
      }
      return item;
    });

    this.staffListObservable.next(updatedList);
  }

  updateChatList(staffId: number) {
    const updatedList: ParticipantResponse[] = this.staffListObservable.value;
    const staff = updatedList.splice(updatedList.findIndex(item => staffId === item.participant.id), 1);
    let added = false;

    updatedList.some((item, index) => {
      if (item.metadata.totalUnreadMessages === 0) {
        added = true;
        updatedList.splice(index, 0, staff[0]);
        return true;
      }
    });

    if (!added) {
      updatedList.push(staff[0]);
    }

    this.staffListObservable.next(updatedList);
  }

  /**
   * Recalculate number of total unread messages
   */
  recount() {
    let count = 0;
    this.staffListObservable.value.forEach(item => {
      count += item.metadata.totalUnreadMessages;
    });
    this.totalUnread.next(count);
  }

}
