import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementTopCardComponent } from './management-top-card.component';
import { MaterialModule } from '../../../shared/material.module';
import { ChipIconModule } from '../../../shared/componets/chip-icon/chip-icon.module';
import { SharedModule } from '../../../shared/shared.module';
import { PointSeparatorModule } from '../../../shared/componets/point-separator/point-separator.module';
import { ConfirmationModalModule } from '../../../shared/componets/confirmation-modal/confirmation-modal.module';
import { DatePipe } from '@angular/common';
import { CustomShiftTimeDisplayModule } from 'src/app/shared/componets/custom-shift-time-display/custom-shift-time-display.module';

@NgModule({
  declarations: [ManagementTopCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ChipIconModule,
    PointSeparatorModule,
    ConfirmationModalModule,
    CustomShiftTimeDisplayModule

  ],
  exports: [
    ManagementTopCardComponent
  ],
  providers: [DatePipe]

})
export class ManagementTopCardModule {
}
