import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ShiftManagementSidebarService } from '../../shift-management-sidebar.service';

@Component({
  selector: 'card-system-top-header',
  templateUrl: './card-system-top-header.component.html',
  styleUrls: ['./card-system-top-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardSystemTopHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;

  // @Input() indicator: string;

  constructor(private _sidebarService: ShiftManagementSidebarService) {
  }

  ngOnInit() {
  }

  close() {
    this._sidebarService.closePanel();
  }

}
