import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ManageUsersAccountService } from '../services/manage-users-account.service';
import { FuseConfigService } from '@theme/services/config.service';
import { theme_config } from '../config/theme_config';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  uiconfig = theme_config;
  constructor(
    private __userService: ManageUsersAccountService,
    private fuseConfigService: FuseConfigService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: any) => {
        if ((String(error.status) === '401' || String(error.status) === '403') && !this.router.url.match(/onboard/) && !this.router.url.match(/login/)) {
          const redirectUrl = this.__userService.getLoginScreen();
          this.__userService.clearLocalStorage();
          this.uiconfig.layout.sidepanel.hidden = true;
          this.uiconfig.layout.toolbar.hidden = true;
          this.fuseConfigService.setConfig(this.uiconfig);
          if (String(error.status) === '403') {
            this.snackbar.open("Your session has expired. Please log back in. If you're having trouble, contact IntelyCare at (844) 683-5922.", 'Close', {
              duration: 6000
            });
          }
          this.router.navigate([redirectUrl]);
        }
        return throwError(error);
      })
    );
  }
}
