<div class="shift-management">
  <div class="shift-management-wrapper" fxLayout="column" fxFlex fxFlexFill>

    <div class="content" fxFlexFill fxFlex="0 1 auto" fxLayout="column">

      <div class="header" fxFlex="0 1 auto">
        <app-management-top-card [title]="'FIll Cancelled Shift'" [shiftDetails]="shiftDetails">
        </app-management-top-card>
      </div>

      <div class="content-wrapper" fxLayoutGap="15px" fxLayout="column" fxFlex="0 1 auto">
        <br/>

        <div class="filter" fxFlex="0 1 auto">
          <shift-management-filter></shift-management-filter>
        </div>


        <div class="requests" fxFlex fxLayout="column">
          <div class="requests-total">
            <span class="requests-total-text">{{allRequests}} Requests Sent to Staff</span>
          </div>
          <div class="all-responses">

            <div class="staff-request-responses">
              <div class="staff-request-responses-wrapper" fxLayout="column" fxLayoutGap="8px">
                <mat-divider></mat-divider>
                <div class="content">
                  <mat-list fxLayout="column" fxLayoutGap="12px" *ngFor="let orequest of requests.requests | keyvalue">

                    <app-staff-card-confirmation [staffMember]="getStaffMember(request.staffID)" [request]="request"
                                                 [response]="orequest.key" (selectionChange)="setSelectedItem($event)"
                                                 *ngFor="let request of orequest.value"></app-staff-card-confirmation>
                  </mat-list>
                </div>
              </div>
            </div>

          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="more-staff" fxFlex="24px">
          <span class="more-staff-text">More Available Staff</span>
        </div>

        <div class="useful-options" fxFlex="0 1 auto" *ngIf="unselectedStaff.length">
          <mat-checkbox class="opt left" [checked]="areAllChecked" (change)="toggleAll($event.checked)">Select All
          </mat-checkbox>
        </div>

        <div class="results">
          <app-staff-card-expandable [staffMembers]="staff" [control]="true"
                                     (selectionChange)="selectStaff($event)"
          ></app-staff-card-expandable>
        </div>
      </div>
    </div>

    <div class="actions" fxFlex="120px">
      <div class="actions-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center" fxLayoutGap="12px">
        <button mat-button class="action-button" fxFlexAlign="center"
                [ngClass]="!selectedStaff.length? 'disabled' : 'complete'"
                [disabled]="!selectedStaff.length" *ngIf="!selectedRequest"
        >Send To Staff <span *ngIf="selectedStaff.length">({{selectedStaff.length}})</span>
        </button>
        <button mat-button class="action-button partial" fxFlexAlign="center" *ngIf="!selectedRequest">Request
          Intelypro
        </button>
        <button mat-button class="action-button complete" fxFlexAlign="center" *ngIf="selectedRequest"
        (click)="confirmRequest()">Confirm</button>
      </div>
    </div>
  </div>
</div>
