import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { fromEvent } from 'rxjs';
import { ManageUsersAccountService } from './manage-users-account.service';
import { filter } from 'rxjs/operators';
import { EventTypes } from '../helpers/EventTypes';
import { UtilsService } from './utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {

  socket: SocketIOClient.Socket;

  constructor(private _userService: ManageUsersAccountService) {
    this._userService.currentUserSubject.pipe(
      filter(e => !!e),
    ).subscribe(e => {
      this.socket = io(UtilsService.getSocketBaseUri(), {
        query: 'clientid=' + e.activeusertype.clientid
      });

      this._onConnect().subscribe();
      this._onWelcome().subscribe();
      this.onNewMessage(EventTypes.TEST).subscribe();
    });
  }

  onNewMessage(event: string) {
    return fromEvent(this.socket, event);
  }

  private _onConnect() {
    return fromEvent(this.socket, 'connect');
  }

  private _onWelcome() {
    return fromEvent(this.socket, 'welcome');
  }
}
