import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftManagementComponent } from './shift-management.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { ShiftDetailsModule } from './components/shift-details/shift-details.module';
import { FillShiftModule } from './components/fill-shift/fill-shift.module';
import { ShiftsToFillModule } from './components/shifts-to-fill/shifts-to-fill.module';
import { RequestsModule } from './components/requests/requests.module';
import { RequestIntelyproModule } from './components/request-intelypro/request-intelypro.module';
import { FillShiftResponsesModule } from './components/fill-shift-responses/fill-shift-responses.module';
import { CreateShiftModule } from './components/create-shift/create-shift.module';
import { ShiftTypesService } from '../services/shift-types/shift-types.service';
import { MultipleShiftsConfirmationComponent } from './components/multiple-shifts-confirmation/multiple-shifts-confirmation.component';
import { CreateShiftsSpreadsheetModule } from '../spreadsheet/create-shifts-spreadsheet/create-shifts-spreadsheet.module';
import { ConfirmShiftsSpreadsheetModule } from '../spreadsheet/confirm-shifts-spreadsheet/confirm-shifts-spreadsheet.module';

@NgModule({
  declarations: [
    ShiftManagementComponent,
    MultipleShiftsConfirmationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ShiftDetailsModule,
    FillShiftModule,
    ShiftsToFillModule,
    RequestsModule,
    RequestIntelyproModule,
    FillShiftResponsesModule,
    CreateShiftModule,
    CreateShiftsSpreadsheetModule,
    ConfirmShiftsSpreadsheetModule
  ],
  exports: [
    ShiftManagementComponent
  ],
  providers: [ShiftTypesService]

})
export class ShiftManagementModule {
}
