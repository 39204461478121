<div class="shift-management">
    <div class="shift-management-wrapper">

        <div class="content">

            <div class="header">
                <app-management-top-card title="Assign Shift(s)" component="spreadsheet" [shiftDetails]="shiftData">
                </app-management-top-card>
            </div>

            <div class="content-wrapper">
                <div class="total-container">
                    <span class="total">{{selectedShifts.length}} shifts
                    </span>
                </div>

                <mat-divider></mat-divider>
                <div class="useful-options">
                    <mat-checkbox class="opt left" [checked]="!selectAllCondition" [disabled]="allOverlap"
                        (change)="toggleAll($event.checked)">
                        Select All
                    </mat-checkbox>
                </div>
            </div>

            <div class="results-wrapper">
                <div class="staff-card-expandable" *ngFor="let shift of shiftDetails; let i = index">
                    <mat-expansion-panel disabled>
                        <mat-expansion-panel-header collapsedHeight="72px" expandedHeight="72px"
                            (click)="selectItem(shift)">
                            <div class="header-content">
                                <div class="staff-card-expandable-checkbox">
                                    <mat-checkbox (click)="$event.stopPropagation();" (change)="selectItem(shift)"
                                        [checked]="getIndexOf(shift) > -1 && !shift.overlapping"
                                        [disabled]="shift.overlapping"></mat-checkbox>
                                </div>
                                <div class="staff-card-expendable-content" [ngClass]="{'disabled': shift.overlapping}">
                                    <span class="bold-item title"
                                        [ngClass]="{'disabledTitle': shift.overlapping}">{{shift.shiftDate | date : 'MMM
                                        dd, yyyy'}}
                                        <i *ngIf="shift.overlapping" class="fas fa-exclamation-triangle"></i>
                                    </span>
                                    <span *ngIf="shift.overlapping" class="overlappingMessage">Schedule Overlap</span>
                                </div>
                            </div>
                        </mat-expansion-panel-header>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>

        <div class="actions">
            <app-custom-drawer-footer [isSecondButtonDisabled]="!selectedShifts.length" [isLoading]="isLoading"
                firstButtonLabel="Back" [secondButtonIcon]="CreateShiftConstants.ASSIGNMENT_ICON"
                secondButtonLabel="Assign Shift(s)" (clickedButton)="assignOrBack($event)">
            </app-custom-drawer-footer>
        </div>
    </div>
</div>