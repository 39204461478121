<div class="header" fxFlex fxLayout="column">
  <div fxFlex fxLayoutAlign="start center" class="title-container" fxLayout="row">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="title-container" fxLayout="column">
      <span class="header-title">{{title}}</span>
      <span class="header-subtitle" *ngIf="subtitle">{{subtitle}}</span>
    </div>
  </div>
</div>
