import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OnBoardingNavigation } from '../../models/newModels/OnBoardingNavigation';
import { onboardNavigation } from '../../config/OnBoardingNavigation';
import { NavigationEnd, Router } from '@angular/router';
import { ManageUsersAccountService } from '../../services/manage-users-account.service';
import { filter, flatMap, map, take } from 'rxjs/operators';
import { BaseResponseModel } from '../../models/BaseResponseModel';
import { FuseConfigService } from '@theme/services/config.service';
import { theme_config } from '../../config/theme_config';
import { TemplateCustomConfig } from '../../models/TemplateCustomConfig';
import { GoogleAnalyticsService } from '../../services/googleanalytics.service';
import { GoogleAnalyticsEvents } from '../../models/GoogleAnalyticsEvents';
import { onboardHubNavigation } from '../../config/OnBoardingHubNavigation';
import { UserTypes } from 'src/app/config/user-types';
import { Constants } from 'src/app/helpers/Constants';
/**
 * OnBoardingService manages all onboarding navigations,state updates,showing and hiding sidebars,and save of partial
 * information of onboarding process in local storage.
 */

@Injectable({
  providedIn: 'root'
})
export class OnBoardingService {

  onBoardingNavigation: BehaviorSubject<OnBoardingNavigation[]> = new BehaviorSubject<OnBoardingNavigation[]>(onboardNavigation);
  state: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  facilityUnitsUpdated: Subject<boolean> = new Subject<boolean>();
  facilityRolesUpdated: Subject<boolean> = new Subject<boolean>();

  private _completeSignUp = new BehaviorSubject<boolean>(false);

  customConfig: TemplateCustomConfig = { navbar: '', sidebar: '' };
  private _currentStep;
  private _currentStepIndex;
  private _uiconfig = theme_config;
  private _analyticsEvents = new GoogleAnalyticsEvents();

  constructor(
    private _accountService: ManageUsersAccountService,
    private _router: Router,
    private _sidebarConfig: FuseConfigService,
    private _analyticsService: GoogleAnalyticsService
  ) {
    this._accountService.currentUserSubject.pipe().subscribe(resp => {

      if (resp?.usertypes.filter(e => e.usertypeid === UserTypes.HubUser).length > 0) {
        this.onBoardingNavigation.next(onboardHubNavigation);
      } else {
        this.onBoardingNavigation.next(onboardNavigation);
      }

      let idx = this.onBoardingNavigation.value.findIndex(e => ('/' + e.uri) === this._router.url.split('?')[0]);

      if (idx === -1) {
        idx = 0;
      }

      this._currentStep = this.onBoardingNavigation.value[idx];
      this._currentStepIndex = idx;

      this.onBoardingNavigation.pipe(
        take(1),
        flatMap(e => this._router.events),
        filter(e => e instanceof NavigationEnd),
        map(e => this._router.url)
      ).subscribe((e: string) => this.completeSteps(e));
    });
  }

  getOnboardingState(): Observable<any> {
    return this._accountService.getUserFlags().pipe(
      map(resp => {
        const flag = resp.data?.userflags?.filter(item => item.flagtypeid === 5)[0];
        return flag?.flagvalue;
      })
    );
  }

  updateState(url: string) {
    const currentUrl = this._accountService.currentUserStateFlag;
    if ((
      this.onBoardingNavigation.value.findIndex(item => item.uri === url) >
      this.onBoardingNavigation.value.findIndex(item => item.uri === currentUrl)
    ) || !currentUrl) {
      this._accountService.currentUserStateFlag = url;
      this._accountService.setUserFlag(5, url).pipe().subscribe();
    }
  }

  updateStateObservable(url: string): Observable<BaseResponseModel<any>> {
    const currentUrl = this._accountService.currentUserStateFlag;
    if (
      currentUrl !== 'done' && (
        (
          this.onBoardingNavigation.value.findIndex(item => item.uri === url) >
          this.onBoardingNavigation.value.findIndex(item => item.uri === currentUrl)
        ) || !currentUrl || (url === 'done')
      )) {
      this._accountService.currentUserStateFlag = url;
      return this._accountService.setUserFlag(5, url);
    } else {
      return of<BaseResponseModel<any>>(null);
    }
  }

  goForward() {
    const nav = this.onBoardingNavigation.value;
    if (this._currentStepIndex < nav.length - 1) {
      const toNavigate = this._currentStep.next;
      nav[this._currentStepIndex] = this._currentStep;
      this.onBoardingNavigation.next(nav);
      this._currentStepIndex = this._currentStepIndex + 1;
      this._currentStep = nav[this._currentStepIndex];
      this._router.navigate([toNavigate], { preserveQueryParams: true });
    }
  }

  goBack() {
    if (this._currentStepIndex >= 0) {
      const nav = this.onBoardingNavigation.value;
      const toNavigate = this._currentStep.previous;
      nav[this._currentStepIndex] = this._currentStep;
      this.onBoardingNavigation.next(nav);
      this._currentStepIndex = this._currentStepIndex - 1;
      this._currentStep = nav[this._currentStepIndex];
      this._router.navigate([toNavigate], { preserveQueryParams: true });
    }
  }

  hideSidebar() {
    this.customConfig.navbar = 'OnlyNavbar';
    this._uiconfig.layout.sidepanel.hidden = true;
    this._sidebarConfig.setConfig(this._uiconfig);
    this._sidebarConfig.setCustomConfig = this.customConfig;
  }

  private completeSteps(stepUrl: string) {
    if (!!this._accountService.currentUserValue) {
      const steps = this.onBoardingNavigation.value;
      const leftAtUrl = steps.find(e => e.uri === this._accountService.currentUserStateFlag) ?? steps[steps.length - 1];

      let i = 0, found = false;

      while (i < steps.length) {
        if (
          (!found && this._getStepUri(steps[i].uri) !== this._getStepUri(leftAtUrl.uri))
        ) {
          steps[i].checked = true;
        } else {
          found = true;
          steps[i].checked = false;
        }
        i++;
      }

      i = steps.findIndex(item => this._getStepUri(item.uri) === stepUrl.split('?')[0]);

      this.onBoardingNavigation.next(steps);
      // at this moment i === index of step that is currently navigated
      this._currentStep = steps[i];
      this._currentStepIndex = i;

      if (stepUrl.includes('onboard')) {
        this._analyticsService.eventEmitter(this._analyticsEvents.onboarding.category,
          this._analyticsEvents.onboarding.action.navigation_change.name,
          this._analyticsEvents.onboarding.action.navigation_change.label.replace('?', stepUrl)
        );
      }
    } else {
      this.onBoardingNavigation.next(onboardNavigation);
    }
  }

  private _getStepUri(stepUri: string) {
    return stepUri.startsWith('/') ? stepUri : '/' + stepUri;
  }

  savePartialInfoInLs(key: string, value: any) {
    let onboard = JSON.parse(localStorage.getItem('onboard'));
    if (!onboard) {
      onboard = {};
    }
    onboard[key] = value;
    localStorage.setItem(`onboard`, JSON.stringify(onboard));
  }

  getPartialInfoInLs(key: string): any {
    const onboard = JSON.parse(localStorage.getItem(`onboard`));
    return !!onboard && !!onboard[key] ? onboard[key] : null;
  }

  removePartialInfoInLs(key: string): void {
    const onboard = JSON.parse(localStorage.getItem('onboard'));
    delete onboard[key];
    localStorage.setItem('onboard', JSON.stringify(onboard));
  }

  deleteOnboardingLS() {
    localStorage.removeItem('onboard');
  }

  public _checkObjects(o1, o2) {
    return Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => {
        if (p !== 'updated') {
          return o1[p] === o2[p];
        } else {
          return true;
        }
      });
  }

  public _presort(a, b) {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  /**
   * Show further steps of full express navigation
   * @param visible
   */
  public viewFurtherSteps(visible: boolean) {
    const index = this.onBoardingNavigation.value.findIndex(item => item.uri === 'onboard/choose-experience');
    for (let i = index + 1; i < this.onBoardingNavigation.value.length; i++) {
      this.onBoardingNavigation.value[i].isStep = visible;
    }
  }

  checkIfEqual(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  public get completeSignUp() {
    return this._completeSignUp.asObservable();
  }
  public triggerCompleteSignUp(change) {
    this._completeSignUp.next(change);
  }

  public getAutomationIdSplitted(name: string) {
    return name.split(' ').join('-');
  }

  calculateEndDateFourWeeksForToday(startDate) {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + Constants.daysToAdd);
    return endDate.toString();
  }
}
