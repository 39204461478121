import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/shared/material.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CreateShiftsSpreadsheetComponent } from "./create-shifts-spreadsheet.component";
import { CustomShiftTimeDisplayModule } from "src/app/shared/componets/custom-shift-time-display/custom-shift-time-display.module";
import { CustomDrawerHeaderModule } from "src/app/shared/componets/custom-drawer-header/custom-drawer-header.module";
import { CustomDrawerFooterModule } from "src/app/shared/componets/custom-drawer-footer/custom-drawer-footer.module";
import { LoaderModule } from "src/app/shared/componets/loader/loader.module";


@NgModule({
    declarations: [CreateShiftsSpreadsheetComponent],
    exports: [CreateShiftsSpreadsheetComponent],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        CustomShiftTimeDisplayModule,
        CustomDrawerHeaderModule,
        CustomDrawerFooterModule,
        LoaderModule

    ],
})
export class CreateShiftsSpreadsheetModule { }
