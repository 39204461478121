import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestCardComponent } from './request-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material.module';



@NgModule({
  declarations: [RequestCardComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  exports: [RequestCardComponent]
})
export class RequestCardModule { }
