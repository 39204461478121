import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileCartSidebarComponent } from './mobile-cart-sidebar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { CartDetailsModule } from './components/cart-details/cart-details.module';


@NgModule({
  declarations: [MobileCartSidebarComponent],
  exports: [
    MobileCartSidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CartDetailsModule
  ]
})
export class MobileCartSidebarModule {
}
