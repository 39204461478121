<div (click)="navigateToPath()" class="side-menu-item-wrapper"
     [ngStyle]="{'background-color': selected ? '#E3F0F6' : 'transparent'}">
  <div *ngIf="selected" class="selected-item"></div>
  <div class="menu-icon">
    <i class="material-icons">{{menu.icon}}</i>
  </div>
  <div class="menu-text-wrapper">
    <div class="menu-item-title">
      <label class="title-label">{{menu.title}}</label>
      <label *ngIf="menu.showNumber" class="total-number">{{menu.num}}</label>
    </div>
    <div class="menu-item-subtitle">
      <label class="subtitle-label">{{menu.subtitle}}</label>
    </div>
  </div>
</div>
