<div class="reset-form">

  <div class="reset-form-wrapper">

    <div class="header-wrapper">
      <div class="header-icon-wrapper">
        <img src="assets/images/reset.svg" class="custom-icon" alt="Reset" />
      </div>

      <div class="user-management-header-wrapper">
        <div class="user-management-header">
          <label class="header-label-1">
            Password Reset
          </label>
          <br />
          <br />
          <div *ngIf="!firstEmailSent; else  recoverHeader">
            <label class="header-label-3">
              Please enter your email to send
            </label>
            <br />
            <label class="header-label-3">
              the verification code.
            </label>
          </div>
          <ng-template #recoverHeader>
            <label class="header-label-3">
              To keep your account safe, We sent a
            </label>
            <br />
            <label class="header-label-3">
              code to: <b style="color: black">{{ givenEmail }}</b>
            </label>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="user-management-body">
      <form [formGroup]="emailForm" (ngSubmit)="sendCode(emailForm.controls.email.value)">
        <mat-form-field *ngIf="!firstEmailSent; else recoverBody" appearance="outline" class="input-custom-width">
          <mat-label class="custom-input-label">Email Address</mat-label>
          <input matInput placeholder="example@email.com" type="email" formControlName="email" required>
          <mat-hint class="custom-hint" *ngIf="emailForm.controls.email.touched && validateEmailForm.email.errors">
            A valid email address is required
          </mat-hint>
        </mat-form-field>
      </form>

      <ng-template #recoverBody>
        <form [formGroup]="recoverForm" (ngSubmit)="resetPassword()">
          <mat-form-field appearance="outline" class="input-custom-width">
            <mat-label class="custom-input-label">Activation Code</mat-label>
            <input formControlName="code" (focus)="verificationCodeFocus = true" (focusout)="verificationCodeFocus = false;"
                   matInput placeholder="Enter Code" required />
            <mat-hint class="custom-hint" *ngIf="recoverForm.controls.code.touched && validateRecoverForm.code.errors">
              Activation code is required
            </mat-hint>
          </mat-form-field>
          <div class="resend-code-wrapper">
            <label class="resend-code-label">
              I didn’t receive an email message
            </label>
            <br />
            <label *ngIf="!processing" class="resend-button" (click)="sendCode(givenEmail)">
              Re-send code
            </label>
          </div>
          <br />
          <mat-form-field appearance="outline" class="input-custom-width suffix">
            <mat-label class="custom-input-label">Create Password</mat-label>
            <input (keyup)="child.validatePassword();child.passwordMatches();" matInput placeholder="Enter password" [type]="hide ? 'password' : 'text'"
                   formControlName="password" required>
            <i matSuffix (click)="hide = !hide" [ngClass]="hide ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            <mat-hint class="custom-hint" *ngIf="recoverForm.controls.password.touched && validateRecoverForm.password.errors">
              A valid password is required
            </mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-custom-width">
            <mat-label class="custom-input-label">Confirm Password</mat-label>
            <input (keyup)="child.passwordMatches();" matInput placeholder="Re-enter password" [type]="hideConfirm ? 'password' : 'text'"
                   formControlName="confirmPassword" required>
            <i matSuffix (click)="hideConfirm = !hideConfirm" [ngClass]="hideConfirm ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            <mat-hint class="custom-hint" *ngIf="recoverForm.controls.confirmPassword.touched && validateRecoverForm.confirmPassword.errors">
              A valid confirmation password is required
            </mat-hint>
          </mat-form-field>

          <app-validate-password (passMatchEvent)="passMatch = $event" [password]="recoverForm.controls.password.value"
                                 [confirm]="recoverForm.controls.confirmPassword.value">
          </app-validate-password>
        </form>
      </ng-template>

      <div class="button-wrapper">
        <button *ngIf="!firstEmailSent; else resetButton" [disabled]="this.emailForm.invalid || processing" [ngStyle]="{'background' : checkButtonColor('email')}" class="main-button" mat-button type="submit" (click)="sendCode(emailForm.controls.email.value)">
          <img *ngIf="processing" src="/assets/images/loader.gif" height="30" width="30" />
          <label *ngIf="!processing" class="main-button-label" [ngStyle]="{cursor: (this.emailForm.invalid || processing) ? null : 'pointer'}">
            SEND CODE
          </label>
        </button>
        <ng-template #resetButton>
          <button class="main-button" mat-button [disabled]="!isValidResetForm() || processing" [ngStyle]="{'background' : checkButtonColor('reset')}" type="submit" (click)="resetPassword()">
            <img *ngIf="processing" src="/assets/images/loader.gif" height="30" width="30" />
            <label *ngIf="!processing" class="main-button-label">
              CREATE PASSWORD
            </label>
          </button>
        </ng-template>
      </div>
      <br />
      <br />
    </div>
  </div>
</div>
