import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { tap, filter, mergeMap, toArray } from "rxjs/operators";
import { BaseResponseModel } from "src/app/models/BaseResponseModel";
import { StaffMemberUpload } from "../../../models/StaffMemberUpload";
import { UtilsService } from "../../../../services/utils/utils.service";
import { StaffManagementService } from "src/app/services/staff-management.service";
import { StaffMember } from "src/app/models/shift-management-models/StaffMember.model";

@Injectable({
  providedIn: "root",
})
export class AddStaffService {
  manuallyAddStaff = new BehaviorSubject<boolean>(false);
  _addedStaff = new Subject<''>();
  fileToUpload: string | ArrayBuffer = null;
  fileUpload: any;
  fileName: string = null;
  staffMembers: Array<StaffMemberUpload> = [];
  private _baseUrl = UtilsService.getStaffBaseUri();
  private _internalUrl = UtilsService.getInternalBaseUri();
  private _shiftJavaUrl = UtilsService.getShiftJavaUri();

  constructor(
    private http: HttpClient,
    private staffManagementService: StaffManagementService
  ) { }

  emptyStaff() {
    this.staffMembers = [];
  }

  getStaff() {
    return this.staffManagementService
      .getAllStaff({ startDate: "", endDate: "" })
      .pipe(
        filter((staff) => !!staff.data),
        mergeMap((staff) => staff.data),
        filter((staffMember) => staffMember.active),
        toArray(),
        tap(
          (staffArray) =>
          (this.staffMembers = staffArray.map((staff) => {
            return {
              id: staff.staffId,
              firstname: staff.firstName,
              lastname: staff.lastName,
              phonenumber: staff.phoneNumber,
              stafftypeid: staff.staffTypeId,
            } as StaffMemberUpload;
          }))
        )
      );
  }

  resetFile() {
    this.fileName = null;
    this.fileToUpload = null;
  }

  // saves on db uploaded member staffs
  uploadFile() {
    return this.http.post<BaseResponseModel<StaffMemberUpload[]>>(
      `${this._internalUrl}/staff/importstaff`,
      this.fileToUpload
    );
  }

  /**
   * Multiple staff upload service call
   */
  uploadSpreadSheet() {
    const formData: FormData = new FormData();
    formData.append('file', this.fileUpload);
    return this.http.post<BaseResponseModel<StaffMember[]>>(
      `${this._shiftJavaUrl}/shift/staff/onboard`,
      formData
    );
  }

  // saves on db manual staff
  manualAddStaff(staff: StaffMemberUpload) {
    return this.http.post<BaseResponseModel<any>>(`${this._baseUrl}`, staff)
  }
  get addedStaff() {
    return this._addedStaff.asObservable();
  }
}
