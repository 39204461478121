import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'securePipe'
})
export class SecurePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }


  transform(url: string, asPdf: boolean = false): Observable<SafeUrl> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(val => {
        if (asPdf) {
          return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val));
        }
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val));
      })
    );
  }

}



