<ng-container *ngIf="shiftDef; else shiftTypeTemplate">
    <span [style.font-size]="timeFontSize">
        <span class="shift-hours">{{ shiftDef.start?.shiftTime }}</span>
        <span class="am-pm" [style.font-size]="periodFontSize">{{ shiftDef.start?.shiftAMPM }}</span>
        <span class="start-end-divider"> - </span>
        <span class="shift-hours">{{ shiftDef.end?.shiftTime }}</span>
        <span class="am-pm" [style.font-size]="periodFontSize">{{ shiftDef.end?.shiftAMPM }}</span>
    </span>
</ng-container>

<ng-template #shiftTypeTemplate>
    <ng-container *ngIf="shiftType; else shiftTimeTemplate">
        <span [style.font-size]="timeFontSize">
            <span class="shift-hours">{{ shiftType.shiftStartHour }}</span>
            <span class="am-pm" [style.font-size]="periodFontSize">{{ shiftType.shiftStartAMPM }}</span>
            <span class="start-end-divider"> - </span>
            <span class="shift-hours">{{ shiftType.shiftEndHour }}</span>
            <span class="am-pm" [style.font-size]="periodFontSize">{{ shiftType.shiftEndAMPM }}</span>
        </span>
    </ng-container>
</ng-template>

<ng-template #shiftTimeTemplate>
    <ng-container *ngIf="startTime && endTime">
        <span [style.font-size]="timeFontSize">
            <span class="shift-hours">{{ shiftStartTime | date: 'h:mm' }}</span>
            <span class="am-pm" [style.font-size]="periodFontSize">{{ shiftStartTime | date: 'a' }}</span>
            <span class="start-end-divider"> - </span>
            <span class="shift-hours">{{ shiftEndTime | date: 'h:mm' }}</span>
            <span class="am-pm" [style.font-size]="periodFontSize">{{ shiftEndTime | date: 'a' }}</span>
        </span>
    </ng-container>
</ng-template>