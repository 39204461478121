import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { MobileLoginRoutingModule } from './mobile-login-routing.module';
import { MobileLoginComponent } from './mobile-login.component';
import { BrowserWarningModule } from '../shared/componets/browser-warning/browser-warning.module';
import { AccountPermissionDeniedComponent } from './account-permission-denied/account-permission-denied.component';
import { MultipleFacilityModule } from './mutiple-facility/multiple-facility.module';

@NgModule({
  declarations: [
    MobileLoginComponent,
    AccountPermissionDeniedComponent
  ],
  exports: [
    MobileLoginComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    MobileLoginRoutingModule,
    BrowserWarningModule,
    MultipleFacilityModule
  ]
})
export class MobileLoginModule { }
