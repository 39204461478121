import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmCartSidebarComponent } from './confirm-cart-sidebar.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { MaterialModule } from '../../../../../shared/material.module';
import { Alert12HourNotificationModule } from 'src/app/shared/componets/alert12-hour-notification/alert12-hour-notification.module';
import { ConfirmCartMobileModule } from './components/confirm-cart-mobile/confirm-cart-mobile.module';



@NgModule({
  declarations: [ConfirmCartSidebarComponent],
  exports: [ConfirmCartSidebarComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    Alert12HourNotificationModule,
    ConfirmCartMobileModule

  ]
})
export class ConfirmCartSidebarModule { }
