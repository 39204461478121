import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Subject, zip, Observable } from 'rxjs';
import { mergeMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { Shift } from 'src/app/models/shift-management-models/shift';
import { ShiftDefs } from 'src/app/models/shift-management-models/ShiftDefs';
import { UnitInfo } from 'src/app/models/shift-management-models/UnitInfo';
import { ShiftsService } from 'src/app/services/apis/shifts.service';
import { FacilityShiftType } from 'src/app/services/shift-types/models/facility-shift-type.model';
import { ShiftTypesService } from 'src/app/services/shift-types/shift-types.service';
import { ShiftManagementSidebarService } from 'src/app/shift-management/shift-management-sidebar.service';

@Component({
  selector: 'app-create-shifts-spreadsheet',
  templateUrl: './create-shifts-spreadsheet.component.html',
  styleUrls: ['./create-shifts-spreadsheet.component.scss']
})
export class CreateShiftsSpreadsheetComponent implements OnInit {
  @Input() selectedRow: any;
  unitInfo: UnitInfo[] = [];
  shiftTDefs: ShiftDefs[] = [];
  staffTypeData: FacilityShiftType[] = [];
  condition: boolean = false;
  createShiftForm: FormGroup;
  unsubscribe: Subject<void> = new Subject();
  hours: number;
  minutes: number;
  shiftsToBeCreate = [];
  datesSelected = [];
  overlappingDates = [];
  existingShifts: Shift[] = [];
  rowDetails;
  allOverlap: boolean;
  selectedShift: string;
  datesToCompare: Shift[] = [];
  navigatedMonths: string[] = [];
  loading: boolean;
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;

  //Function which will generate a function that will return a type of MatCalendarCellCssClasses,
  // which can be as simple as a string representing the CSS class to apply
  dateClass = (date) => {
    if (this._findDate(date, this.datesSelected) !== -1 && this._findDate(date, this.overlappingDates) === -1) {
      return this.isTodaysDate(date) ? 'today' : 'selected';
    }
    if (this.createShiftForm.status === "VALID" && this._findDate(date, this.overlappingDates) !== -1) {
      return 'overlapping';
    }
    return '';
  };

  overlappingDays = (date): boolean => {
    return !(this.createShiftForm.status === "VALID" && this._findDate(date, this.overlappingDates) !== -1);
  };

  constructor (
    private _shiftsService: ShiftsService,
    private _shiftManagementSidebar: ShiftManagementSidebarService,
    private shiftTypesService: ShiftTypesService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createForm() {
    const location = this.selectedRow['selectedDates'] ? this.selectedRow.unit.id : '';
    const shiftTime = this.selectedRow['selectedDates'] ? this.selectedRow.shift_time : '';

    this.createShiftForm = new FormGroup({
      shiftTime: new FormControl(shiftTime, Validators.required),
      location: new FormControl(location, Validators.required)
    });
  }

  get shiftTime(): FormControl {
    return this.createShiftForm?.get('shiftTime') as FormControl;
  }

  get location(): FormControl {
    return this.createShiftForm?.get('location') as FormControl;
  }

  getMinDate(): string {
    return formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  }

  initializeDropDowns(): Observable<any> {
    return zip(
      this.getUnits(),
      this.getShiftTypes(),
      this.getStaffTypes()
    );
  }

  getUnits(): Observable<UnitInfo[]> {
    return this._shiftsService.getUnits().pipe(
      tap((units: UnitInfo[]) => this.unitInfo = units)
    );
  }

  getShiftTypes(): Observable<ShiftDefs[]> {
    return this._shiftsService.getShiftDefs().pipe(
      tap((shiftDefs: ShiftDefs[]) => {
        this.shiftTDefs = shiftDefs;
      })
    );
  }

  calculateHours(startTime: string, endTime: string, toreturn: boolean): string {
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const start = moment(today.format('yyyy-MM-DD') + ' ' + startTime).toDate().getTime();
    let end = moment(today.format('yyyy-MM-DD') + ' ' + endTime).toDate().getTime();
    if (end <= start) {
      end = moment(end).add(1, 'days').toDate().getTime();
    }
    const seconds = Math.floor((end - start) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    if (toreturn) {
      return '<div class="position-text"><span class="number">' + hours
        + '</span><span class="letter">H  &nbsp; </span><span class="number">'
        + minutes + '</span><span class="letter">MIN</span></div>';
    } else {
      this.hours = hours;
      this.minutes = minutes;
      if (this.createShiftForm.status === "VALID") {
        this.shiftsToBeCreate = [];
        this.checkOverlapingShifts();
      }
      return 'true';
    }
  }

  closeDrawer(): void {
    this._shiftManagementSidebar.closePanel();
  }

  onSelect(event) {
    if (!this.loading) {
      const month = moment.utc(event).format('YYYY-MM-01');
      if (this.navigatedMonths.findIndex(date => date === month) === -1) {
        this.navigatedMonths.push(month);
        const monthList = [...this.navigatedMonths];
        monthList.sort((s1, s2) => this.compare(s1, s2));
        this.getStaffShifts(monthList[0], this.selectedRow.staffId).pipe(
          takeUntil(this.unsubscribe),
        ).subscribe();
      }

      const index = this._findDate(event, this.datesSelected);
      if (index === -1) {
        this.datesSelected.push(event);
      } else {
        this.datesSelected.splice(index, 1);
      }
      (this.calendar.monthView as any)._createWeekCells();
      if (this.createShiftForm.status === "VALID") {
        this.shiftsToBeCreate = [];
        this.checkOverlapingShifts();
      }
    }

  }

  private _findDate(event, dates) {
    return dates.findIndex(dateSelected => dateSelected.isSame(event, 'day'));
  }

  isTodaysDate(date) {
    return moment.utc(date).isSame(new Date(), 'day');
  }

  getStaffTypes(): Observable<FacilityShiftType[]> {
    return this.shiftTypesService.getShiftTypes().pipe(
      mergeMap((staffTypes: FacilityShiftType[]) => staffTypes),
      toArray(),
      tap((staffTypes: FacilityShiftType[]) => this.staffTypeData = staffTypes)
    );
  }

  navigateToConfirm() {
    if (!this.selectedRow || this.shiftsToBeCreate.length === 0) {
      this.shiftsToBeCreate = [];
      this.checkOverlapingShifts();
    }
    this.rowDetails.months = this.navigatedMonths;
    this._shiftManagementSidebar.openConfirmShiftSpreadsheet(this.rowDetails, this.shiftsToBeCreate);
  }

  locationSelected() {
    if (this.createShiftForm.status === "VALID") {
      this.shiftsToBeCreate = [];
      this.checkOverlapingShifts();
    }
  }


  checkOverlapingShifts() {
    this.rowDetails = this.selectedRow;
    const shiftDefid = this.createShiftForm.value.shiftTime?.shiftDefId;
    const location = this.createShiftForm.get('location').value;
    const selectedShift = this.shiftTDefs.find(shift => shift?.shiftDefId === shiftDefid);
    const startTime = this.convertTimeFormat(null, null, null, selectedShift?.startTime);
    const endTime = this.convertTimeFormat(null, null, null, selectedShift?.endTime);
    this.rowDetails.shift_time = selectedShift;
    this.rowDetails.unit = this.unitInfo.find(unit => unit.id === location);
    this.datesSelected.sort((s1, s2) => this.compare(formatDate(s1, 'yyyy-MM-dd', 'en-US'), formatDate(s2, 'yyyy-MM-dd', 'en-US')));
    this.getDates();
    this.datesSelected.forEach(date => {
      const newShift = {
        shiftDate: date,
        shiftTypeId: shiftDefid,
        unitId: location,
        staffId: this.rowDetails.staffId,
        overlapping: this.overlappingShifts({ shiftDate: formatDate(date, "yyyy-MM-dd", 'en-US'), shiftTime: selectedShift }),
        startHour: startTime.hours,
        startMin: startTime.minutes,
        endHour: endTime.hours,
        endMin: endTime.minutes,
        staffTypeId: this.rowDetails.staffTypeId,
      };
      this.shiftsToBeCreate.push(newShift);
    });
    this.allOverlap = this.selectedRow.allOverlap ? this.shiftsToBeCreate.every(shift => shift.overlapping) : this.allOverlap;
    this.overlappingDates = this.shiftsToBeCreate.filter(shift => shift.overlapping).map(date => date.shiftDate);;
    this.condition = this.selectedRow['selectedDates'] ? this.datesSelected.length === this.overlappingDates.length : false;

  }

  overlappingShifts(shiftCreate: { shiftDate, shiftTime; }) {
    const startTime = shiftCreate.shiftTime.startTime + ":00";
    const endTime = shiftCreate.shiftTime.endTime + ":00";
    const overlappingShift = this.datesToCompare.find(shift => shift.shiftDate == shiftCreate.shiftDate);
    return !!overlappingShift && this.check_time_overlap(startTime, endTime, overlappingShift.startTime, overlappingShift.endTime);
  }

  compare(a: string, b: string): number {
    return (a < b ? -1 : 1);
  }

  getDates() {
    this.datesToCompare = this.existingShifts.filter(shift => this.datesSelected.findIndex(date => formatDate(date, 'yyyy-MM-dd', 'en-US') === shift.shiftDate) > -1);
  }

  check_time_overlap(createStart, createEnd, overlapStart, overlapEnd) {
    const isTimeBetween = function (startTime, endTime, serverTime) {
      let start = moment(startTime, "H:mm")
      let end = moment(endTime, "H:mm")
      let server = moment(serverTime, "H:mm")
      if (end < start) {
        return (server > start && server <= moment('23:59:59', "h:mm:ss")) || (server >= moment('0:00:00', "h:mm:ss") && server < end);
      } else {
        return (server > start) && (server < end);
      }
    }
    return ((createStart < overlapEnd && overlapStart < createEnd)
      || ((isTimeBetween(overlapStart, overlapEnd, createStart) || isTimeBetween(overlapStart, overlapEnd, createEnd)) || (createStart === overlapStart && createEnd === overlapEnd))
      || (isTimeBetween(createStart, createEnd, overlapStart) || isTimeBetween(createStart, createEnd, overlapEnd)));
  }


  convertTimeFormat(hour?: number, minute?: number, ampm?: boolean, time?: string): { hours: number, minutes: number; } {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      const minutes = Number(time.match(/:(\d+)/)[1]);
      return { hours: hours, minutes: minutes };
    } else {
      if (ampm && hour < 12) { hour = +hour + 12; }
      if (!ampm && hour == 12) { hour -= 12; }
      return { hours: hour, minutes: minute };
    }
  }

  getStaffShifts(startDate: string, staffId: number) {
    return this._shiftsService.getShifts({ startDate: startDate, staffID: staffId })
      .pipe(
        takeUntil(this.unsubscribe),
        tap(shifts => this.existingShifts = shifts.data.shifts)
      );
  }

  getData() {
    this.loading = true;
    this.navigatedMonths = this.selectedRow.months ? this.selectedRow.months : this.navigatedMonths;
    this.datesSelected = this.selectedRow['selectedDates'] ? this.selectedRow['selectedDates'] : this.datesSelected;
    this.overlappingDates = this.selectedRow['overlapping'] ? this.selectedRow['overlapping'] : this.overlappingDates;
    this.allOverlap = this.selectedRow.allOverlap ? this.selectedRow.allOverlap : this.allOverlap;
    this.condition = this.selectedRow['selectedDates'] ? this.datesSelected.length === this.overlappingDates.length : false;
    const monthList = [...this.navigatedMonths];
    monthList.sort((s1, s2) => this.compare(s1, s2));
    const startDate = monthList.length !== 0 ? monthList[0] : new Date();
    this.createForm();
    zip(
      this.initializeDropDowns(),
      this.getStaffShifts(formatDate(startDate, 'yyyy-MM-dd', 'en-US'), this.selectedRow.staffId)
    ).subscribe(resp => {
      const shiftTime = this.selectedRow['selectedDates'] ? this.selectedRow.shift_time : '';
      this.selectedShift = shiftTime?.shiftName;
      this.calculateHours(shiftTime.startTime, shiftTime.endTime, false);
      this.loading = false;
    });
  }
}