import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  datesSelected: number;
}
@Component({
  selector: 'app-multiple-shifts-confirmation',
  templateUrl: './multiple-shifts-confirmation.component.html',
  styleUrls: ['./multiple-shifts-confirmation.component.scss']
})
export class MultipleShiftsConfirmationComponent implements OnInit {
  @Output() createShifts: EventEmitter<boolean> = new EventEmitter();
  shiftsCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.shiftsCount = this.data.datesSelected;
  }

  createBulkShifts(value: boolean): void {
    this.createShifts.emit(value);
  }
}
