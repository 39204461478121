import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staffType'
})
export class StaffTypePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value.toLowerCase().indexOf('nurse') > -1 ? value.split(' ')[1] : value;
  }

}
