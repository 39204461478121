import { Pipe, PipeTransform } from '@angular/core';
import { ConvertersService } from '../../services/utils/converters.service';

@Pipe({
  name: 'toShiftHours'
})
export class ToShiftHoursPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    return ConvertersService._datesToShiftHours({ startTime: value, endTime: args });
  }

}
