import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ShiftManagementSidebarService } from './shift-management-sidebar.service';
import { takeUntil } from 'rxjs/operators';
import { Shift } from '../models/shift-management-models/shift';
import { StaffMember } from '../models/shift-management-models/StaffMember.model';
import { ShiftDefs } from '../models/shift-management-models/ShiftDefs';
import { UnitInfo } from '../models/shift-management-models/UnitInfo';
import { CreateShift } from '../models/java-models';
/**
 * ShiftManagementComponent manages showing different content in the right sidebar depending on some conditions.
 */

@Component({
  selector: 'app-shift-management',
  templateUrl: './shift-management.component.html',
  styleUrls: ['./shift-management.component.scss']
})
export class ShiftManagementComponent implements OnInit, OnDestroy {

  public TYPE_SHIFT_DETAILS = 'shiftDetails';
  public TYPE_FILL_SHIFT = 'fillShift';
  public TYPE_FILL_SHIFT_RESPONSES = 'fillShiftResponses';
  public TYPE_SHIFTS_TO_FILL = 'shiftsToFill';
  public TYPE_REQUESTS = 'requests';
  public TYPE_REQUEST_INTELYPRO = 'requestIntelypro';
  public TYPE_CREATE_SHIFT = 'createShift';
  public TYPE_SPREADSHEET_CREATE_SHIFT = 'spreadsheetCreateShift';
  public TYPE_SPREADSHEET_CONFIRM_SHIFT = 'spreadsheetConfirmShift';

  public shiftManagementState: {
    shiftDetails?: Shift;
    staffMember?: StaffMember,
    requests?: any,
    replacing?: StaffMember,
    returnTo?: 'requests' | 'allShifts',
    fillShiftTab?: 'search' | 'responses' | 'addStaff',
    viewType: string,
    shiftTime?: ShiftDefs,
    location?: UnitInfo,
    spreadsheetData?: any,
    shiftsToCreateSpreadsheet?: CreateShift[];
  };
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private _shiftManagementService: ShiftManagementSidebarService) {
  }

  ngOnInit(): void {
    // gets shift management configuration
    this._shiftManagementService.shiftChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(e => this.shiftManagementState = e);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
