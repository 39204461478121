<div class="individual-staff-wrapper" id="add-staff">
  <div class="content">
    <form 
     [formGroup]="staffMemberForm"
     class="main-container-wrapper">
        <h6>staff profile</h6> 
        <mat-form-field 
          appearance="outline" 
          class="mat-form-text">
          <mat-label>First Name</mat-label>
          <input 
           maxlength="15"
           formControlName="name"
           matInput
           placeholder="First Name"
           automation-id="first-name"
           required>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="mat-form-text">
          <mat-label>Last Name</mat-label>
          <input 
           maxlength="15"
           formControlName="surname"
           matInput
           placeholder="Last Name"
           automation-id="last-name"
           required>
        </mat-form-field>

        <mat-form-field
         appearance="outline"
         class="mat-form-text-w24">
          <mat-label>Mobile Number</mat-label>
          <input 
           [dropSpecialCharacters]="true" 
           [validation]="true"
           formControlName="phone"
           matInput
           placeholder="Mobile Number"
           mask='(000) 000-0000'
           automation-id="mobile-number"
           required>
        </mat-form-field>

        <mat-form-field 
         appearance="outline" 
         class="mat-form-text-w35">
          <mat-label>Staff Type</mat-label>
          <mat-select
           formControlName="staffType"
           automation-id="staff-type" 
           required>
            <mat-option 
             *ngFor="let staffType of staffTypes" 
             [value]="staffType.staffTypeId"
             [attr.automation-id]="'staff-type-options-individual-staff-form-' + staffType.staffTypeName">
             {{ staffType.staffTypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <h6>general</h6> 

        <mat-form-field 
         appearance="outline" 
         class="mat-form-text-w35">
          <mat-label>Hire Date</mat-label>
          <input 
           matInput 
           formControlName="hireDate" 
           placeholder="Choose a date" 
           [matDatepicker]="picker"
           (click)="picker.open()" 
           (keypress)="picker.open()" 
           class="custom-date-input"
           automation-id="hire-date">
          <mat-datepicker-toggle 
            matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>                 

        <mat-form-field 
         appearance="outline"
         class="mat-form-text-w24">
          <mat-label>Employment Status</mat-label>
          <mat-select 
           formControlName="employmentType"
           automation-id="employment-type">
            <mat-option 
             *ngFor="let employmentType of employmentTypes"
             [value]="employmentType.employmentTypeId"
             [attr.automation-id]="'employement-status-options-individual-staff-form-' + _staffManagementService.getAutomationIdSplitted(employmentType.employmentTypeName)">
             {{ employmentType.employmentTypeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field 
         appearance="outline"
         class="mat-form-text-w24"
         automation-id="gender">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option 
             *ngFor="let gender of genderTypes" 
             [value]="gender.id"
             [attr.automation-id]="'gender-options-individual-staff-form-' + gender.name">
             {{ gender.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>         
      
    </form>
 </div>

  <div class="actions">
    <button 
     mat-button
     class="cancel-button"
     (click)="closeSidebar()"
     automation-id="cancel-button">
      <label class="button-label">Cancel</label>
    </button>
    <button  
     mat-button
     [disabled]="!isValidUser() || processing"
     class="staff-button"       
     (click)="saveUser()"
     automation-id="add-staff">
      <label class="button-label">
        <i class="fas fa-user-plus"></i>
        Add Staff
      </label>
    </button>
  </div>
</div>
