import { Component, Input, OnInit } from '@angular/core';
import { CartData } from 'src/app/monthly-view/components/bulk-shift-booking/models/cart-data';

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss']
})
export class CartDetailsComponent implements OnInit {

  @Input() item: CartData;
  @Input() panelTitle: boolean = false;
  @Input() isToggel: boolean = false;
  @Input() day: string;

  constructor() { }

  ngOnInit(): void {
  }

}
