import * as moment from 'moment';

export class TimezoneHandling {

  /**
   * Convert given date and time, from given timezone, to local date and time
   * @param givenDate: Date and time of a specific timezone
   * @param timezone: Timezone of the given date to convert
   * @constructor
   */
  static ConvertToLocalDateTime(givenDate: Date, timezone = 'America/New_York'): Date {
    return TimezoneHandling._ConvertUTCToLocal(TimezoneHandling._ConvertToUTC(givenDate, timezone));
  }

  /**
   * Convert given shift time, from a given timezone, to local timezone
   * @param shiftTime: Given shift time to convert to local shift time (format "h:mm A - h:mm A")
   * @param date: Given date on format "YYYY-MM-DD"
   * @param timezone: Given timezone of shift times to convert
   * @constructor
   */
  static ConvertShiftTimeToLocal(shiftTime?: string, date: string = moment().format('YYYY-MM-DD'), timezone = 'America/New_York'): string {
    const shiftTimeArray: string[] = !!shiftTime ? shiftTime.split('-') : [];
    if (shiftTimeArray.length === 2) {
      const startDate = TimezoneHandling._ConvertTimeToDateTime(shiftTimeArray[0].trim(), date);
      const endDate = TimezoneHandling._ConvertTimeToDateTime(shiftTimeArray[1].trim(), date);
      let endDateString = date;

      if (endDate.getTime() < startDate.getTime()) {
        endDateString = moment(date).add(1, 'days').format('YYYY-MM-DD');
      }

      shiftTime = TimezoneHandling.ConvertTimeToLocal(shiftTimeArray[0].trim(), date, timezone) + ' - ' +
        TimezoneHandling.ConvertTimeToLocal(shiftTimeArray[1].trim(), endDateString, timezone);
    }
    return shiftTime;
  }

  /**
   * Convert given time, from a given timezone, to local timezone
   * @param givenTime: Given time to convert to local time (format "h:mm A")
   * @param date: Given date on format "YYYY-MM-DD"
   * @param timezone: Given timezone of time to convert
   * @constructor
   */
  static ConvertTimeToLocal(givenTime?: string, date: string = moment().format('YYYY-MM-DD'), timezone = 'America/New_York'): string {
    return !!givenTime ? moment(TimezoneHandling.ConvertToLocalDateTime(TimezoneHandling._ConvertTimeToDateTime(givenTime, date), timezone))
      .format('h:mm A') : null;
  }

  /**
   * Convert given Date to UTC
   * @param date: Given date of a specific timezone
   * @param timezone: Timezone given for the Date to convert
   * @private
   */
  private static _ConvertToUTC(date: Date, timezone): Date {
    const utcDate = new Date().toLocaleString('en-US', { timeZone: 'UTC' });
    const givenDate = new Date().toLocaleString('en-US', { timeZone: timezone });
    const diff = (moment(utcDate).toDate().getTime() - moment(givenDate).toDate().getTime()) / (1000 * 3600);
    return moment(date).add(diff, 'hours').toDate();
  }

  /**
   * Convert UTC date to local Date
   * @param date: Date given in UTC
   * @private
   */
  private static _ConvertUTCToLocal(date) {
    const offset = date.getTimezoneOffset() / 60;
    return moment(date).subtract(offset, 'hours').toDate();
  }

  /**
   * Convert given time to Date and Time of today
   * @param time: Given time on format "h:mm A"
   * @param date: Given date on format "YYYY-MM-DD"
   * @private
   */
  private static _ConvertTimeToDateTime(time: string, date: string = moment().format('YYYY-MM-DD')): Date {
    return moment(date + ' ' + time).toDate();
  }

}
