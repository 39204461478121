import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AllStaff } from '../models/AllStaff';
import { FuseSidebarService } from '@theme/components/sidebar/sidebar.service';
import { FuseSidebarComponent } from '@theme/components/sidebar/sidebar.component';
import { ShiftType } from '../models/ShiftType';
import { Days } from '../models/Days';
import { Rating } from '../models/Rating';
import { ShiftsService } from '../services/apis/shifts.service';
import { map } from 'rxjs/operators';
import { StaffJavaService } from '../services/apis/staff-java.service';
/**
 * StaffModuleManagementService gets staff information from database .
 * Manages the open and close of staff sidebars.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class StaffModuleManagementService {

  public static ADD_STAFF = 'addStaff';
  public staffChange: Subject<{ staffMember: AllStaff, viewType: string, shifts: ShiftType[], days: Days[] }> = new Subject();
  public updateStaffTable: Subject<boolean> = new Subject();
  public updateRow: Subject<AllStaff> = new Subject();
  public cleared: Subject<boolean> = new Subject();
  public profileClicked: Subject<AllStaff> = new Subject();
  public totalStaffNumber: Subject<number> = new Subject();
  private _sidebarName = 'staffManagement';
  private _sidebar: FuseSidebarComponent;

  constructor(
    private _http: HttpClient,
    private _shiftJavaService: ShiftsService,
    private _staffJavaService: StaffJavaService,
    private _sidebareService: FuseSidebarService
  ) { }

  getUpdatedRow(): Observable<AllStaff> {
    return this.updateRow.asObservable();
  }

  getTotalStaffMembersNumber(): Observable<number> {
    return this.totalStaffNumber.asObservable();
  }

  getClickedProfile(): Observable<AllStaff> {
    return this.profileClicked.asObservable();
  }

  clearAll(): Observable<boolean> {
    return this.cleared.asObservable();
  }
  // opens the panel when clicking add staff
  openAddStaffPanel(shifts: ShiftType[], days: Days[]) {
    this._openIfClosed();
    this.staffChange.next({ staffMember: null, viewType: StaffModuleManagementService.ADD_STAFF, shifts: shifts, days: days });
  }

  /**
   * Get's current week number.
   */
  getWeekNumber(date: string): Observable<number> {
    return this._shiftJavaService.getWeekNumber(date).pipe(
      map(resp => resp.data)
    );
  }
  _getStaff() {
    return this._staffJavaService.getAllStaffFiltered({ startDate: '', endDate: '', agencyStaff: false, myStaff: true, onlyActive: false })
      .pipe(map(resp => resp.data));
  }

  closePanel() {
    if (this._sidebar.opened) {
      this._sidebareService.getSidebar(this._sidebarName).toggleOpen();
    }
    this.staffChange.next(null);
  }

  private _openIfClosed() {
    if (!this._sidebar) {
      this._sidebar = this._sidebareService.getSidebar(this._sidebarName);
      this._sidebar.openedChanged.subscribe(e => {
        if (!e) {
          this.closePanel();
        }
      });
    }
    if (!this._sidebar.opened) {
      this._sidebar.toggleOpen();
    }
  }
}
