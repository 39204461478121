import { Injectable } from '@angular/core';
import { UtilsService } from './utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseResponseModel } from '../models/BaseResponseModel';
import * as jwt_decode from 'jwt-decode';
import { UserPermissionsData } from '../models/newModels/UserPermissionsData';
import { UserPayload } from '../models/shift-management-models/UserPayload';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ManageUsersAccountService } from './manage-users-account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private _baseUri = UtilsService.getAuthorizationBaseUri();

  constructor(
    private _http: HttpClient,
    private _cookie: CookieService,
    private _manageAccountService: ManageUsersAccountService
  ) { }

  getUserAccessGroups(): Observable<BaseResponseModel<UserPermissionsData[]>> {
    return of<BaseResponseModel<UserPermissionsData[]>>({
      code: '200',
      message: 'Success',
      data: [{
        useraccessgroupid: 1,
        name: 'Modify Schedule',
        clientid: 0
      }, {
        useraccessgroupid: 2,
        name: 'Billing Access',
        clientid: 0
      }, {
        useraccessgroupid: 3,
        name: 'Approve Shift Boost / Overtime',
        clientid: 0
      }]
    });
  }

  linkSchedulingToHub(): Observable<BaseResponseModel<UserPayload>> {
    return this._http.post<BaseResponseModel<UserPayload>>(`${this._baseUri}` + '/client/linkschedulingtohub', {})
      .pipe(
        tap(e => {
          const jd = moment(jwt_decode(e.data.jwtString).jwtcreated).add(1, 'days').toDate();
          this._cookie.set('IntelycareAuthCookie', e.data.jwtString, jd, '/');
          this._manageAccountService.userLoggedInSubject.next(true);
        })
      );
  }
}
