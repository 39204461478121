<div class="add-new-staff" fxFlex fxLayout="column">

  <div class="add-new-staff-content">

    <form [formGroup]="addStaffForm" fxLayout="column">

      <div class="names" fxFlex fxLayout="row" fxLayoutGap="16px">

        <mat-form-field fxFlex [appearance]="'outline'">
          <input #name matInput [formControlName]="'firstName'">
          <mat-label>First Name *</mat-label>
        </mat-form-field>

        <mat-form-field fxFlex [appearance]="'outline'">
          <input #lastname matInput [formControlName]="'lastName'">
          <mat-label>Last Name *</mat-label>
        </mat-form-field>

      </div>

      <mat-form-field fxFlex [appearance]="'outline'">
        <input #agency matInput [formControlName]="'agencyName'">
        <mat-label>Agency Name *</mat-label>
      </mat-form-field>

      <mat-form-field fxFlex [appearance]="'outline'">
        <input matInput mask='(000) 000-0000' [formControlName]="'phoneNumber'">
        <mat-label>Phone Number</mat-label>
      </mat-form-field>

    </form>

  </div>

  <div class="add-new-staff-actions" fxFlex="1 1 auto" fxLayoutAlign="center center">
    <button mat-button class="action-button" [ngClass]="addStaffForm.valid ? 'complete' : 'disabled'"
            [disabled]="!addStaffForm.valid" (click)="addStaff()">
      <mat-icon>assignment_ind</mat-icon>
      Assign to this shift
    </button>
  </div>
</div>
