<div class="staff-card-confirmation">
  <mat-expansion-panel [expanded]="expanded" [disabled]="true">

    <mat-expansion-panel-header [collapsedHeight]="'72px'" [expandedHeight]="'72px'"
                                [ngClass]="{selectable: response === 'accepted'}">
      <div class="staff-card-confirmation-container" fxFlex fxLayout="row"
           fxLayoutAlign="start center">

        <div class="staff-card-confirmation-actions" *ngIf="response === 'accepted'">
          <mat-radio-button (change)="selectionChange.next(request)"></mat-radio-button>
        </div>

        <div class="staff-card-confirmation-chip">
          <app-chip-icon [name]="staffMember ? staffMember.firstName + ' ' + staffMember.lastName : ''"
                         [customIcon]="true" [iconMN]="'intelypro'"></app-chip-icon>
        </div>

        <div class="staff-card-confirmation-content" fxFlex="1 1 auto" fxLayout="column">
          <span class="bold-item title">{{staffMember? (staffMember.firstName + ' ' + staffMember.lastName) : 'IntelyPro'}}</span>

          <div class="info" fxLayout="row" *ngIf="!!staffMember">
            <span class="subtitle">{{staffMember.abbreviation | staffType}}</span>
            <mat-icon class="separator">fiber_manual_record</mat-icon>
            <span class="subtitle">
                {{staffMember.employmentTypeName | employmentType}}</span>
              <mat-icon class="separator">fiber_manual_record</mat-icon>
              <span class="subtitle">{{ staffMember.hoursWorkedPerWeek}} 
                <span class="time">H </span> &nbsp; {{staffMember.minutesWorkedPerWeek}} 
                <span class="time"> MIN SCHEDULED</span>
               </span>
          </div>

        </div>

        <div class="icon-indicator" fxLayoutAlign="center center" *ngIf="!!staffMember">
          <button mat-icon-button>
            <mat-icon *ngIf="!expanded" (click)="$event.stopPropagation();expanded = !expanded">
              keyboard_arrow_down
            </mat-icon>
            <mat-icon *ngIf="expanded" (click)="$event.stopPropagation();expanded = !expanded">
              keyboard_arrow_up
            </mat-icon>
          </button>
        </div>

      </div>
    </mat-expansion-panel-header>

    <div class="expansion-panel-body" fxFlex fxLayout="column" *ngIf="!!staffMember" [ngClass]="{selectable: control}">


      <div class="user-info" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
        <span class="user-info-title">Gender</span>
        <span class="user-info-subtitle">{{staffMember.gender | titlecase}}</span>
      </div>

      <div class="user-info" fxLayout="row" fxLayoutGap="12px">
        <span class="user-info-title">Hire Date</span>
        <span class="user-info-subtitle">{{staffMember.hireDate | date : 'M/dd/yyyy'}}</span>
      </div>

      <div class="user-info last" fxLayout="row" fxLayoutGap="12px">
        <span class="user-info-title">Phone</span>
        <span class="user-info-subtitle">{{formatNumber(staffMember.phoneNumber)}}</span>
      </div>

    </div>

  </mat-expansion-panel>
</div>
