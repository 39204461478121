import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss']
})
export class RadioButtonsComponent implements OnInit {
  @Input() selectedOption;
  @Input() options;
  @Output() radioOptionChanged: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  eventFired(e) {
    this.selectedOption = e;
    this.selectedOption.checked = true;
    this.options.filter(option => option.value !== this.selectedOption.value)
      .map(option => option.checked = false);
    this.radioOptionChanged.emit(this.selectedOption)
  }
}
