import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseResponseModel } from '../models/BaseResponseModel';
import { Observable, throwError } from 'rxjs';
import { StaffData } from '../models/newModels/StaffData';
import { AllStaff } from '../models/AllStaff';
import { formatDate } from '@angular/common';
import { StaffTypeData } from '../models/newModels/StaffTypeData';
import { UnitData } from '../models/newModels/UnitData';
import { UtilsService } from './utils/utils.service';
import { environment } from '../../environments/environment';
import { EmergencyContact } from '../models/EmergencyContact';
import { Reference } from '../models/Reference';
import { ReferenceStatus } from '../models/ReferenceStatus';
import { GenderPreference } from '../models/newModels/GenderPreference';
import { ManageUsersAccountService } from './manage-users-account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, catchError } from 'rxjs/operators';
/**
 * StaffService manages staff operatios (post,get) in database.
 */

const httpOptions = {
  observe: 'response' as 'response'
};

const formUrlEncoded = {
  headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
};

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  private _baseUri = UtilsService.getStaffBaseUri();
  private _hideBaseUri = UtilsService.getShiftJavaUri();
  private _isystemBaseUri = UtilsService.getISystemBaseUri();
  private _authUrl = UtilsService.getAuthorizationBaseUri();
  private _backgroundCheckUri = UtilsService.getBackgroundCheckUri();
  private _request = {
    service: environment.SERVICE
  };
  constructor (private _http: HttpClient,
    private _userService: ManageUsersAccountService, private _snackbar: MatSnackBar) {
  }

  getStaff(userid: number): Observable<BaseResponseModel<StaffData>> {
    return this._http.post<BaseResponseModel<StaffData>>(`${this._baseUri}/get`, { ...this._request, userid });
  }

  getStaffData(staffId: number): Observable<BaseResponseModel<StaffData>> {
    const requestBody = {
      'staffid': staffId,
      'service': environment.SERVICE
    };

    return this._http.post<BaseResponseModel<StaffData>>(`${this._baseUri}/get`, requestBody);
  }

  saveStaffMember(staffMember: AllStaff, staffTypeNewModel: StaffTypeData, sendMessage = true): Observable<BaseResponseModel<{
    registrationlinkid: number,
    staffid: number
  }>> {
    const requestBody = {
      firstname: staffMember.firstName,
      lastname: staffMember.lastName,
      email: staffMember.email,
      gender: staffMember.gender ? staffMember.gender.name : '',
      phonenumber: staffMember.phone,
      stafftypeid: staffTypeNewModel?.stafftypeid,
      staffsubtypeid: 0,
      employmenttypeid: staffMember.employmentType ? staffMember.employmentType.employmentTypeId : (staffMember.agencyname ? 4 : null),
      hiredate: !staffMember.hireDate ? null : formatDate(staffMember.hireDate, 'yyyy-MM-dd', 'en-US'),
      agencyname: staffMember.agencyname,
      scheduler: staffMember.scheduler,
      sendmessage: sendMessage,
      roletypeid: staffMember.roleTypeId,
      useraccessgroups: staffMember.userPermissions
    };

    return this._http.post<BaseResponseModel<{ registrationlinkid: number, staffid: number }>>(`${this._baseUri}`, requestBody);
  }

  updateStaffMember(
    staffMember: AllStaff,
    staffTypeNewModel: StaffTypeData,
    addedUnits: UnitData[],
    removedUnits: UnitData[]): Observable<BaseResponseModel<any>> {
    const requestBody = {
      staffid: staffMember.id,
      firstname: staffMember.firstName,
      lastname: staffMember.lastName,
      email: staffMember.email,
      // 'ssn': staffMember.ssn,
      birthday: !staffMember.birthDate || staffMember.birthDate === '' ? null : formatDate(staffMember.birthDate, 'yyyy-MM-dd', 'en-US'),
      gender: staffMember.gender ? staffMember.gender.name : '',
      phonenumber: staffMember.phone,
      stafftypeid: staffTypeNewModel.stafftypeid,
      staffsubtypeid: staffTypeNewModel.staffsubtypeid,
      employmenttypeid: staffMember.employmentType ? staffMember.employmentType.employmentTypeId : null,
      addunits: [], // addedUnits,
      deleteunits: [], // removedUnits,
      notes: staffMember.notes,
      hiredate: !staffMember.hireDate || staffMember.hireDate === '' ? null : formatDate(staffMember.hireDate, 'yyyy-MM-dd', 'en-US'),
      service: environment.SERVICE,
      roletypeid: staffMember.roleTypeId,
      useraccessgroups: staffMember.userPermissions,
      admin: staffMember.admin
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/modify`, requestBody);
  }

  generalStaffUpdate(requestObj: any): Observable<BaseResponseModel<any>> {
    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/modify`, requestObj);
  }
  updateUserTypeRoles(requestObj: any): Observable<BaseResponseModel<any>> {
    return this._http.put<BaseResponseModel<any>>(`${this._authUrl}/user/usertousertype/`, requestObj);
  }
  updateHiddenFlagOnStaff(staffId: number, hidden: boolean = true) {
    const requestBody = {
      staffId: staffId,
      hidden: hidden
    };

    return this._http.post<BaseResponseModel<any>>(`${this._hideBaseUri}/staff/hidden`, [requestBody]);
  }

  removeStaffMember(id: number): any {
    const requestBody = {
      'staffid': id,
      'service': environment.SERVICE
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/remove`, requestBody);
  }

  removeAgencyStaffMember(id: number): Observable<BaseResponseModel<any>> {
    return this._http.post<BaseResponseModel<any>>(`${this._hideBaseUri}/staff/remove?staffid=${id}`, {});
  }

  sendSchedulerEmail(userId: number): any {
    const requestBody = {
      userid: userId
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/sendscheduleremail`, requestBody);
  }

  sendMassRegistrationLinks(): Observable<BaseResponseModel<any>> {
    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/sendregistrationlink/mass`, {});
  }

  sendRegistrationLink(id: number): any {
    const requestBody = {
      'staffid': id.toString()
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/sendregistrationlink`, requestBody);
  }

  getReferenceStatusTypes(): Observable<ReferenceStatus[]> {
    return this._http.get<ReferenceStatus[]>('./assets/random-data/referenceType.json');
  }

  setContacts(id: number, contacts: EmergencyContact[]): Observable<BaseResponseModel<any>> {
    const requestBody = {
      staffid: id,
      contacts: contacts.map(function (item) {
        return {
          contactid: item.id,
          firstname: item.firstname,
          lastname: item.lastname,
          phonenumber: item.phone,
          contacttypeid: 1,
          relationship: item.relationship
        };
      })
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/setcontacts`, requestBody);
  }

  removeContact(id: number, active = false): Observable<BaseResponseModel<any>> {
    const requestBody = {
      contactid: id,
      active: active
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/contact/remove`, requestBody);
  }

  setReferences(id: number, references: Reference[]): Observable<BaseResponseModel<any>> {
    const requestBody = {
      staffid: id,
      references: references.map(function (item) {
        return {
          referenceid: item.id,
          firstname: item.firstname,
          lastname: item.lastname,
          phonenumber: item.phone,
          status: item.status ? item.status.name : null,
          referenceagency: item.facility
        };
      })
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/setcontacts`, requestBody);
  }

  removeReference(id: number, active = false): Observable<BaseResponseModel<any>> {
    const requestBody = {
      referenceid: id,
      active: active
    };

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/reference/remove`, requestBody);
  }

  isClientOnHold() {
    return this._http.get<BaseResponseModel<{ onhold: boolean }>>(this._baseUri + '/clientonhold', httpOptions);
  }

  isClientOnHoldISystem() {
    const requestBody = new URLSearchParams();
    requestBody.set('cid', String(this._userService.currentUserValue?.activeusertype?.clientid));
    requestBody.set('section', 'Balance');
    requestBody.set('uid', String(this._userService.currentUserValue?.userid));
    requestBody.set('hash', '');

    return this._http.post<any>(this._isystemBaseUri + '/pages/partcare.php', String(requestBody), formUrlEncoded);
  }

  getGenderPreferences(): Observable<BaseResponseModel<GenderPreference>> {
    return this._http.post<BaseResponseModel<GenderPreference>>(this._isystemBaseUri + '/client/getClientGenderPreferences.php',
      { cid: this._userService.currentUserValue.activeusertype.clientid });
  }

  getIsystemNurseTypes() {
    const fd = new FormData();
    fd.append('Section', 'Coreid');
    fd.append('cid', String(this._userService.currentUserValue.activeusertype.clientid));
    fd.append('uid', String(this._userService.currentUserValue.userid));
    return this._http.post<BaseResponseModel<any>>(`${this._isystemBaseUri}/shift/create.php`, fd);
  }

  getCoreID() {
    return this.getIsystemNurseTypes().pipe(
      map(response => response.data.coreid)
    );
  }

  getStaffUnits(): Observable<BaseResponseModel<UnitData[]>> {
    return this._http.get<BaseResponseModel<UnitData[]>>(this._baseUri + '/unit');
  }

  getStaffTypes(): Observable<StaffTypeData[]> {
    // return this._http.get<BaseResponseModel<StaffTypeData[]>>(this._baseUri + '/stafftype');

    return this._http.get<BaseResponseModel<StaffTypeData[]>>(this._baseUri + '/stafftype').pipe(
      map((response: BaseResponseModel<StaffTypeData[]>) => response.data),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  setFacilityRoles(requestBody: any): Observable<any> {
    return this._http.post<any>(`${this._baseUri}/facility/stafftypes`, requestBody);
  }

  updateStaffSML(staff: StaffData) {
    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/modify`, staff);
  }

  getDayAbbreviations(dayId: number, day: string): string {
    return dayId === 4 || dayId === 6 || dayId === 7
      ? day.substr(0, 2)
      : day.substr(0, 1);
  }

  makeStaffFavorite(favorite: Boolean, uid: number) {
    return this._http.post<BaseResponseModel<any>>(this._isystemBaseUri + '/provider/enableFavorite.php',
      { cid: this._userService.currentUserValue.activeusertype.clientid, uid: uid, favorite: favorite });
  }

  getProviderBookMeStatus(pid: number): Observable<BaseResponseModel<boolean>> {
    return this._http.get<BaseResponseModel<boolean>>(this._backgroundCheckUri + `/bookme/status/pid/${pid}`);
  }

  getBillRatesForFacility() {
    return this._http.post<any>(this._isystemBaseUri + '/manage/clichrgprof.php', {
      cid: this._userService.currentUserValue?.activeusertype?.clientid,
      Section: 'load',
      uid: String(this._userService.currentUserValue?.userid)
    });
  }
}
