import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { onboardNavigation } from '../../config/OnBoardingNavigation';
import { ManageUsersAccountService } from '../manage-users-account.service';
import { OnBoardingService } from '../../on-boarding/services/on-boarding.service';
import { OnBoardingNavigation } from '../../models/newModels/OnBoardingNavigation';
import { map, tap } from 'rxjs/operators';
import { onboardHubNavigation } from '../../config/OnBoardingHubNavigation';
import { monthlyViewType } from '../../config/monthly-view-type';
import { JWTPayloadData } from '../../models/newModels/JWTPayloadData';
import { UserTypes } from 'src/app/config/user-types';

@Injectable({
  providedIn: 'root'
})
export class OnBoardingGuard implements CanActivate, CanActivateChild {
  onBoardingNavigation: OnBoardingNavigation[] = onboardNavigation;
  facilityType = monthlyViewType;

  constructor(
    private router: Router,
    private onBoardingService: OnBoardingService,
    private authenticationService: ManageUsersAccountService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.currentUserStateFlagSubject.pipe(
      map((e: string) => {
        const user = this.authenticationService.currentUserValue;
        if (user) {
          if (user.activeusertype?.usertypeid === UserTypes.Staff) {
            this.router.navigate(
              [this.authenticationService.navigateToLandingPage(user.activeusertype?.usertypeid)],
              { preserveQueryParams: true }
            );
            return false;
          }
          if (e === 'done' || Number(e) >= 23 || this.authenticationService.facilityType !== this.facilityType.internal) {
            return true;
          } else {
            this._setProperOnBoardingFlow(user);
            if (!!this.onBoardingNavigation.find(item => item.uri === e)) {
              // If saved state exists on onboarding flow
              this.router.navigate(['/' + e], { preserveQueryParams: true });
            } else {
              // Update and set to default first page of flow
              this.onBoardingService.updateStateObservable(this.onBoardingNavigation[0].next)
                .pipe(
                  tap(() => this.router.navigate([this.onBoardingNavigation[0].next], { preserveQueryParams: true }))
                )
                .subscribe();
            }
          }
        } else {
          // If user does not exist navigate to register page
          if (state.url !== '/' + this.onBoardingNavigation[0].uri) {
            this.router.navigateByUrl(this.authenticationService.getLoginScreen());
          }
          return true;
        }
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.currentUserStateFlagSubject.pipe(
      map((e: string) => {
        const user = this.authenticationService.currentUserValue;
        if (user) {
          if (user.activeusertype?.usertypeid === UserTypes.Staff) {
            this.router.navigate(
              [this.authenticationService.navigateToLandingPage(user.activeusertype?.usertypeid)],
              { preserveQueryParams: true }
            );
            return false;
          }
          this._setProperOnBoardingFlow(user);
          const currentIndex = this.onBoardingNavigation.findIndex(item => item.uri === 'onboard/' + next.routeConfig.path);
          const currentSavedStateIndex = this.onBoardingNavigation.findIndex(item => item.uri === e);
          if (e === 'done' || Number(e) >= 23 || this.authenticationService.facilityType !== this.facilityType.internal) {
            this.router.navigate(
              [this.authenticationService.navigateToLandingPage(user?.activeusertype?.usertypeid)],
              { preserveQueryParams: true }
            );
            return true;
          }
          if (currentSavedStateIndex === -1) {
            // If this saved state is not on onboarding path, update to first page of flow
            this.onBoardingService
              .updateStateObservable(this.onBoardingNavigation[0].next)
              .pipe(
                tap(() => this.router.navigate([this.onBoardingNavigation[0].next], { preserveQueryParams: true }))
              )
              .subscribe();
          }
          if (currentIndex > currentSavedStateIndex || currentIndex <= 0) {
            // If we are trying to navigate to a not existing route, step not reached yet, or to the register page while logged in
            this.router.navigate(['/' + e], { preserveQueryParams: true });
          }
          return true;
        } else {
          // If not logged in, navigate to register page
          if ('onboard/' + next.routeConfig.path !== this.onBoardingNavigation[0].uri) {
            this.router.navigate([`/${this.onBoardingNavigation[0].uri}`], { preserveQueryParams: true });
          }
          return true;
        }
      })
    );
  }
  /**
   * Set proper onboarding flow depending on active user type logged in
   * @param user
   * @private
   */
  private _setProperOnBoardingFlow(user: JWTPayloadData) {
    if (user.usertypes?.filter(userType => userType?.usertypeid === UserTypes.HubUser).length > 0) {
      this.onBoardingNavigation = onboardHubNavigation;
    } else {
      this.onBoardingNavigation = onboardNavigation;
    }
  }
}
