export class OnboardingActions {

  first_landing = {
    name: 'Landing Page',
    label: 'Landing on Onboarding First Page'
  };

  navigation_change = {
    name: 'Global Navigation',
    label: 'Global navigation. Page: "?"'
  };

  navigation_back = {
    name: 'Back Button Clicked',
    label: 'Back Button Clicked'
  };

  edit_address = {
    name: 'Edit Address',
    label: 'Edit Facility Address'
  };

  edit_floor_names = {
    name: 'Edit Floor Names',
    label: 'Edit Facility Floor Names'
  };

  edit_roles = {
    name: 'Edit Roles',
    label: 'Edit Facility Roles'
  };

  download_csv_template = {
    name: 'Download CSV Template',
    label: 'Download CSV Template'
  };

  upload_csv_template = {
    name: 'Upload CSV Template',
    label: 'Upload CSV Template'
  };

  manual_add_staff = {
    name: 'Manual Add Staff',
    label: 'Manual Add Staff'
  };

  edit_firstname = {
    name: 'Edit First Name',
    label: 'Edit First Name'
  };

  edit_lastname = {
    name: 'Edit Last Name',
    label: 'Edit Last Name'
  };

  edit_phonenumber = {
    name: 'Edit Phone Number',
    label: 'Edit Phone Number'
  };

  set_start_day_of_week = {
    name: 'Set Start Day Of Week',
    label: 'Set Start Day Of Week'
  };

  set_schedule_type = {
    name: 'Schedule Type ?',
    label: 'Schedule Type ?'
  };

  add_custom_shifttime = {
    name: 'Custom ShiftType Added "?"',
    label: 'Custom ShiftType Added  "?"'
  };

  sign_up = {
    name: 'Sign Up',
    label: 'Sign Up button click'
  };
}
