import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShoppingCartService } from 'src/app/monthly-view/components/bulk-shift-booking/services/shopping-cart.service';

@Component({
  selector: 'app-alert12-hour-notification',
  templateUrl: './alert12-hour-notification.component.html',
  styleUrls: ['./alert12-hour-notification.component.scss']
})
export class Alert12HourNotificationComponent implements OnInit {
  private _unsubscribe: Subject<any> = new Subject<any>();
  isAlert12hour: boolean = false;

  constructor(private _cartService: ShoppingCartService,) { }

  ngOnInit(): void {
    this._cartService.isAlert12HourMsg.pipe(
      takeUntil(this._unsubscribe))
      .subscribe(alert =>{
        this.isAlert12hour = alert;
    })
  }
  toggleChange(){
    this.isAlert12hour = !this.isAlert12hour;
  }
}
