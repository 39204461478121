/**
 * This is Create Shift pop up, which is prompted when a create shift button is clicke din Dashboard.
 * It is used to create both Standard and Partial Shift
 */

import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Subject, zip } from 'rxjs';
import { first, tap, flatMap, toArray, finalize } from 'rxjs/operators';
import { ViewLiveUpdateService } from 'src/app/services/view-live-update.service';
import { ViewsStateManagerService } from 'src/app/services/views-state-manager.service';
import { DailyViewConfigModel } from 'src/app/models/daily-view-config-model';
import { Census } from 'src/app/models/shift-management-models/Census';
import { Shift } from 'src/app/models/shift-management-models/shift';
import { ShiftDefs } from 'src/app/models/shift-management-models/ShiftDefs';
import { UnitInfo } from 'src/app/models/shift-management-models/UnitInfo';
import { ShiftsService } from 'src/app/services/apis/shifts.service';
import { DailyViewService } from 'src/app/services/daily-view.service';
import { ShiftManagementService } from 'src/app/services/shift-management.service';
import { ShiftManagementSidebarService } from '../../shift-management-sidebar.service';
import { ShiftCreationType } from 'src/app/enums';
import { Observable } from 'rxjs/internal/Observable';
import { CreateShiftConstants } from 'src/app/shared/constants/createShift';
import { TimeConstants } from 'src/app/config/TimeConstants';
import { CreateShift } from 'src/app/models/java-models';
import { FacilityShiftType } from 'src/app/services/shift-types/models/facility-shift-type.model';
import { ShiftTypesService } from 'src/app/services/shift-types/shift-types.service';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MultipleShiftsConfirmationComponent } from '../multiple-shifts-confirmation/multiple-shifts-confirmation.component';

@Component({
  selector: 'app-create-shift',
  templateUrl: './create-shift.component.html',
  styleUrls: ['./create-shift.component.scss']
})
export class CreateShiftComponent implements OnInit, OnDestroy {

  @Input() initLocation: UnitInfo;
  @Input() initShiftTime: ShiftDefs;

  unitInfo: UnitInfo[] = [];
  shiftTDefs: ShiftDefs[] = [];
  staffTypeData: FacilityShiftType[] = [];
  viewConfig: DailyViewConfigModel;
  census: Census;
  isLoading: boolean = false;
  createShiftForm: FormGroup;
  shiftToAssign: Shift;
  today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  shiftOptions: string = ShiftCreationType.STANDARD;
  unsubscribe: Subject<void> = new Subject();
  endTime: string[] = CreateShiftConstants.TIME_IN_HOURS;
  hours: number;
  minutes: number;
  defaultDate: any;
  public readonly ShiftCreationType = ShiftCreationType;
  public readonly CreateShiftConstants = CreateShiftConstants;
  public readonly TimeConstants = TimeConstants;
  public datesSelected = [];

  //Function which will generate a function that will return a type of MatCalendarCellCssClasses,
  // which can be as simple as a string representing the CSS class to apply
  public dateClass = (date) => {
    if (this._findDate(date) !== -1) {
      return this.isTodaysDate(date) ? 'today' : 'selected';
    }
    return '';
  }
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;

  constructor(
    private _dailyViewService: DailyViewService,
    private _liveUpdate: ViewLiveUpdateService,
    private _shiftsService: ShiftsService,
    private _shiftManagementService: ShiftManagementService,
    private _shiftManagementSidebar: ShiftManagementSidebarService,
    private _snackbar: MatSnackBar,
    private _viewStateManager: ViewsStateManagerService,
    private shiftTypesService: ShiftTypesService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.initializeDropDowns();
    this._dailyViewService.dailyViewConfig.pipe(
      first(),
      tap((shiftDateConfig: DailyViewConfigModel) => {
        this.viewConfig = shiftDateConfig;
        this.defaultDate = this.viewConfig?.date.currentDate;
        this.datesSelected.push(moment.utc(this.viewConfig?.date.currentDate))
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Creates the form.
   */
  createForm() {
    this.createShiftForm = new FormGroup({
      shiftOptions: new FormControl(this.shiftOptions, Validators.required),
      shiftDate: new FormControl(formatDate(
        this._viewStateManager.dailyViewConfig.date.currentDate, 'yyyy-MM-dd', 'en-US'), Validators.required
      ),
      shiftTime: new FormControl('', Validators.required),
      location: new FormControl(this.initLocation?.id, Validators.required),
      staffType: new FormControl('', Validators.required),
    });
  }

  get shiftTime(): FormControl {
    return this.createShiftForm.get('shiftTime') as FormControl;
  }

  get staffType(): FormControl {
    return this.createShiftForm.get('staffType') as FormControl;
  }


  get location(): FormControl {
    return this.createShiftForm.get('location') as FormControl;
  }


  get calculatedHours() {
    const ampmEnd = this.createShiftForm.value.endFormat ? 'PM' : 'AM';
    const singleDigitEndTime = this.createShiftForm.value.partialEndHour + ':' + this.createShiftForm.value.partialEndMinutes + ' ' + ampmEnd;
    const ampm = this.createShiftForm.value.startFormat ? 'PM' : 'AM';
    const singleDigitStartTime = this.createShiftForm.value.partialStartHour + ':' + this.createShiftForm.value.partialStartMinutes + ' ' + ampm;

    return this.createShiftForm.value.partialStartHour && this.createShiftForm.value.partialStartMinutes
      && this.createShiftForm.value.partialEndHour && this.createShiftForm.value.partialEndMinutes ?
      this.calculateHours(singleDigitStartTime, singleDigitEndTime, false) : 'false';
  }
  /**
   * Get the selected Date from dashboard.
   */
  getCurrentDate(): string {
    return formatDate(this.viewConfig?.date.currentDate, 'yyyy-MM-dd', 'en-US');
  }

  /**
   * Get minimum date for the calendar.
   */
  getMinDate(): string {
    return formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  }

  /**
   * Initialize all the drop downs.
   */
  initializeDropDowns(): void {
    zip(
      this.getUnits(),
      this.getShiftTypes(),
      this.getStaffTypes()
    ).pipe(
      first()
    ).subscribe();
  }

  /**
   * Get location drop downs.
   */
  getUnits(): Observable<UnitInfo[]> {
    return this._shiftsService.getUnits().pipe(
      tap((units: UnitInfo[]) => this.unitInfo = units)
    );
  }

  /**
   * Get shift Types drop downs.
   */
  getShiftTypes(): Observable<ShiftDefs[]> {
    return this._shiftsService.getShiftDefs().pipe(
      tap((shiftDefs: ShiftDefs[]) => {
        this.shiftTDefs = shiftDefs;

        if (this.initShiftTime) {
          this.initShiftTime = this.shiftTDefs.find(item => item.shiftDefId === this.initShiftTime?.shiftDefId);
          this.createShiftForm.get('shiftTime').setValue(this.initShiftTime);
          this.calculateHours(this.initShiftTime?.startTime, this.initShiftTime?.endTime, false);
        }
      })
    );
  }

  /**
   * Get shift Types drop downs.
   */
  getStaffTypes(): Observable<FacilityShiftType[]> {
    return this.shiftTypesService.getShiftTypes().pipe(
      flatMap((staffTypes: FacilityShiftType[]) => staffTypes),
      toArray(),
      tap((staffTypes: FacilityShiftType[]) => this.staffTypeData = staffTypes)
    );
  }

  /**
   * called on calendar date click.
   * Resets the endtime
   */
  selectShiftDate() {
    this.endTime = CreateShiftConstants.TIME_IN_HOURS;
  }


  /**
   * Calculate shift time drop down along with time difference.
   */
  calculateHours(startTime: string, endTime: string, toreturn: boolean): string {
    const start = moment(this.today.format('yyyy-MM-DD') + ' ' + startTime).toDate().getTime();
    let end = moment(this.today.format('yyyy-MM-DD') + ' ' + endTime).toDate().getTime();
    if (end <= start) {
      end = moment(end).add(1, 'days').toDate().getTime();
    }
    const seconds = Math.floor((end - start) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    if (toreturn) {
      return '<div class="position-text"><span class="number">' + hours
        + '</span><span class="letter">H  &nbsp; </span><span class="number">'
        + minutes + '</span><span class="letter">MIN</span></div>';
    } else {
      this.hours = hours;
      this.minutes = minutes;
      return 'true';
    }

  }

  /**
   * Convert the time from 12hrs format to 24hrs format
   */
  convertTimeFormat(hour?: number, minute?: number, ampm?: boolean, time?: string): { hours: number, minutes: number } {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      const minutes = Number(time.match(/:(\d+)/)[1]);
      return { hours: hours, minutes: minutes };
    } else {
      if (ampm && hour < 12) { hour = +hour + 12; }
      if (!ampm && hour == 12) { hour -= 12; }
      return { hours: hour, minutes: minute };
    }
  }


  /**
   * Called on click of Create Shift and Assign To this shift button to create shift.
   */
  clickedButton(buttonLbl: string): void {
    if (this.datesSelected.length > 1) {
      const dialogRef = this.dialog.open(MultipleShiftsConfirmationComponent, {
        width: '500px',
        panelClass: 'day-info-dialog',
        data: { datesSelected: this.datesSelected.length },
        disableClose: true
      });
      const sub = dialogRef.componentInstance.createShifts.pipe(
        first(),
        tap((button: boolean) => {
          if (button) {
            this.createMultipleShifts(buttonLbl);
          }
          dialogRef.close();
        })
      ).subscribe();
    } else {
      this.createShift(buttonLbl, '');
    }
  }

  /**
   * Method to handle the logic for multiple open shifts
   * @param buttonLbl : Specifies the button clicked.
   */
  createMultipleShifts(buttonLbl: string): void {
    this.datesSelected.forEach((selectedDate: string) => {
      const shiftDate: string = moment(selectedDate).format('yyyy-MM-DD');
      this.createShift(buttonLbl, shiftDate);
    });
  }
  /**
   * Method to call the create shift API for single and multiple open shifts
   * @param1 buttonLbl: Specifies the button clicked.
   * @param2 shiftdate: Shiftdate for bulk create.
   */
  createShift(buttonLbl: string, shiftdate: string): void {
    this.isLoading = true;
    let shiftTempdate: string;
    this.datesSelected.length > 1 ?
      (shiftTempdate = moment(shiftdate).format('yyyy-MM-DD')) :
      (shiftTempdate = this.shiftOptions === ShiftCreationType.STANDARD ?
        moment(this.datesSelected[0]).format('yyyy-MM-DD') :
        moment(this.createShiftForm.value.shiftDate).format('yyyy-MM-DD'));
    const shiftDate: string = shiftTempdate;
    const unitId = this.createShiftForm.value.location;
    const shiftDefid = this.createShiftForm.value.shiftTime?.shiftDefId;
    let startTIme: string;
    let endTIme: string;
    if (this.shiftOptions === ShiftCreationType.STANDARD) {
      startTIme = this.shiftTDefs
        .find(shift => shift?.shiftDefId === shiftDefid)?.startTime;
      endTIme = this.shiftTDefs
        .find(shift => shift?.shiftDefId === shiftDefid).endTime;
    }
    let singleDigitStartTime: { hours: number, minutes: number };
    let singleDigitEndTime: { hours: number, minutes: number };
    if (this.shiftOptions === ShiftCreationType.PARTIAL) {
      singleDigitStartTime = this.convertTimeFormat(this.createShiftForm.value.partialStartHour,
        this.createShiftForm.value.partialStartMinutes, this.createShiftForm.value.startFormat);
      singleDigitEndTime = this.convertTimeFormat(this.createShiftForm.value.partialEndHour,
        this.createShiftForm.value.partialEndMinutes, this.createShiftForm.value.endFormat);
    } else {
      singleDigitStartTime = this.convertTimeFormat(null, null, null, startTIme);
      singleDigitEndTime = this.convertTimeFormat(null, null, null, endTIme);
    }
    this.census = this.shiftTDefs
      .find(shift => shift.shiftDefId === shiftDefid)?.census?.
      find(e => e.dayOfWeek === moment(shiftDate).isoWeekday() && e.unitID === unitId);

    const newShift: CreateShift = {
      censusId: this.census?.censusId,
      shiftDate: shiftDate,
      shiftTypeId: this.createShiftForm.value.staffType.shiftTypeId,
      unitId: unitId,
      partial: this.shiftOptions === ShiftCreationType.PARTIAL,
      startHour: +singleDigitStartTime.hours,
      startMin: +singleDigitStartTime.minutes,
      endHour: +singleDigitEndTime.hours,
      endMin: +singleDigitEndTime.minutes
    };
    if (this.shiftOptions === ShiftCreationType.PARTIAL) {
      delete newShift.censusId;
    }
    this._shiftManagementService.createShifts([newShift]).pipe(
      first(),
      tap((shifts: Shift[]) => {
        if (shifts?.length > 0) {
          if ( shifts?.length !== 1 || shifts[0].shiftDate === this.getCurrentDate() ) {
            this._liveUpdate.forceReload.next();
          }
          this._snackbar.open('Shift created successfully.', 'X', {
            duration: 3000
          });
          if (buttonLbl === CreateShiftConstants.CREATE_LABEL) {
            this._shiftManagementSidebar.closePanel();
            this.shiftToAssign = null;
          } else {
            this.shiftToAssign = shifts[0];
            const location: UnitInfo[] = this.unitInfo.filter((loc) => {
              if (loc.id === this.createShiftForm.value.location) {
                return loc;
              }
            });
            if (this.shiftToAssign.partial) {
              this.shiftToAssign.partialShift = {
                startTime: this.convertToDoubleDigit(this.shiftToAssign?.startHour)
                  + this.convertToDoubleDigit(this.shiftToAssign?.startMin) + '00',
                endTime: this.convertToDoubleDigit(this.shiftToAssign?.endHour)
                  + this.convertToDoubleDigit(this.shiftToAssign?.endMin) + '00'
              };
            } else {
              this.shiftToAssign.startTime = this.convertToDoubleDigit(this.shiftToAssign?.startHour)
                + this.convertToDoubleDigit(this.shiftToAssign?.startMin) + '00';
              this.shiftToAssign.endTime = this.convertToDoubleDigit(this.shiftToAssign?.endHour)
                + this.convertToDoubleDigit(this.shiftToAssign?.endMin) + '00';
            }
            this.shiftToAssign.shiftID = this.shiftToAssign.shiftId;
            this.shiftToAssign.staffTypeID = this.shiftToAssign.staffTypeId;
            this.shiftToAssign.subStaffTypeID = this.shiftToAssign.subStaffTypeId;
            this.shiftToAssign.floorName = location[0]?.floorName;
            this.shiftToAssign.isPartial = this.shiftOptions === ShiftCreationType.PARTIAL ? true : false;
            this.datesSelected.length <= 1 ?
              this._shiftManagementSidebar.openFillShiftPanel(shifts[0]) : '';
          }
        } else {
          this._snackbar.open('Not able to create shift. Please contact admin.', 'X', {
            duration: 3000
          });
        }
      }),
      finalize(() => this.isLoading = false),
    ).subscribe();
  }

  /**
   * convert single digit value to double digit.
   */
  convertToDoubleDigit(value: number): string {
    const newValue: string = value?.toString();
    return newValue?.length === 1 ? ('0' + newValue + ':') : (newValue + ':');
  }

  /**
   * remove and add controls based on shift creation options.
   */
  changeShiftOption(selectedOption: string): void {
    this.shiftOptions = selectedOption;
    if (this.shiftOptions === ShiftCreationType.STANDARD) {
      this.createShiftForm.removeControl('partialStartHour');
      this.createShiftForm.removeControl('partialStartMinutes');
      this.createShiftForm.removeControl('partialEndHour');
      this.createShiftForm.removeControl('partialEndMinutes');
      this.createShiftForm.removeControl('startFormat');
      this.createShiftForm.removeControl('endFormat');

      this.createShiftForm.addControl('shiftTime', new FormControl(this.initShiftTime, Validators.required));
    } else {
      this.createShiftForm.addControl('endFormat', new FormControl(false, Validators.required));
      this.createShiftForm.addControl('startFormat', new FormControl(false, Validators.required));
      this.createShiftForm.addControl('partialStartHour', new FormControl('', Validators.required));
      this.createShiftForm.addControl('partialStartMinutes', new FormControl('', Validators.required));
      this.createShiftForm.addControl('partialEndHour', new FormControl('', Validators.required));
      this.createShiftForm.addControl('partialEndMinutes', new FormControl('', Validators.required));
      this.createShiftForm.removeControl('shiftTime');
    }
  }

  /**
   * to close the create shift side nav.
   */
  closeDrawer(): void {
    this._shiftManagementSidebar.closePanel();
  }
  //This method is called every time a date is selected, if it is than the date will unselect.
  onSelect(event) {
    const index = this._findDate(event);
    if (index === -1) {
      this.datesSelected.push(event);
    } else {
      this.datesSelected.splice(index, 1)
    }
    (this.calendar.monthView as any)._createWeekCells();
  }
  //Finds all the dates that are selected in the calendar
  private _findDate(event) {
    return this.datesSelected.findIndex(dateSelected => dateSelected.isSame(event, 'day'))
  }

  //This method shows if a date is a default date in calendar
  isTodaysDate(date) {
    return moment.utc(date).isSame(new Date(), 'day')
  }

}
