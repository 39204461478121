import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { BoostRange } from '../../../models/BoostModels';
import { ModifyBoostService } from '../../../monthly-view/services/modify-boost.service';
import { BoostDataService } from '../../../monthly-view/services/boost-data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ManageUsersAccountService } from '../../../services/manage-users-account.service';
import { BoostApprovalService } from '../../../monthly-view/services/boost-approval.service';
import { OvertimeSettingsService } from 'src/app/settings/service/overtime-settings.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-boost-shift',
  templateUrl: './boost-shift.component.html',
  styleUrls: ['./boost-shift.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoostShiftComponent implements OnInit {

  user: any;
  @Input() request: any = [];
  @Input() autoToggle: false;
  @Output() emitter: EventEmitter<any> = new EventEmitter();
  showBoostOptions = false;
  boostRangeList: number[] = [];
  boostRangeIncrementBy = 5;

  overtimeSettingValue: string;
  boostForm: FormGroup;
  isDevice: boolean;
  boostVisibility;

  constructor(
    private _manageUsersAccountService: ManageUsersAccountService,
    private _modifyBoostService: ModifyBoostService,
    private _boostApprovalService: BoostApprovalService,
    private _boostDataService: BoostDataService,
    private _overTimeSettingsService: OvertimeSettingsService,
    public mobileService: MobileService) {
    this.user = this._manageUsersAccountService.currentUserValue;
    this._boostApprovalService.hasPromoteAccess(
      { clientid: this.user.activeusertype.clientid, userid: this.user.userid }
    ).pipe(
      first(),
      /*tap((access: any) => console.warn(access))*/
    ).subscribe((access: any) => this.boostVisibility = access);
  }

  ngOnInit(): void {
    this.isDevice = this.mobileService.isDevice();
    this.boostForm = new FormGroup({
      boostAmount: new FormControl(null),
      allowOvertime: new FormControl(null),
      sureShift: new FormControl(null)
    });
    if (this.autoToggle) {
      this.toggleBoostOptions();
      this.boostForm.valueChanges.subscribe(val => {
        this.extractValues(val);
      });
    }

    /**
     * Get's the overtime flag for the current client
     */
    this._overTimeSettingsService.getOvertimeSettingRequest(this.user.activeusertype.clientid).pipe(
      first(),
      tap((overtimeSetttingValue: string) => {
        this.overtimeSettingValue = overtimeSetttingValue;
      })
    ).subscribe();
  }

  extractValues(val) {
    const boostResponse = {};
    Object.keys(val).forEach(k => { if (val[k]) { boostResponse[k] = val[k]; } });
    this.emitter.emit({ boostSucceeded: true, boostResponse });
  }

  getSelectedText(overtimeSetting: string) {
    switch (overtimeSetting) {
      case 'allFac':
        return '40+ hours for IntelyCare';
      case 'myFac':
        return '40+ hours at my facility';
      case 'No':
        return 'Don\'t allow overtime';
      default:
        return '';
    }
  }

  boostShifts(reqIds: number[]) {
    this._modifyBoostService.processBoosting = true;
    const cid: number = this.user.activeusertype.clientid;
    const uid: number = this.user.userid;
    this._modifyBoostService.boostAllRequests(cid, uid, reqIds,
      this.boostForm.controls.boostAmount.value,
      (this.boostForm.controls.allowOvertime.value?.value || this.boostForm.controls.allowOvertime.value || 'No'),
      this.boostForm.controls.sureShift.value)
      .pipe(
        tap(() => {
          this.extractValues(this.boostForm.value);
          this._modifyBoostService.processBoosting = false;
        })
      )
      .subscribe();
  }

  toggleBoostOptions() {
    this.showBoostOptions = !this.showBoostOptions;
    const cid: number = this.user ? this.user.activeusertype.clientid : null;
    const uid: number = this.user ? this.user.userid : null;

    if (this.boostRangeList.length === 0 && this.showBoostOptions) {
      this._boostDataService.getBoostRangeForBulkShift(cid, uid).pipe(
        first(),
        tap((boostRange: BoostRange) => {
          this.boostRangeList = [];
          let eachBoostValue: number = Number(boostRange.minRange);
          do {
            this.boostRangeList.push(eachBoostValue);
            eachBoostValue = eachBoostValue + this.boostRangeIncrementBy;
          } while (eachBoostValue <= Number(boostRange.maxRange));
        }),
      ).subscribe();
    }

  }
}

@Component({
  selector: 'app-boost-summary',
  template: `
      <div *ngFor="let boost of boostKeys" [attr.automation-id]="'boost-icon-' + boost" class="boosted-percentage-wrapper">
        <label *ngIf="boostMap[boost]" class="boosted-percentage">
          <i *ngIf="boostMap[boost].icon" [ngClass]="boostMap[boost].icon"></i>
          <img *ngIf="boostMap[boost].img" [src]="boostMap[boost].img" [alt]="boostMap[boost].alt"/>
          {{boostMap[boost].label}}
        </label>
      </div>
  `,
  styleUrls: ['./boost-shift.component.scss']
})
export class BoostSummaryComponent implements OnChanges {

  @Input() boostResponse: any;
  boostMap: any = {
    boostAmount: { icon: 'fal fa-rocket' },
    allowOvertime: { img: '../assets/images/overtime.svg' },
    sureShift: { icon: 'fas fa-shield-check' }
  };
  boostKeys = [];

  constructor() {
  }

  ngOnChanges() {
    if (this.boostResponse) {
      this.boostKeys = Object.keys(this.boostResponse);
      this.boostKeys.forEach(boost => {
        if (boost === 'allowOvertime') {
          this.boostMap[boost] = { ...this.boostMap[boost], label: this.boostResponse[boost].name, alt: 'Allow Overtime' };
        } else if (boost === 'boostAmount') {
          this.boostMap[boost] = { ...this.boostMap[boost], label: `Boosted ${this.boostResponse[boost]}%` };
        } else if (boost === 'sureShift') {
          this.boostMap[boost] = { ...this.boostMap[boost], label: 'Sure Shift' };
        }
      });
    }
  }

}
