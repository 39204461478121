import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 0 && value / 60 < 1) {
      return value + '';

    } else {
      return value / 60 + 'hrs';
    }
  }
}