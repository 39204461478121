import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { BaseResponseModel } from '../models/BaseResponseModel';
import { JWTPayloadData } from '../models/newModels/JWTPayloadData';
import { UtilsService } from './utils/utils.service';
import { CookieService } from 'ngx-cookie-service';
import { SettingsResponseModel } from '../models/newModels/SettingsResponseModel';
import { FacilityRolesData } from '../models/newModels/FacilityRolesData';
import { FacilityData } from '../models/newModels/FacilityData';
import { FacilityType } from '../models/newModels/FacilityTypes';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';
import { ExternalFacility } from '../models/newModels/ExternalFacility';
import { StateData } from '../models/newModels/StateData';
import { UserFlagsResponseData } from '../models/newModels/UserFlagsResponseData';
import { SettingsData } from '../models/newModels/SettingsData';
import { Settings } from '../models/shift-management-models/Settings';
import { PendoData } from '../models/newModels/PendoData';
import { UserTypeData } from '../models/newModels/UserTypeData';
import { BillingSummaryModel } from '../models/newModels/BillingSummaryModel';
import { UserTypes } from '../config/user-types';
import { Router } from '@angular/router';
import { JavaBaseResponse } from '../models/shift-management-models/java-base-response';

declare let pendo: any;

/**
 * ManageUsersAccountService manages the user account.The registration,login,logout.Saves, updates user from local storage and database
 * and removes from ls when user logout-s.
 */

const httpOptions = {
  withCredentials: false,
  observe: 'response' as 'response'
};

@Injectable({
  providedIn: 'root'
})

export class ManageUsersAccountService {
  userInfo: BehaviorSubject<BillingSummaryModel> = new BehaviorSubject<BillingSummaryModel>(null);
  clientSettings: BehaviorSubject<Settings[]> = new BehaviorSubject<Settings[]>([]);
  currentUserSubject: BehaviorSubject<JWTPayloadData> = new BehaviorSubject<JWTPayloadData>(null);
  currentUserStateFlagSubject: BehaviorSubject<string> = new BehaviorSubject<string>('/onboard/facility/address');
  userLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  registrationErrorMessage: BehaviorSubject<string> = new BehaviorSubject('');
  showAllItemsInToolbar$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private api = UtilsService.getUserBaseUri();
  private authApi = UtilsService.getAuthorizationBaseUri();
  private _shiftJavaUri = UtilsService.getShiftJavaUri();
  private _auth = UtilsService.getAuthorizationBaseUri();

  constructor (private http: HttpClient, private _cookie: CookieService) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const flag = JSON.parse(localStorage.getItem('currentUserStateFlag'));
    const clientSettings = JSON.parse(localStorage.getItem('clientSettings'));

    /**
     * Using window here instead of activated route since this constructor
     * runs before any routes are configured and can be read from (I tired)
     */
    if (!!user && !window.location.href.includes('clientid') && !window.location.href.includes('token')) {
      this.userLoggedInSubject.next(true);
      this.currentUserSubject.next(user);
    }

    if (!!flag) {
      this.currentUserStateFlagSubject.next(flag);
    }

    if (!!clientSettings) {
      this.clientSettings.next(clientSettings);
    }
  }

  public get currentUserValue(): JWTPayloadData {
    return this.currentUserSubject.value;
  }

  public get fullName(): string {
    return `${this.currentUserSubject.value?.firstname} ${this.currentUserSubject.value?.lastname}`;
  }

  // if clienttype is not defined default to 1 (internal scheduling user)
  public get facilityType(): number {
    return Number(this.clientSettings.value?.find(item => item.name === 'clienttype')?.value ?? 1);
  }

  // Saves the logged in user in ls
  public setCurrentUser(user: JWTPayloadData) {
    const userTypes = this.getUserTypesFromLS();

    if (userTypes.length) {
      user.usertypes = userTypes;
    }

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  public getUserTypesFromLS(): UserTypeData[] {
    return JSON.parse(localStorage.getItem('userTypes')) as UserTypeData[] ?? [];
  }

  // Retrieve client settings
  public get clientSettingsGetter(): Settings[] {
    return this.clientSettings.value;
  }

  // Saves settings in LS
  public setClientSettings(settings: Settings[]) {
    localStorage.setItem('clientSettings', JSON.stringify(settings));
    this.clientSettings.next(settings);
  }

  public get currentUserStateFlag(): string {
    return this.currentUserStateFlagSubject.value;
  }

  public set currentUserStateFlag(url: string) {
    localStorage.setItem('currentUserStateFlag', JSON.stringify(url));
    this.currentUserStateFlagSubject.next(url);
  }

  public get getRegistrationErrorMessage(): string {
    return this.registrationErrorMessage.value;
  }

  retrieveStaffMemberData(registrationlinkid: number) {
    const requestBody = {
      'registrationlinkid': registrationlinkid
    };

    return this.http.post<BaseResponseModel<any>>(this.api + '/get/registrationlink', requestBody, httpOptions);
  }

  saveStaffMemberAccount(requestBody: any) {
    return this.http.post<BaseResponseModel<any>>(this.api + '/signup', requestBody, httpOptions).pipe(
      tap(response => {
        if (!!String(response.body.code).match(/200|201/)) {
          this.resetCookie(response.body);
          this.getPayLoad().subscribe();
        }
      }));
  }

  // Updates the user profile name (from manage profile in settings)
  public updateUsername(firstname, lastname) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    user.firstname = firstname;
    user.lastname = lastname;
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  setFacilityAccount(requestBody: any): Observable<BaseResponseModel<any>> {
    return this.http.post<BaseResponseModel<any>>(this.api, requestBody);
  }

  resendActivationCode() {
    return this.http.post<BaseResponseModel<any>>(this.api + '/activate/newemailcode', {}, httpOptions);
  }

  verifyCode(code: string, userId: string) {
    const requestBody = {
      'code': code,
      'userId': userId
    };

    return this.http.post<BaseResponseModel<any>>(this.api + '/activate/fromemailcode', requestBody, httpOptions).pipe(
      tap(response => {
        if (!!String(response.body.code).match(/200|201/)) {
          this.resetCookie(response.body);
        }
      })
    );
  }

  sendRecoveryCode(email: string) {
    const requestBody = {
      'email': email
    };

    return this.http.post<BaseResponseModel<any>>(this.api + '/password/reset/requestemailcode', requestBody, httpOptions);
  }

  verifyRecoveryCode(code: string, email: string, password: string) {
    const requestBody = {
      'email': email,
      'code': code,
      'newpassword': password
    };

    return this.http.post<BaseResponseModel<any>>(this.api + '/password/reset/fromemailcode', requestBody, httpOptions).pipe(
      tap(response => {
        if (!!String(response.body.code).match(/200|201/)) {
          this.resetCookie(response.body);
        }
      })
    );
  }

  login(email: string, password: string, userType: number) {
    const requestBody = {
      'email': email,
      'password': password
    };

    if (userType !== 1) {
      requestBody['defaultusertypes'] = [{ priority: '1', usertypeid: UserTypes.Scheduler }, { priority: '2', usertypeid: UserTypes.HubUser }];
      requestBody['productTypes'] = [2, 3, 4];
    } else {
      requestBody['defaultusertypes'] = [{ priority: '1', usertypeid: UserTypes.Staff }];
    }

    return this.http.post<BaseResponseModel<any>>(this.api + '/logon', requestBody);
  }

  getUserTypes(payload: any) {
    return this.http.get<BaseResponseModel<string>>(`${this._auth}/user/usertousertype/${payload?.userid}`).pipe(
      map(resp => resp?.data),
      catchError(() => of(JSON.stringify([payload?.jwtpayload.activeusertype]))),
      tap(userTypes => localStorage.setItem('userTypes', userTypes)),
      map(resp => JSON.parse(resp) as UserTypeData[])
    );
  }

  getPayLoad(handleFlag = true) {
    // For bearer tokens, populated in loginAsFacility()
    if (this.currentUserValue && this.currentUserValue['isBearer']) {
      return of({data: this.currentUserValue} as BaseResponseModel<JWTPayloadData>);
    }
    return this.http.get<BaseResponseModel<JWTPayloadData>>(this.api + '/payload', httpOptions).pipe(
      map(resp => resp.body),
      filter(payload => String(payload.code) === '200' && !!payload.data?.userid != null),
      tap(payload => {
        this.setCurrentUser(payload.data);
      }),
      switchMap(resp => forkJoin({
        state: !!handleFlag ? this.getUserFlags().pipe(
          tap(stateResp => {
            if (stateResp.data?.userflags?.find(item => item.flagtypeid === 5)?.flagvalue) {
              this.currentUserStateFlag = stateResp.data?.userflags?.find(item => item.flagtypeid === 5)?.flagvalue;
            }
          })
        ) : of({}),
        payload: of(resp)
      })),
      map(resp => resp.payload));
  }

  /**
   * Method to login with facility
   * @param clientid is facilityId
   * @param newToken  is JWT token
   * @param usertypeid is id
   * @returns http response
   */
  loginAsFacility(clientid: number, newToken: string, usertypeid, external = false) {
    if(newToken.includes("Bearer")) {
      // Create HttpParams for the POST request call. Setting the query params to camel case to satisfy endpoint.
      const params = new HttpParams()
        .set('userTypeId', usertypeid)
        .set('clientId', String(clientid));

      // Create HttpHeaders for the POST request call. This will add the bearer token to the header.
      const headers = new HttpHeaders().set('Authorization', newToken);

      return this.http.post<JavaBaseResponse<any>>(`${this.authApi}/client/linkschedulingtohub/hubToClientPlatformToken`, {}, {headers, params}).pipe(
        tap(response => {
          if(response.status === 'OK' && response.success == true) {
            // Change the fields for the jwtData to be lowercased.
            let jwtDataPayload = response.data.jwtData;

            // Changing the fields inside the JWT payload from camel case to lower case. This is for compatibilty reasons.
            function jwtPayloadToLowercase(object) {
              Object.keys(object).forEach((key) => {
                if (object[key] && typeof object[key] === 'object') jwtPayloadToLowercase(object[key]);
                object[key.toLowerCase()] = object[key];
              });
            }

            jwtPayloadToLowercase(jwtDataPayload);

            // We set "isBearer" to make sure we do not call the /payload API.
            jwtDataPayload['isBearer'] = true;
            this.setCurrentUser(jwtDataPayload);

            // In the payload from the endpoint, all fields are camel cased.           e.g. "jwtCreated"
            // Though parsing the cookie values, all the fields are all lower cased.   e.g. "jwtcreated"
            const cookieDate = this.getJwtCreatedFromCookieValue(response.data.cookieValue, false);
            const refreshCookieDate = this.getJwtCreatedFromCookieValue(response.data.refreshCookieValue, true);
            if (external) {
              this.setCookieWithNullSameSite(response.data.cookieName, response.data.cookieValue, cookieDate);
              this.setCookieWithNullSameSite(response.data.refreshCookieName, response.data.refreshCookieValue, refreshCookieDate);
            } else {
              this._cookie.set(response.data.cookieName, response.data.cookieValue, cookieDate, '/');
              this._cookie.set(response.data.refreshCookieName, response.data.refreshCookieValue, refreshCookieDate, '/');
            }
            this.userLoggedInSubject.next(true);
          }
        })
      );
    } else {
      const jd = moment(jwt_decode(newToken).jwtcreated).add(1, 'days').toDate();

      if (external) {
        this.setCookieWithNullSameSite('IntelycareAuthCookie', newToken, jd);
      } else {
        this._cookie.set('IntelycareAuthCookie', newToken, jd, '/');
      }

      return this.http.post<BaseResponseModel<any>>(`${this.api}/jwt/activeusertype`, {
        usertypeid,
        clientid
      }).pipe(
        tap(response => {
          if (Number(response.code) === 200) {
            this.setCurrentUser(response.data.jwtpayload);
            const cookieDate = this.getJwtCreatedFromCookieValue(response.data.cookievalue, false);
            const refreshCookieDate = this.getJwtCreatedFromCookieValue(response.data.refreshcookievalue, true);
            if (external) {
              this.setCookieWithNullSameSite(response.data.cookiename, response.data.cookievalue, cookieDate);
              this.setCookieWithNullSameSite(response.data.refreshcookiename, response.data.refreshcookievalue, refreshCookieDate);
            } else {
              this._cookie.set(response.data.cookiename, response.data.cookievalue, cookieDate, '/');
              this._cookie.set(response.data.refreshcookiename, response.data.refreshcookievalue, refreshCookieDate, '/');
            }
            this.userLoggedInSubject.next(true);
          }
        }),
        // Map the BaseResponseModel into a JavaBaseResponse model for compatibility sake.
        map(resp => {
          resp.data.jwtData = {...resp.data.jwtpayload};
          return {
            status: Number(resp.code) === 200 ? 'OK' : 'BAD_REQUEST',
            success: Number(resp.code) === 200,
            data: resp.data
          } as JavaBaseResponse<any>
        })
      );
    }
  }

  getJwtCreatedFromCookieValue(cookieValue: string, refreshCookie: boolean) {
    let jwtCreated = jwt_decode(cookieValue).jwtcreated;
    if(jwtCreated.includes(" +")) {
      jwtCreated = jwtCreated.split(" +")[0];
    }
    // If for refresh cookie, 2 days. If for normal cookie, only 1 day.
    return moment(jwtCreated).add(refreshCookie ? 2 : 1, 'days').toDate();
  }

  setCookieWithNullSameSite(cookieName: string, cookieValue: string, expirationDate, path = '/') {
    this._cookie.set(cookieName, cookieValue, expirationDate, path, null, true, 'None');
  }

  logout() {
    return this.http.post<BaseResponseModel<any>>(this.api + '/logoff', {}, httpOptions).pipe(
      map(response => {
        const loginscreen = this.getLoginScreen();
        this.clearLocalStorage();
        return loginscreen;
      }));
  }

  clearLocalStorage() {
    this.currentUserStateFlagSubject.next(null);
    localStorage.removeItem('currentUserStateFlag');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userTypes');
    localStorage.removeItem('intelypro');
    localStorage.removeItem('dow');
    localStorage.removeItem('onboard');
    localStorage.removeItem('clientSettings');
    localStorage.removeItem('openShifts-dayView');
    localStorage.removeItem('calendarViewType');
    localStorage.removeItem('calendarViewSelectedMonth');
    localStorage.removeItem('calendarViewStartDate');
    localStorage.removeItem('calendarViewEndDate');
    localStorage.removeItem('spreadsheetFilters');
    localStorage.removeItem('timecardFilters');
    localStorage.removeItem('monthly-sidepanel');
    localStorage.clear();
    sessionStorage.clear();
    this._cookie.delete('IntelycareAuthCookie', '/');
    this._cookie.delete('IntelycareRefreshCookie', '/');
    this._cookie.deleteAll();
    this.currentUserSubject.next(null);
  }

  acceptTermsAndConditions() {
    return this.http.post<BaseResponseModel<any>>(this.api + '/accepttos', {}).pipe(
      tap(response => {
        if (!!String(response.code).match(/200|201/)) {
          this.resetCookie(response);
        }
      })
    );
  }

  getClientSettings(): Observable<BaseResponseModel<SettingsResponseModel>> {
    return this.http.get<BaseResponseModel<SettingsResponseModel>>(this.api + '/clientsettings');
  }

  getRoleRestriction(): Observable<BaseResponseModel<SettingsData[]>> {
    return this.http.get<BaseResponseModel<SettingsData[]>>(this.api + '/clientsettings');
  }

  // Based on user id landing page will be different
  navigateToLandingPage(userId: number): string {
    switch (userId) {
      case 1:
        return '/online';
      case 2:
      case 9:
      case 12:
        return '/dashboard';
      case 4:
        return '/training/dashboard';
      case 5:
        return '/training/course';
      default:
        return '/training/dashboard';
    }
  }

  // Based on user id right activation view will be different
  navigateToRightActivationView(userId: number): string {
    switch (userId) {
      case 1:
        return '/activate';
      case 2:
        return '/facility/activate';
      case 4:
        return '/training/dashboard';
      case 5:
        return '/training/activate';
    }
  }

  getLoginScreen(): string {
    const currentUser = this.currentUserValue;
    if (!!currentUser) {
      switch (currentUser.activeusertype.usertypeid) {
        case UserTypes.Staff:
          return '/login';
        case UserTypes.Scheduler:
        case UserTypes.HubUser:
          return '/facility/login';
        case 4:
          return '/training/facility/login';
        case 5:
          return '/training/login';
        default:
          return '/training/facility/login';
      }
    }
    return '/facility/login';
  }

  getLoginUrlFromUserType(userTypeId: number): string {
    switch (userTypeId) {
      case 1:
        return '/login';
      case 2:
        return '/facility/login';
      case 4:
        return '/training/facility/login';
      case 5:
        return '/training/login';
      default:
        return '/training/facility/login';
    }
  }

  getRoles(): Observable<BaseResponseModel<FacilityRolesData[]>> {
    return this.http.get<BaseResponseModel<FacilityRolesData[]>>(this.api + '/roles');
  }

  getFacilityTypes(): Observable<BaseResponseModel<FacilityType[]>> {
    return this.http.get<BaseResponseModel<FacilityType[]>>(this.api + '/facilitytypes');
  }

  getClientData(): Observable<BaseResponseModel<FacilityData>> {
    return this.http.get<BaseResponseModel<FacilityData>>(this.api + '/client');
  }

  setClientData(requestBody: any): Observable<BaseResponseModel<{ clientid: number, jwtvalue: any }>> {
    return this.http.post<BaseResponseModel<{ clientid: number, jwtvalue: any }>>(this.api + '/client', requestBody);
  }

  updateClientData(requestBody: any): Observable<BaseResponseModel<FacilityData>> {
    return this.http.put<BaseResponseModel<FacilityData>>(this.api + '/client', requestBody);
  }

  setFacilityDB(): Observable<BaseResponseModel<any>> {
    return this.http.post<BaseResponseModel<any>>(this.api + '/facilitydb', {});
  }

  getExternalFacilityNames(search: string): Observable<BaseResponseModel<ExternalFacility[]>> {
    return this.http.get<BaseResponseModel<ExternalFacility[]>>(this.api + `/client/unregisteredfederalproviders?search=${search}`);
  }

  getExternalFacilityData(federalprovidernumber: string): Observable<BaseResponseModel<ExternalFacility>> {
    return this.http.get<BaseResponseModel<ExternalFacility>>(this.api + '/client/federalproviderinfo?' +
      `federalprovidernumber=${federalprovidernumber}`);
  }

  getStates(): Observable<BaseResponseModel<StateData[]>> {
    return this.http.get<BaseResponseModel<StateData[]>>(this.api + '/states');
  }

  getUserFlags(): Observable<BaseResponseModel<UserFlagsResponseData>> {
    return this.http.get<BaseResponseModel<UserFlagsResponseData>>(`${this.api}/flag`);
  }

  setUserFlag(flagtypeid: number, value: any): Observable<BaseResponseModel<any>> {
    return this.http.post<BaseResponseModel<any>>(`${this.api}/flag`, { flagtypeid, value });
  }

  resetCookie(resp: any) {
    const jd = moment(jwt_decode(resp.data.cookievalue).jwtcreated).add(1, 'days').toDate();
    const jd1 = moment(jwt_decode(resp.data.refreshcookievalue).jwtcreated).add(2, 'days').toDate();
    this._cookie.set(resp.data.cookiename, resp.data.cookievalue, jd, '/');
    this._cookie.set(resp.data.refreshcookiename, resp.data.refreshcookievalue, jd1, '/');
  }

  set setDOW(dow: number) {
    localStorage.setItem('dow', String(dow));
  }

  get getDOW() {
    return !isNaN(Number(localStorage.getItem('dow'))) ? Number(localStorage.getItem('dow')) : 1;
  }

  initializePendo(payload: JWTPayloadData) {
    if (payload?.activeusertype?.clientid > 0) {
      const productIndicator = new Map([
        [1, "internal"],
        [2, "hybrid"],
        [3, "intelypro"]
      ]);

      const pendoData: PendoData = {
        visitor: {
          id: payload.userid.toString(),
          email: payload.email,
          full_name: `${payload.firstname} ${payload.lastname}`,
          product_indicator: productIndicator.get(this.facilityType)
        },
        account: {
          id: String(payload.activeusertype.clientid),
          name: payload.activeusertype.clientname
        }
      };

      pendo.initialize(pendoData);
      pendo.identify(pendoData);
    } else {
      console.log('Pendo not initialized because clientId = 0');
    }
  }

  setClientType(userType: number): Observable<any> {
    return this.http.put<any>(`${this._shiftJavaUri}/settings/clienttype/value/${userType}`, {});
  }

}
