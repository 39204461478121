import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FuseConfigService } from '@theme/services/config.service';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserTypes } from 'src/app/config/user-types';
import { monthlyViewType } from '../../config/monthly-view-type';
import { theme_config } from '../../config/theme_config';
import { ManageUsersAccountService } from '../manage-users-account.service';

@Injectable(
  { providedIn: 'root' }
)
export class FacilityAuthGuard implements CanActivate {
  constructor(
    public _deviceService: DeviceDetectorService,
    private fuseConfigService: FuseConfigService,
    private cookieService: CookieService,
    private router: Router,
    private authenticationService: ManageUsersAccountService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.currentUserSubject.pipe(
      map(e => {
        if (!!e) {
          switch (e.activeusertype.usertypeid) {
            case UserTypes.Staff:
              theme_config.layout.sidepanel.hidden = true;
              theme_config.layout.toolbar.hidden = true;
              this.fuseConfigService.setConfig(theme_config);
              this.router.navigateByUrl(this.authenticationService.navigateToLandingPage(e.activeusertype.usertypeid));
              return false;

            case UserTypes.Scheduler:
            case UserTypes.HubUser:
              if (!this.cookieService.get('IntelycareAuthCookie')) {
                this.authenticationService.clearLocalStorage();
                e = null;
              }

              switch (this.authenticationService.facilityType) {
                case monthlyViewType.internal:
                  if (state.url === '/dashboard') {
                    this.router.navigateByUrl('/daily');
                    return false;
                  }
                  break;

                case monthlyViewType.intelypro:
                  if (!this._isSmallDevice() || state.url === '/redirect') {
                    if (state.url === '/daily') {
                      this.router.navigateByUrl('/monthly');
                      return false;
                    }
                  } else if (this._isSmallDevice()) {
                    if (this.router.url === '/facility/login') {
                      this.router.navigateByUrl('/redirect');
                      return false;
                    }
                  } else {
                    this.router.navigateByUrl(
                      this.authenticationService.getLoginUrlFromUserType(2)
                    );
                  }
                  return true;

                case monthlyViewType.hybrid:
                  if (!this._isSmallDevice() || state.url === '/redirect') {
                    return true;
                  } else if (this._isSmallDevice()) {
                    if (this.router.url === '/facility/login') {
                      this.router.navigateByUrl('/redirect');
                      return false;
                    }
                  } else {
                    this.router.navigateByUrl(
                      this.authenticationService.getLoginUrlFromUserType(2)
                    );
                  }
                  return true;
              }
          }
        }
      })
    );
  }

  private _isSmallDevice(): boolean {
    const iFrameDetection = (window === window.parent) ? false : true;
    return this._deviceService.isMobile() || iFrameDetection;
  }
}
