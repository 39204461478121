import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, Subject, zip } from 'rxjs';
import { StaffManagementService } from './staff-management.service';
import { catchError, debounceTime, delay, filter, flatMap, groupBy, map, mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { DailyViewService } from './daily-view.service';
import { DailyViewConfigModel } from '../models/daily-view-config-model';
import { Constants } from '../helpers/Constants';
import { GroupingDetails } from '../models/newModels/GroupingDetails';
import { ManageUsersAccountService } from './manage-users-account.service';
import { SocketIoService } from './socket-io.service';
import { EventTypes } from '../helpers/EventTypes';
import { ShiftsService } from './apis/shifts.service';
import { Shift } from '../models/shift-management-models/shift';
import { StaffMember } from '../models/shift-management-models/StaffMember.model';
import { ShiftTypesService } from './shift-types/shift-types.service';
import { FacilityShiftType } from './shift-types/models/facility-shift-type.model';

@Injectable({
  providedIn: 'root'
})
export class ViewLiveUpdateService {
  // subject for shifts changes
  createOpenShift: Subject<any> = new Subject();
  // subject for manual updating shifts after route change
  manualUpdate: Subject<any> = new Subject();
  // subject for request changes
  requestSent: BehaviorSubject<any> = new BehaviorSubject('');
  // subject for getting data after fetching from server
  shiftDataReload: BehaviorSubject<any> = new BehaviorSubject('');
  updatedOpenShifts: BehaviorSubject<Shift[]> = new BehaviorSubject([]);

  forceReload: Subject<any> = new Subject<any>();

  private reloadShiftTypes: BehaviorSubject<any> = new BehaviorSubject(true);

  dataLoadComplete: Subject<void> = new Subject();

  private _staffHashMap: { [s: number]: StaffMember };
  private _allShifts: Observable<Shift> = new Observable<Shift>();
  private config: DailyViewConfigModel;
  private staffTypesAllow = {};
  private allStaffTypes = {};
  private staffTypesArray: FacilityShiftType[] = [];

  constructor(
    private _shiftServiceJava: ShiftsService,
    private _staffService: StaffManagementService,
    private _userService: ManageUsersAccountService,
    private _socket: SocketIoService,
    private _dailyViewService: DailyViewService,
    private shiftTypesService: ShiftTypesService,

  ) {

    this._dailyViewService.dailyViewConfig.pipe(
    ).subscribe(e => {
      this.config = e;
    });

    combineLatest([
      this.reloadShiftTypes.asObservable(),
      this._userService.userLoggedInSubject.pipe(filter(e => !!e))
    ]).pipe(
      switchMap(_ => this.shiftTypesService.getShiftTypes())
    ).subscribe(e => {
      this.staffTypesArray = e;
      this.setIsIntelyProAllowedHashMap(e);
      this.setAllStaffTypesHashMap(e);
    });

    this._userService.userLoggedInSubject.pipe(
      filter(e => !!e),
      flatMap(e => this._dailyViewService.dailyViewConfig.pipe(
        filter(a => !!a),
        take(1),
      ))
    ).subscribe(e => {
      this._subscribeToShiftChanges();
      this._subscribeToRequestChanges();
    });
  }

  public shiftTypeChange() {
    this.reloadShiftTypes.next(true);
  }

  private static _staffListToHashMap(staffList: StaffMember[]) {
    return staffList ? staffList.reduce((p, c) => {
      p[c.staffId] = c;
      return p;
    }, {}) : {};
  }

  isIPAllowed(shiftTypeId: number) {
    return this.staffTypesAllow[shiftTypeId];
  }

  getSearchParameters() {
    return this.config.dateRange === 'daily' ? {
      startDate: formatDate(this.config.date.currentDate, 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(this.config.date.currentDate, 'yyyy-MM-dd', 'en-US')
    } : {
      startDate: formatDate(this.config.date.from, 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(this.config.date.to, 'yyyy-MM-dd', 'en-US')
    };

  }

  toStaffInfo(staffid: number): StaffMember {
    return this._staffHashMap[staffid];
  }

  getStaffMembers(condition: GroupingDetails) {
    return this._allShifts.pipe(
      filter(e => {
        return e.shiftTypeId === condition.stafftypeid
          && e.censusID === condition.census?.censusId
          && e.statusID === Constants.SHIFT_STATUS_DESCRIPTION.scheduled.id;
      }),
      filter(e => !!this.toStaffInfo(e.staffID)),
      groupBy(e => e.staffID),
      mergeMap(e => zip(of(e.key), e.pipe(toArray()))),
      map(e => ({
        staffMember: this.toStaffInfo(e[0]),
        shifts: e[1]
      })),
      toArray(),
      map(e => ([...e])),
    );
  }

  getOpenShifts(condition: GroupingDetails) {
    return this._allShifts.pipe(
      filter(e => {
        return e.shiftTypeId === condition.stafftypeid
          && e.censusID === condition.census?.censusId
          && (e.statusID === Constants.SHIFT_STATUS_DESCRIPTION.open.id || e.statusID === Constants.SHIFT_STATUS_DESCRIPTION.callOut.id);
      }),
      toArray(),
    );
  }

  getAllShiftsByCensusIds(censusIds: number[]) {
    return this._allShifts.pipe(
      filter(shift =>
        censusIds.includes(shift.censusID)
        && Object.keys(this.allStaffTypes).some(shiftTypeId => +shiftTypeId === shift.shiftTypeId)
        && (!shift.staffID || !!this.toStaffInfo(shift.staffID))
      )
    );
  }
  getFilledShifts() {
    return this._allShifts.pipe(
      filter(shift =>
        shift.statusID === Constants.SHIFT_STATUS_DESCRIPTION.scheduled.id
        && Object.keys(this.allStaffTypes).some(shiftTypeId => +shiftTypeId === shift.shiftTypeId)
        && !!this.toStaffInfo(shift.staffID)
      ), toArray(),
    );
  }

  getTotalFilledHours() {
    let duration = 0;
    this._allShifts.forEach((shift: Shift) => {
      duration += shift.duration;
    });
    return of(duration);
  }

  getAllOpenShifts() {
    return this._allShifts.pipe(
      delay(10),
      filter(e => (e.statusID === Constants.SHIFT_STATUS_DESCRIPTION.open.id ||
        e.statusID === Constants.SHIFT_STATUS_DESCRIPTION.callOut.id)
        && Object.keys(this.allStaffTypes).some(shiftTypeId => +shiftTypeId === e.shiftTypeId)
      ),
      toArray(),
      tap(openedShifts => this.updatedOpenShifts.next(openedShifts)));
  }

  getStaffTypeName(shiftypeId: number) {
    return this.allStaffTypes[shiftypeId];
  }

  getStaffSubTypes(shiftTypeId): FacilityShiftType {
    return this.staffTypesArray.find(e => e.shiftTypeId === shiftTypeId);
  }

  private _subscribeToShiftChanges() {
    return merge(
      this._dailyViewService.dailyViewConfig,
      this.forceReload,
      this._socket.onNewMessage(EventTypes.ADD_SHIFT),
      this._socket.onNewMessage(EventTypes.DELETE_SHIFT),
      this._socket.onNewMessage(EventTypes.SCHEDULE_SHIFT),
      this._socket.onNewMessage(EventTypes.MOVE_STAFF_TO_SHIFT),
      this._socket.onNewMessage(EventTypes.DECLINE_REQUEST),
      this._socket.onNewMessage(EventTypes.CONFIRM_SHIFT),
      this._socket.onNewMessage(EventTypes.ADD_CALLOUT),
      this._socket.onNewMessage(EventTypes.DELETE_STAFF),
      this._socket.onNewMessage(EventTypes.ADD_STAFF_TO_SHIFT),
      this._socket.onNewMessage(EventTypes.REMOVE_STAFF_TO_SHIFT)
    ).pipe(
      debounceTime(500),
      takeUntil(this._userService.userLoggedInSubject.pipe(
        filter(e => !e),
        tap(e => this._dailyViewService.dailyViewConfig.next(null))
      )
      ),
      tap(e => this._setEmptyState()),
      debounceTime(500),
      flatMap(e => zip(
        this.getAllStaff(),
        // this.getAllShifts(),
        this.getAllShiftsJava(),
        // this.getCensusBeds()
      )),
      tap(() => this.dataLoadComplete.next()),
      catchError(e => of({})),
    ).subscribe(e => {
      this.shiftDataReload.next('');
    });
  }

  private setAllStaffTypesHashMap(e: FacilityShiftType[]) {
    this.allStaffTypes = e.reduce((p, q) => {
      p[q.shiftTypeId] = q.displayName || q.name;
      return p;
    }, {});
  }

  private setIsIntelyProAllowedHashMap(e: FacilityShiftType[]) {
    this.staffTypesAllow = e.reduce((p, q) => {
      p[q.shiftTypeId] = q.isIntelyPro;
      return p;
    }, {});
  }

  private _setEmptyState() {
    this._allShifts = new Observable<Shift>();
    this.shiftDataReload.next('');
  }

  private getAllShiftsJava() {
    return this._shiftServiceJava.getShifts(this.getSearchParameters()).pipe(
      tap(e => { this._allShifts = from(!!e.data?.shifts ? e.data?.shifts : []) })
    );
  }

  private getAllStaff() {
    return this._staffService.getAllStaff(this._getDates()).pipe(
      map(e => e.data),
      map(ViewLiveUpdateService._staffListToHashMap),
      tap(e => this._staffHashMap = e));
  }

  private _getDates() {
    return {
      startDate: formatDate(this.config.date.from, 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(this.config.date.to, 'yyyy-MM-dd', 'en-US'),
      noHidden: true
    };
  }

  private _subscribeToRequestChanges() {
    return merge(
      this._socket.onNewMessage(EventTypes.CONFIRM_SHIFT),
      this._socket.onNewMessage(EventTypes.DECLINE_REQUEST),
      this._socket.onNewMessage(EventTypes.SEND_REQUEST),
      this._socket.onNewMessage(EventTypes.ACCEPT_REQUEST),
    ).pipe(
      flatMap(e => Array.isArray(e) ? from(e) : of(e))
    ).subscribe(e => this.requestSent.next(e));
  }
}