<div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" class="drawer-footer">
  <button [disabled]="isFirstButtonDisabled || isLoading" [ngClass]="{ 'disabled': isFirstButtonDisabled || isLoading }"
    automation-id="create-shift" (click)="buttonClicked(firstButtonLabel)" fxLayoutAlign="center center"
    fxLayoutGap="15px" class="shift-management-buttons shift-secondary-btn">
    <mat-icon *ngIf="firstButtonIcon">{{ firstButtonIcon }}</mat-icon>
    {{ firstButtonLabel }}
  </button>

  <button [disabled]="isSecondButtonDisabled || isLoading"
    [ngClass]="{ 'disabled': isSecondButtonDisabled || isLoading }" automation-id="assign-to-the-shift"
    (click)="buttonClicked(secondButtonLabel)" fxLayoutAlign="center center"
    class="shift-management-buttons shift-primary-btn">
    <mat-icon *ngIf="secondButtonLabel">{{ secondButtonIcon }}</mat-icon>
    {{ secondButtonLabel }}
  </button>
</div>