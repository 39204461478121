import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ShiftDefs } from 'src/app/models/shift-management-models/ShiftDefs';
import { ShiftType } from 'src/app/models/ShiftType';

@Component({
  selector: 'app-custom-shift-time-display',
  templateUrl: './custom-shift-time-display.component.html',
  styleUrls: ['./custom-shift-time-display.component.scss']
})
export class CustomShiftTimeDisplayComponent implements OnInit {

  @Input() shiftDef: ShiftDefs;
  @Input() shiftType: ShiftType;

  @Input() startTime: string;
  @Input() endTime: string;

  @Input() timeFontSize: string = '14px';
  @Input() periodFontSize: string = '10px';

  constructor() { }

  ngOnInit(): void {
  }

  get shiftStartTime(): Date {
    return moment(this.startTime, 'HH:mm:ss').toDate();
  }

  get shiftEndTime(): Date {
    return moment(this.endTime, 'HH:mm:ss').toDate();
  }
}
