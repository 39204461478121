import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointSeparatorComponent } from './point-separator.component';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [PointSeparatorComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [PointSeparatorComponent]
})
export class PointSeparatorModule { }
