import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, flatMap, map, take, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { Constants } from '../../../helpers/Constants';
import { formatDate } from '@angular/common';
import { ShiftManagementService } from '../../../services/shift-management.service';
import { ViewLiveUpdateService } from '../../../services/view-live-update.service';
import { ShiftManagementSidebarService } from '../../shift-management-sidebar.service';
import { of, Subject, zip } from 'rxjs';
import { StaffService } from '../../../services/staff.service';
import { ShiftNew, ShiftRequest } from 'src/app/models/java-models';

@Component({
  selector: 'request-intelypro',
  templateUrl: './request-intelypro.component.html',
  styleUrls: ['./request-intelypro.component.scss']
})
export class RequestIntelyproComponent implements OnInit, OnDestroy {

  openedShifts: ShiftNew[] = [];
  selectedShifts: ShiftNew[] = [];
  selectedSubtypes: { [shiftsid: number]: number } = {};
  selectedGenders: { [shiftsid: number]: string } = {};
  canRequestIntelyPro = false;
  buttonClicked = false;
  selectGender = false;
  defaultGender = null;
  private _unsubscribe: Subject<any> = new Subject();

  constructor(private _shiftService: ShiftManagementService,
    private _liveUpdate: ViewLiveUpdateService,
    private _staffService: StaffService,
    private _sidebar: ShiftManagementSidebarService) {
  }

  get areAllSelected() {
    return this.openedShifts.length && this.openedShifts.length === this.selectedShifts.length;
  }

  ngOnInit() {
    this.openedShifts = [];
    const start = moment().toDate();

    this.getAllOpenShifts(start).pipe(
      flatMap(e => e.shifts),
      filter(e => this._liveUpdate.isIPAllowed(e.staffTypeID)),
      flatMap((e: ShiftNew) => zip(of(e), this._shiftService.hasIntelyproRequest(e.shiftID))),
      filter(e => !e[1]),
      map(e => e[0]),
      tap(e => this.openedShifts.push(e)),
      takeUntil(this._unsubscribe)
    ).subscribe(e => {
    },
      error => {
      },
      () => {
        this.openedShifts = this.openedShifts.sort((e1, e2) => {
          const a = moment(e1.shiftDate + ' ' + e1.startTime).toDate().getTime();
          const b = moment(e2.shiftDate + ' ' + e2.startTime).toDate().getTime();
          return a > b ? 1 : a < b ? -1 : 0;
        });
      });

    this._staffService.isClientOnHoldISystem().subscribe(e => {
      this.canRequestIntelyPro = !!e && e.billingStatus !== 'Hold' && e.clientStatus === 'Yes';
    }, error => {
      this.canRequestIntelyPro = true;
    });

    this._staffService.getGenderPreferences().pipe(
      map(e => !!e.data ? e.data : null)
    ).subscribe(preference => {
      this.selectGender = !!preference ? preference.genderpreference.preferenceid === Constants.GENDER_PREFERENCE.select : false;
      this.defaultGender = !this.selectGender ? preference.genderpreference.preferenceid === Constants.GENDER_PREFERENCE.femaleOnly ? 'F' : 'E' : 'E';
    }, error => {
      this.selectGender = false;
      this.defaultGender = 'E';
    });
  }

  getAllOpenShifts(start) {
    return this._shiftService.getShifts({
      statusArray: [Constants.SHIFT_STATUS_DESCRIPTION.open.id, Constants.SHIFT_STATUS_DESCRIPTION.callOut.id],
      startDate: formatDate(start, 'yyyy-MM-dd', 'en-US'),
      asc: 1
    });
  }

  shiftSelected(insert: boolean, subtype: number, shift: ShiftNew, gender: string) {
    const idx = this.selectedShifts.indexOf(shift);
    if (insert) {
      if (idx < 0) {
        this.selectedShifts.push(shift);
        this.selectedSubtypes[shift.shiftID] = subtype;
        this.selectedGenders[shift.shiftID] = gender;
      } else {
        this.selectedGenders[shift.shiftID] = gender;
      }
    } else {
      this.selectedShifts.splice(idx, 1);
      delete this.selectedSubtypes[shift.shiftID];
      delete this.selectedGenders[shift.shiftID];
    }
  }

  requestIntelypro() {
    this.buttonClicked = true;
    const request: ShiftRequest = {
      message: null,
      staffID: null,
      intelyPro: 1
    };

    const req: ShiftRequest[] = this.selectedShifts.map(e => ({
      ...request, shiftID: e.shiftID,
      stafftypeid: e.staffTypeID,
      staffsubtypeid: this.selectedSubtypes[e.shiftID],
      gender: this.selectGender ? this.selectedGenders[e.shiftID] : this.defaultGender
    }));
    this._shiftService.sendRequest(req).pipe(
      take(1)
    ).subscribe(e => {
      this.buttonClicked = false;
      this._sidebar.closePanel();
    },
      error1 => {
        // should include error handling
        this.buttonClicked = false;
      },
      () => {
        this.buttonClicked = false;
      });
  }

  toggleAll(newValue: boolean) {
    this.selectedShifts = newValue ? [...this.openedShifts] : [];
    this.selectedSubtypes = newValue ? this.openedShifts.reduce((p, s) => {
      p[s.shiftID] = null;
      return p;
    }, {}) : {};
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
