import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-change-shift',
  templateUrl: './change-shift.component.html',
  styleUrls: ['./change-shift.component.scss']
})
export class ChangeShiftComponent implements OnInit, AfterViewInit {


  @Output() shiftChange: EventEmitter<any> = new EventEmitter();
  @Input() currentShift: number;
  @Input() currentDate: moment.Moment;
  @Input() elements: { name: string, value: number }[];
  dateToday = moment().toDate();
  changeShift = false;
  dateFormControl: FormControl = new FormControl('', [Validators.required]);

  constructor() {
  }

  ngOnInit() {
  }

  toggleChangeShift() {
    this.dateFormControl.patchValue(this.currentDate, { emitEvent: true });
    this.changeShift = !this.changeShift;
  }

  ngAfterViewInit(): void {
    this.dateFormControl.reset(this.currentDate, { emitEvent: true });
  }

  moveShift() {
    this.shiftChange.next({
      date: moment(this.dateFormControl.value['_i']),
      shift: this.currentShift
    });
  }
}
