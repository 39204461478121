import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private _cs: CookieService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: false,
      setHeaders: { ...this.headers }
    });
    return next.handle(req);
  }

  get headers(): { [p: string]: string } {
    const token = this._cs.get('IntelycareAuthCookie');
    const refreshtoken = this._cs.get('IntelycareAuthCookie');
    return !!token ? { Authorization: token } : {};
  }
}
