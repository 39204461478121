export class ShiftManagementActions {

  scheduler_click_print = {
    name: 'Scheduler Print',
    label: 'Print Button Click'
  };

  scheduler_print = {
    name: 'Scheduler Print',
    label: 'Continue Button Click on Print'
  };

  schedular_remove_with_reason = {
    name: 'Scheduler Remove Staff from Shift',
    label: 'Scheduler Remove Staff from Shift with reason'
  };

  add_to_request = {
    name: 'Scheduler "Add to Request"',
    label: 'Scheduler "Add to Request"'
  };
}
