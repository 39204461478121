<div class="popup-container">
  <div class="title-container">
    <h2>Are you sure?</h2>
    <a matDialogClose 
       automation-id="close-popup-button"
       mat-button class="close">
       <i class="fal fa-times"></i>
    </a>
  </div>
    <div class="header-container">
      <i class="fas fa-exclamation-circle"></i>
      <p>Creating Multiple Open Shifts</p>
    </div>
    <h5 class="warning">You are creating 
      <strong> {{shiftsCount}} </strong>
       open shifts
    </h5>
    <h5 class="description">Each open shift will need to be 
        <strong>filled</strong> and/or <strong>modified</strong> individually.
    </h5>
    <div class="button-container">
      <button mat-button
              (click)="createBulkShifts(true)"
              automation-id="keep-intelypro-button" 
              class="cancel-button button">
              Yes, Continue
      </button>
      <button mat-button
              (click)="createBulkShifts(false)"
              automation-id="close-intelypro-button"
              class="continue-button button">
              No, Cancel
      </button>
    </div>
  </div>
  