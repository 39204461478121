import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { ConfirmCartMobileComponent } from './confirm-cart-mobile.component';


@NgModule({
  declarations: [ConfirmCartMobileComponent],
  exports: [
    ConfirmCartMobileComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class ConfirmCartMobileModule {
}
