export class UserManagementActions {
  scheduler_login = {
    name: 'Scheduler Login',
    label: 'Login Button Click'
  };

  staff_login = {
    name: 'Staff Login',
    label: 'Login Button Click'
  };

  sign_up = {
    name: 'Staff Create Account',
    label: 'Register'
  };

  activate = {
    name: 'Activate Staff Account',
    label: 'Activate'
  };

  log_out = {
    name: 'User Log Out',
    label: 'Log Out'
  };

  reset_password = {
    name: 'Recover Staff Password',
    label: 'Reset Password'
  };
}

