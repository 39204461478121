import { Component, OnInit } from '@angular/core';
import { MenuItems } from '../../../models/MenuItems';
import { StaffModuleManagementService } from '../../../staff-management/staff-module-management.service';

@Component({
  selector: 'app-facility-left-sidebar',
  templateUrl: './facility-left-sidebar.component.html',
  styleUrls: ['./facility-left-sidebar.component.scss']
})
export class FacilityLeftSidebarComponent implements OnInit {

  menuItems = new MenuItems();
  currentUrl = '/directory';

  constructor(
    private staffModuleManagementService: StaffModuleManagementService
  ) {
    this.staffModuleManagementService.getTotalStaffMembersNumber().pipe().subscribe(resp => {
      this.menuItems.directory.num = resp;
    });
  }

  ngOnInit() {
  }

}
