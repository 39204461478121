<div class="request-intelypro">
  <div class="request-intelypro-wrapper" fxFlex fxLayout="column" fxLayoutGap="16px">

    <div class="request-intelypro-header">
      <card-system-top-header [title]="'Request IntelyPro'"></card-system-top-header>
    </div>

    <div class="count-indicator" fxLayoutAlign="center center">
      <span>{{openedShifts.length}} open shifts</span>
    </div>

    <div class="content" fxFlex="0 1 auto" fxFlexFill>
      <div class="content-wrapper" fxLayout="column" fxLayoutGap="20px">
        <mat-checkbox *ngIf="openedShifts.length" (change)="toggleAll($event.checked)"
                      [checked]="areAllSelected">Select All
        </mat-checkbox>

        <div class="opened-shifts" fxLayout="column" fxLayoutGap="20px">
          <request-intelypro-card [shiftDetails]="shift"
                                  [selected]="selectedShifts.indexOf(shift) > -1" [selectGender]="selectGender"
                                  (selectionChange)="shiftSelected($event.selected, $event.subtype, shift, $event.gender)"
                                  *ngFor="let shift of openedShifts"></request-intelypro-card>
        </div>
      </div>
    </div>

    <div class="actions" fxFlex="84px">
      <div class="actions-wrapper" fxFlex="" fxLayout="column" fxLayoutAlign="center center">
        <button mat-button class="action-button"
                [disabled]="selectedShifts.length === 0 || !canRequestIntelyPro || buttonClicked"
                fxFlexAlign="center"
                [ngClass]="selectedShifts.length === 0 || !canRequestIntelyPro || buttonClicked ? 'disabled' : 'complete' "
                (click)="requestIntelypro()"
        > Request IntelyPro
        </button>
      </div>
    </div>

  </div>
</div>
