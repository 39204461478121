import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConvertersService {

  constructor() { }

  static _datesToShiftHours(shiftData: { startTime: string, endTime: String }): string {
    const start = moment().hour(Number(shiftData.startTime?.split(':')[0])).minute(0).toDate();
    const end = moment().hour(Number(shiftData.endTime?.split(':')[0])).minute(0).toDate();
    return formatDate(start, 'h:mm a', 'en-US') + ' - ' + formatDate(end, 'h:mm a', 'en-US');
  }


  static _datesToShiftHoursMobile(shiftData: { startTime: string, endTime: String }): string {
    const start = moment().hour(Number(shiftData.startTime?.split(':')[0])).minute(0).toDate();
    const end = moment().hour(Number(shiftData.endTime?.split(':')[0])).minute(0).toDate();
    return formatDate(start, 'h:mma', 'en-US') + '-' + formatDate(end, 'h:mma', 'en-US');
  }



  static _datesToShiftHoursMinutes(shiftData: { starttime: string, endtime: String }): string {
    const start = moment().hour(Number(shiftData.starttime?.split(':')[0])).minute(Number(shiftData.starttime?.split(':')[1])).toDate();
    const end = moment().hour(Number(shiftData.endtime?.split(':')[0])).minute(Number(shiftData.endtime?.split(':')[1])).toDate();
    return formatDate(start, 'h:mm a', 'en-US') + '-' + formatDate(end, 'h:mm a', 'en-US');
  }

  static _calculateShiftHours(shiftData: { startTime: string, endTime: String }): string {
    const start = new Date().setHours(Number(shiftData.startTime?.split(':')[0]));
    const end = new Date().setHours(Number(shiftData.endTime?.split(':')[0]));
    let hours = (end - start) / (3600 * 1000);

    if (end < start) {
      hours += 24;
    }

    return String(Math.round(hours));
  }




  static _getShiftLength(shiftDetails, today) {
    return shiftDetails.isPartial ?
      this._calculateHours(shiftDetails.partialShift.startTime, shiftDetails.partialShift.endTime, today)
      : this._calculateHours(shiftDetails.startTime, shiftDetails.endTime, today);
  }

  static _calculateHours(startTime: string, endTime: string, today): string {
    const start = moment(today.format('yyyy-MM-DD') + ' ' + startTime).toDate().getTime();
    let end = moment(today.format('yyyy-MM-DD') + ' ' + endTime).toDate().getTime();
    if (end <= start) {
      end = moment(end).add(1, 'days').toDate().getTime();
    }
    const seconds = Math.floor((end - start) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    return hours
      + 'H ' + (minutes === 0 ? '' : (minutes < 10 ? '0' + minutes : minutes) + 'MIN');
  }

  static _getStandardDate(date: string) {
    // Format date from MM-DD-YYYY HH:mm:ss to YYYY-MM-DD HH:mm:ss. Return unchange date if format is different
    const parsed = new Date(date);
    if (!date || !isNaN(parsed.getDate())) {
        return date;
    } 
    const dateParts = date.split(' ');
    if (dateParts.length === 2) {
        const [datePart, timePart] = dateParts;
        const [month, day, year] = datePart.split('-');
        if (month && day && year && timePart) {
            return `${year}-${month}-${day} ${timePart}`;
        }
    } 
    return date;
  }
}
