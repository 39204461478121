<div class="details" [class.isToggel]="isToggel">
    <div class="displayDate">
        <ng-container *ngIf="panelTitle; else listView"><span *ngFor="let day of item.days">{{day | date:'E LLL d'}}</span></ng-container>
        <ng-template #listView>{{day | date:'E LLL d'}}
        </ng-template>
    </div>
    <div class="text">{{ item.shiftTime.shiftName }}</div>
    <div class="displayType">
        <div *ngFor="let st of item.staffTypes || []">
            <span *ngIf="!st.staffType.stateSpecificLabel?.length">
                <span class="text">{{ st.count }} {{ st.staffType.shiftTypeLabel }}</span>
            </span>
        </div>
    </div>
</div>