import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShiftData } from '../../../../../models/newModels/ShiftData';
import { ViewLiveUpdateService } from '../../../../../services/view-live-update.service';

@Component({
  selector: 'request-intelypro-card',
  templateUrl: './request-intelypro-card.component.html',
  styleUrls: ['./request-intelypro-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestIntelyproCardComponent implements OnInit {

  @Output() selectionChange: EventEmitter<{ selected: boolean; subtype: number; gender: string }> = new EventEmitter();
  @Input() shiftDetails: ShiftData;
  @Input() selectGender: boolean;
  @Input() selected = false;
  subtype = null;
  gender = 'E';

  expanded = false;
  subtypes: { name: string, value: number }[];

  constructor(private _liveService: ViewLiveUpdateService) {
  }

  ngOnInit() {
    this.subtypes = this._liveService.getStaffSubTypes(this.shiftDetails.stafftypeid).intelyProStaffTypes.map(e => ({
      name: e.name.split(' ')[1],
      value: e.intelyProStaffTypeId
    }));
  }

  toggleSelection(checked: boolean) {
    this.selected = checked;
    this.expanded = checked && !!this.subtypes.length;
    this.selectionChange.next({ selected: this.selected, subtype: this.subtype, gender: this.gender });
  }

  getAllSubtypeValues() {
    return this.subtypes.map(e => e.name).join(' or ');
  }

  changeSubtype(value: number) {
    this.subtype = value;
    this.selectionChange.next({ selected: this.selected, subtype: this.subtype, gender: this.gender });
  }

  changeGender(value: string) {
    this.gender = value;
    this.selectionChange.next({ selected: this.selected, subtype: this.subtype, gender: this.gender });
  }

}
