import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, combineLatest, merge, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-validate-password',
  templateUrl: './validate-password.component.html',
  styleUrls: ['./validate-password.component.scss']
})
export class ValidatePasswordComponent implements OnInit, OnDestroy {

  @Input() password: string = '';
  @Input() confirm: string = '';
  @Input() passwordFormControl: FormControl;
  @Input() confirmFormControl: FormControl;
  @Output() passMatchEvent = new EventEmitter();
  @Output() passwordValid = new EventEmitter();
  passValidLength = false;
  passContainsNumber = false;
  passContainsLower = false;
  passContainsCapital = false;
  passMatch = false;
  public readonly imagePath = "assets/images/check_24px.svg"
  private _unsubscribe = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    if (this.passwordFormControl && this.confirmFormControl) {
      this.passwordFormControl.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(value => {
        this.validatePassword(!!value ? value : '');
      });
      combineLatest([this.passwordFormControl.valueChanges, this.confirmFormControl.valueChanges]).pipe(takeUntil(this._unsubscribe)).subscribe(([password, confirmPass]) => {
        this.passwordMatches(password, confirmPass);
      });
    }
  }

  ngOnDestroy(): void {
      this._unsubscribe.next();
      this._unsubscribe.complete();
  }

  validatePassword(password?: string) {
    const pass = typeof password !== 'undefined' ? password : this.password;
    if (new RegExp(/\d+/, 'g').test(pass)) {
      this.passContainsNumber = true;
    } else {
      this.passContainsNumber = false;
    }

    const re = /.*[A-Z]+.*/;
    const lc = /.*[a-z]+.*/;

    if (re.test(pass)) {
      this.passContainsCapital = true;
    } else {
      this.passContainsCapital = false;
    }

    if (lc.test(pass)) {
      this.passContainsLower = true;
    } else {
      this.passContainsLower = false;
    }

    if (pass.length >= 6) {
      this.passValidLength = true;
    } else {
      this.passValidLength = false;
    }
    this.passwordValid.emit(this.passContainsNumber && this.passContainsCapital && this.passContainsLower && this.passValidLength);
  }

  passwordMatches(password?: string, confirmPass?: string) {
    const confPass = confirmPass ? confirmPass : this.confirm;
    const pass = password ? password : this.password;
    if (confPass === pass && pass !== '') {
      this.passMatch = true;
    } else {
      this.passMatch = false;
    }
    this.passMatchEvent.emit(this.passMatch);

  }

}
