import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UnitInfo } from 'src/app/models/shift-management-models/UnitInfo';

@Component({
  selector: 'app-change-unit',
  templateUrl: './change-unit.component.html',
  styleUrls: ['./change-unit.component.scss']
})
export class ChangeUnitComponent implements OnInit, OnChanges {

  @Output() unitMoved: EventEmitter<any> = new EventEmitter();
  @Input() unitToMove: number;
  @Input() elements: UnitInfo[];

  changeUnit = false;
  control: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.control.setValue(this.unitToMove);
  }

  public moveToUnit() {
    this.unitMoved.next(this.control.value);
  }

  toggleChangeUnit() {
    this.changeUnit = !this.changeUnit;
  }
}
