import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacilityLeftSidebarComponent } from './facility-left-sidebar.component';
import { FacilityIconModule } from '../facility-icon/facility-icon.module';
import { SideMenuItemModule } from '../side-menu-item/side-menu-item.module';

@NgModule({
  declarations: [FacilityLeftSidebarComponent],
  exports: [
    FacilityLeftSidebarComponent
  ],
  imports: [
    CommonModule,
    FacilityIconModule,
    SideMenuItemModule
  ]
})
export class FacilityLeftSidebarModule { }
