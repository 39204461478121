<div class="responses-content" fxLayout="column">
  <div class="responses-wrapper" fxLayout="column" fxLayoutGap="8px">

    <div class="staff-request-responses">
      <div class="staff-request-responses-wrapper">
        <mat-list fxLayout="column" fxLayoutGap="12px" *ngFor="let orequest of responses.requests | keyvalue">
          <app-staff-card-confirmation [staffMember]="getStaffMember(irequest.staffid)" [response]="orequest.key"
                                       (selectionChange)="setSelectedItem(irequest)"
                                       *ngFor="let irequest of orequest.value"></app-staff-card-confirmation>
        </mat-list>
      </div>
    </div>

    <div class="actions" fxFlex="76px">
      <div class="actions-wrapper" fxFlex fxLayout="column" fxLayoutAlign="center center">
        <button class="action-button" mat-button
                [disabled]="!selectedRequest"
                [ngClass]="!!selectedRequest ? 'complete' : 'disabled'"
                (click)="confirmShift()"
        >Confirm
        </button>
      </div>
    </div>

  </div>
</div>
