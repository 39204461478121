import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/shared/material.module";
import { SharedModule } from "src/app/shared/shared.module";
import { ConfirmShiftsSpreadsheetComponent } from "./confirm-shifts-spreadsheet.component";
import { ChipIconModule } from "src/app/shared/componets/chip-icon/chip-icon.module";
import { StaffCardConfirmationModule } from "src/app/shared/componets/staff-card-confirmation/staff-card-confirmation.module";
import { StaffCardExpandableModule } from "src/app/shared/componets/staff-card-expandable/staff-card-expandable.module";
import { ManagementTopCardModule } from "src/app/shift-management/components/management-top-card/management-top-card.module";
import { CustomDrawerFooterModule } from "src/app/shared/componets/custom-drawer-footer/custom-drawer-footer.module";


@NgModule({
    declarations: [ConfirmShiftsSpreadsheetComponent],
    exports: [ConfirmShiftsSpreadsheetComponent],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        ManagementTopCardModule,
        StaffCardExpandableModule,
        ChipIconModule,
        StaffCardConfirmationModule,
        CustomDrawerFooterModule,

    ],
})
export class ConfirmShiftsSpreadsheetModule { }
