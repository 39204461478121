import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardSystemShiftDetailsComponent } from './card-system-shift-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../shared/material.module';
import { PointSeparatorModule } from '../../../shared/componets/point-separator/point-separator.module';

@NgModule({
  declarations: [CardSystemShiftDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    PointSeparatorModule
  ],
  exports: [
    CardSystemShiftDetailsComponent
  ]
})
export class CardSystemShiftDetailsModule {
}
