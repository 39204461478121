import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  constructor(
    public _deviceService: DeviceDetectorService,
  ) { }

  isDevice() {
    const iFrameDetection = (window === window.parent) ? false : true;
    return this._deviceService.isMobile() || this._deviceService.isTablet() || iFrameDetection;
  }
}
