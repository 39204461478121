import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-point-separator',
  templateUrl: './point-separator.component.html',
  styleUrls: ['./point-separator.component.scss']
})
export class PointSeparatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
