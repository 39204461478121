<div class="add-staff-wrapper" id="add-staff">
  <div class="title-wrapper">
    <mat-icon 
      class="close-icon" 
      (click)="closeSidebar()"
      automation-id="close-icon">
      close
    </mat-icon>
    <label class="title-text">
      Add / Upload Staff
    </label>
  </div>
  <mat-radio-group 
    class="radio-group">
      <mat-radio-button 
      class="radio-option"
      [ngClass]="selectedFormDisplay === 1 ? 'text-hightlight' : 'text-hightlight-no'" 
      automation-id="individual-staff" 
      value="1"
      checked
      (click)="setStaffForm(1)">
      <span>Individual Staff Member</span>
      </mat-radio-button>
    <mat-radio-button
      class="radio-option"
      [ngClass]="selectedFormDisplay === 2 ? 'text-hightlight' : 'text-hightlight-no'" 
      automation-id="multiple-staff-member" 
      value="2"
      (click)="setStaffForm(2)">
      <span>Multiple Staff Member List</span>
      <h5> (via Spreadsheet Upload) </h5>
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="selectedFormDisplay === 1" class="content">
    <app-individual-staff-form></app-individual-staff-form>
  </div>
  <app-confirm-facility-schedule-details (clicked)="clickedConfirm=$event"
    *ngIf="selectedFormDisplay === 2 && !clickedConfirm" class="confirm-sched">
  </app-confirm-facility-schedule-details>
  <app-download-template-upload 
    *ngIf="selectedFormDisplay === 2 && clickedConfirm"
    (renderPage)="navigatPage($event)">
  </app-download-template-upload>
</div>