
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  ALLOW_ANALYTICS: true,
  AUTH_BASE_URL: 'https://api.intelycare.com/auth',
  AUTH_SERVICE_BASE_URL: 'https://api.intelycare.com/authservice',
  DOCUMENT_BASE_URL: 'https://api.intelycare.com/document',
  GOOGLE_KEY: 'AIzaSyCvfsuYeV6GtDthXGiVxnDE-HAex6h6atY',
  ICARE_BASE_URL: 'https://api.intelycare.com/icaredata',
  SHIFT_JAVA_URL: 'https://api.intelycare.com/shiftmanagement',
  STAFF_BASE_URL: 'https://api.intelycare.com/staff',
  USER_BASE_URL: 'https://api.intelycare.com/jwt/user',
  ALLOW_MARKETO: true,
  BACKGROUND_CHECK_URL: 'https://api.intelycare.com/background',
  CLIENTID: '1',
  ISYSTEM_BYPASS_GATEWAY_BASE_URL: 'https://demand.prod.platform.legacy.intelycare.com/isystem',
  LEGACY_HUB_LOGIN: 'https://portal.intelycare.com/hub/views/login.html',
  SCHEDULING_URL: 'https://demand.prod.platform.legacy.intelycare.com',
  SHIFT_BASE_URL: 'https://api.intelycare.com/backend',
  SOCKET_BASE_URL: 'https://socket.prod.platform.legacy.intelycare.com',
  TASK_BASE_URL: 'https://api.intelycare.com/staff/notes',
  INTERNAL_URL: 'https://api.intelycare.com',
  ISYSTEM_BASE_URL: 'https://api.intelycare.com/isystem',
  JOB_BOARD_URL: 'https://intelycare.com/job-board/intelycare-auth-callback',
  SERVICE: 'ISchedule',
  SHIFT_URL: 'https://api.intelycare.com/shift'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.