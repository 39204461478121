<div class="container">
    <div class="header">
        <h3>Shift Request Summary</h3>
        <button automation-id="shift-request-summary-close" (click)="close()" mat-icon-button>
            <i class="fal fa-times"></i>
        </button>
    </div>
    <mat-divider></mat-divider>
    <div class="content">
        <div *ngIf="!cartItems.length; else showCartItems" class="no-items">
            No items in your cart
        </div>
        <ng-template #showCartItems>
            <div class="cart-list">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let item of cartItems" class="mobile-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <app-cart-details class="cart-details" [item]="item"
                                    [panelTitle]="true"></app-cart-details>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let day of item.days">
                            <div class="title">
                                <app-cart-details class="cart-details" [item]="item" [day]="day"
                                    [isToggel]="true"></app-cart-details>
                            </div>
                            <div class="body">
                                <div class="location">Location : {{ item.unit?.floorName }}</div>
                                <div *ngFor="let st of item.staffTypes || []">
                                    <span *ngIf="!st.staffType.stateSpecificLabel?.length">
                                        <span class="text">{{st.staffType.shiftTypeLabel}} : {{st.count}} {{st.gender
                                            !==
                                            'F' ?
                                            'Male or Female' :
                                            'Female'}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <mat-divider class="custom-divider"></mat-divider>
                        </ng-container>
                        <div class="action">
                            <button automation-id="delete-item" (click)="delete(item)" class="delete" mat-icon-button>
                                <mat-icon>delete</mat-icon>
                                Delete Shift
                            </button>
                            <button automation-id="update-item" (click)="update(item)" class="edit" mat-icon-button>
                                <mat-icon>edit</mat-icon>
                                Edit Shift
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-template>
    </div>
    <div class="footer">
        <div class="mobile-summary">
            <div class="summary-title">SUMMARY</div>
            <div class="summary-content">
                <span *ngFor="let el of totalPerStaffType | keyvalue; let isLast = last">
                    {{el.value.total}}
                    <span *ngIf="el.value.staffType?.stateSpecificLabel?.length">
                        <span *ngFor="let state of el.value.staffType?.stateSpecificLabel">
                            {{state.shiftTypeLabel}}
                        </span>
                    </span>
                    <span *ngIf="!el.value.staffType?.stateSpecificLabel?.length">
                        {{el.value.staffType.shiftTypeLabel}}
                    </span>
                    <span *ngIf="!isLast">+</span>
                </span>
                <span>Total IPs Requested</span>
                <br>
                <span>{{totalIntelypros}} Total Shifts Requested</span>
            </div>
            <div></div>
        </div>
        <div class="buttons">
            <button class="action-button complete" [class.disabled]="!(cartItems.length && !requesting)"
                [disabled]="!cartItems.length || requesting" (click)="submit()" mat-button>
                submit requests
                <img *ngIf="requesting" src="/assets/images/loader.gif" height="30" width="30" />
            </button>
            <button class="action-button complete" [class.disabled]="!(cartItems.length && !requesting)"
                (click)="clearCart()" [disabled]="!cartItems.length || requesting" mat-button>
                cancel requests
            </button>
        </div>
    </div>
</div>