import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-browser-warning',
  templateUrl: './browser-warning.component.html',
  styleUrls: ['./browser-warning.component.scss']
})
export class BrowserWarningComponent implements OnInit {
  isChromeBrowser: boolean = false;

  constructor(public _deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.showWarningIfNotChrome();

  }

  showWarningIfNotChrome() {
    const deviceInfo: any = this._deviceService.getDeviceInfo();
    const isMobile: boolean = this._deviceService.isMobile();

    if ((!isMobile && deviceInfo.browser === 'Chrome') || isMobile) {
      this.isChromeBrowser = true;
    } else {
      this.isChromeBrowser = false;
    }
  }
}
