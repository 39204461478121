import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { FuseSidebarModule } from '@theme/components';
import { ContentModule } from '../../components/content/content.module';
import { NavbarModule } from '../../components/navbar/navbar.module';
import { QuickPanelModule } from '../../components/quick-panel/quick-panel.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { VerticalLayout2Component } from './layout-2.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FacilityLeftSidebarModule } from '../../../shared/componets/facility-left-sidebar/facility-left-sidebar.module';
import { FacilityIconModule } from '../../../shared/componets/facility-icon/facility-icon.module';
import { OnBoardingSidebarModule } from '../../../shared/componets/on-boarding-sidebar/on-boarding-sidebar.module';

@NgModule({
  declarations: [
    VerticalLayout2Component
  ],
  imports: [
    RouterModule,
    SharedModule,
    FuseSidebarModule,
    ContentModule,
    NavbarModule,
    QuickPanelModule,
    ToolbarModule,
    MatIconModule,
    MatButtonModule,
    FacilityLeftSidebarModule,
    OnBoardingSidebarModule,
    FacilityIconModule
  ],
  exports: [
    VerticalLayout2Component
  ]
})
export class VerticalLayout2Module {
}
