import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ShoppingCartService } from '../../../../services/shopping-cart.service';
import { MonthlyViewService } from 'src/app/monthly-view/services/monthly-view.service';
import { map } from 'rxjs/operators';
import { TotalCartInfo } from '../../../../models/total-cart-info-data';

@Component({
  selector: 'app-confirm-cart-mobile',
  templateUrl: './confirm-cart-mobile.component.html',
  styleUrls: ['./confirm-cart-mobile.component.scss']
})
export class ConfirmCartMobileComponent implements OnInit {

  @Input() totalCartInfo: TotalCartInfo;
  @Output() done = new EventEmitter();
  boostResponse: any = {};
  requestIds$: Observable<number[]>;
  hasBoost$: Observable<boolean>;

  constructor(
    private _cartService: ShoppingCartService,
    private _monthlyViewService: MonthlyViewService
  ) { }

  ngOnInit(): void {
    this.requestIds$ = this._cartService.requestedShifts.pipe(
      map(shiftResponse => shiftResponse.map(item => item.reqId))
    );
    this.hasBoost$ = this._monthlyViewService.hasBoostButton;
  }

  close() {
    this.done.emit()
  }

  getBoostResponse($event: any) {
    this.boostResponse = $event;
  }

}
