import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FuseConfigService } from '@theme/services/config.service';
import { FuseNavigationService } from '@theme/components/navigation/navigation.service';
import { FuseSidebarService } from '@theme/components/sidebar/sidebar.service';
import { Platform } from '@angular/cdk/platform';
import { ShoppingCartService } from '../monthly-view/components/bulk-shift-booking/services/shopping-cart.service';
import { ChatAdapter } from 'ng-chat';
import { CustomChatAdapter } from '../chat/adapter/CustomChatAdapter';
import { ChatService } from '../chat/services/chat.service';
import { ManageUsersAccountService } from '../services/manage-users-account.service';
import { SocketIoService } from '../services/socket-io.service';
import { NavigationEnd, Router } from '@angular/router';
import { navigation } from '../config/navigation';
import { monthlyViewType } from '../config/monthly-view-type';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
  clientId = -1;
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  public adapter: ChatAdapter;
  _NavbarRoutes = ['/directory', '/daily', '/daily-new', '/spreadsheet', '/settings', '/monthly', '/week', '/request-intelypro', '/dashboard', '/timecards'];
  unsubscribe = new Subject<any>();
  viewType = monthlyViewType;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {Platform} _platform
   */
  constructor (
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _platform: Platform,
    private _router: Router,
    private _socket: SocketIoService,
    private _chatService: ChatService,
    private _userService: ManageUsersAccountService,
    public shoppingCartService: ShoppingCartService
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en-US' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     setTimeout(() => {
            this._translateService.setDefaultLang('en-US');
            this._translateService.setDefaultLang('tr');
         });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    _router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const showChatWindow = this.showChat();

        if (!showChatWindow) {
          this.adapter = null;
          this.unsubscribe.next();
        } else if (!this.adapter) {
          this.adapter = new CustomChatAdapter(this._socket, this._chatService, this.unsubscribe);
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });


    this._chatService.totalUnread.pipe(
      tap(count => {
        let title = '<span class="material-icons">question_answer</span><span>Nurse Connect</span>';
        const titleEl = document.querySelectorAll('.ng-chat-title')[0];

        if (titleEl) {

          if (count > 0) {
            titleEl.classList.add('new-message');
            title += '<span class="unread-total-count"> (' + count + ')</span>';
          } else {
            titleEl.classList.remove('new-message');
          }
          titleEl.innerHTML = title;
          const chatEl = document.getElementById('ng-chat');
          chatEl.style.visibility = 'visible';
        }
      })
    ).subscribe();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  showChat() {
    return this._NavbarRoutes.some(route => this._router.url.includes(route))
      && !!this._userService.currentUserValue && this._userService.facilityType !== this.viewType.intelypro;
  }

  triggerSeen(event) {
    if (event.length) {
      const staffId = event[0].fromId !== this.clientId ? event[0].fromId : event[0].toId;
      this._markMessagesAsRead(staffId);
    }
  }

  windowOpened(item) {
    setTimeout(() => {
      this.setIdentifiers(item.id, item.displayName);
    }, 150);
  }

  windowClosed(item) {
    this._markMessagesAsRead(item.id);
  }

  setIdentifiers(staffId: number, fullName: string) {
    const openedWindows = document.querySelectorAll('.ng-chat-window');
    openedWindows.forEach(item => {
      const node = item.querySelector(`[title="${fullName}"]`);
      if (node) {
        // const printIcon = '<span class="export-img"><img src="../../assets/images/downloadChat.svg" ' +
        //   '(click)="downloadConversation(staffId)" height="22" width="22" />' +
        //   '<span class="custom-export-tooltip">Export</span></span>';
        // node.parentElement.insertAdjacentHTML('afterend', printIcon);
        // node.parentElement.nextSibling.addEventListener('click', () => this.downloadConversation(staffId));
        node.parentElement.closest('ng-chat-window').setAttribute('id', `chat-window-${staffId}`);
        node.parentElement.closest('.ng-chat-title').setAttribute('id', `chat-title-${staffId}`);
      }
    });
  }

  confirmSidebarClosed(opened: boolean) {
    if (!opened) {
      this.shoppingCartService.totalCartInfo.next(null);
    }
  }

  private _markMessagesAsRead(staffId) {
    this._chatService.setMessagesAsSeen(staffId)
      .pipe(
        tap(() => {
          this._chatService.updateStaffUnreadMessages(staffId, 0);
          this._chatService.updateChatList(staffId);
          this._chatService.recount();
        })
      )
      .subscribe();
  }

  downloadConversation(staffId) {
    console.log(staffId);
  }

}
