import { Injectable } from '@angular/core';
import { FuseSidebarService } from '@theme/components/sidebar/sidebar.service';
import { BehaviorSubject } from 'rxjs';
import { StaffMember } from '../models/shift-management-models/StaffMember.model';
import { FuseSidebarComponent } from '@theme/components/sidebar/sidebar.component';
import { RequestHelperData } from '../models/newModels/RequestHelperData';
import { Shift } from '../models/shift-management-models/shift';
import { UnitInfo } from '../models/shift-management-models/UnitInfo';
import { ShiftDefs } from '../models/shift-management-models/ShiftDefs';
import { CreateShift } from '../models/java-models';

/**
 * ShiftManagementSidebarService methods open different shift sidebars.
 */

@Injectable({
  providedIn: 'root'
})
export class ShiftManagementSidebarService {
  public static TYPE_FILL_SHIFT = 'fillShift';
  public static TYPE_SHIFTS_TO_FILL = 'shiftsToFill';
  public static TYPE_REQUESTS = 'requests';
  public static TYPE_CREATE_SHIFT = 'createShift';
  public static TYPE_SPREADSHEET_CREATE_SHIFT = 'spreadsheetCreateShift';
  public static TYPE_SPREADSHEET_CONFIRM_SHIFT = 'spreadsheetConfirmShift';

  public shiftChange: BehaviorSubject<{
    shiftDetails?: Shift,
    staffMember?: StaffMember,
    requests?: RequestHelperData | RequestHelperData[],
    replacing?: StaffMember,
    returnTo?: 'requests' | 'allShifts',
    fillShiftTab?: 'search' | 'responses' | 'addStaff',
    viewType: string,
    shiftTime?: ShiftDefs,
    location?: UnitInfo,
    spreadsheetData?: any,
    shiftsToCreateSpreadsheet?: CreateShift[];
  }> = new BehaviorSubject(null);
  private _sidebarName = 'shiftManagement';
  private _sidebar: FuseSidebarComponent;
  constructor(private _sidebareService: FuseSidebarService) {
  }

  openFillShiftPanel(shiftDetails: Shift, replacing?: StaffMember, returnTo: 'requests' | 'allShifts' = null,
    fillShiftTab: 'search' | 'responses' | 'addStaff' = 'search') {
    this._openIfClosed();
    this.shiftChange.next({ shiftDetails, replacing, viewType: ShiftManagementSidebarService.TYPE_FILL_SHIFT, fillShiftTab, returnTo });
  }

  toggleFillShiftPanel(shiftDetails: Shift) {
    this.shiftChange.next(null);
    setTimeout(() => {
      this.openFillShiftPanel(shiftDetails);
    }, 500);
  }

  openRequests() {
    this._openIfClosed();
    this.shiftChange.next({ viewType: ShiftManagementSidebarService.TYPE_REQUESTS });
  }

  openShiftsToFill() {
    this._openIfClosed();
    this.shiftChange.next({ viewType: ShiftManagementSidebarService.TYPE_SHIFTS_TO_FILL });
  }

  openCreateShift(shiftTime?: ShiftDefs, location?: UnitInfo): void {
    this._openIfClosed();
    this.shiftChange.next({ viewType: ShiftManagementSidebarService.TYPE_CREATE_SHIFT, shiftTime: shiftTime, location: location });
  }


  openCreateShiftSpreadsheet(row): void {
    this._openIfClosed();
    this.shiftChange.next({ viewType: ShiftManagementSidebarService.TYPE_SPREADSHEET_CREATE_SHIFT, spreadsheetData: row });
  }

  openConfirmShiftSpreadsheet(row, shiftsToCreate): void {
    this._openIfClosed();
    this.shiftChange.next({ viewType: ShiftManagementSidebarService.TYPE_SPREADSHEET_CONFIRM_SHIFT, spreadsheetData: row, shiftsToCreateSpreadsheet: shiftsToCreate });
  }


  closePanel() {
    if (this._sidebar.opened) {
      this._sidebareService.getSidebar(this._sidebarName).toggleOpen();
    }
    this.shiftChange.next(null);
  }

  private _openIfClosed() {
    if (!this._sidebar) {
      this._sidebar = this._sidebareService.getSidebar(this._sidebarName);
      this._sidebar.openedChanged.subscribe(e => {
        if (!e) {
          this.closePanel();
        }
      });
    }
    if (!this._sidebar.opened) {
      this._sidebar.toggleOpen();
    }
  }
}
