import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {EMPTY, of, Subject} from 'rxjs';
import {filter, map, takeUntil, tap, first, catchError, distinctUntilChanged, repeatWhen, switchMap} from 'rxjs/operators';
import { FuseConfigService } from '@theme/services/config.service';
import { NavigationEnd, Router } from '@angular/router';
import { ManageUsersAccountService } from '../../../services/manage-users-account.service';
import { StaffService } from '../../../services/staff.service';
import { JWTPayloadData } from '../../../models/newModels/JWTPayloadData';
import { BillingSummaryModel } from '../../../models/newModels/BillingSummaryModel';
import { theme_config } from '../../../config/theme_config';
import { monthlyViewType } from '../../../config/monthly-view-type';
import { OnBoardingService } from 'src/app/on-boarding/services/on-boarding.service';
import { MatDialog } from '@angular/material/dialog';
import { MutipleFacilityComponent } from 'src/app/mobile-login/mutiple-facility/mutiple-facility.component';
import { UserTypeData } from 'src/app/models/newModels/UserTypeData';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StaffManagementService } from 'src/app/services/staff-management.service';
import { AddStaffService } from 'src/app/on-boarding/components/add-staff/services/add-staff.service';
import { MonthlyViewService } from '../../../monthly-view/services/monthly-view.service';
import { BoostApprovalService } from 'src/app/monthly-view/services/boost-approval.service';
import { UserTypes } from 'src/app/config/user-types';
import { MobileService } from 'src/app/services/mobile.service';
import {JavaBaseResponse} from '../../../models/shift-management-models/java-base-response';
import {Settings} from '../../../models/shift-management-models/Settings';
import {ShiftsService} from '../../../services/apis/shifts.service';
/**
 * ToolbarComponent is a component which shows the horizontal nav bar with the logo, router.
 * links to Dashboard,Settings,Staff Directory ,loged in user and the funcionality to logout.
 * @Input() toolbarTheme is an input which has the type of the toolbar theme.
 */

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() toolbarTheme: string;
  @Input() navbar: string;
  isOpened = false;
  viewTypes: any = monthlyViewType;
  uiconfig = theme_config;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  currentUser: JWTPayloadData;
  userStatusOptions: any[];
  currentRoute = '';
  routeNamesMapping: any;
  showFacilityName = false;
  billingSummaryData: BillingSummaryModel;
  onloadError: boolean;
  isHubUser: boolean = false;
  accountFormTouched = false;
  facilityType: number;
  showFacilitySection: boolean;
  facilityArray: UserTypeData[];
  staffCount: Number;
  profileOptions: any[] = [];
  selectedView: any;
  canRequestIntelypro = false;
  isDevice: boolean;
  isIFrame = false;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param _fuseConfigService layout config
   * @param _accountService user management service
   * @param route angula router
   */
  constructor(
    public route: Router,
    public dialog: MatDialog,
    private _staffService: StaffService,
    private addStaffService: AddStaffService,
    public _deviceService: DeviceDetectorService,
    private _fuseConfigService: FuseConfigService,
    private _onBoardingService: OnBoardingService,
    private _monthlyViewService: MonthlyViewService,
    public _accountService: ManageUsersAccountService,
    private staffManagementService: StaffManagementService,
    private _boostApprovalService: BoostApprovalService,
    public _mobileService: MobileService,
    private _javaShiftsService: ShiftsService,
  ) {
    this.facilityType = this._accountService.facilityType;
    this.routeNamesMapping = {
      '': 'Home',
      'daily': 'Home',
      'directory': 'Staff Directory'
    };

    // Set the defaults
    this.userStatusOptions = [];

    this.languages = [
      {
        id: 'en-US',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.isIFrame = (window === window.parent) ? false : true;
    this.staffManagementService.getStaffCount()
      .pipe(
        repeatWhen(() => this.addStaffService.addedStaff),
        tap(res => this.staffCount = res?.data)
      )
      .subscribe();
    this._accountService.currentUserSubject.pipe().subscribe(resp => {
      this.currentUser = resp;
      this.isHubUser = resp?.usertypes?.some(e => e.usertypeid === UserTypes.HubUser);
    });
    this._onBoardingService.completeSignUp.pipe(distinctUntilChanged()).subscribe(res => this.accountFormTouched = res)

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;

        if (settings.layout.sidepanel.position === 'left' && settings.layout.sidepanel.hidden === false) {
          this.showFacilityName = false;
        } else {
          this.showFacilityName = true;
        }
      });

    this.route.events.pipe(
      takeUntil(this._unsubscribeAll),
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        return this.routeNamesMapping[event.url.replace('/', '')];
      })
    ).subscribe(resp => {
      this.currentRoute = resp;
    });

    this.currentRoute = this.routeNamesMapping[this.route.url.replace('/', '')];

    this.profileOptions = [
      { value: 0, desc: "Personal Schedule", checked: this.currentRoute === 'online' },
      { value: 1, desc: "Facility Schedule", checked: this.currentRoute !== 'online' }
    ];
    this.selectedView = this.profileOptions.filter(option => option.checked);

    this.onloadError = false;
    this._staffService.isClientOnHoldISystem().pipe(
      first(),
      tap((billingData: BillingSummaryModel) => {
        this._accountService.userInfo.next(billingData);
        if (billingData.code === 200) {
          this.billingSummaryData = billingData;
          // Replacing ',' with nothing, as curency pipe does not understant ','.
          //As per the documentation [https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace] changed the string to regex.
          this.billingSummaryData.balance = this.billingSummaryData.balance?.replace(/,/g, '');
          this.billingSummaryData.minPay = this.billingSummaryData.minPay?.replace(',', '');
          this.canRequestIntelypro = billingData.billingStatus !== 'Hold' && billingData.clientStatus === 'Yes';
        } else {
          this.onloadError = true;
        }
      }),
      catchError(() => {
        this.onloadError = true;
        return EMPTY;
      })
    ).subscribe();

    this.facilityArray = this.currentUser?.usertypes?.filter(
      (facility, i, usertypes) =>
        usertypes.findIndex(fac => fac.clientid === facility.clientid) === i
        && facility.clientname !== '' && facility.usertypeid === UserTypes.Scheduler
    );
    this.showFacilitySection = this.facilityArray?.length >= 2 ? true : false;
    this.isDevice = this._mobileService.isDevice();
  }

  notLoggedIn() {
    return !this.currentUser || (this.route.url === '/onboard/register');
  }

  /**
   *  @Method to open facility selection popup
   */
  openFacility(): void {
    const userName = this.currentUser.firstname;
    this.dialog.open(MutipleFacilityComponent, {
      disableClose: false,
      width: '818px',
      height: '473px',
      panelClass: 'day-info-dialog',
      data: { facilities: this.facilityArray, userName, canClose: true },
    }).afterClosed().pipe(
      tap(() => this._boostApprovalService.emptyClientAttributes()),
      filter((switchFlag: boolean) => switchFlag),
      tap(() => {
        localStorage.removeItem('clientSettings');
        localStorage.removeItem('intelypro');
      }),
      switchMap(() => {
        return this._javaShiftsService.getClientSettings().pipe(
          catchError(err => of<JavaBaseResponse<Settings[]>>({ data: [] })),
          tap(settings => this._accountService.setClientSettings(settings.data))
        );
      }),
      tap(() => window.location.reload())
    ).subscribe();
  }


  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // /**
  //  * Toggle sidebar open
  //  *
  //  * @param key
  //  */
  // toggleSidebarOpen(key): void {
  //   this._fuseSidebarService.getSidebar(key).toggleOpen();
  // }

  // /**
  //  * Search
  //  *
  //  * @param value
  //  */
  // search(value): void {
  //   // Do your search here...
  // }

  login() {
    this.route.navigateByUrl('/facility/login');
  }

  logout() {
    this._accountService.logout().subscribe(redirectUrl => {
      this._boostApprovalService.emptyClientAttributes();
      this.route.navigate([redirectUrl]);
      this.uiconfig.layout.sidepanel.hidden = true;
      this.uiconfig.layout.toolbar.hidden = true;
      this._fuseConfigService.setConfig(this.uiconfig);
      this.clearSessionStorage();
    });
  }

  clearSessionStorage() {
    sessionStorage.removeItem('viewType');
    sessionStorage.removeItem('openShifts-dayView');
  }

  navigateTo(page: string) {
    this._monthlyViewService.requestIp.next(false);
    this.route.navigateByUrl(`/${page}`);
  }

  goToRequestIp() {
    this._monthlyViewService.requestIp.next(true);
    this.route.navigateByUrl('/monthly');
  }

  updateUserSettings(userType) {
    const clientSettings = JSON.parse(localStorage.getItem('clientSettings'));
    clientSettings.find(clientSetting => clientSetting.name === 'clienttype').value = userType;
    this._accountService.setClientSettings(clientSettings);
    this.route.navigateByUrl('/daily');
  }
  changeExperience(schedule) {
    const user = this._accountService.currentUserValue;
    user.activeusertype.usertypeid = UserTypes.Staff;
    user.active = true;
    this._accountService.setCurrentUser(user);
    this.route.navigateByUrl('/online');
  }
  hasTwoRoles() {
    let userTypes = this._accountService.currentUserValue?.usertypes.filter(userType =>
      userType["usertypeid"] === UserTypes.Staff || userType["usertypeid"] === UserTypes.Scheduler);
    return userTypes?.length == 2;
  }

  checkToHoverMenu(menus: string[]) {
    let hover = false;

    if (menus.find(menu => this.route.url.includes(menu))) {
      hover = true;
    }

    return hover;
  }
}
