<div class="dialog-header" mat-dialog-title>
  <div *ngIf="!!header" class="header-title">
    {{header}}
  </div>
  <div class="header-message">
    {{message}}
  </div>
</div>

<div *ngIf="!globalSettings; else settingsDeleteConfirmation" class="dialog-footer" mat-dialog-actions>
  <button class="custom-button" mat-button [mat-dialog-close]="firstValue" automation-id="first-label-confirmation-dialog">
    <span class="custom-button-label">
      {{firstLabel}}
    </span>
  </button>
  <button class="custom-button" mat-button [mat-dialog-close]="secondValue" automation-id="second-label-confirmation-dialog">
    <span class="custom-button-label">
      {{secondLabel}}
    </span>
  </button>
</div>

<ng-template #settingsDeleteConfirmation>
  <div class="settings-custom-btn" mat-dialog-actions>
    <button class="cancel-btn" mat-button [mat-dialog-close]="firstValue" automation-id="first-label-settings-delete-confirmation">
      {{firstLabel}}
    </button>
    <button class="confirm-btn" mat-button [mat-dialog-close]="secondValue" automation-id="second-label-settings-delete-confirmation">
      {{secondLabel}}
    </button>
  </div>
</ng-template>
