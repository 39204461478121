import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@theme/directives/directives';
import { PipesModule } from '../helpers/pipes/pipes.module';
import { CdkDetailRowDirective } from './cdk-detail-row.directive';
import { DateSuffix } from './date-suffix';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BoostShiftComponent, BoostSummaryComponent } from './componets/boost-shift/boost-shift.component';
import { MaterialModule } from './material.module';
import { StopPropagationDirective } from './directives/stop-propagation.directive';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DirectivesModule,
    PipesModule,
    CdkDetailRowDirective,
    DateSuffix,
    SafeHtmlPipe,
    BoostShiftComponent,
    BoostSummaryComponent,
    StopPropagationDirective
  ],
  imports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  declarations: [
    CdkDetailRowDirective,
    DateSuffix,
    SafeHtmlPipe,
    BoostShiftComponent,
    BoostSummaryComponent,
    StopPropagationDirective
  ]
})
export class SharedModule {
}
