import { MenuItem } from './MenuItem';

export class MenuItems {
  directory: MenuItem = {
    title: 'Directory',
    subtitle: 'Your staff, Intelypros and agency',
    url: '/directory',
    icon: 'perm_contact_calendar',
    num: 0,
    showNumber: true
  };

  settings: MenuItem = {
    title: 'Facility Settings',
    subtitle: 'Locations, schedules and more...',
    url: '/test',
    icon: 'supervised_user_circle',
    num: 0,
    showNumber: false
  };

  schedules: MenuItem = {
    title: 'Facility Schedules',
    subtitle: 'Locations, schedules and more...',
    url: '/test2',
    icon: 'info',
    num: 0,
    showNumber: false
  };
}

