import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JavaBaseResponse } from '../../models/shift-management-models/java-base-response';
import { ShiftsBaseResponse } from '../../models/shift-management-models/shifts-base-response';
import { UtilsService } from '../utils/utils.service';
import { ShiftRequestModel } from '../../models/shift-management-models/shift-request-model';
import { ShiftDefs } from '../../models/shift-management-models/ShiftDefs';
import { ShiftToAdd } from 'src/app/models/shift-management-models/shift-to-add';
import { UnitInfo } from 'src/app/models/shift-management-models/UnitInfo';
import { PrintRequestData } from '../../models/newModels/PrintRequestData';
import { AnyJsonType } from 'src/app/models/newModels/AnyJsonType';
import { catchError, flatMap, map, toArray } from 'rxjs/operators';
import { DeleteShiftRequest } from '../../models/shift-management-models/DeleteShiftRequest';
import { Settings } from '../../models/shift-management-models/Settings';
import { ConfigureBlockBookingOptionsModel } from 'src/app/models/ConfigureBlockBookingOptionsModel';
import { BlockBookingData, BlockShift, BookMeBlockData } from 'src/app/models/BlockBooking/BlockBookingData';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class ShiftsService {
  private readonly _basedUri = UtilsService.getShiftJavaUri();
  private readonly _shiftJavaUri = UtilsService.getShiftUri();
  public readonly getShiftUnitsInfoSub$: BehaviorSubject<UnitInfo[]> = new BehaviorSubject<UnitInfo[]>([]);
  public readonly getShiftDefsInfoSub$: BehaviorSubject<ShiftDefs[]> = new BehaviorSubject<ShiftDefs[]>([]);

  constructor(
    private _http: HttpClient,
    private _snackbar: MatSnackBar
  ) { }

  public getShifts(filters: ShiftRequestModel): Observable<JavaBaseResponse<ShiftsBaseResponse>> {
    const params = Object.keys(filters).map(e => {
      return Array.isArray(filters[e]) ? filters[e].map(a => `${e}=${a}`).join('&') : `${e}=${filters[e]}`;
    }).join('&');
    return this._http.get<JavaBaseResponse<ShiftsBaseResponse>>(`${this._basedUri}/shifts/?${params}`);
  }

  public getShiftDefs(req: any = {}): Observable<ShiftDefs[]> {
    return this._http.get<JavaBaseResponse<ShiftDefs[]>>(`${this._basedUri}/shifts/def`, { params: req }).pipe(
      map(res => res.data),
      flatMap(res => res),
      map(res => {
        res.hours = res.hours ?? 0;
        res.duration = res.duration ?? 0;
        res.minutes = res.minutes ?? 0;
        return res;
      }), toArray()
    );
  }

  public setShiftDefs(req: ShiftDefs[]): Observable<JavaBaseResponse<ShiftDefs[]>> {
    return this._http.post<JavaBaseResponse<ShiftDefs[]>>(`${this._basedUri}/shifts/def`, req);
  }

  public deleteShift(req: DeleteShiftRequest): Observable<any> {
    return this._http.delete<JavaBaseResponse<ShiftDefs[]>>(`${this._basedUri}/shifts`, { params: req as any });
  }
  public cancelIPRequest(reqId: number): Observable<any> {
    return this._http.put<JavaBaseResponse<any>>(`${this._basedUri}/callout/cancel`, { reqId: reqId });
  }

  public printViewPassGateway(req: PrintRequestData): Observable<Blob> {
    const reqparams = Object.keys(req).reduce((c, e, i) => {
      return c + (i === 0 ? '' : '&') + e + '=' + req[e];
    }, '?');
    return this._http.get<Blob>(`${this._basedUri}/reports/daily` + reqparams, { responseType: 'blob' as 'json' });
  }

  public manageShifts(req: ShiftToAdd[]): Observable<JavaBaseResponse<any>> {
    return this._http.post<JavaBaseResponse<any>>(`${this._basedUri}/shift/staff`, req);
  }

  public acceptRequest(req: any): Observable<JavaBaseResponse<any>> {
    return this._http.put<JavaBaseResponse<any>>(`${this._basedUri}/shift/request/sendaccept`, req);
  }

  public getUnits(req: any = {}): Observable<UnitInfo[]> {
    return this._http.get<JavaBaseResponse<UnitInfo[]>>(`${this._basedUri}/units`, { params: req }).pipe(
      map(res => res.data)
    );
  }

  public manageUnits(unit: UnitInfo[]): Observable<JavaBaseResponse<UnitInfo[]>> {
    return this._http.post<JavaBaseResponse<UnitInfo[]>>(`${this._basedUri}/units`, unit);
  }

  public downloadMonthlyReport(req: any) {
    const reqparams = Object.keys(req).reduce((c, e, i) => {
      return c + (i === 0 ? '' : '&') + e + '=' + req[e];
    }, '?');
    return this._http.get<string>(`${this._basedUri}/reports/monthly` + reqparams, { responseType: 'blob' as 'json' });
  }

  public getMonthlyStaffCount(req: any): Observable<JavaBaseResponse<{ staffCount: number }>> {
    const reqparams = Object.keys(req).reduce((c, e, i) => {
      return c + (i === 0 ? '' : '&') + e + '=' + req[e];
    }, '?');
    return this._http.get<JavaBaseResponse<{ staffCount: number }>>(`${this._basedUri}/reports/monthly/staff/count${reqparams}`);
  }

  public getClientSetting(setting: string): Observable<JavaBaseResponse<Settings>> {
    return this._http.get<JavaBaseResponse<Settings>>(`${this._basedUri}/settings/${setting}`);
  }

  public getClientSettings(): Observable<JavaBaseResponse<Settings[]>> {
    return this._http.get<JavaBaseResponse<Settings[]>>(`${this._basedUri}/settings`);
  }

  setJson(json: AnyJsonType) {
    return this._http.post<AnyJsonType>(`${this._basedUri}/onboarding/steptype/${json.steptype} `, json.stepjson);
  }

  getJson(type: string): Observable<any> {
    return this._http.get<JavaBaseResponse<any>>(`${this._basedUri}/onboarding/steptype/${type}`).pipe(
      map(e => !!e['data'] ? e['data'] : null)
    );
  }

  getWeekNumber(Date: string): Observable<JavaBaseResponse<number>> {
    return this._http.get<JavaBaseResponse<number>>(`${this._basedUri}/weekNumber/?weekDate=${Date}`);
  }

  /**
   *  API call to get count of shifts that an intelypro worked for a facility.
   *  @param staffId : staffId of the intelyPro assigned for a shift
   */
  public getShiftCount(staffId: number): Observable<number> {
    const params: HttpParams = new HttpParams().append('staffID', String(staffId));
    return this._http.get<JavaBaseResponse<number>>(`${this._basedUri}/staffing/shiftCount`, { params }).pipe(
      map((shiftCount: JavaBaseResponse<number>) => shiftCount.data),
      catchError(() => of(4))
    );
  }

  /**
   *  API call to get the 'accepted offers' and 'auto-approve' data for Block Booking.
   *  @param blockId : blockId of the block created
   *  @param cId : client Id of the current client
   *  @param data : data of 'accepted offers' and 'auto-approve' to be set
   */
  public setBlockBookingData(blockId: number, cId: number, data: any): Observable<any> {
    return this._http.post<any>(`${this._shiftJavaUri}/block/${blockId}/settings/${cId}`, data);
  }

  deleteBlock(blockId: number): Observable<any> {
    return this._http.post<any>(`${this._shiftJavaUri}/block/${blockId}/delete`, {});
  }

  getBlockBookingData(blockId: number): Observable<BlockBookingData> {
    return this._http.get<JavaBaseResponse<BlockBookingData>>(`${this._shiftJavaUri}/block/${blockId}`).pipe(
      map(resp => resp.data)
    );
  }

   /**
  * This will boost all the pending requests
  * clientId as params
  */
   getBoostableBlockShifts(clientId: number): Observable<BlockShift[]> {
    return this._http.get<JavaBaseResponse<BlockShift[]>>(this._shiftJavaUri + `/facility/${clientId}/block/shift/boost/all`).pipe(
      map(response => response.data)
    );
  }


  getBlockBookingSettings(clientId: number): Observable<ConfigureBlockBookingOptionsModel> {
    return this._http.get<JavaBaseResponse<ConfigureBlockBookingOptionsModel>>(`${this._shiftJavaUri}/block/settings/${clientId}`).pipe(
      map(resp => resp.data)
    );
  }

  getBookMeIntelyproData(blockId: number): Observable<BookMeBlockData> {
    return this._http.get<JavaBaseResponse<BookMeBlockData>>(`${this._shiftJavaUri}/block/${blockId}/bookme/intelypro`).pipe(
      map(resp => resp.data)
    );
  }
}
