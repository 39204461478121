<div class="user-form">
  <div class="user-form-wrapper">
    <div class="header-wrapper">
      <div class="header-icon-wrapper">
        <img src="assets/images/register.svg" class="custom-icon" alt="No Result" />
      </div>

      <div class="user-management-header-wrapper">
        <div class="user-management-header">
          <label class="header-label-2">
            <b>Hi {{ staffFirstName }}!</b>
          </label>

          <label class="header-label-2">
            {{ facilityName }} has invited you to
          </label>
          <br />

          <label class="header-label-2">
            see your schedule and pick up shifts.
          </label>
          <br />
          <br />
        </div>
      </div>

      <label class="header-label-3">
        Create Your Account
      </label>
    </div>

    <form [formGroup]="registerForm" (ngSubmit)="register()">
      <div class="user-management-body">
        <mat-form-field appearance="outline" class="input-custom-width suffix">
          <mat-label class="custom-input-label">Email Address</mat-label>
          <input (click)="customEmailError = ''" (keyup)="customEmailError = ''" [attr.readonly]="givenEmail != '' ? true : null"
                 matInput placeholder="example@email.com" type="email" formControlName="email" required />
          <i *ngIf="givenEmail" matSuffix class="far fa-lock-alt"></i>
          <mat-hint class="custom-hint" *ngIf="registerForm.controls.email.touched && validateForm.email.errors">
            A valid email address is required
          </mat-hint>
          <mat-hint class="custom-hint" *ngIf="customEmailError != ''">
            {{customEmailError}}
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-custom-width suffix">
          <mat-label class="custom-input-label">Create Password</mat-label>
          <input (keyup)="child.validatePassword();child.passwordMatches();" matInput placeholder="Enter password"
                 [type]="hide ? 'password' : 'text'" formControlName="password" required />
          <i matSuffix (click)="hide = !hide" [ngClass]="hide ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
          <mat-hint class="custom-hint" *ngIf="registerForm.controls.password.touched && validateForm.password.errors">A valid password is required</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-custom-width suffix">
          <mat-label class="custom-input-label">Confirm Password</mat-label>
          <input (keyup)="child.passwordMatches();" matInput placeholder="Re-enter password"
                 [type]="hideConfirm ? 'password' : 'text'" formControlName="confirmPassword" required />
          <i matSuffix (click)="hideConfirm = !hideConfirm" [ngClass]="hideConfirm ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
          <mat-hint class="custom-hint" *ngIf="registerForm.controls.confirmPassword.touched && validateForm.confirmPassword.errors">
            A valid confirmation password is required
          </mat-hint>
        </mat-form-field>

        <app-validate-password (passMatchEvent)="passMatch = $event" [password]="registerForm.controls.password.value"
                               [confirm]="registerForm.controls.confirmPassword.value">
        </app-validate-password>

        <div class="button-wrapper">
          <button mat-button class="main-button" [disabled]="!isValidUser() || processing" [ngStyle]="{'background' : checkButtonColor()}" type="submit" (click)="register()">
            <img *ngIf="processing" src="/assets/images/loader.gif" height="30" width="30" />
            <label *ngIf="!processing" class="main-button-label">
              CREATE ACCOUNT
            </label>
          </button>
        </div>
        <br />
        <br />
      </div>
    </form>
  </div>
</div>





