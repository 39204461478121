import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  private _unsubscribe: Subject<any> = new Subject<any>();

  constructor(private loaderService: LoaderService) { }

  /**
   * Subscribe the loader state and intialize the isLoading field.
   */
  ngOnInit(): void {
    this.loaderService.loaderState$.pipe(
      tap((isLoaded: boolean) => this.isLoading = isLoaded),
      takeUntil(this._unsubscribe)
    ).subscribe();
  }

  /**
   * unsubscribe the resource
   */
  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
