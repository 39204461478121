import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaffingStaffTypes } from '../../../../../models/java-models/staffing-staff-typesmodel';

@Component({
  selector: 'app-confirm-submit',
  templateUrl: './confirm-submit.component.html',
  styleUrls: ['./confirm-submit.component.scss']
})
export class ConfirmSubmitComponent implements OnInit {

  totalShifts = 0;
  shifts: {
    [st: number]: {
      staffType: StaffingStaffTypes;
      total: number;
    }
  } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.shifts = data.shifts;
    this.totalShifts = data.totalRequests;
  }

  ngOnInit(): void { }

}
