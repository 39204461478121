import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UtilsService } from './utils/utils.service';
import { JavaBaseResponse } from '../models/shift-management-models/java-base-response';
import { StaffMember } from '../models/shift-management-models/StaffMember.model';
/**
 * StaffManagementService gets all staff and filter staff from database.
 * Refresh star rating in staff list.
 */

@Injectable({
  providedIn: 'root'
})
export class StaffManagementService {

  public refreshStaffRating$: Subject<number> = new Subject();
  refreshFavorite$: Subject<number> = new Subject();
  private _baseJavaUri = UtilsService.getShiftJavaUri();

  constructor (private _http: HttpClient) {
  }

  /**
   * Refresh star rating in a staff list.
   */
  refreshStaffRating(pid: number): void {
    this.refreshStaffRating$.next(pid);
  }

  /**
   * Refresh favorite in a staff list.
   */
  refreshStaffFavorite(pid: number): void {
    this.refreshFavorite$.next(pid);
  }

  getAllStaff(req?: { startDate: string, endDate: string }): Observable<JavaBaseResponse<StaffMember[]>> {
    return this._http.get<JavaBaseResponse<StaffMember[]>>(`${this._baseJavaUri}/staff`, {
      params: req
    });
  }

  getStaffCount(): Observable<JavaBaseResponse<Number>> {
    return this._http.get<JavaBaseResponse<Number>>(`${this._baseJavaUri}/staff/count`)
  }
  public getAutomationIdSplitted(name: string) {
    return name.split(' ').join('-');
  }
}
