<!-- SIDE PANEL -->
<ng-container *ngIf="hasLeftBar">
  <app-facility-left-sidebar *ngIf="customConfig && customConfig.sidebar === 'DefaultSidebar'">
  </app-facility-left-sidebar>
  <app-on-boarding-sidebar *ngIf="customConfig && customConfig.sidebar === 'OnBoardingSidebar'">
  </app-on-boarding-sidebar>
</ng-container>

<ng-container>
</ng-container>

<!-- / SIDE PANEL -->

<div id="main">

  <!-- TOOLBAR: Above fixed -->
  <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-fixed'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>
  <!-- / TOOLBAR: Above fixed -->

  <div id="container-1" class="container" fusePerfectScrollbar (scroll)="onContentScroll($event)"
    [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

    <!-- TOOLBAR: Above static -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-static'">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above static -->

    <div id="container-2" class="container" style="height: 100%; overflow: hidden;"
      [ngStyle]="{'background': staffDirectory ? '#F7F9FA' : null}">

      <!-- NAVBAR: Left -->
      <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
        <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Left -->

      <div id="container-3" class="container">

        <!-- TOOLBAR: Below -->
        <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below'">
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <!-- / TOOLBAR: Below -->

        <!-- / MAIN MENU -->

        <!-- CONTENT -->
        <content style="height: 10px; overflow: auto;"></content>
        <!-- / CONTENT -->

        <!-- FOOTER: Below -->
        <ng-container *ngIf="fuseConfig.layout.footer.position === 'below'">
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
        <!-- / FOOTER: Below -->

      </div>

      <!-- NAVBAR: Right -->
      <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
        <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Right -->

    </div>

    <!--<button mat-button class="scroll-to-top" *ngIf="showScrollToTop" (click)="scrollContainerToTop()">-->
    <!--<mat-icon>keyboard_arrow_up</mat-icon>-->
    <!--</button>-->

  </div>


</div>

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
  <quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS ------------------------------------------------------------------------------------------ -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
    [toolbarTheme]="customConfig.navbar === 'DefaultNavbar' ? 'default' : 'light'" [navbar]="customConfig.navbar"
    [ngStyle]="{background: customConfig.navbar === 'DefaultNavbar' ? null : '#FFFFFF'}"
    [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ?
           fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position" [ngClass]="{'shadow-box': addToolbarShadow()}">
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->


<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded" lockedOpen="gt-md"
    *ngIf="!fuseConfig.layout.navbar.hidden">
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->
