<div class="chip-icon" [style]="{background: template == 'light' ? '#2EA2CD' : null}" fxFlex  fxLayoutAlign="center center">
  <div class="chip-icon-content" fxFlex="1 1 auto"  fxLayoutAlign="center center">
    <span [style]="{color: template == 'light' ? '#FFFFFF' : '#141414'}"
          class="chip-icon-initials" *ngIf="name" fxFlex fxLayoutAlign="center center">
      {{ getContent() }}
    </span>
    <mat-icon *ngIf="!name" [ngClass]="{'custom-icon': customizeUserIcon}"
              [svgIcon]="customIcon ? iconMN : ''">{{ iconMN }}</mat-icon>
  </div>
</div>
