import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarComponent } from './toolbar.component';
import { SharedModule } from '../../../shared/shared.module';
import { FuseSearchBarModule } from '@theme/components';
import { ChipIconModule } from '../../../shared/componets/chip-icon/chip-icon.module';
import { FacilityIconModule } from '../../../shared/componets/facility-icon/facility-icon.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RadioButtonsModule } from 'src/app/shared/componets/radio-buttons/radio-buttons.module';
import { AddStaffDialogModule } from './add-staff-dialog/add-staff-dialog.module';


@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSidenavModule,
    SharedModule,
    FuseSearchBarModule,

    ChipIconModule,
    FacilityIconModule,
    RadioButtonsModule
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule {
}
