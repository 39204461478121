<div class="requests">
  <div class="requests-wrapper" fxFlex fxFlexFill fxLayout="column" fxLayoutGap="16px">

    <div class="requests-header">
      <card-system-top-header [title]="'Pending Responses'"></card-system-top-header>
    </div>

    <div class="count-indicator" fxLayoutAlign="center center">
      <span>{{allRequests.length}} pending responses</span>
    </div>

    <div class="content" fxFlex="0 1 auto">
      <div class="content-wrapper" fxLayout="column" fxLayoutGap="20px">

        <div class="request-entity" fxLayout="column" *ngFor="let request of allRequests; let i = index">
          <card-system-shift-details [shiftDetails]="request.shift" [selectable]="false"></card-system-shift-details>

          <div class="shift-actions" fxLayout="row" fxLayoutGap="16px">
            <button mat-button class="action-button complete">
              <mat-icon>person_add</mat-icon>
              Confirm Staff
            </button>

            <span class="accepted-requests" fxLayoutAlign="center center" *ngIf="request.requests.accepted.length > 0">
              {{request.requests.accepted.length}} persons accepted this shift
            </span>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>