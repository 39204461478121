import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { UtilsService } from '../services/utils/utils.service';
import { JavaPayload } from '../models/newModels/JavaPayload';
import { ShiftUpdateRequest } from '../models/java-models/ShiftUpdateRequest';
import { JavaBaseResponse } from '../models/shift-management-models/java-base-response';
import { CalendarViewType } from '../enums/calendar-type.enum';

@Injectable({
    providedIn: 'root'
})
export class SpreadsheetService {
    selectedMonth: BehaviorSubject<Date> = new BehaviorSubject(
        localStorage.getItem('calendarViewSelectedMonth') ?
            new Date(localStorage.getItem('calendarViewSelectedMonth')) :
            new Date()
    );
    forceReload: Subject<any> = new Subject<any>();
    groupByType: Subject<any> = new Subject<any>();
    calendarViewBy: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('calendarViewType') ?? CalendarViewType.month);
    fourWeeksDate: ReplaySubject<any> = new ReplaySubject(1);
    changeWeekDatas: Subject<any> = new Subject<any>();
    searchedName: Subject<any> = new Subject<any>();
    currentDay: Subject<any> = new Subject<any>();
    filters: BehaviorSubject<{ location: any[], staffType: any[], shiftTime: any[], staffCategory: any[] }> = new BehaviorSubject<{ location: any[], staffType: any[], shiftTime: any[], staffCategory: any[] }>({ location: [], staffType: [], shiftTime: [], staffCategory: [] });
    private _basedUri = UtilsService.getShiftJavaUri();

    constructor(private http: HttpClient) { }

    getSpreadSheetData(startDate, endDate): Observable<any> {
        return this.http.get<JavaPayload<any[]>>(
            `${this._basedUri}/shifts/spreadsheetview?startDate=${startDate}&endDate=${endDate}`
        );
    }

    getOpenShiftCount(startDate, endDate, groupBy): Observable<any> {
        return this.http.get<JavaPayload<any[]>>(`${this._basedUri}/shifts/totalOpenshifts?startDate=${startDate}&endDate=${endDate}&groupBy=${groupBy}`);
    }

    updateShift(shiftUpdateRequest: ShiftUpdateRequest[]): Observable<JavaBaseResponse<ShiftUpdateRequest[]>> {
        return this.http.put<JavaPayload<ShiftUpdateRequest[]>>(
            `${this._basedUri}/shifts/`,
            shiftUpdateRequest
        );
    }

    public downloadSpreadsheet(startDate: string, endDate: string, shiftTime, staffType, staffCategory, location): Observable<string> {
        return this.http.get<string>(`${this._basedUri}/shifts/downloadexcel?startDate=${startDate}&endDate=${endDate}&shiftTimeArray=${shiftTime}&staffTypeArray=${staffType}&staffCategoriesArray=${staffCategory}&locationArray=${location}`,
            { responseType: 'blob' as 'json' }
        );
    }
}
