export class Constants {

  // SHIFT STATUS DESCRIPTION

  static SHIFT_STATUS_DESCRIPTION = {
    open: {
      id: 1,
      description: 'Open'
    },
    requestSent: {
      id: 2,
      description: 'Request Sent'
    },
    callOut: {
      id: 3,
      description: 'Call Out'
    },
    decline: {
      id: 4,
      description: 'Decline'
    },
    scheduled: {
      id: 5,
      description: 'Scheduled'
    },
    accepted: {
      id: 6,
      description: 'Accepted'
    },
    canceled: {
      id: 7,
      description: 'Canceled'
    }
  };

  static GENDER_PREFERENCE = {
    femaleOnly: 1,
    any: 2,
    select: 3
  };
  // The daysToAdd is a constant that represent the days that are added to the date selected on start day in settings
  // in order to complete one month on the Rolling 4 Weeks
  static daysToAdd: number = 27;

  static documentReferrer = 'mykronos.com';

  static SHIFT_CREATION_SOURCE = {
    ukg: 'UKGDIMENSIONS'
  };

  static HOME_SHIFT_STATUS = {
    review: 'review',
    accepted: 'accepted',
    released: 'released',
    blockBookingRequests: 'block-booking-requests',
    blockBookingAccepted: 'block-booking-accepted'
  };

}
