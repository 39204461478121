<div fxFlex fxLayout="column" class="shift-management-filter">

  <div class="shift-management-filter-wrapper" fxFlex fxLayout="row" fxLayoutGap="0" fxLayoutAlign="center center">
    <mat-form-field [appearance]="'outline'" fxFlex="1 1 auto" fxLayoutAlign="start center">
      <input matInput class="filter-input" fxFlex [formControl]="control" placeholder="search all staff ..."
             type="search"
             (blur)="blur.emit($event)"
             (focus)="focus.emit($event)"
      >
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
    <button mat-button (click)="toggle()">
      <span fxFlex fxLayout="row">
        <span fxLayoutAlign="center center">FILTER</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>
  </div>


  <div class="filters" [ngClass]="{'hidden': !searchOpened}">

    <mat-icon class="close-icon" (click)="toggle()">close</mat-icon>

    <div fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="20px">

      <div class="shift" fxLayout="column" fxLayoutGap="12px">
        <span class="shift">Shift Time</span>
        <app-selectable-button-group #shiftsOptions [elements]="shifts | async" [multiple]="true"
                                     [selectedFilters]="selectedFilter.shift"
                                     (valueChange)="subject.next(['shift', $event])"></app-selectable-button-group>
      </div>

      <mat-divider></mat-divider>

      <div class="unit" fxLayout="column" fxLayoutGap="12px">
        <span class="unit">Employment Type</span>
        <app-selectable-button-group #employmentTypeOptions [elements]="employmentType | async" [multiple]="true"
                                     [selectedFilters]="selectedFilter.employmentType"
                                     (valueChange)="subject.next(['employmentType', $event])"></app-selectable-button-group>
      </div>
    </div>

  </div>

</div>

<div class="filter-overlay" *ngIf="searchOpened" (click)="toggle()">
</div>
