import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loaderState$ = this.loaderSubject.asObservable();

  constructor() { }

  /**
   * shows the loader
   */
  showLoader(): void {
    this.loaderSubject.next(true);
  }

  /**
   * hides the loader
   */
  hideLoader(): void {
    this.loaderSubject.next(false);
  }

}
