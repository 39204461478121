<!-- SHIFT DETAILS -->
<ng-container
  *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_SHIFT_DETAILS">
  <app-shift-details [shiftDetails]="shiftManagementState.shiftDetails"
                     [staffMember]="shiftManagementState.staffMember"></app-shift-details>
</ng-container>

<!-- FILL SHIFT -->
<ng-container
  *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_FILL_SHIFT">
  <app-fill-shift-component [shiftDetails]="shiftManagementState.shiftDetails"
                            [currentTab]="shiftManagementState.fillShiftTab" [returnTo]="shiftManagementState.returnTo"
                            [replacing]="shiftManagementState.replacing"></app-fill-shift-component>
</ng-container>

<!-- FILL SHIFT -->
<ng-container *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_REQUESTS">
  <requests></requests>
</ng-container>

<!-- FILL SHIFT -->
<ng-container
  *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_REQUEST_INTELYPRO">
  <request-intelypro></request-intelypro>
</ng-container>

<!-- FILL SHIFT -->
<ng-container *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_SHIFTS_TO_FILL">
  <shifts-to-fill></shifts-to-fill>
</ng-container>

<!-- FILL SHIFT RESPONSES -->
<ng-container *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_FILL_SHIFT_RESPONSES">
  <fill-shift-responses [shiftDetails]="shiftManagementState.shiftDetails" [requests]="shiftManagementState.requests"></fill-shift-responses>
</ng-container>

<!-- CREATE SHIFT -->
<ng-container *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_CREATE_SHIFT ">
  <app-create-shift [initLocation]="shiftManagementState.location"
                    [initShiftTime]="shiftManagementState.shiftTime"></app-create-shift>
</ng-container>


<!-- CREATE SHIFT SPREADSHEET-->
<ng-container  *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_SPREADSHEET_CREATE_SHIFT ">
  <app-create-shifts-spreadsheet [selectedRow]="shiftManagementState.spreadsheetData" > </app-create-shifts-spreadsheet>
</ng-container>

<!-- CONFIRM SHIFT SPREADSHEET -->
<ng-container *ngIf="shiftManagementState && shiftManagementState.viewType === TYPE_SPREADSHEET_CONFIRM_SHIFT">
  <app-confirm-shifts-spreadsheet [selectedRow]="shiftManagementState.spreadsheetData"  [shiftDetails]="shiftManagementState.shiftsToCreateSpreadsheet"> </app-confirm-shifts-spreadsheet>
</ng-container>