import { NgModule } from '@angular/core';
import { BaseLayoutComponent } from './base-layout.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FuseSidebarModule } from '@theme/components';
import { VerticalLayout2Module } from './vertical/layout-2/layout-2.module';
import { ShiftManagementModule } from '../shift-management/shift-management.module';
import { StaffManagementSidebarsModule } from '../staff-management/components/staff-management-sidebars/staff-management-sidebars.module';
import { ManageMobileUserModule } from '../manage-mobile-user/manage-mobile-user.module';
import { MobileLoginModule } from '../mobile-login/mobile-login.module';
import { MobileForgotPasswordModule } from '../mobile-forgot-password/mobile-forgot-password.module';
import { CartSidebarModule } from '../monthly-view/components/bulk-shift-booking/components/cart-sidebar/cart-sidebar.module';
import { ConfirmCartSidebarModule } from '../monthly-view/components/bulk-shift-booking/components/confirm-cart-sidebar/confirm-cart-sidebar.module';
import { BoostApprovalQueueModule } from '../monthly-view/components/boost-approval-queue/boost-approval-queue.module';
import { NgChatModule } from 'ng-chat';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MobileFooterModule } from './components/mobile-footer/mobile-footer.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MobileCartSidebarModule } from '../monthly-view/components/bulk-shift-booking/components/mobile-cart-sidebar/mobile-cart-sidebar/mobile-cart-sidebar.module';

@NgModule({
  imports: [
    CommonModule,
    VerticalLayout2Module,
    MatIconModule,
    MatTooltipModule,
    FuseSidebarModule,
    ShiftManagementModule,
    StaffManagementSidebarsModule,
    ManageMobileUserModule,
    MobileLoginModule,
    MobileForgotPasswordModule,
    CartSidebarModule,
    MobileCartSidebarModule,
    ConfirmCartSidebarModule,
    BoostApprovalQueueModule,
    NgChatModule,
    MobileFooterModule,
    MatBadgeModule
  ],
  exports: [
    VerticalLayout2Module,
    BaseLayoutComponent
  ],
  declarations: [BaseLayoutComponent]
})
export class BaseLayoutModule {
}
