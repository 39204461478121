export const EventTypes = {

  TEST: 'test',
  SEND_REQUEST: 'sendrequest',
  ACCEPT_REQUEST: 'acceptrequest',
  DECLINE_REQUEST: 'declinerequest',
  CONFIRM_SHIFT: 'confirmshift',
  SCHEDULE_SHIFT: 'scheduleshift',
  ADD_SHIFT: 'addshift',
  REMOVE_STAFF_TO_SHIFT: 'removestafftoshift',
  ADD_STAFF_TO_SHIFT: 'addstafftoshift',
  DELETE_SHIFT: 'deleteshift',
  MOVE_STAFF_TO_SHIFT: 'movestaftoshift',
  ADD_CALLOUT: 'addcallout',
  DELETE_STAFF: 'deletestaff',
  SMS_RECEIVED: 'smsreceived'
};
