import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateGuard } from './services/guards/activate.guard';
import { AuthGuard } from './services/guards/auth.guard';
import { FacilityAuthGuard } from './services/guards/facility-auth.guard';
import { OnBoardingGuard } from './services/guards/on-boarding.guard';
import { StaffAuthGuard } from './services/guards/staff-auth.guard';
import { LoggedInGuard } from './services/guards/logged-in.guard';
import { DeviceGuard } from './services/guards/device.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'facility',
    canActivate: [FacilityAuthGuard]
  },
  {
    // This is ukg landing page for generating token
    path: 'landingpage',
    loadChildren: () => import('./ukg-landing-page/ukg-landing-page.module').then(m => m.UkgLandingPageModule)
  },
  {
    // This is the login screen and forgot password
    path: 'facility',
    loadChildren: () => import('./web-login/web-login.module').then(m => m.WebLoginModule),
    canActivate: [LoggedInGuard]
  },
  {
    // This is the home view of shifts
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard, DeviceGuard]
  },
  {
    // This is the daily view of shifts
    path: 'daily',
    loadChildren: () => import('./scheduling/components/daily/daily.module').then(m => m.DailyModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard]
  },
  {
    // This is the dashboard to display open, released and accepted shifts
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard, DeviceGuard]
  },
  {
    // This is the monthly view of shifts
    path: 'monthly',
    loadChildren: () => import('./monthly-view/monthly-view.module').then(m => m.MonthlyViewModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard, DeviceGuard]
  },
  {
    // This is the week view of shifts
    path: 'week',
    loadChildren: () => import('./week-view/week-view.module').then(m => m.WeekViewModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard, DeviceGuard]
  },
  {
    // This is the requestIntelypro view of shifts
    path: 'request-intelypro',
    loadChildren: () => import('./request-intelypro/request-intelypro.module').then(m => m.RequestIntelyproModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard, DeviceGuard]
  },
  {
    // This is the view of your existing staff
    path: 'directory',
    loadChildren: () => import('./staff-management/staff-management.module').then(m => m.StaffManagementModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard]
  }, {
    // This is the view of your existing staff
    path: 'spreadsheet',
    loadChildren: () => import('./spreadsheet/spreadsheet.module').then(m => m.SpreadsheetModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard]
  }, {
    path: 'timecards',
    loadChildren: () => import('./timecards/timecards.module').then(m => m.TimecardsModule),
    canActivate: [FacilityAuthGuard, OnBoardingGuard]
  },
  {
    path: 'online',
    loadChildren: () => import('./online-staff-view/online-staff-view.module').then(m => m.OnlineStaffViewModule),
    canActivate: [StaffAuthGuard, ActivateGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./manage-mobile-user/manage-mobile-user.module').then(m => m.ManageMobileUserModule)
  },
  {
    path: 'onboard',
    loadChildren: () => import('./on-boarding/on-boarding.module').then(m => m.OnBoardingModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./job-board/job-board.module').then(m => m.JobBoardModule)
  },
  {
    path: 'activate',
    loadChildren: () => import('./activate-staff-account/activate-staff-account.module').then(m => m.ActivateStaffAccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./mobile-login/mobile-login.module').then(m => m.MobileLoginModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'reset',
    loadChildren: () => import('./mobile-forgot-password/mobile-forgot-password.module').then(m => m.MobileForgotPasswordModule)
  },
  {
    path: 'redirect',
    loadChildren: () => import('./wrong-device/wrong-device.module').then(m => m.WrongDeviceModule),
    canActivate: [FacilityAuthGuard, ActivateGuard]
  },
  {
    path: '**',
    redirectTo: 'daily'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
