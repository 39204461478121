<div class="create-shift">
  <form [formGroup]="createShiftForm" class="create-form" novalidate>
    <div class="header">
      <app-custom-drawer-header [title]="CreateShiftConstants.TITLE" (closeEvent)="closeDrawer()">
      </app-custom-drawer-header>

    </div>
    <div class="radio-group" fxLayout="row">
      <mat-radio-group id="radio-group-create-shift" aria-label="Select an option"
        (change)="changeShiftOption($event.value)" formControlName="shiftOptions">
        <mat-radio-button [value]="ShiftCreationType.STANDARD" automation-id="standard-shift">Standard
        </mat-radio-button>
        <mat-radio-button [value]="ShiftCreationType.PARTIAL" automation-id="partial-shift">Partial
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="shiftOptions === ShiftCreationType.PARTIAL" class="form-field standard" fxLayout="row">
      <mat-form-field class="shift-date" appearance="outline" automation-id="shift-date" (click)="picker.open()">
        <input #shiftDate matInput [value]="getCurrentDate()" [matDatepicker]="picker" [min]="today"
          class="calendar-input" formControlName="shiftDate" (dateChange)="selectShiftDate()">
        <span class="calendar-date date">{{shiftDate.value | date : 'EEEE, MMMM dd, yyyy'}}</span>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="shiftOptions === ShiftCreationType.STANDARD" class="create-shift-calendar">
      <mat-calendar #calendar [startAt]="defaultDate" [dateClass]="dateClass" [minDate]="today"
        (selectedChange)="onSelect($event)">
      </mat-calendar>
    </div>

    <div [ngClass]="{'standard-container':shiftOptions === ShiftCreationType.STANDARD}">
      <div class="form-field standard short-field" fxLayout="row" *ngIf="shiftOptions === ShiftCreationType.STANDARD">
        <mat-form-field appearance="outline" automation-id="shift-time">
          <mat-label>Shift Time {{shiftTime?.status ==='VALID'? '*' : ''}}</mat-label>
          <mat-select (selectionChange)="calculateHours($event.value.startTime, $event.value.endTime, false)"
            placeholder="Shift Time" disableOptionCentering="true" formControlName="shiftTime"
            automation-id="selected-shift-time" panelClass="pannelCustomClass">
            <mat-select-trigger>{{ shiftTime?.value?.shiftName }}</mat-select-trigger>
            <mat-option *ngFor="let shiftType of shiftTDefs" [value]="shiftType">
              <app-custom-shift-time-display timeFontSize="16px" periodFontSize="12px"
                [startTime]="shiftType?.startTime" [endTime]="shiftType?.endTime">
              </app-custom-shift-time-display>
              <span [innerHTML]="calculateHours(shiftType?.startTime,shiftType?.endTime, true)"></span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="shiftTime?.status ==='VALID'" fxLayout="row" fxLayoutGap="12" class="shift-hours-time">
          <span class="number"> {{hours}}</span><span class="letter">H &nbsp; </span>
          <span class="number">{{minutes}}</span><span class="letter">MIN</span>
        </div>
      </div>
      <div class="partial-shift-time" *ngIf="shiftOptions === ShiftCreationType.PARTIAL">
        <mat-label class="partial-shift-label">Start Time</mat-label>
        <div class="form-field standard partial-time" fxLayout="row">
          <mat-form-field appearance="outline" automation-id="start-time-hour">
            <mat-select #starttime placeholder="H" formControlName="partialStartHour"
              automation-id="selected-start-time-hour" disableOptionCentering="true" panelClass="pannelCustomClass">
              <mat-option class="partial-shift-option" *ngFor="let startHour of TimeConstants.HOURS"
                [value]="startHour">
                {{ startHour }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" automation-id="start-time-minute">
            <mat-select #starttime placeholder="Min" formControlName="partialStartMinutes"
              automation-id="selected-start-time-minute" disableOptionCentering="true" panelClass="pannelCustomClass">
              <mat-option class="partial-shift-option" *ngFor="let startMinute of TimeConstants.MINUTES"
                [value]="startMinute">
                {{ startMinute }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-ampm-toggle
            [showToggle]="!createShiftForm.controls.partialStartHour.invalid && !createShiftForm.controls.partialStartMinutes.invalid"
            (toggleAmPm)="createShiftForm.controls.startFormat.setValue($event)">
          </app-ampm-toggle>
        </div>
        <div class="partial-shift-hours" fxLayout="row" fxLayoutGap="12" fxLayoutAlign="end center"
          *ngIf="calculatedHours == 'true'">
          <span class="number"> {{hours}}</span><span class="letter">H &nbsp; </span>
          <span class="number">{{minutes}}</span><span class="letter">MIN</span>
        </div>
        <mat-label class="partial-shift-label">End Time</mat-label>
        <div class="form-field standard partial-time" fxLayout="row" fxLayoutGap="12">
          <mat-form-field appearance="outline" automation-id="end-time-hour">
            <mat-select #endtimeHours placeholder="H" formControlName="partialEndHour"
              automation-id="selected-end-time-hour" disableOptionCentering="true" panelClass="pannelCustomClass">
              <mat-option class="partial-shift-option" *ngFor="let endHour of TimeConstants.HOURS" [value]="endHour">
                {{ endHour }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" automation-id="end-time-minute">
            <mat-select #endtimeMinutes placeholder="Min" formControlName="partialEndMinutes"
              automation-id="selected-end-time-minute" disableOptionCentering="true" panelClass="pannelCustomClass">
              <mat-option class="partial-shift-option" *ngFor="let endMinute of TimeConstants.MINUTES"
                [value]="endMinute">
                {{ endMinute }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-ampm-toggle
            [showToggle]="!createShiftForm.controls.partialEndHour.invalid && !createShiftForm.controls.partialEndMinutes.invalid"
            (toggleAmPm)="createShiftForm.controls.endFormat.setValue($event)">
          </app-ampm-toggle>
        </div>
      </div>

      <div class="form-field standard" fxLayout="row">
        <mat-form-field appearance="outline" automation-id="location">
          <mat-label>Location {{location?.status==='VALID' ? '*' : ''}}
          </mat-label>
          <mat-select [disableOptionCentering]="shiftOptions === ShiftCreationType.STANDARD" placeholder="Location"
            formControlName="location" automation-id="selected-location" panelClass="pannelCustomClass">
            <mat-option *ngFor="let unit of unitInfo" [value]="unit?.id">{{unit?.floorName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-field short-field standard" fxLayout="row">
        <mat-form-field appearance="outline" automation-id="shift-type">
          <mat-label>Shift Type
            <span>{{staffType?.status ==='VALID'? '*' : ''}}</span>
          </mat-label>
          <mat-select disableOptionCentering="true" placeholder="Shift Type" formControlName="staffType"
            automation-id="selected-shift-type" panelClass="pannelCustomClass">
            <mat-select-trigger>{{staffType?.value?.displayName || staffType?.value?.name}}</mat-select-trigger>
            <mat-option *ngFor="let staffType of staffTypeData" [value]="staffType">
              {{staffType?.displayName || staffType?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="footer">
    <app-custom-drawer-footer [isFirstButtonDisabled]="!createShiftForm.valid || !datesSelected.length"
      [isSecondButtonDisabled]="!createShiftForm.valid || (datesSelected.length > 1) || !datesSelected.length"
      (clickedButton)="clickedButton($event)" [isLoading]="isLoading"
      [firstButtonIcon]="CreateShiftConstants.CREATE_SHIFT_ICON" [firstButtonLabel]="CreateShiftConstants.CREATE_LABEL"
      [secondButtonIcon]="CreateShiftConstants.ASSIGNMENT_ICON"
      [secondButtonLabel]="CreateShiftConstants.ASSIGNMENT_LABEL">
    </app-custom-drawer-footer>
  </div>
  <div class="loader-container" *ngIf="isLoading">
    <div class="show-loader" automation-id="loading-icon">
      <img src="../../../../assets/images/loader.gif" height="40" width="40" />
    </div>
  </div>
</div>