import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AddStaffService } from 'src/app/on-boarding/components/add-staff/services/add-staff.service';

@Component({
  selector: 'app-template-upload',
  templateUrl: './template-upload.component.html',
  styleUrls: ['./template-upload.component.scss']
})
export class TemplateUploadComponent implements OnInit {

  @Output() renderPage: EventEmitter<number> = new EventEmitter<number>();

  loading = false;
  isError = false;
  filename: string;
  response: string;
  errorList: string[];

  constructor(private _addStaffService: AddStaffService) {
  }

  /**
   * On component initialisation fileUpload function is called
   */
  ngOnInit(): void {
    this.loading = true;
    this.filename = this._addStaffService.fileName;
    this.fileUpload().pipe(
      tap(response => {
        this.loading = false;
        this._manageErrorMessages(response);
      }, error => {
        this.loading = false;
        this.isError = true;
        this._manageErrorMessages(error);
      })
    ).subscribe();
  }

  /**
   * Service call to upload spreadsheet
   */
  private fileUpload() {
    return this._addStaffService.uploadSpreadSheet().pipe();
  }

  /**
   * Go back to add staff page
   */
  backButtonClick(page: boolean): void {
    this.renderPage.emit(2);
  }

  /**
   * Function to create error list
   */
  _manageErrorMessages(response: any) {
    this.errorList = [];
    response?.error?.data?.forEach(item => {
      const error = `<div><b class="err-row-width">Row  ${item.rownumber} Error: </b> ${item.errormessage}.</div>`;
      this.errorList.push(error);
    });
  }
}
