<div appFileUpload *ngIf="!usedInsideApp" (click)="fileInput.click(); updateGoogleAnalytics(); changeToBrowseImage()"
  (onFileDropped)="uploadFile($event)" class="uploadfilecontainer"  [ngClass]="{ 'no-pointer' : isDisabled }">
  <img [src]="currentPath">
  <button automation-id="browse-file-button-csv" mat-button>Browse File...</button>
  <input hidden type="file" [disabled]="isDisabled" #fileInput accept=".csv" (change)="uploadFile($event.target.files)" automation-id="input-csv-file-upload">
  <div *ngIf="isInvalidFile" class="error">Please only upload a .CSV file</div>
</div>


<button (click)="fileInput.click(); updateGoogleAnalytics();" *ngIf="usedInsideApp" mat-button class="reupload-button" automation-id="upload-csv-file-button"><span
    class="button-label">Upload file Again</span> </button>
<input hidden type="file" #fileInput accept=".csv" (change)="uploadFile($event.target.files)" automation-id="input-csv-file-upload-again">