import { Pipe, PipeTransform } from '@angular/core';
import { ConvertersService } from '../../services/utils/converters.service';

@Pipe({
  name: 'toShiftHoursMobile'
})
export class ToShiftHoursMobilePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    return ConvertersService._datesToShiftHoursMobile({ startTime: value, endTime: args });
  }

}
