import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { GoogleAnalyticsService } from './services/googleanalytics.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from '@theme/theme.module';
import { theme_config } from './config/theme_config';
import { BaseLayoutModule } from './base-layout/base-layout.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CookieService } from 'ngx-cookie-service';
import { ErrorHandlingInterceptor } from './helpers/error-handling.interceptor';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import * as moment from 'moment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { WrongDeviceModule } from './wrong-device/wrong-device.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ThemeModule.forRoot(theme_config),
    SharedModule,
    BaseLayoutModule,
    DeviceDetectorModule,
    WrongDeviceModule,
    NgxDaterangepickerMd.forRoot({
      format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: 'MM/DD/YYYY', // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' - ', // default is ' - '
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 0 // first day is monday
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_KEY,
      libraries: ['places']
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    CookieService,
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
