import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoostApprovalQueueComponent } from './boost-approval-queue.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RequestCardModule } from './components/request-card/request-card.module';
import { MatBadgeModule } from '@angular/material/badge';



@NgModule({
  declarations: [BoostApprovalQueueComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    RequestCardModule,
    MatBadgeModule
  ],
  exports: [BoostApprovalQueueComponent]
})
export class BoostApprovalQueueModule { }
