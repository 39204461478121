import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatePasswordComponent } from './validate-password.component';
import { SharedModule } from '../../shared.module';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [
    ValidatePasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  exports: [
    ValidatePasswordComponent
  ]
})
export class ValidatePasswordModule { }
