import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileForgotPasswordComponent } from './mobile-forgot-password.component';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { MobileForgotPasswordRoutingModule } from './mobile-forgot-password-routing.module';
import { ValidatePasswordModule } from '../shared/componets/validate-password/validate-password.module';

@NgModule({
  declarations: [
    MobileForgotPasswordComponent
  ],
  exports: [
    MobileForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    MobileForgotPasswordRoutingModule,
    ValidatePasswordModule
  ]
})
export class MobileForgotPasswordModule { }
