import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmFacilityScheduleDetailsComponent } from './confirm-facility-schedule-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material.module';



@NgModule({
  declarations: [ConfirmFacilityScheduleDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
  ],
  exports: [ConfirmFacilityScheduleDetailsComponent]
})
export class ConfirmFacilityScheduleDetailsModule { }
