<div class="confirm-container">
    <h5>Your Requests are Submitted</h5>
    <div class="confirm-body">
        <div class="summary-info">
            <img src="assets/images/check.png" />
            <p>Thank you for your requests! Our team has
                received your request coverage and will work
                toward fulfilling your shifts.
            </p>
        </div>
        <div class="requests-container">
            <h5>Request Details</h5>
            <ng-container *ngFor="let item of totalCartInfo?.cartItems">
                <ng-container *ngFor="let day of item.days">
                    <div class="requests-details" automation-id="request-details-container">
                        <p>{{item.unit?.floorName}}</p>
                        <div class="body">
                            <p class="day">{{day | date:'E LLL d'}}</p>
                            <p>{{item.shiftTime.shiftName}}</p>
                        </div>
                        <div *ngFor="let st of item.staffTypes || []">
                            <ng-container *ngIf="st.staffType.stateSpecificLabel?.length > 0">
                                <ng-container *ngFor="let state of st.staffType?.stateSpecificLabel">
                                    <span>{{state.shiftTypeLabel}} {{st.count}}</span>
                                </ng-container>
                            </ng-container>
                            <ng-container  *ngIf="!st.staffType.stateSpecificLabel?.length">
                                <span class="gender-info" >{{st.staffType.shiftTypeLabel}} {{st.count}}</span>
                            </ng-container>
                            <span>{{st.gender !== 'F' ? 'Male or Female' : 'Female'}}</span>
                        </div>
                        <ng-container *ngIf="boostResponse.boostSucceeded">
                            <app-boost-summary [boostResponse]="boostResponse.boostResponse"></app-boost-summary>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="confirm-footer">
        <ng-container *ngIf="(requestIds$ | async) as request">
            <ng-container *ngIf="!boostResponse.boostSucceeded && request.length > 0">
                <div class="boost-shift">
                    <app-boost-shift [request]="request" (emitter)="getBoostResponse($event)"></app-boost-shift>
                </div>
            </ng-container>
        </ng-container>
        <button mat-button (click)="close()" automation-id="done-button">Done</button>
    </div>
</div>