import { BulkRemove } from './../models/shift-management-models/BulkRemove';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { StaffShiftsData } from '../models/newModels/StaffShiftsData';
import { RequestData } from '../models/newModels/RequestData';
import { UtilsService } from './utils/utils.service';
import { SettingData } from '../models/newModels/SettingData';
import { BaseResponseModel } from '../models/BaseResponseModel';
import { MonthlyShiftData } from '../models/newModels/MonthlyShiftData';
import { catchError, map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CallOutReasonModel } from '../models/newModels/CallOutReasonModel';
import { JavaPayload } from '../models/newModels/JavaPayload';
import { ShiftCancelData } from '../models/shift-management-models/cancel-shift-Data.model';
import { Shift } from '../models/shift-management-models/shift';
import {
  RemoveStaff,
  ShiftDefsRequest,
  ShiftDefsResponse,
  ShiftRequest,
  ShiftResponse,
  MoveStaffData,
  DeclineShift,
  RemoveShiftStaff,
  GetShiftsRequest,
  GetShiftsResponse,
  RecentShift,
  CreateShift,
  RemoveStaffReason,
  IntelyRequestResponse
} from '../models/java-models';
import { JavaBaseResponse } from '../models/shift-management-models/java-base-response';
import { EditPermanentShiftModel } from '../models/shift-management-models/edit-permanent-shift.model';
import { StartOfWeek } from '../models/shift-management-models/StartOfWeek';
import { ShiftHistoryResponse } from '../models/ShiftHistoryResponse';
import { BillingPayload } from '../models/newModels/BIllingPayload';

/**
 * ShiftManagementService manages shift operations (get,update,post) in database.
 */

const formUrlEncoded = {
  headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
};

@Injectable({
  providedIn: 'root'
})
export class ShiftManagementService {
  public refreshOpenDate$: Subject<void> = new Subject();
  private _isystem = UtilsService.getISystemBaseUri();
  private _shiftJavaUri = UtilsService.getShiftJavaUri();
  constructor(
    private _http: HttpClient,
    private _snackbar: MatSnackBar) {
  }

  /**
   * to refresh data
   */
  refresh(): void {
    this.refreshOpenDate$.next();
  }

  /**
   * Get all open shifts.
   * @param params : Shift's details
   */
  public getShifts(filters: GetShiftsRequest): Observable<GetShiftsResponse> {
    const arrayOfKeys: string[] = Object.keys(filters);
    let params: HttpParams = new HttpParams();
    arrayOfKeys.forEach((key: string) => {
      params = params.append(key, String(filters[key]));
    });
    return this._http.get<JavaPayload<GetShiftsResponse>>(`${this._shiftJavaUri}/shifts/`, { params }).pipe(
      map((resp: JavaPayload<GetShiftsResponse>) => resp.data),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  public getAllShiftRequests(shiftId?: number, staffId?: number, startdate?: string, enddate?: string): Observable<RequestData[]> {
    let reqParam = '?';
    reqParam += shiftId ? `shiftId=${shiftId}` : '';
    reqParam += staffId ? `staffId=${staffId}` : '';
    reqParam += startdate ? `&startDate=${startdate}` : '';
    reqParam += enddate ? `&endDate=${enddate}` : '';
    reqParam.replace('?&', '?'); // in case that shiftid or staffid is nor passed
    return this._http.get<JavaBaseResponse<RequestData[]>>(`${this._shiftJavaUri}/shift/staff/requests${reqParam}`)
      .pipe(map(e => e.data));
  }

  /**
   * Get's callout reasons.
   */
  public getCalloutReasons(): Observable<CallOutReasonModel[]> {
    return this._http.get<JavaPayload<CallOutReasonModel[]>>(`${this._shiftJavaUri}/calloutReasons/`).pipe(
      map((response: JavaPayload<CallOutReasonModel[]>) => response.data),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Get's cancel data for a shift.
   * @param reqdid : Shift's request ID
   * @param uid : User ID
   */
  public getShiftCancelData(reqdid: number, uid: number): Observable<ShiftCancelData> {
    const params = new FormData();
    params.append('reqdid', String(reqdid));
    params.append('uid', String(uid));
    return this._http.post<BaseResponseModel<ShiftCancelData>>(`${this._isystem}/pages/shiftCancelDet.php`, params).pipe(
      map((response: BaseResponseModel<ShiftCancelData>) => response.data),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Removes a staff from a shift with reason.
   * @param staffID : Staff ID
   * @param shiftID : Shift ID
   * @param calloutReasonID : Reason ID
   */
  public removeStaffWithReason(staffID: number, shiftID: number, calloutReasonID: number, notes?: string, reqId?: number): Observable<RemoveStaffReason> {
    const body: Object = <Object>{
      'reqId': reqId,
      'staffId': staffID,
      'shiftId': shiftID,
      'reasonId': calloutReasonID,
      'Notes': notes
    };
    return this._http.post<JavaPayload<RemoveStaffReason>>(`${this._shiftJavaUri}/callout`, body).pipe(
      map((resp: JavaPayload<RemoveStaffReason>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Sent IntelyPro resuest.
   * @param ShiftID : Shift ID
   */
  public hasIntelyproRequest(ShiftID?: number): Observable<boolean> {
    return this._http.get<JavaPayload<IntelyRequestResponse>>(`${this._shiftJavaUri}/shift/intelyrequestsent/${ShiftID}`).pipe(
      map((resp: JavaPayload<IntelyRequestResponse>) => resp.data.sent),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Change shift time and date.
   * @param TOCensusID : TO Census ID
   * @param FromShiftId : From Shift ID
   * @param ToShiftDay : To Shift Day
   * @param toShiftId : TO Shift ID
   */
  public changeShiftTimeAndDate(
    toCensusID: number,
    fromShiftId: number,
    toShiftDay: string,
    toShiftId?: number
  ): Observable<MoveStaffData> {
    const body: MoveStaffData = {
      'toCensusId': toCensusID,
      'fromShiftId': fromShiftId,
      'toShiftDay': toShiftDay,
      'toShiftId': toShiftId,
    };
    return this._http.post<JavaPayload<MoveStaffData>>(`${this._shiftJavaUri}/shift/staff/moveShift`, body).pipe(
      map((resp: JavaPayload<MoveStaffData>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
    * Call's backend API to assign shifts.
    * @param body : Assign shift data
    */
  public assignToShift(body: { staffId: number, shiftId: number }): Observable<JavaPayload<any>> {
    return this._http.post<JavaPayload<any>>(`${this._shiftJavaUri}/shift/staff/scheduleshift`, body).pipe(
      tap(resp => {
        this._snackbar.open(resp.message, 'X', {
          duration: 3000
        });
      }),
      map((resp: JavaPayload<any>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Call monthly API to get shifts daily information for a given month of year
   * @param month : Month selected
   * @param year : Year selected
   */
  public getMonthlyShiftInfo(month: string, year: string): Observable<BaseResponseModel<MonthlyShiftData[]>> {
    return this._http.get<BaseResponseModel<MonthlyShiftData[]>>(`${this._shiftJavaUri}/shift/monthly`, {
      params: {
        month: month,
        year: year
      }
    });
  }

  public getMonthlyShiftsInfo(startDate: string, endDate: string): Observable<BaseResponseModel<MonthlyShiftData[]>> {
    return this._http.get<BaseResponseModel<MonthlyShiftData[]>>(`${this._shiftJavaUri}/shift/range/start/${startDate}/end/${endDate}`);
  }

  /**
   * Call's backend API to create new shifts.
   * @param body : New shifts data
   */
  public createShifts(body: CreateShift[]): Observable<Shift[]> {
    return this._http.post<JavaPayload<Shift[]>>(`${this._shiftJavaUri}/shift`, body).pipe(
      map((resp: JavaPayload<Shift[]>) => resp.data),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  public getStaffShifts(staffId: number): Observable<StaffShiftsData[]> {
    return this._http.get<JavaPayload<StaffShiftsData[]>>(`${this._shiftJavaUri}/shift/staff/id/${staffId}/staffshifts`)
      .pipe(
        map(e => e.data)
      );
  }

  public editStaffPermanentShifts(editPermanenShift: EditPermanentShiftModel) {
    return this._http.post(`${this._shiftJavaUri}/shift/staff/edit`, editPermanenShift).pipe(
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );

  }

  /**
    * Call's backend API to Remove staff from shift.
    * @param requestBody : User shift deatils
    */
  public removeStaffFromShift(requestBody: RemoveStaff): Observable<RemoveStaff> {
    return this._http.post<JavaPayload<RemoveStaff>>(`${this._shiftJavaUri}/shift/staff/remove`, requestBody).pipe(
      map((resp: JavaPayload<RemoveStaff>) => {
        resp.data.message = resp.message;
        return resp.data;
      }),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Call's backend API to Remove shift from staff.
   * @param staffid : Staff ID
   * @param openShifts : Shift has assign or not
   */

  public removeShiftsFromStaff(staffid: number, openShifts: boolean = true): Observable<RemoveShiftStaff> {
    const params = new HttpParams()
      .append('staffId', String(staffid))
      .append('openShifts', String(openShifts));
    return this._http.delete<JavaPayload<RemoveShiftStaff>>(`${this._shiftJavaUri}/shift/staff?`, { params }).pipe(
      map((resp: JavaPayload<RemoveShiftStaff>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
  * Call's backend API to Send shift request.
  * @param requests : Shift deatils
  */
  public sendRequest(requests: ShiftRequest[]): Observable<ShiftResponse[]> {
    return this._http.put<JavaPayload<ShiftResponse[]>>(`${this._shiftJavaUri}/shift/request/send`, requests).pipe(
      map((resp: JavaPayload<ShiftResponse[]>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.errors[0], 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  acceptShiftRequest(shiftHistoryId: number) {
    return this._http.put<any>(`${this._shiftJavaUri}/shift/request/${shiftHistoryId}/accept`, {});
  }

  /**
  * Call's backend API to Decline the shift.
  * @param shiftHistoryID : Shift History ID
  */

  declineShiftRequest(shiftHistoryID: number): Observable<DeclineShift> {
    const params = new HttpParams()
      .append('shiftHistoryId', String(shiftHistoryID));
    return this._http.put<JavaPayload<DeclineShift>>(`${this._shiftJavaUri}/shift/request/decline`, null, { params }).pipe(
      map((resp: JavaPayload<DeclineShift>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  confirmShiftRequest(shiftHistoryId: number) {
    return this._http.put<any>(`${this._shiftJavaUri}/shift/confirm?shiftHistoryId=${shiftHistoryId}`, {});
  }

  getAssignedStaffToShift(ShiftDate: string, ShiftID: number): Observable<JavaBaseResponse<number[]>> {
    return this._http.get<JavaBaseResponse<number[]>>(`${this._shiftJavaUri}/shift/staffids?shiftDate=${ShiftDate}&shiftDefId=${ShiftID}`);
  }

  setWeekStartAndDOW(dow: any): Observable<any> {
    return this._http.put<any>(`${this._shiftJavaUri}/weekNumber/set/${dow}`, {});
  }

  getWeekStartAndDOW(): Observable<JavaBaseResponse<StartOfWeek>> {
    return this._http.get<JavaBaseResponse<StartOfWeek>>(`${this._shiftJavaUri}/settings/startweek/info`);
  }

  /**
     * Call's backend API to Add shift def.
     * @param shifts : Shift details
     */

  addshifttimes(shifts: ShiftDefsRequest[]): Observable<ShiftDefsResponse[]> {
    return this._http.post<JavaPayload<ShiftDefsResponse[]>>(`${this._shiftJavaUri}/shifts/def`, shifts).pipe(
      map((resp: JavaPayload<ShiftDefsResponse[]>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Call's backend API to Get all the shifts.
   */
  getRecentShifts(): Observable<RecentShift[]> {
    return this._http.get<JavaPayload<RecentShift[]>>(`${this._shiftJavaUri}/shift/staff/nextlast/`).pipe(
      map((resp: JavaPayload<RecentShift[]>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  setAutoConfirm(setting: SettingData) {
    return this._http.put(`${this._shiftJavaUri}/settings/${setting.settingsname}/value/${setting.settingvalue}`, {});
  }

  postShiftHistoryI(reqdid: number, type: string, uid: number): Observable<ShiftHistoryResponse[]> {
    const requestBody = new URLSearchParams();
    requestBody.set('reqdid', String(reqdid));
    requestBody.set('type', type);
    requestBody.set('uid', String(uid));
    return this._http.post<{ code: number, data: ShiftHistoryResponse[] }>(`${this._isystem}/manage/reports.php`,
      String(requestBody), formUrlEncoded)
      .pipe(
        map(resp => resp.data)
      );
  }

  postViewStatus(section: string, cid: number, reqdid: number, uid: number): Observable<any> {
    const requestBody = new URLSearchParams();
    requestBody.set('section', section);
    requestBody.set('cid', String(cid));
    requestBody.set('reqdid', String(reqdid));
    requestBody.set('uid', String(uid));
    return this._http.post<any[]>(`${this._isystem}/pages/partcare.php`, String(requestBody), formUrlEncoded);
  }

  /**
  * Call's backend API to remove bulk shifts.
  */
  public bulkRemove(bulkRemoveRequest: BulkRemove): Observable<any> {
    return this._http.post<JavaPayload<any>>(`${this._shiftJavaUri}/callout/bulkremove`, bulkRemoveRequest).pipe(
      map((resp: JavaPayload<any>) => resp.data),
      catchError(error => {
        this._snackbar.open(error.error.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  public cloneStaffShifts(body: any): Observable<JavaPayload<any>> {
    return this._http.post<JavaPayload<any>>(`${this._shiftJavaUri}/shift/staff/copyshifts`, body).pipe(
      map((resp) => resp),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  getOpenShifts(cid: number, startDate: string, endDate: string): Observable<JavaPayload<any[]>> {
    return this._http.get<JavaPayload<any[]>>(`${this._shiftJavaUri}/shifts/openshifts?clientId=${cid}&startDate=${startDate}&endDate=${endDate}`);
  }

  updateRevisons(formData): Observable<BillingPayload> {
    const requestBody = new URLSearchParams();
    Object.keys(formData).forEach(key => {
      requestBody.set(key, formData[key]);
    })
    return this._http.post<BillingPayload>(`${this._isystem}/manage/billing.php`, String(requestBody), formUrlEncoded)
    .pipe(
      catchError(error => {
        this._snackbar.open(error?.message, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }
}
