import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { navigation } from '../../../config/navigation';
import { FuseConfigService } from '@theme/services/config.service';
import { PerfectScrollbarDirective } from '@theme/directives/perfect-scrollbar/perfect-scrollbar.directive';
import { TemplateCustomConfig } from '../../../models/TemplateCustomConfig';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'vertical-layout-2',
  templateUrl: './layout-2.component.html',
  styleUrls: ['./layout-2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayout2Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  customConfig: TemplateCustomConfig;
  showScrollToTop = false;
  hasLeftBar = false;
  staffDirectory = false;
  @ViewChild(PerfectScrollbarDirective, { static: true }) private _container: PerfectScrollbarDirective;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _router: Router,
    private _fuseConfigService: FuseConfigService
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    _router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.match(/\/directory/)) {
          this.staffDirectory = true;
        } else {
          this.staffDirectory = false;
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
        this.hasLeftBar = (!this.fuseConfig.layout.sidepanel.hidden) && (this.fuseConfig.layout.sidepanel.position === 'left')
          ? true : false;
      });

    this._fuseConfigService.getCustomConfig
      .pipe()
      .subscribe(resp => {
        this.customConfig = resp;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onContentScroll(event) {
    this.showScrollToTop = event.srcElement.scrollTop > 800;
  }

  scrollContainerToTop() {
    this._container.scrollToTop(0, 200);
  }

  addToolbarShadow() {
    if (this._router.url === '/directory' || this._router.url.includes('/settings')) {
      return true;
    }

    return false;
  }
}
