import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartData } from 'src/app/monthly-view/components/bulk-shift-booking/models/cart-data';
import { ShoppingCartService } from 'src/app/monthly-view/components/bulk-shift-booking/services/shopping-cart.service';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss']
})


export class MobileFooterComponent implements OnInit {

  @Input() ipRequest = false;
  cart: CartData[] = [];
  private _unsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private _cartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
    this._cartService.cartState.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe(e => {
      this.cart = !!e ? e : [];
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  get totalRequests() {
    let total = 0;
    for (const request of this.cart) {
      total += request.staffTypes.reduce((current, type) => current += type.count, 0) * request.days.length;
    }
    return total;
  }

  viewShoppingCart() {
    this._cartService.openShoppingCartSidebar();
  }

  clearCart() {
    this._cartService.clearCart();
  }
}

