import { Component, Input, OnInit } from '@angular/core';
import { ShiftData } from '../../../models/newModels/ShiftData';
import { flatten } from '@angular/compiler';
import { RequestHelperData } from '../../../models/newModels/RequestHelperData';
import { ViewLiveUpdateService } from '../../../services/view-live-update.service';
import { RequestData } from '../../../models/newModels/RequestData';
import { ShiftManagementService } from '../../../services/shift-management.service';
import { ShiftManagementSidebarService } from '../../shift-management-sidebar.service';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';

@Component({
  selector: 'fill-shift-responses',
  templateUrl: './fill-shift-responses.component.html',
  styleUrls: ['./fill-shift-responses.component.scss']
})
export class FillShiftResponsesComponent implements OnInit {

  @Input() shiftDetails: ShiftData;
  @Input() requests: RequestHelperData;

  staff: StaffMember[] = [];
  staffList: StaffMember[] = [];
  selectedStaff: StaffMember[] = [];
  unselectedStaff: StaffMember[] = [];

  selectedRequest: RequestData;

  constructor(private _liveService: ViewLiveUpdateService,
    private _shiftService: ShiftManagementService,
    private _sidebar: ShiftManagementSidebarService) {
  }

  get areAllChecked() {
    return this.selectedStaff.length > 0 && this.unselectedStaff.length > 0;
  }

  get allRequests(): number {
    return this.requests ? flatten(Object.values(this.requests.requests)).length : 0;
  }

  ngOnInit() { }

  selectStaff(staff: StaffMember) {
    this.selectedStaff.push(staff);
  }

  toggleAll(newValue) {
    this.selectedStaff = newValue ? [...this.staff] : [];
  }

  setSelectedItem(selectedRequest: RequestData) {
    this.selectedRequest = selectedRequest;
  }

  getStaffMember(staffid: number) {
    return this._liveService.toStaffInfo(staffid);
  }

  confirmRequest() {
    this._shiftService.confirmShiftRequest(this.selectedRequest.shiftHistoryID).subscribe(e => {
      this._sidebar.closePanel();
    });
  }
}
