import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ampm-toggle',
  templateUrl: './ampm-toggle.component.html',
  styleUrls: ['./ampm-toggle.component.scss']
})
export class AmpmToggleComponent implements OnInit {
  @Input() showToggle;
  @Output() toggleAmPm: EventEmitter<boolean> = new EventEmitter();

  checkedToggle = false;
  constructor() { }

  ngOnInit(): void {
  }

  checkToggle(e) {
    this.checkedToggle = e.checked;
    this.toggleAmPm.emit(this.checkedToggle);
  }

}
