import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { CalendarViewType } from 'src/app/enums/calendar-type.enum';
import { CreateShift } from 'src/app/models/java-models';
import { ShiftUpdateRequest } from 'src/app/models/java-models/ShiftUpdateRequest';
import { Shift } from 'src/app/models/shift-management-models/shift';
import { CreateShiftConstants } from 'src/app/shared/constants/createShift';
import { ShiftManagementSidebarService } from 'src/app/shift-management/shift-management-sidebar.service';
import { SpreadsheetService } from '../spreadsheet.service';

@Component({
  selector: 'app-confirm-shifts-spreadsheet',
  templateUrl: './confirm-shifts-spreadsheet.component.html',
  styleUrls: ['./confirm-shifts-spreadsheet.component.scss']
})
export class ConfirmShiftsSpreadsheetComponent implements OnInit {
  @Input() shiftDetails: CreateShift[];
  @Input() selectedRow: any;
  public datesSelected = [];
  shiftData: Shift;
  selectedShifts = [];
  unselectedShifts = [];
  total = 0;
  isLoading = false;
  shifts: any = {};
  allOverlap: boolean;
  selectAllCondition: boolean;
  public readonly CreateShiftConstants = CreateShiftConstants;
  public readonly calendarViewType = CalendarViewType;

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _shiftManagementSidebar: ShiftManagementSidebarService,
    private spreadsheetService: SpreadsheetService,
  ) {
  }

  ngOnInit() {
    this.shiftData = {
      firstName: this.selectedRow?.firstName,
      lastName: this.selectedRow?.lastName,
      shiftDate: formatDate(new Date(), 'yyyy-MM-dd', 'en-Us'),
      floorName: this.selectedRow?.unit.floorName,
      shiftName: this.selectedRow?.shift_time.shiftName,
      staffTypeName: this.selectedRow?.staff_type,
      isPartial: false,
      endTime: this.selectedRow?.shift_time.endTime,
      startTime: this.selectedRow?.shift_time.startTime
    };
    this.selectedShifts = [...this.shiftDetails.filter(shift => !shift.overlapping)];
    this.selectedRow.overlapping = [...this.shiftDetails.filter(shift => shift.overlapping)].map(overlappingShift => overlappingShift.shiftDate);
    this.allOverlap = this.shiftDetails.every(shift => shift.overlapping);
    this.selectAllCondition = this.allOverlap || this.selectedShifts.length !== this.shiftDetails.filter(shift => !shift.overlapping).length;
  }


  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  selectItem(item) {
    if (!item.overlapping) {
      const idx = this.getIndexOf(item);
      if (idx < 0) {
        this.selectedShifts.push(item);
      } else {
        this.selectedShifts.splice(idx, 1);
      }
      this.unselectedShifts = this.shiftDetails.filter(a => this.selectedShifts.findIndex(b => a.shiftDate === b.shiftDate) === -1);
      this._cdr.detectChanges();
      this.allOverlap = this.shiftDetails.every(shift => shift.overlapping);
      this.selectAllCondition = this.allOverlap || this.selectedShifts.length !== this.shiftDetails.filter(shift => !shift.overlapping).length;
    }
  }

  getIndexOf(item) {
    if (!item.overlapping) {
      let indx = -1;
      this.selectedShifts.forEach((shift, i) => {
        if (shift.shiftDate === item.shiftDate) {
          indx = i;
        }
      });
      return indx;
    }
  }

  toggleAll(newValue) {
    this.selectedShifts = newValue ? [...this.shiftDetails.filter(shift => !shift.overlapping)] : [];
    this.selectedShifts = [...this.selectedShifts];
    this._cdr.markForCheck();
  }



  createShift(): void {
    this.isLoading = true;
    let shiftsToUpdate: ShiftUpdateRequest[] = [];
    this.selectedShifts.forEach((shift) => {
      let shiftToUpdate: ShiftUpdateRequest = {
        clientId: this.selectedRow.clientId,
        date: formatDate(shift.shiftDate, 'yyyy-MM-dd', 'en-US'),
        staffId: shift.staffId,
        unitId: shift.unitId,
        shiftDefId: shift.shiftTypeId,
        staffTypeId: shift.staffTypeId,
        active: true,
      };
      shiftsToUpdate.push(shiftToUpdate);
    });

    this.spreadsheetService
      .updateShift(shiftsToUpdate)
      .pipe(
        first(),
        finalize(() => this.isLoading = false),
      ).subscribe(res => {
        const viewType = localStorage.getItem('calendarViewType');
        this.spreadsheetService.calendarViewBy.next(viewType);
      });
  }

  assignOrBack(event) {
    if (event === 'Back') {
      this.selectedShifts = [...this.selectedShifts, ...this.shiftDetails.filter(shift => shift.overlapping)];
      this.selectedRow.selectedDates = this.selectedShifts.map(shifts => shifts.shiftDate);
      this.selectedRow.allOverlap = this.selectedShifts.every(shift => shift.overlapping);
      this._shiftManagementSidebar.openCreateShiftSpreadsheet(this.selectedRow);
    } else {
      this.createShift();
      this._shiftManagementSidebar.closePanel();
    }
  }
}