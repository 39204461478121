<div fxLayout="column" fxLayoutGap="5px">

    <div fxLayout="column" fxLayoutAlign="space-between">

        <div  class="request-information">
            <p class="request-by">Request submitted to boost {{ !request.blockId ? 'shift' : 'block booking' }} from {{ request.requestUserName }}</p>
            <div *ngIf="!request.blockId; else blockBookingInfo">
                <p class="type-information">{{ request.typedet }} &#9679; {{ getHours(request.hours) }}</p>
                <p class="floor-information">{{ request.floor }}</p>
            </div>
            <ng-template #blockBookingInfo>
                <div>
                    <p class="type-information">{{ request.formatStartDate }} - {{ request.formatEndDate }}</p>
                    <p class="type-information">
                        <img src="assets/images/block-booking-icon.png" class="block-booking-icon">
                        {{ request.typedet }}
                    </p>
                    <p class="floor-information">{{ request.unit }}</p>
                </div>
            </ng-template>
        </div>
        
        <div fxFlex="0 0 auto" class="rate-information" fxLayout="column" fxLayoutGap="4px">
            <ng-container *ngIf="!request.blockId">
                <ng-container *ngIf="request.rate.length > 1; else singleCurrentRate">
                    <div class="current-rate" *ngFor="let rate of request.rate">
                        <p class="nurse">{{ rate.pType }} Boosted Rate</p>
                        <p class="rate">{{ rate.newCharge | currency: 'USD' }}/hr</p>
                    </div>
                </ng-container>
                <ng-template #singleCurrentRate>
                    <div class="current-rate">
                        <p class="nurse">Boosted Rate</p>
                        <p class="rate" *ngIf="request.rate.length == 1">{{ request.rate[0].newCharge | currency: 'USD' }}/hr</p>
                    </div>
                </ng-template>
                
                <div fxLayout="row" class="previous-rate">
                    <p>Previous Billing Rate</p>
                    <p *ngIf="request.rate.length > 1; else singlePastRate">
                        <span *ngFor="let rate of request.rate; let i = index">
                           {{ rate.pType }} {{ (rate.charge | currency: 'USD') + '/hr' + (i !== (request.rate.length - 1) ? ' |' : '') }}
                        </span>
                    </p>
                    <ng-template #singlePastRate>
                        <p *ngIf="request.rate.length == 1">{{ (request.rate[0].charge | currency: 'USD') + '/hr' }}</p>
                    </ng-template>
                </div>
            </ng-container>
            <div class="boost-section">
                <span *ngIf="request.boostpercentage" 
                    class="promotion-rate"
                    fxLayout="row" 
                    fxLayoutAlign="center center"
                    [class.inactive-rate]="request.status !== 'Pending' && request.status !== 'Approved'">
                    <i class="fal fa-rocket icon boost-icon"></i>
                    {{ request.boostpercentage }}%
            </span>
            <span *ngIf="request?.overtimeSetting === OverTime.AllFACILITY ||
                            request?.overtimeSetting === OverTime.MYFACILITY || 
                            request?.overtimeSetting === OverTime.NO"
                    [class.inactive-rate]="request.status !== 'Pending' && request.status !== 'Approved'"
                    class="promotion-rate">
                    <img src="assets/images/overtime.svg" alt="Overtime Boost" >
                    {{ request?.overtimeSetting === OverTime.AllFACILITY ? OVER_TIME.allFac 
                        : (request?.overtimeSetting === OverTime.MYFACILITY 
                        ? OVER_TIME.myFac
                        : request?.overtimeSetting === OverTime.NO ? OVER_TIME.No : ''
                        ) 
                        }}
            </span>
            <!-- Temporary used style attribute to hide sure shift. -->
            <span *ngIf="request?.sureShift"
                    [class.inactive-rate]="request.status !== 'Pending' && request.status !== 'Approved'"
                    class="promotion-rate"> 
                <i class="fas fa-shield-check icon boost-icon"></i>
                Sure Shift Boost
            </span>
          </div>
        </div>
    </div>

    <div class="action-row">
        <div *ngIf="active && hasAccess(); else currentStatus" fxLayout="row" fxLayoutGap="16px">
            <button *ngIf="request.Approve === '1'" 
                type="button" 
                mat-button 
                class="icare-primary-button"
                (click)="changeStatus('Approved')"
                [attr.automation-id]="'boost-approve-' + index">APPROVE</button>
            <button *ngIf="request.Reject === '1'" 
                type="button" 
                mat-button 
                class="icare-secondary-icon-button"
                (click)="changeStatus('Rejected')"
                [attr.automation-id]="'boost-reject-' + index">REJECT</button>
            <button *ngIf="request.Cancel === '1'" 
                type="button" 
                mat-button 
                class="icare-secondary-icon-button"
                (click)="changeStatus('Expired')"
                [attr.automation-id]="'boost-cancel-' + index">DELETE BOOST REQUEST</button>
        </div>
        <ng-template #currentStatus>
            <p class="status-text" [ngClass]="request.status">
                <i *ngIf="request.status === 'Approved'" class="fa fa-check-circle"></i>
                {{ getStatusText(request.status) }}
            </p>
        </ng-template>
    </div>
</div>