import { Component, OnInit } from '@angular/core';
import { RequestHelperData } from '../../../models/newModels/RequestHelperData';
import { ShiftManagementService } from '../../../services/shift-management.service';
import { Constants } from '../../../helpers/Constants';
import { formatDate } from '@angular/common';
import { filter, flatMap, groupBy, map, tap, toArray } from 'rxjs/operators';
import { of, zip } from 'rxjs';
import { RequestData } from '../../../models/newModels/RequestData';
import * as moment from 'moment';
import { GetShiftsRequest } from 'src/app/models/java-models';

@Component({
  selector: 'requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  allRequests: RequestHelperData[] = [];
  private _allOpenShifts = {};

  constructor(
    private _shiftManagementService: ShiftManagementService
  ) {
  }

  get _searchParams(): GetShiftsRequest {
    return {
      statusArray: [Constants.SHIFT_STATUS_DESCRIPTION.open.id, Constants.SHIFT_STATUS_DESCRIPTION.callOut.id],
      startDate: formatDate(0, 'yyyy-MM-dd', 'en-US'),
    };
  }

  ngOnInit() {
    this._shiftManagementService.getShifts(this._searchParams).pipe(
      map(e => this._shiftsToHashMap(e)),
      tap(e => this._allOpenShifts = e),
      flatMap(f => this._shiftManagementService.getAllShiftRequests(null, null, formatDate(moment().toDate(), 'yyyy-MM-dd', 'en-US')).pipe(
        flatMap((e: RequestData[]) => e),
        filter(e => e.shiftHistoryID !== null),
        groupBy(e => e.shiftID),
        flatMap(e => zip(of(e.key), e.pipe(toArray()))),
        map(e => ({ shift: this._allOpenShifts[e[0]], requests: this.groupRequests(e[1]) })),
        filter(e => !!e.shift),
        toArray(),
      )
      )).subscribe(e => {
        this.allRequests = e.sort((s1, s2) => {
          const a = moment(s1.shift.shiftdate + ' ' + s1.shift.starttime).toDate().getTime();
          const b = moment(s2.shift.shiftdate + ' ' + s2.shift.starttime).toDate().getTime();
          return a > b ? 1 : a < b ? -1 : 0;
        });
      });
  }

  groupRequests(requests: RequestData[]) {
    return {
      accepted: requests.filter(e => e.statusId === 6),
      pending: requests.filter(e => e.statusId !== 6 && e.statusId !== 4),
      rejected: requests.filter(e => e.statusId === 4),
    };
  }

  private _shiftsToHashMap(e) {
    return e.shifts.reduce((p, s) => {
      p[s.shiftid] = s;
      return p;
    }, {});
  }
}
