import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CartData } from '../models/cart-data';
import { FuseSidebarService } from '@theme/components/sidebar/sidebar.service';
import { delay } from 'rxjs/operators';
import { ShiftsService } from 'src/app/services/apis/shifts.service';
import { RequestShiftsResponse } from '../../../../models/shift-management-models/RequestShiftsResponse';
import { Shift } from 'src/app/models/shift-management-models/shift';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private static STEP_TYPE = 'ntelyproCart';
  cartState: BehaviorSubject<CartData[]> = new BehaviorSubject<CartData[]>([]);
  totalCartInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showCart: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectCartItem: Subject<CartData> = new Subject<CartData>();
  requestedShifts: Subject<RequestShiftsResponse[]> = new Subject<RequestShiftsResponse[]>();
  requestSelectedDates: BehaviorSubject<Shift> = new BehaviorSubject<Shift>(null);
  isAlert12HourMsg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _sidebar: FuseSidebarService,
    private _shiftJavaService: ShiftsService) { }

  getStoredCart() {
    this._shiftJavaService.getJson(ShoppingCartService.STEP_TYPE).pipe(
      delay(100)
    ).subscribe(e => {
      this.cartState.next(!!e && Array.isArray(e) ? e as CartData[] : []);
    }, error => console.log(error));
  }

  addToCart(cartElement: CartData) {
    let allVals;
    if (!cartElement.id) {
      cartElement.id = new Date().getTime();
      allVals = [...this.cartState.value, cartElement];
    } else {
      const idx = this.cartState.value.findIndex(e => e.id === cartElement.id);
      this.cartState.value.splice(idx, 1, cartElement);
      allVals = [...this.cartState.value];
    }
    this._updateCartState(allVals);
  }

  openConfirmationCartSidebar() {
    this._sidebar.getSidebar('intelyproConfirmCart').open();
  }

  closeConfirmationCartSidebar() {
    this._sidebar.getSidebar('intelyproConfirmCart').close();
  }

  openShoppingCartSidebar() {
    this.showCart.next({});
    this._sidebar.getSidebar('intelyproCart').open();
  }

  closeShoppingCartSidebar() {
    this.showCart.next(null);
    this._sidebar.getSidebar('intelyproCart').close();
  }

  updateItem(item: CartData) {
    this.selectCartItem.next(item);
  }

  deleteItem(item: CartData) {
    this._updateCartState(this.cartState.value.filter(e => e !== item && e.id !== item.id));
  }

  clearCart() {
    this._updateCartState([]);
  }

  private _updateCartState(items: CartData[]) {
    this._shiftJavaService.setJson({ steptype: ShoppingCartService.STEP_TYPE, stepjson: items }).subscribe();
    this.cartState.next(items);
  }
}
