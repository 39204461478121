/**
 *  BoostApprovalService
 *    Main service to be used with anything pertaining to boosting or promoting shifts
 *
 *   Subject: boostRequests - This is all the requests currently in the approval queue
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseSidebarService } from '@theme/components/sidebar/sidebar.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, tap, catchError, shareReplay } from 'rxjs/operators';
import { BoostRequestModel, BoostRequestResponse, RequestList } from '../../models/BoostModels/BoostModels';
import * as moment from 'moment';
import { BaseResponseModel } from 'src/app/models/BaseResponseModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ClientAttributesResponse } from 'src/app/models/newModels/ClientAttributesResponse';

@Injectable({
  providedIn: 'root'
})
export class BoostApprovalService {

  private SIDEBAR_NAME = 'boostApprovalQueue';
  private _baseUri = UtilsService.getISystemBaseUri();

  boostRequests = new Subject<{
    past: { date: Date, requests: BoostRequestModel[] }[],
    active: { date: Date, requests: BoostRequestModel[] }[]
  }>();

  private ClientAttributes$: Observable<ClientAttributesResponse>;

  constructor(
    private _sidebar: FuseSidebarService,
    private _snackbar: MatSnackBar,
    private _http: HttpClient) { }

  toggleQueueSidebar() {
    this._sidebar.getSidebar(this.SIDEBAR_NAME).toggleOpen();
  }

  // Need to call this in order to see if the facility has boost active
  hasPromoteAccess(request: { userid: number, clientid: number }): Observable<ClientAttributesResponse> {
    if (!this.ClientAttributes$) {
      const params = new FormData();
      params.append('Section', 'get');
      params.append('cid', String(request.clientid));
      params.append('uid', String(request.userid));
      this.ClientAttributes$ = this._http.post<any>(`${this._baseUri}/manage/clientattributes.php`, params).pipe(
        catchError(error => of(false)),
        map(response => {
          localStorage.setItem("facilityState",response.state);
          if (response.code === 200) {
            return {
              boostButton: response['object'].boostButton === '1',
              blockBooking: response['object'].blockBooking === '1',
              approval: response['object'].boostApproval === '1',
              sureShift: !!Number(response['object'].sureShift),
              costPlus: response['object']['Cost+'] === '1'
            };
          } else {
            return {
              boostButton: false,
              blockBooking: false,
              approval: false,
              sureShift: false,
              costPlus: false
            };
          }
        }),
        shareReplay(1)
      );
    }
    return this.ClientAttributes$;
  }

  emptyClientAttributes() {
    this.ClientAttributes$ = null;
  }

  // Retrieves all boost requests
  getBoostRequests(request: { userid: number, clientid: number }) {
    const params = new FormData();
    params.append('section', 'boostRequests');
    params.append('cid', String(request.clientid));
    params.append('uid', String(request.userid));
    return this._http.post<BoostRequestResponse>(`${this._baseUri}/pages/partcare.php`, params).pipe(
      map(lists => {
        const activeList = this.getQueueList(lists.active, lists.activeBlocks);
        const pastList = this.getQueueList(lists.past, lists.pastBlocks);
        return {
          past: pastList,
          active: activeList
        };
      }),
      tap(requests => this.boostRequests.next(requests))
    );
  }

  reviewRequest(reqdid: string, action: string, uid: number, blockRequest: boolean = false) {
    const params = new FormData();
    params.append('section', 'reviewBoostRequest');
    params.append('uid', String(uid));
    params.append('action', action);
    params.append(!blockRequest ? 'reqdid' : 'blockId', reqdid);

    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/pages/partcare.php`, params).pipe(
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  private getQueueList(list: RequestList, blockList: RequestList) {
    let requestList = [];
    if (list && Object.keys(list).length) {
      requestList = Object.keys(list).map(date => {
        let requests = list[date] as BoostRequestModel[];
        if (blockList && blockList[date]) {
          requests = requests.concat(blockList[date]);
          delete blockList[date];
        }

        return {
          date: moment(date).toDate(),
          requests
        }
      });
    }

    if (blockList && Object.keys(blockList).length) {
      const blockRequests = Object.keys(blockList).map(date => {
        return {
          date: moment(date).toDate(),
          requests: blockList[date] as BoostRequestModel[]
        }
      });
      requestList = requestList.concat(blockRequests);
      requestList.sort((previous, next) => {
        if (previous.date < next.date) return -1;
        if (previous.date > next.date) return 1;
        return 0;
      });
    }

    return requestList;
  }
}
