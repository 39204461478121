import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material.module';
import { MutipleFacilityComponent } from './mutiple-facility.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    MutipleFacilityComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class MultipleFacilityModule { }
