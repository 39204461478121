<div *ngIf="!loading" class="create-shift">
    <form [formGroup]="createShiftForm" class="create-form" novalidate>
        <div class="header">
            <app-custom-drawer-header title="Assign Shift(s)" (closeEvent)="closeDrawer()">
            </app-custom-drawer-header>
        </div>
        <div class="staff-details" fxLayout="column" fxLayoutAlign="space-between start">
            <span class="staff-name">{{selectedRow?.firstName}} {{selectedRow?.lastName}}</span>
            <span class="staff-type">{{selectedRow?.staff_type}}</span>
        </div>
        <div class="create-shift-calendar-spreadsheet">
            <mat-calendar #calendar [startAt]="navigatedMonths[navigatedMonths.length - 1]"
                [dateFilter]="overlappingDays" [dateClass]="dateClass" (selectedChange)="onSelect($event)">
            </mat-calendar>
        </div>

        <div class="standard-container">
            <span *ngIf="selectedShift" class="label">Shift Time *</span>
            <div class="form-field standard short-field time-container" fxLayout="row">
                <mat-form-field appearance="outline" automation-id="shift-time">
                    <mat-label *ngIf="!selectedShift">Shift Time {{shiftTime?.status ==='VALID'? '*' : ''}}</mat-label>
                    <mat-select (selectionChange)="calculateHours($event.value.startTime, $event.value.endTime, false)"
                        [placeholder]="selectedShift ? selectedShift : 'Shift Time'" disableOptionCentering="true"
                        formControlName="shiftTime" automation-id="selected-shift-time" panelClass="pannelCustomClass">
                        <mat-select-trigger>{{ shiftTime?.value?.shiftName }}</mat-select-trigger>
                        <mat-option *ngFor="let shiftType of shiftTDefs" [value]="shiftType">
                            <app-custom-shift-time-display timeFontSize="16px" periodFontSize="12px"
                                [startTime]="shiftType?.startTime" [endTime]="shiftType?.endTime">
                            </app-custom-shift-time-display>
                            <span [innerHTML]="calculateHours(shiftType?.startTime,shiftType?.endTime, true)"></span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="shiftTime?.status ==='VALID'" fxLayout="row" fxLayoutGap="12" class="shift-hours-time">
                    <span class="number"> {{hours}}</span><span class="letter">H &nbsp; </span>
                    <span class="number">{{minutes}}</span><span class="letter">MIN</span>
                </div>
            </div>

            <div class="form-field standard location-container" fxLayout="row">
                <mat-form-field appearance="outline" automation-id="location">
                    <mat-label>Location {{location?.status==='VALID' ? '*' : ''}}
                    </mat-label>
                    <mat-select placeholder="Location" (selectionChange)="locationSelected()" formControlName="location"
                        automation-id="selected-location" panelClass="pannelCustomClass">
                        <mat-option *ngFor="let unit of unitInfo" [value]="unit?.id">{{unit?.floorName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div fxLayoutAlign="center center" fxLayoutGap="12px" class="drawer-footer footer">
        <button [disabled]="(!createShiftForm.valid || !datesSelected.length || allOverlap) || condition || loading"
            [ngClass]="{ 'disabled': (!createShiftForm.valid || !datesSelected.length || allOverlap) || condition || loading}"
            automation-id="create-shift" (click)="navigateToConfirm()" fxLayoutAlign="center center"
            class="shift-management-buttons shift-primary-btn" mat-button>
            <mat-icon class="assign-icon">assignment_ind</mat-icon> Next
        </button>
    </div>
</div>
<div *ngIf="loading" class="show-loader" automation-id="loading-icon">
    <img src="assets/images/loader.svg" height="40" width="40" />
</div>