import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfigService } from '@theme/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageUsersAccountService } from '../services/manage-users-account.service';
import { JWTPayloadData } from '../models/newModels/JWTPayloadData';
import { catchError, filter, first, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../services/googleanalytics.service';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { ShiftManagementService } from '../services/shift-management.service';
import { AuthorizationService } from '../services/authorization.service';
import { OnBoardingService } from '../on-boarding/services/on-boarding.service';
import { ShiftsService } from '../services/apis/shifts.service';
import { EMPTY, forkJoin, of } from 'rxjs';
import { JavaBaseResponse } from '../models/shift-management-models/java-base-response';
import { Settings } from '../models/shift-management-models/Settings';
import { MatDialog } from '@angular/material/dialog';
import { AccountPermissionDeniedComponent } from './account-permission-denied/account-permission-denied.component';
import { MutipleFacilityComponent } from './mutiple-facility/mutiple-facility.component';
import { UserTypeData } from '../models/newModels/UserTypeData';
import { UserTypes } from '../config/user-types';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss']
})
export class MobileLoginComponent implements OnInit {

  @Input() userTypeId = UserTypes.Staff;
  snackErrorMessage: any;
  user: JWTPayloadData;
  hide = true;
  submitted = false;
  processing = false;
  isIntelypro = false;
  defaultEmail = '';
  returnUrl: string;
  loginForm: FormGroup;
  external = false;
  config: any;

  constructor(
    public googleAnalyticsService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _javaShiftsService: ShiftsService,
    private _shiftsService: ShiftManagementService,
    private _fuseConfigService: FuseConfigService,
    private _onBoardingService: OnBoardingService,
    private _authService: AuthorizationService,
    private _accountService: ManageUsersAccountService,
    private _cookieService: CookieService,
    public dialog: MatDialog
  ) {
    this.config = this._fuseConfigService.defaultConfig;
    this.config.layout.toolbar.hidden = true;
    this._fuseConfigService.setConfig(this.config);
    this.external = Boolean(this.route.snapshot.queryParams['external']) ?? false;

    if (this.route.snapshot.queryParams['clientid'] && this.route.snapshot.queryParams['token']) {
      this._accountService.clearLocalStorage();
      this._cookieService.delete('IntelycareAuthCookie');
      const clientid = Number(this.route.snapshot.queryParams['clientid']);
      const token = decodeURIComponent(this.route.snapshot.queryParams['token']);
      let userTypeId = UserTypes.HubUser;
      if (this.route.snapshot.queryParams['external']) {
        userTypeId = UserTypes.ExternalFacilityUser;
      }
      this._accountService.loginAsFacility(clientid, token, userTypeId, this.external).pipe(
        switchMap(userData => {
          if (String(userData.status) === 'OK' && userData.success == true) {
            return forkJoin({
              settings: this._javaShiftsService.getClientSettings().pipe(
                catchError(err => of<JavaBaseResponse<Settings[]>>({ data: [] })),
                tap(settings => this._accountService.setClientSettings(settings.data))
              ),
              payload: this._getPayload().pipe(catchError(err => of<JavaBaseResponse<JWTPayloadData>>({ data: null })))
            });
          } else {
            return EMPTY;
          }
        }),
        switchMap(() => this._onBoardingService.updateStateObservable('done'))
      ).subscribe(() => {
        this.router.navigateByUrl(
          this._accountService.navigateToLandingPage(this._accountService.currentUserValue.activeusertype.usertypeid)
        );
      });
    } else if (this._accountService.currentUserValue) {
      this.router.navigateByUrl(
        this._accountService.navigateToLandingPage(this._accountService.currentUserValue.activeusertype.usertypeid)
      );
    }
  }

  get validateForm() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [this.defaultEmail, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]],
      password: ['', [Validators.required]],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getRegisterErrorMessage();
  }

  getRegisterErrorMessage() {
    if (this._accountService.getRegistrationErrorMessage) {
      this.snackErrorMessage = this._snackBar.open(this._accountService.getRegistrationErrorMessage, '', {
        duration: 10000,
        panelClass: ['mobile-blue-snackbar']
      });
      this._accountService.registrationErrorMessage.next('');
    }
  }

  removeRegisterErrorMessage() {
    if (this.snackErrorMessage) {
      this.snackErrorMessage.dismiss();
    }
  }

  /**
   * @method to check login credentials and login
   */
  login(): void {
    this.removeRegisterErrorMessage();
    this.submitted = true;
    if (!this.loginForm.invalid && !this.processing) {
      this.processing = true;
      this._accountService.login(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value,
        this.userTypeId
      ).pipe(
        tap(resp => this._updateCookie(resp.data)),
        switchMap(resp => forkJoin({
          userTypes: this._accountService.getUserTypes(resp.data)
            .pipe(catchError(() => of([]))),
          payload: of(resp)
        })),
        tap(resp => {
          switch (Number(resp.payload.code)) {
            case 200:
            case 201:
              const payload: JWTPayloadData = resp.payload.data.jwtpayload;
              const userName: string = payload.firstname;
              const facilityArray = resp.userTypes.filter(
                (facility, i, usertypes) =>
                  usertypes.findIndex(fac => fac.clientid === facility.clientid) === i
                  && facility.clientname !== '' && facility.usertypeid === UserTypes.Scheduler
              );

              if (payload) {
                if (facilityArray.length >= 2) {
                  const dialogRef = this.dialog.open(MutipleFacilityComponent, {
                    disableClose: true,
                    width: '861px',
                    height: '485px',
                    panelClass: 'day-info-dialog',
                    data: { facilities: facilityArray, userName, canClose: false },
                    autoFocus: false
                  });
                  const sub = dialogRef.componentInstance.selectFacility.pipe(
                    filter((facility: UserTypeData) => !!facility),
                    switchMap((facility: UserTypeData) => {
                      const clientid = Number(facility[0].clientid);
                      const usertypeid = Number(facility[0].usertypeid);
                      const token = resp.payload.data.cookievalue;
                      return this._accountService.loginAsFacility(clientid, token, usertypeid, this.external).pipe(
                        tap((client) => {
                          if (client) {
                            this.navigateTo(client.data.jwtData, client);
                            dialogRef.close();
                          }
                        })
                      );
                    })
                  ).subscribe();
                  dialogRef.afterClosed().pipe(
                    first(),
                    tap(() => sub.unsubscribe())).subscribe();
                } else {
                  this.navigateTo(payload, resp.payload);
                }
              }
              break;
            case 307:
              this.dialog.open(AccountPermissionDeniedComponent, {
                disableClose: true,
                data: resp.payload.data?.name || 'friend'
              });
              break;
            default:
              this._showMessage(resp.payload.message);
              this.processing = false;
              break;
          }
        },
          catchError(error => {
            this._showMessage(error?.message);
            this.processing = false;
            return error;
          }))).subscribe();
    }
  }

  /**
   * @method to navigate after login based on scenarios
   * @param payload: payload data from API
   * @param resp: resp from login API
   */
  navigateTo(payload, resp): void {
    switch (payload.activeusertype.usertypeid) {
      case 1:
        if (this.userTypeId === UserTypes.Staff) {
          this._setStartDOW().subscribe(() => {
            this._accountService.setCurrentUser(payload);
            this._accountService.userLoggedInSubject.next(true);
            this._writeOnAnalytics();
            this.router.navigateByUrl(this._accountService.navigateToLandingPage(this.userTypeId));
          });
        } else {
          this._showMessage('This user does not have access on this platform');
        }
        break;
      case 2:
        forkJoin({
          setting: this._javaShiftsService.getClientSettings().pipe(
            catchError(err => of<JavaBaseResponse<Settings[]>>({ data: [] }))
          ),
          payload: this._getPayload().pipe(catchError(err => of<JavaBaseResponse<JWTPayloadData>>({ data: null })))
        }).subscribe(
          res => {
            this._accountService.setClientSettings(res.setting.data);
            this.router.navigateByUrl(this._accountService.navigateToLandingPage(this.userTypeId));
            this.processing = false;
          },
          error => {
            this.processing = false;
          }
        );
        break;
      case 9:
        if (payload.activeusertype.clientid !== 0) {
          forkJoin({
            setting: this._javaShiftsService.getClientSettings().pipe(
              catchError(err => of<JavaBaseResponse<Settings[]>>({ data: [] }))
            ),
            payload: this._getPayload().pipe(catchError(err => of<JavaBaseResponse<JWTPayloadData>>({ data: null })))
          }).subscribe(
            res => {
              this._accountService.setClientSettings(res.setting.data);
              this.router.navigateByUrl(this._accountService.navigateToLandingPage(this.userTypeId));
              this.processing = false;
            },
            error => {
              this.processing = false;
            }
          );
        } else {
          this._authService.linkSchedulingToHub().pipe().subscribe(() => {
            forkJoin({
              setting: this._javaShiftsService.getClientSettings().pipe(
                catchError(err => of<JavaBaseResponse<Settings[]>>({ data: [] }))
              ),
              payload: this._getPayload().pipe(catchError(err => of<JavaBaseResponse<JWTPayloadData>>({ data: null })))
            }).subscribe(
              res => {
                this.processing = false;
                this._accountService.setClientSettings(res.setting.data);

                // To skip the onboarding process of Staffing and Hybrid facilities, this needs to be switched temporarily to done
                this._onBoardingService.updateStateObservable('done').subscribe(() => {
                  this.router.navigateByUrl(this._accountService.navigateToLandingPage(this.userTypeId));
                });
              },
              error => {
                this._onBoardingService.updateStateObservable('onboard/welcome').subscribe(() => {
                  this.router.navigateByUrl(this._accountService.navigateToLandingPage(this.userTypeId));
                });
                this.processing = false;
                this._showMessage(resp.message);
              }
            );
          }, error => {
            this._onBoardingService.updateStateObservable('onboard/welcome').subscribe(() => {
              this.router.navigateByUrl(this._accountService.navigateToLandingPage(this.userTypeId));
            });
            this.processing = false;
            this._showMessage(resp.message);
          });
        }
        break;
      default:
        this._showMessage('This user does not have access on this platform');
        break;
    }
  }

  checkButtonColor() {
    if (this.isValidUser() && !this.processing) {
      return '#1885B7';
    }

    return '#A2A4A7';
  }

  isValidUser() {
    if (this.loginForm.invalid) {
      return false;
    }

    return true;
  }

  private _updateCookie(resp: any) {
    if (resp?.cookievalue) {
      const jd = moment(jwt_decode(resp.cookievalue).jwtcreated).add(1, 'days').toDate();
      const jd1 = moment(jwt_decode(resp.refreshcookievalue).jwtcreated).add(2, 'days').toDate();

      if (this.external) {
        this._accountService.setCookieWithNullSameSite(resp.cookiename, resp.cookievalue, jd);
        this._accountService.setCookieWithNullSameSite(resp.refreshcookiename, resp.refreshcookievalue, jd1);
      } else {
        this._cookieService.set(resp.cookiename, resp.cookievalue, jd, '/');
        this._cookieService.set(resp.refreshcookiename, resp.refreshcookievalue, jd1, '/');
      }
    }
  }

  private _getPayload(handleFlag = true) {
    return this._accountService.getPayLoad(handleFlag).pipe(
      tap(resp => {
        this.user = this._accountService.currentUserValue;
        this._accountService.userLoggedInSubject.next(true);
        this._writeOnAnalytics();
        this._accountService.initializePendo(resp.data);
      }),
      flatMap(e => this._setStartDOW())
    );
  }

  private _setStartDOW() {
    return this._shiftsService.getWeekStartAndDOW().pipe(
      map(e => !!e?.data.dayOfWeekValue ? e.data.dayOfWeekValue : 1),
      tap(e => this._accountService.setDOW = e)
    );
  }

  private _writeOnAnalytics() {
    const category = GoogleAnalyticsService._GetEvents().user_management.category;
    let eventName = '';
    let eventLabel = '';

    switch (this.userTypeId) {
      case UserTypes.Staff:
        eventName = GoogleAnalyticsService._GetEvents().user_management.action.staff_login.name;
        eventLabel = GoogleAnalyticsService._GetEvents().user_management.action.staff_login.label;
        break;
      case UserTypes.Scheduler:
        eventName = GoogleAnalyticsService._GetEvents().user_management.action.scheduler_login.name;
        eventLabel = GoogleAnalyticsService._GetEvents().user_management.action.scheduler_login.label;
        break;
    }

    this.googleAnalyticsService.eventEmitter(category, eventName, eventLabel);
  }

  private _showMessage(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['mobile-blue-snackbar']
    });
  }
}
