import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input,
  OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { of, Subject, zip } from 'rxjs';
import { filter, mergeMap, map, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { ShiftManagementFilterComponent } from '../shift-management-filter/shift-management-filter.component';
import { StaffCardExpandableComponent } from '../../../shared/componets/staff-card-expandable/staff-card-expandable.component';
import { ShiftManagementService } from '../../../services/shift-management.service';
import { ConvertersService } from '../../../services/utils/converters.service';
import { FilterStaffData } from '../../../models/newModels/FilterStaffData';
import { AditionalDataService } from '../../../services/aditional-data.service';
import { ViewLiveUpdateService } from '../../../services/view-live-update.service';
import { AllStaff } from '../../../models/AllStaff';
import { StaffService } from '../../../services/staff.service';
import { StaffTypeData } from '../../../models/newModels/StaffTypeData';
import { RequestData } from '../../../models/newModels/RequestData';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { ManageUsersAccountService } from '../../../services/manage-users-account.service';
import { ManagementTopCardComponent } from '../management-top-card/management-top-card.component';
import { Constants } from '../../../helpers/Constants';
import { GoogleAnalyticsService } from '../../../services/googleanalytics.service';
import { DailyService } from '../../../scheduling/service/daily.service';
import { Shift } from '../../../models/shift-management-models/shift';
import { ShiftsService } from '../../../services/apis/shifts.service';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';
import { StaffJavaService } from 'src/app/services/apis/staff-java.service';
import { ShiftRequest } from 'src/app/models/java-models';
import { ModifyBoostService } from '../../../monthly-view/services/modify-boost.service';
import { monthlyViewType } from '../../../config/monthly-view-type';
import { FacilityShiftType } from 'src/app/services/shift-types/models/facility-shift-type.model';

/**
 * FillShiftComponent has @Input() shiftDetail which has the information for the shift.
 * @Input() returnTo opens sidebar content based on input value.
 * @Input() currentTab is the current tab opened in the right side bar.
 */

@Component({
  selector: 'app-fill-shift-component',
  templateUrl: './fill-shift.component.html',
  styleUrls: ['./fill-shift.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FillShiftComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() shiftDetails: Shift;
  @Input() replacing: StaffMember;
  @ViewChild(ShiftManagementFilterComponent, { static: true }) shiftManagementFilter: ShiftManagementFilterComponent;
  @ViewChild(StaffCardExpandableComponent, { static: true }) staffCardExpandable: StaffCardExpandableComponent;
  @ViewChild(ManagementTopCardComponent, { static: true }) topCardComponent: ManagementTopCardComponent;
  @Input() returnTo: 'requests' | 'allShifts';
  @Input() currentTab: 'search' | 'responses' | 'addStaff' = 'search';
  responsesTab: 'accepted' | 'pending' | 'rejected' = 'accepted';

  staff: StaffMember[] = [];
  selectedStaff: StaffMember[] = [];
  unselectedStaff: StaffMember[] = [];
  staffType: any = null
  total = 0;
  shiftRequests: { accepted: RequestData[], pending: RequestData[], rejected: RequestData[] } = { accepted: [], pending: [], rejected: [] };
  selectedRequest: RequestData;
  isLoading = false;
  hasRequestedIntelypro = true;
  requestIntelyProSelected = false;
  intelyproStaffType: number = null;
  canRequestIntelypro = false;
  selectGender = false;
  selectedGender: string = null;
  shiftBoosts: any;
  filter = new Subject<any>();
  filterOptions: any = {};
  sortCriteria = 'hours';
  search = new Subject<any>();
  searchCriteria = '';
  searching = false;
  noRestriction = false;
  showFilters = true;
  shifts: any = {};
  employments: any;
  manualAdd = false;
  manualStaffToAdd = { firstName: '', lastName: '', agencyName: '', phoneNumber: '' };
  viewType = monthlyViewType;
  requestClicked = false;
  shiftStatuses = Constants.SHIFT_STATUS_DESCRIPTION;

  private _unsubscribeAll: Subject<any> = new Subject();
  private assignedStaff: number[] = [];

  constructor(
    private _staffService: StaffJavaService,
    private _staff: StaffService,
    private _userService: ManageUsersAccountService,
    private _shiftJavaService: ShiftsService,
    private _shiftService: ShiftManagementService,
    private _additional: AditionalDataService,
    private _dailyService: DailyService,
    private _liveUpdate: ViewLiveUpdateService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _cdr: ChangeDetectorRef,
    private _modifyBoostService: ModifyBoostService,
  ) {
  }

  get areAllChecked() {
    return this.selectedStaff.map(e => e.staffId).sort((s1, s2) => s2 - s1).join()
      ===
      this.unselectedStaff.map(e => e.staffId).sort((s1, s2) => s2 - s1).join();
  }

  get allowIntelypro(): boolean {
    return this._liveUpdate.isIPAllowed(this.shiftDetails?.shiftTypeId);
  }

  private static checkQ(s: StaffMember, q: string) {
    if (!q) {
      return false;
    }
    const fullname = s.firstName + '' + s.lastName;
    return fullname.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1;
  }

  ngOnInit() {
    if (this.shiftDetails.statusID === Constants.SHIFT_STATUS_DESCRIPTION.callOut.id) {
      this.replacing = this._liveUpdate.toStaffInfo(this.shiftDetails.staffID);
    }

    this._userService.getRoleRestriction().pipe(
      map(e => e.data)
    ).subscribe(resp => {
      if (resp?.filter(a => a.settingtypeid === 3)[0]?.settingvalue === '1') {
        this.noRestriction = true;
      }
      this._subscribeToFilterChanges();
      this._subscribeToSearchChanges();

      const req: any = {
        canRequestForShiftId: this.shiftDetails.shiftID,
        agencyStaff: false,
        myStaff: true,
        onlyActive: true,
        noHidden: true,
        ...this._getStartAndEndDateOfWeek()
      };

      if (!!this.noRestriction) {
        req.prioritizedStaffType = this.shiftDetails.staffTypeID;
      } else {
        req.shiftTypeId = !!this.shiftDetails.shiftTypeId ? this.shiftDetails.shiftTypeId : 0;
      }

      this._staffService.getAllStaffFiltered(req).subscribe(e => {
        this.staff = e.data;
        this._cdr.markForCheck();
      });
    });


    zip(
      this._getEmploymentTypes(),
      this._getShiftTypes(),
      this._getAssignedStaff()
    ).subscribe(e => {
      this.filter.next({
        // shift: this._getCurrentShift(),
        staffType: [{ type: this.shiftDetails.staffTypeID, subtype: this.shiftDetails.subStaffTypeID }],
      });
      this._cdr.markForCheck();
    });

    this.getAllShiftRequests();

    this._shiftService.hasIntelyproRequest(this.shiftDetails.shiftID).subscribe(e => {
      this.hasRequestedIntelypro = e;
    });

    this._staff.isClientOnHoldISystem().subscribe(e => {
      this.canRequestIntelypro = !!e && e.billingStatus !== 'Hold' && e.clientStatus === 'Yes';
    }, error => this.canRequestIntelypro = false);


    // this._userService.getClientSettings().pipe(
    //   map(e => !!e.data ? e.data.genderpreference : null)
    // ).subscribe(preference => {
    //   this.selectGender = !!preference ? preference.preferenceid === Constants.GENDER_PREFERENCE.select : false;
    //   this.selectedGender = this.selectGender ? 'E' : preference.preferenceid === Constants.GENDER_PREFERENCE.femaleOnly ? 'F' : 'E';
    // });

    this._staff.getGenderPreferences().pipe(
      map(e => !!e.data ? e.data : null)
    ).subscribe(preference => {

      this.selectGender = !!preference ? preference.genderpreference.preferenceid === Constants.GENDER_PREFERENCE.select : false;
      this.selectedGender = !this.selectGender ? preference.genderpreference.preferenceid === Constants.GENDER_PREFERENCE.femaleOnly ? 'F' : 'E' : 'E';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shiftDetails) {
      this.getAllShiftRequests();
    }
  }

  getAllShiftRequests() {
    const requests: RequestData[] = this.shiftDetails?.shiftRequests?.map(request => request as RequestData) ?? [];
    this.shiftRequests = this.groupRequests(requests);
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  selectStaff(staff: StaffMember[]) {
    this.selectedStaff = staff;
  }

  groupRequests(requests: RequestData[]) {
    return {
      accepted: requests.filter(e => e.statusId === 6),
      pending: requests.filter(e => e.statusId !== 6 && e.statusId !== 4),
      rejected: requests.filter(e => e.statusId === 4),
    };
  }

  getStaffMember(staffid: number) {
    return this._liveUpdate.toStaffInfo(staffid);
  }

  setSelectedItem(selectedRequest: RequestData) {
    this.selectedRequest = selectedRequest;
  }

  removeFilterOpt(key: string, u) {
    this.filterOptions[key].splice(this.filterOptions[key].indexOf(u), 1);
    this.filter.next(this.filterOptions);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleAll(newValue) {
    this.requestIntelyProSelected = newValue && !this.hasRequestedIntelypro && this.canRequestIntelypro && this.allowIntelypro;
    this.selectedStaff = newValue ? [...this.unselectedStaff] : [];
    this.staffCardExpandable.hardsetSelected(this.selectedStaff);
    this._cdr.markForCheck();
  }

  sortByField(field: string) {
    this.sortCriteria = field === this.sortCriteria ? '' : field;
    const a = this.unselectedStaff.sort((first, next) => this._sortCondition(first, next));
    this.unselectedStaff = [...a];
    this._cdr.markForCheck();
  }

  hideSuggestions() {
    this.searching = true;
    this.showFilters = false;
    if (!this.searchCriteria) {
      this.unselectedStaff = [];
    }
  }

  showSuggestions() {
    this.searching = false;
    if (!this.searchCriteria) {
      this.showFilters = true;
      this.filter.next(this.filterOptions);
    }
  }

  sendRequestForShift() {
    this.requestClicked = true;
    if (!!this.selectedStaff.length) {
      const singleReq: ShiftRequest = {
        shiftID: this.shiftDetails.shiftID,
        intelyPro: 0,
        message: 1
      };
      const req: ShiftRequest[] = this.selectedStaff.map(selectedStaff => ({ ...singleReq, staffID: selectedStaff.staffId, gender: this.selectedGender }));
      this._shiftService.sendRequest(req).subscribe(e => {
        this._dailyService.requestJustSent.next(req);
        this._getAssignedStaff().subscribe();
        this.currentTab = 'responses';
        this.responsesTab = 'pending';
        this.requestClicked = !this.requestIntelyProSelected;
        this.unselectedStaff = this.unselectedStaff.filter(a => this.selectedStaff.findIndex(b => a.staffId === b.staffId) > -1);
        this.selectedStaff = [];
        this.staffCardExpandable.hardsetSelected(this.selectedStaff);
        this._liveUpdate.forceReload.next();
        this._cdr.markForCheck();
        this._sendAnalyticsAddToRequest(req.length);
      },
        error1 => {
          this.requestClicked = !this.requestIntelyProSelected;
        });
    }

    if (this.requestIntelyProSelected) {
      this.requestIntelypro();
    }
  }

  assignStaffToShift() {
    this.isLoading = true;
    const assignToShift = {
      'staffId': this.selectedStaff[0].staffId,
      'shiftId': this.shiftDetails?.shiftID
    };
    this._shiftService.assignToShift(assignToShift).subscribe(
      () => {
        this.topCardComponent.changeShiftAfterAction();
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      });
  }

  // Adds a new staf and assigns the shift
  onAddNewStaffClose(res: AllStaff) {
    if (!res) {
      this.manualAdd = false;
      return;
    }

    this._dailyService.getState().pipe(
      tap(dayViewData => {
        this.staffType = dayViewData.shiftTypes.find(shiftType => shiftType.shiftTypeId === this.shiftDetails.shiftTypeId)?.staffTypes[0];
      }),
      takeUntil(this._unsubscribeAll)
    ).subscribe();

    const ids: StaffTypeData = {
      stafftypeid: this.staffType?.staffTypeId,
      staffsubtypeid: 0,
      stafftypename: this.staffType?.abbreviation
    };

    this._staff.saveStaffMember(res, ids, false).pipe(
      map(staff => staff.data),
      mergeMap(staff => this._shiftService.assignToShift({
        'staffId': staff.staffid,
        'shiftId': this.shiftDetails.shiftID
      })),
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      this.topCardComponent.changeShiftAfterAction();
    });
  }

  requestIntelypro() {
    const request: ShiftRequest = {
      message: null,
      staffID: null,
      shiftID: this.shiftDetails.shiftID,
      intelyPro: 1,
      staffTypeId: this.intelyproStaffType,
      staffSubTypeId: 0,
      gender: this.selectedGender
    };
    const user = this._userService.currentUserValue;
    this._shiftService.sendRequest([request])
      .pipe(take(1))
      .subscribe(request => {
        this.hasRequestedIntelypro = true;
        this._liveUpdate.forceReload.next();
        this.currentTab = 'responses';
        this.responsesTab = 'pending';
        this.canRequestIntelypro = false;
        this.requestIntelyProSelected = false;
        this.requestClicked = false;
        if (request[0]['reqId']) {
          this._modifyBoostService.boostAllRequests(
            user.activeusertype.clientid,
            user.userid,
            request[0]['reqId'],
            this.shiftBoosts?.boostAmount + '',
            this.shiftBoosts?.allowOvertime?.value + '',
            this.shiftBoosts?.sureShift ? true : false).subscribe(res => console.log(res));
        }
      },
        error1 => {
          this.requestClicked = false;
        });
  }

  confirmRequest() {
    this.isLoading = true;
    this._shiftService.confirmShiftRequest(this.selectedRequest.shiftHistoryID).subscribe(e => {
      this.topCardComponent.changeShiftAfterAction();
      this.isLoading = false;
    },
      () => {
        this.isLoading = false;
      });
  }

  _getAssignedStaff() {
    return this._shiftJavaService.getShiftDefs().pipe(
      mergeMap(shift => shift),
      filter(shift => (this.shiftDetails?.isPartial && (shift.shiftDefId === this.shiftDetails?.shiftDefId)) ||
        (this.checkTimes(shift.startTime, this.shiftDetails.startTime) && this.checkTimes(shift.endTime, this.shiftDetails.endTime))),
      mergeMap(shift => this._shiftService.getAssignedStaffToShift(this.shiftDetails.shiftDate, shift.shiftDefId)
        .pipe(
          map(shift => shift.data),
          tap(assignedStaff => this.assignedStaff = assignedStaff)
        )));
  }

  checkTimes(firstTime, secondTime) {
    return moment(firstTime, 'HH:mm:ss').toDate().getTime() === moment(secondTime, 'HH:mm:ss').toDate().getTime();
  }

  showRequestIPOption() {
    return this._userService.facilityType !== this.viewType.internal && this.allowIntelypro && !this.hasRequestedIntelypro &&
      this.canRequestIntelypro && !(this.shiftDetails.isPartial || this.shiftDetails.partial);
  }

  private _getCurrentShift() {
    return Object.keys(this.shifts)
      .filter(shift => this.shifts[shift] === ConvertersService._datesToShiftHours({
        startTime: this.shiftDetails.startTime,
        endTime: this.shiftDetails.endTime
      }))
      .map(shift => Number(shift));
  }

  private _getEmploymentTypes() {
    return this._additional.getEmploymentType().pipe(
      map(emplyType => emplyType.data),
      map(emplyType => emplyType.reduce((p, q) => {
        p[q.employmenttypeid] = q.employmenttype;
        return p;
      }, {})),
      tap(employments => this.employments = employments));
  }

  private _getShiftTypes() {
    return this._shiftJavaService.getShiftDefs().pipe(
      mergeMap(shift => shift), toArray(),
      map(shift => shift.reduce((p, q) => {
        p[q.shiftDefId] = ConvertersService._datesToShiftHours(q);
        return p;
      }, {})),
      tap(shift => this.shifts = shift));
  }

  private _sortCondition(first: StaffMember, next: StaffMember) {
    let condition: number;
    switch (this.sortCriteria) {
      case 'hours':
        condition = first.hoursWorkedPerWeek - next.hoursWorkedPerWeek;
        break;
      case 'firstName':
        const f = first.firstName;
        const n = next.firstName;
        condition = f > n ? 1 : f < n ? -1 : 0;
        break;
      case 'lastName':
        const fl = first.lastName;
        const nl = next.lastName;
        condition = fl > nl ? 1 : fl < nl ? -1 : 0;
        break;
      case 'staffType':
        const fabb = first.abbreviation;
        const nabb = next.abbreviation;
        condition = fabb > nabb ? 1 : fabb < nabb ? -1 : 0;
        break;
      default:
        condition = 0;
    }
    return condition;
  }

  private _subscribeToFilterChanges() {
    this.filter.pipe(
      tap(filterItem => this._setFilters(filterItem)),
      mergeMap(() => this._staffService.getAllStaffFiltered(this._getStaffFilterData()).pipe(
        mergeMap(staffFilter => !!staffFilter.data ? staffFilter.data : []),
        filter((staff: StaffMember) => !!staff && this.assignedStaff.indexOf(staff.staffId) < 0),
        toArray()
      )),
      takeUntil(this._unsubscribeAll)
    ).subscribe((unselectedStaff: StaffMember[]) => {
      this.unselectedStaff = unselectedStaff ? this.sortCriteria ? unselectedStaff.sort((first, next) =>
        this._sortCondition(first, next)) : unselectedStaff : [];
      this.total = this.unselectedStaff.length;
      this.selectedStaff = [];
      this.staffCardExpandable.hardsetSelected(this.selectedStaff);
      this.shiftManagementFilter.forceSetFilters(this.filterOptions, false);
      this._cdr.markForCheck();
    }, error => console.log(error));
  }

  private _subscribeToSearchChanges() {
    this.search.pipe(
      tap(input => this.searchCriteria = input),
      mergeMap(() => of(this.staff).pipe(
        mergeMap(search => search),
        filter(search => {
          return !!search && FillShiftComponent.checkQ(search, this.searchCriteria)
            && this.assignedStaff.indexOf(search.staffId) < 0;
        }),
        toArray())),
      takeUntil(this._unsubscribeAll)
    ).subscribe(unselectedStaff => {
      this.unselectedStaff = unselectedStaff;
      this.selectedStaff = [];
      this.staffCardExpandable.hardsetSelected(this.selectedStaff);
      this.total = unselectedStaff.length;
      this._cdr.markForCheck();
    });
  }

  private _setFilters(filters: any) {
    this.filterOptions = { ...this.filterOptions, ...filters };
  }

  private _getStaffFilterData(): FilterStaffData {
    const shifts = this.filterOptions.shift ? this.filterOptions.shift.map(filteredShift => filteredShift) : '';
    const empt = this.filterOptions.employmentType ? this.filterOptions.employmentType.map(filteredEmployType => filteredEmployType) : '';
    const stids = this.filterOptions.staffType ? this.filterOptions.staffType.map(filteredStaffType => filteredStaffType.type) : '';

    const req: FilterStaffData = {
      myStaff: true,
      onlyActive: true,
      noHidden: true,
      agencyStaff: false,
      canRequestForShiftId: this.shiftDetails.shiftID,
      shiftTimes: shifts.toString(),
      employmentTypes: empt.toString(),
      shiftTypeId: this.shiftDetails.shiftTypeId,
      ...this._getStartAndEndDateOfWeek()
    };

    if (!!this.noRestriction) {
      req.prioritizedStaffType = stids;
    }
    return req;
  }

  private _getStartAndEndDateOfWeek() {
    const current = moment(this.shiftDetails.shiftDate);
    const startDate = formatDate(current.clone().startOf('isoWeek').toDate(), 'yyyy-MM-dd', 'en-US');
    const endDate = formatDate(current.clone().endOf('isoWeek').toDate(), 'yyyy-MM-dd', 'en-US');
    return { startDate, endDate };
  }

  private _notCalloutStaff(request: RequestData) {
    return !!this.replacing ? this.replacing.staffId !== request.staffID : true;
  }

  private _sendAnalyticsAddToRequest(numberOfRequests?: number) {
    this._googleAnalyticsService.eventEmitter(
      GoogleAnalyticsService._GetEvents().shift_management.category,
      GoogleAnalyticsService._GetEvents().shift_management.action.add_to_request.name,
      GoogleAnalyticsService._GetEvents().shift_management.action.add_to_request.label,
      numberOfRequests
    );
  }
}
