<div *ngIf="userTypeId === 2; else staffLoginVIew" class="facility-login-wrapper">
  <div class="divider-row"></div>
  <div class="login-body-wrapper">
    <img class="small-device-logo" src="assets/images/branding/intelycare-logo-primary-tm-logo-full-color-rgb.svg"
         width="192" height="92" />
    <img class="web-device-logo" src="assets/images/branding/intelycare-logo-stacked.svg" width="192" height="92" />
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-body">
      <div class="input-fields-wrapper">
        <div fxLayout="column" fxLayoutGap="5px">
          <input
            [ngStyle]="{'border': loginForm.controls.email.touched && loginForm.controls.email.invalid ? '2px solid #E5098C' : '1.5px solid #7FBFDC'}"
            class="custom-input-field" matInput
            [placeholder]=" !(loginForm.controls.email.touched && loginForm.controls.email.invalid) ? 'Email Address' : 'A valid email is required'"
            type="email" formControlName="email"
            required />
        </div>

        <div fxLayout="column" fxLayoutGap="5px">
          <input
            [ngStyle]="{'border': loginForm.controls.password.touched && loginForm.controls.password.invalid ? '2px solid #E5098C' : '1.5px solid #7FBFDC'}"
            class="custom-input-field" matInput
            [placeholder]=" !(loginForm.controls.email.touched && loginForm.controls.email.invalid) ? 'Password' : 'Password is required'"
            [type]="hide ? 'password' : 'text'"
            formControlName="password" required />
        </div>

        <div class="forgot-password-wrapper" fxFlexAlign="end">
          <a *ngIf="userTypeId === 1" routerLink="/reset">Forgot password?</a>
          <a *ngIf="userTypeId === 2" routerLink="/facility/reset">Forgot password?</a>
          <a *ngIf="userTypeId === 5" routerLink="/training/recover">Forgot password?</a>
          <a *ngIf="userTypeId === 4" routerLink="/training/facility/recover">Forgot password?</a>
        </div>
      </div>

      <button mat-button [disabled]="!isValidUser() || processing" class="login-btn" type="submit"
              (click)="login()">
        <img *ngIf="processing" src="/assets/images/loader.gif" height="30" width="30" />
        <label *ngIf="!processing" [ngStyle]="{'cursor': !isValidUser() || processing ? '' : 'pointer'}">
          Login
        </label>
      </button>
    </form>
  </div>
  <app-browser-warning></app-browser-warning>
</div>
<ng-template #staffLoginVIew>
  <div class="login-form">

    <div class="login-form-wrapper">

      <div class="header-wrapper">
        <div class="header-icon-wrapper">
          <img src="assets/images/register.svg" class="custom-icon" alt="No Result" />
        </div>

        <div class="user-management-header-wrapper">
          <div class="user-management-header">
            <label class="header-label-1">
              Hello!
            </label>
            <br />
            <br />
            <label class="header-label-2">
              Log In
            </label>
          </div>
        </div>
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="user-management-body">

          <mat-form-field appearance="outline" class="input-custom-width">
            <mat-label class="custom-input-label">Email address</mat-label>
            <input matInput placeholder="example@email.com" type="email" formControlName="email" required>
            <mat-hint class="custom-hint" *ngIf="loginForm.controls.email.touched && validateForm.email.errors">
              A valid email address is required
            </mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" class="input-custom-width suffix">
            <mat-label class="custom-input-label">Password</mat-label>
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password"
              required>
            <i matSuffix (click)="hide = !hide" [ngClass]="hide ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            <mat-hint class="custom-hint" *ngIf="loginForm.controls.password.touched && validateForm.password.errors">A
              valid password is required</mat-hint>
          </mat-form-field>

          <div class="forgot-password-wrapper">
            <a *ngIf="userTypeId === 1" routerLink="/reset" class="forgot-password">Forgot password?</a>
            <a *ngIf="userTypeId === 2" routerLink="/facility/reset" class="forgot-password">Forgot password?</a>
            <a *ngIf="userTypeId === 5" routerLink="/training/recover" class="forgot-password">Forgot password?</a>
            <a *ngIf="userTypeId === 4" routerLink="/training/facility/recover" class="forgot-password">Forgot
              password?</a>
          </div>

          <br />
          <br />

          <div class="button-wrapper">
            <button mat-button [disabled]="!isValidUser() || processing" [ngStyle]="{'background' : checkButtonColor()}"
              class="main-button" type="submit" (click)="login()">
              <img *ngIf="processing" src="/assets/images/loader.gif" height="30" width="30" />
              <label *ngIf="!processing" class="main-button-label">
                Log In
              </label>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
