<ng-container *ngIf="!isDevice; else mobileCartView">
<div class="confirm-cart-sidebar" fxFlex="100%" fxFlexFill fxLayout="column">
  <div class="confirm-cart-header" fxFlex="64px" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title">Your Requests Have Been Submitted</span>
    <button (click)="close()" mat-icon-button>
      <i class="fal fa-times"></i>
    </button>
  </div>

  <div class="confirm-cart-body" fxLayout="column" fxLayoutGap="50px">
    <div class="content-description" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
      <i fxFlex="0 0 auto" class="fas fa-check-circle"></i>
      <div class="text" fxFlexAlign="start">
        Thank you for your requests! Our team has received your
        request coverage and will work toward fulfilling your shifts.
      </div>
    </div>
    <div class="request-details" fxLayout="column" fxLayoutGap="15px">
      <label>Request Details</label>
      <table>
        <thead>
        <th fxFlex="40%">Shift Time & Floor</th>
        <th fxFlex="30%">IntelyPros</th>
        <th fxFlex="30%">Shift Dates</th>
        </thead>
        <tbody>
        <tr *ngFor="let item of totalCartInfo?.cartItems">
          <td fxFlex="40%">
            <div fxLayout="column" fxLayoutGap="8px">
              <span class="bold-item">{{item.shiftTime.shiftName}}</span>
              <span class="light-item">{{item.unit?.floorName}}</span>
            </div>
          </td>
          <td fxFlex="30%">
            <div fxLayout="column" fxLayoutGap="12px">
              <div *ngFor="let st of item.staffTypes || []" fxLayoutGap="5px" fxLayout="column">
                <span *ngIf="st.staffType.stateSpecificLabel?.length > 0">
                  <span *ngFor="let state of st.staffType?.stateSpecificLabel">
                    <span class="bold-item">{{st.count}} {{state.shiftTypeLabel}} </span>
                  </span>
                </span>
                <span *ngIf="!st.staffType.stateSpecificLabel?.length">
                  <span class="bold-item">{{st.count}} {{st.staffType.shiftTypeLabel}}</span>
                </span>
                <span class="light-item">{{st.gender !== 'F' ? 'Male or Female' : 'Female'}}</span>
              </div>
            </div>
          </td>
          <td fxFlex="30%">
            <div fxLayout="column" fxLayoutGap="5px">
              <span class="light-item" *ngFor="let day of item.days">{{day | date:'E LLL d'}}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="(requestIds$ | async) as request">
      <ng-template [ngIf]="!boostResponse.boostSucceeded && request.length > 0">
        <app-boost-shift [request]="request" (emitter)="getBoostResponse($event)"></app-boost-shift>
      </ng-template>
    </ng-container>
    <div class="summary" fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="15px">
      <label class="title">Summary</label>
      <div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="26px">
        <div class="summary-block" fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="13px">
          <label>Total Shifts Requested</label>
          <div class="total-shift" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <span class="shift-count">{{totalCartInfo?.totalIntelypros}} SHIFTS</span>
            <div *ngIf="boostResponse.boostSucceeded" class="">
              <app-boost-summary [boostResponse]="boostResponse.boostResponse"></app-boost-summary>
            </div>
          </div>
        </div>
        <div class="summary-block" fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="13px">
          <label>Total IntelyPros Requested</label>
          <ng-container *ngFor="let el of totalCartInfo?.totalPerStaffType | keyvalue">
            {{el.value.total}}
            <span *ngIf="el.value.staffType?.stateSpecificLabel?.length">
              <span *ngFor="let state of el.value.staffType?.stateSpecificLabel">
                  {{state.shiftTypeLabel}}
              </span>
            </span>
            <span *ngIf="!el.value.staffType?.stateSpecificLabel?.length"> {{el.value.staffType.shiftTypeLabel}}</span>
          </ng-container>
        </div>
        <ng-container *ngIf="boostResponse.boostSucceeded">
          <div *ngIf="boostResponse.boostResponse.boostAmount" class="sent-request">
            Rate Boost has been applied to your request(s).
          </div>
          <div *ngIf="boostResponse.boostResponse.allowOvertime?.value" class="sent-request">
            Overtime Boost has been applied to your request(s).
          </div>
          <div *ngIf="boostResponse.boostResponse.sureShift" class="sent-request">
            Sure Shift has been applied to your request(s).
          </div>
        </ng-container>
      </div>
    </div>
    <app-alert12-hour-notification></app-alert12-hour-notification>
    
  </div>
  <div class="confirm-cart-footer" fxFlex="94px" fxLayout="row" fxLayoutAlign="center center">
    <button type="button" mat-button (click)="close()">Done</button>
  </div>
</div>
</ng-container>

<ng-template #mobileCartView>
  <app-confirm-cart-mobile [totalCartInfo]="totalCartInfo" (done)="close()"></app-confirm-cart-mobile>
</ng-template>
