<div>
  <mat-selection-list [formControl]="control" [disableRipple]="true" class="selectable-button-group">
  
    <mat-list-option class="selectable-button" *ngFor="let el of elements " [value]="el.value"
      [ngClass]="{selected: selected && selected.indexOf(el.value) >= 0}">
      <div class="text">
        <img *ngIf="el.name == 'Favorites'" 
        [src]="selected[0] == 1 ? 'assets/images/favorites-selected.svg' : 'assets/images/favorites.svg'">
        {{el.name}}
      </div>
    </mat-list-option>
  
  </mat-selection-list>
</div>
