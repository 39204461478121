import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomShiftTimeDisplayComponent } from './custom-shift-time-display.component';



@NgModule({
  declarations: [CustomShiftTimeDisplayComponent],
  imports: [
    CommonModule
  ],
  exports: [CustomShiftTimeDisplayComponent]
})
export class CustomShiftTimeDisplayModule { }
