<div class="validation-wrapper">
  <label class="validation-header">Your password must contain:</label>
  <ul class="validation-label" >
    <li [style.list-style]="passValidLength ? 'url('+imagePath+')': 'none'">6 or more characters</li>
    <li [style.list-style]="passContainsNumber ? 'url('+imagePath+')': 'none'">1 or more numbers</li>
    <li [style.list-style]="passContainsLower ? 'url('+imagePath+')': 'none'">1 or more lowercase letters</li>
    <li [style.list-style]="passContainsCapital ? 'url('+imagePath+')': 'none'">1 or more uppercase letters</li>
    <li [style.list-style]="passMatch ? 'url('+imagePath+')': 'none'">Passwords that match</li>

  </ul>

</div>
