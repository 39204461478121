import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../../../models/MenuItem';

@Component({
  selector: 'app-side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss']
})
export class SideMenuItemComponent implements OnInit {

  selected = false;
  _currentUrl: string;
  @Output() currentUrlChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() menu: MenuItem;
  @Input() set currentUrl(val: string) {
    this.currentUrlChange.emit(val);
    this._currentUrl = val;
  }
  get currentUrl() {
    return this._currentUrl;
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.checkSelectedMenu(this._currentUrl);
    this.currentUrlChange.pipe().subscribe(resp => {
      this.checkSelectedMenu(resp);
    });
  }

  navigateToPath() {
    if (this.router.url !== this.menu.url) {
      this.currentUrlChange.emit(this.menu.url);
      this.router.navigate([this.menu.url]);
    }
  }

  checkSelectedMenu(currentUrl: string) {
    if (currentUrl === this.menu.url) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

}
