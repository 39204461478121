<div *ngIf="ipRequest" class="container">
    <button *ngIf="!!cart.length" (click)="clearCart()" automation-id="clear-cart-footer" mat-button>clear
        cart</button>
    <div class="shopping-cart">
        <span *ngIf="!cart.length">Your cart is empty</span>
        <button *ngIf="cart.length" (click)="viewShoppingCart()" automation-id="view-cart-footer" class="view-cart"
            mat-button>
            view cart
        </button>
        <i class="fad fa-shopping-cart" (click)="viewShoppingCart()" [matBadge]="totalRequests"
            [matBadgeHidden]="!cart.length" [matBadgeSize]="'medium'" matBadgeColor="primary"
            [class.small-badge]="totalRequests >= 100"></i>
    </div>
</div>