import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OnBoardingNavigation } from '../../../models/newModels/OnBoardingNavigation';
import { OnBoardingService } from '../../../on-boarding/services/on-boarding.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
/*OnBoardingSidebarComponent
Displays all onboarding steps.
*/

@Component({
  selector: 'app-on-boarding-sidebar',
  templateUrl: './on-boarding-sidebar.component.html',
  styleUrls: ['./on-boarding-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnBoardingSidebarComponent implements OnInit, OnDestroy {

  navigation: OnBoardingNavigation[] = [];
  private _unsubscribe: Subject<any> = new Subject<any>();
  show: boolean;
  constructor(private _OnBoardingService: OnBoardingService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    public _deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    const iFrameDetection = (window === window.parent) ? false : true;
    const isMobile: boolean = this._deviceService.isMobile() || iFrameDetection;
    isMobile ? this.show = true : this.show = false;
    this._OnBoardingService.onBoardingNavigation.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe(e => {
      this.navigation = e.filter(a => a.isStep);
      this._cdr.markForCheck();
    });
  }

  checkIfCurrent(url) {
    const onBoardNav = this._OnBoardingService.onBoardingNavigation.value;
    const node = onBoardNav.find(item => item.uri === url);
    // Get list of nodes that are on the same step
    const siblings = onBoardNav.filter(item => item.name === node.name);
    return (this._router.url === ('/' + url)) || (siblings.filter(item => ('/' + item.uri) === this._router.url).length > 0);
  }

  navigateTo(url: string) {
    this._router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  collapseSidenav(): void {
    this.show = !this.show;
  }

}
