/**
 * This is Footer component provide footer with two buttons.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreateShiftConstants } from 'src/app/shared/constants/createShift';
/**
 * @Input() firstButtonIcon to show the first button icon.
 * @Input() firstButtonLabel to show the first button label.
 * @Input() secondButtonIcon to show the second button icon.
 * @Input() secondButtonLabel to show the second button label.
 * @Input() isButtonDisabled to enable and disable the button.
 * @Output() clickedButton sends the button label.
 */
@Component({
  selector: 'app-custom-drawer-footer',
  templateUrl: './custom-drawer-footer.component.html',
  styleUrls: ['./custom-drawer-footer.component.scss']
})
export class CustomDrawerFooterComponent {
  @Input() isLoading: boolean;
  @Input() firstButtonIcon: string;
  @Input() firstButtonLabel: string;
  @Input() secondButtonIcon: string;
  @Input() secondButtonLabel: string;
  @Input() isFirstButtonDisabled: boolean;
  @Input() isSecondButtonDisabled: boolean;

  @Output() clickedButton: EventEmitter<string> = new EventEmitter<string>();

  public readonly CreateShiftConstants = CreateShiftConstants;

  constructor() { }

  /**
   * on click of buttons it is called to pass data.
   */
  buttonClicked(buttonLabel: string): void {
    this.clickedButton.emit(buttonLabel);
  }
}
