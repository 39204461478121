import {
  ChatAdapter, Group, Message, ChatParticipantStatus,
  ParticipantResponse, ChatParticipantType, IChatParticipant, MessageType
} from 'ng-chat';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { ChatService } from '../services/chat.service';
import { StaffContact } from '../models/StaffContact';
import { CustomMessage } from '../models/CustomMessage';
import { SocketIoService } from '../../services/socket-io.service';
import { EventTypes } from '../../helpers/EventTypes';

export class CustomChatAdapter extends ChatAdapter {

  public static mockedParticipants: IChatParticipant[] = [];
  private _staffList: StaffContact[] = [];

  constructor(
    private _socket: SocketIoService,
    private _chatService: ChatService,
    private _unsubscribe: Subject<any>
  ) {
    super();
    this._getStaffList();
    this._listenForNewMessages();
  }

  /**
   * Inherited method to fill chat list with staff members
   */
  listFriends(): Observable<ParticipantResponse[]> {
    CustomChatAdapter.mockedParticipants = [];
    return this._chatService.staffListObservable;
  }

  /**
   * Inherited method to retireve chat conversation history for a given staff member
   * @param staffId: staff to get conversation
   */
  getMessageHistory(staffId: any): Observable<Message[]> {
    return this._chatService.getConversation(staffId)
      .pipe(
        switchMap(messageList => messageList),
        map(data => {
          return {
            fromId: data.fromId,
            toId: data.toId,
            message: data.message,
            dateSent: data.dateSent,
            dateSeen: data.dateSeen
          } as Message;
        }), toArray(),
        tap(() => {
          this._chatService.updateStaffUnreadMessages(staffId, 0);
          this._chatService.recount();
        })
      );
  }

  /**
   * Inherited method to trigger a new message
   * @param message: message to send
   */
  sendMessage(message: Message): void {
    const customMessage = {
      ...message,
      type: 'text',
      toPhoneNumber: this._staffList.find(staff => staff.id === message.toId).phoneNumber
    } as CustomMessage;

    this._chatService.sendMessage(customMessage).pipe(
      tap(() => this._chatService.updateChatList(message.toId))
    ).subscribe();
  }

  groupCreated(group: Group): void {
    CustomChatAdapter.mockedParticipants.push(group);
    CustomChatAdapter.mockedParticipants = CustomChatAdapter.mockedParticipants.sort((first, second) =>
      second.displayName > first.displayName ? -1 : 1
    );
    // Trigger update of friends list
    this.listFriends().subscribe(response => {
      this.onFriendsListChanged(response);
    });
  }

  /**
   * Handle socket for listening new messages
   * @private
   */
  private _listenForNewMessages() {
    this._socket.onNewMessage(EventTypes.SMS_RECEIVED)
      .pipe(
        takeUntil(this._unsubscribe),
        map(response => response['data']),
        tap((message: CustomMessage) => {
          const newMessage = {
            type: MessageType.Text,
            fromId: message.fromId,
            toId: message.toId,
            message: message.message,
            dateSent: message.dateSent
          } as Message;
          const unread = this._chatService.staffListObservable.value.find(item => item.participant.id === newMessage.fromId)
            .metadata.totalUnreadMessages;

          const participant = CustomChatAdapter.mockedParticipants.find(item => item.id === newMessage.fromId);
          this._reorderStaffList(newMessage.fromId);
          this._chatService.updateStaffUnreadMessages(newMessage.fromId, unread + 1);
          this._chatService.recount();
          this.onMessageReceived(participant, newMessage);
        })
      ).subscribe();
  }

  /**
   * Update observable with data coming from API
   * @private
   */
  private _getStaffList() {
    let count = 0;
    this._chatService.getStaffChatList()
      .pipe(
        tap(staffList => this._staffList = staffList),
        switchMap(staff => staff),
        map(staff => {
          const participantResponse = new ParticipantResponse();
          participantResponse.participant = {
            id: staff.id,
            avatar: staff.imgUrl,
            displayName: staff.displayName,
            status: ChatParticipantStatus.Offline,
            participantType: ChatParticipantType.User
          } as IChatParticipant;
          CustomChatAdapter.mockedParticipants.push(participantResponse.participant);
          participantResponse.metadata = { totalUnreadMessages: staff.unreadCount };
          count += staff.unreadCount;
          return participantResponse;
        }), toArray(),
        tap(list => this._chatService.staffListObservable.next(list)),
        tap(() => {
          this._chatService.totalUnread.next(count);
        })
      ).subscribe();
  }

  /**
   * Update vhat list depending on last sent message
   * @param staffId: Id of staff last sent message
   * @private
   */
  private _reorderStaffList(staffId: number) {
    const staffList = this._chatService.staffListObservable.value;
    staffList.forEach((item, i) => {
      if (item.participant.id === staffId) {
        staffList.splice(i, 1);
        staffList.unshift(item);
      }
    });
    this._chatService.staffListObservable.next(staffList);
  }
}
