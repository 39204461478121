<div class="shift-management">
  <div class="shift-management-wrapper" fxLayout="column" fxFlex fxFlexFill>


    <div class="content" fxFlexFill fxLayout="column" fxFlex="0 1 auto">

      <div class="header" fxFlex="0 1 auto">
        <app-management-top-card title="Shift Details" [shiftDetails]="shiftDetails"></app-management-top-card>
      </div>

      <div class="content-wrapper" fxLayoutGap="20px" fxLayout="column">
        <br/>

        <app-staff-card-expandable [staffMembers]="[staffMember]" [control]="false"
                                   [showHoursThisWeek]="!!currentConfig && currentConfig.dateRange !== 'monthly'"></app-staff-card-expandable>

        <app-change-unit *ngIf="!removingFromStaff" [unitToMove]="currentunit" [elements]="unitoptions"
                         (unitMoved)="moveShift($event, currentdate, currentshiftdefid)" ></app-change-unit>

        <app-change-shift *ngIf="!removingFromStaff" [elements]="shifttypeoptions"
                          [currentShift]="currentshiftdefid" [currentDate]="currentdate"
                          (shiftChange)="moveShift(currentunit, $event.date, $event.shift)"></app-change-shift>

        <div class="remove-div" [ngClass]="{hidden : !removingFromStaff}"
             fxFlex fxLayout="column" fxLayoutGap="20px">
          <mat-divider></mat-divider>
          <div class="remove-div-content" fxLayout="column" fxLayoutGap="12px">
            <span class="remove-div-title">Remove from shift</span>
            <span>Reason For Cancellation <span class="required">*Required</span></span>
            <app-selectable-button-group #cancelReason [elements]="calloutReasons" [maximumSelections]="1"
                                         [multiple]="false" (valueChange)="callOutReason = $event[0]">
            </app-selectable-button-group>
          </div>

        </div>
      </div>
    </div>
    <div class="actions" fxFlex="84px">
      <div class="actions-wrapper" fxFlex fxLayout="row" fxLayoutAlign="center" fxLayoutGap="12px">
        <button mat-button class="action-button partial" *ngIf="!removingFromStaff"
                fxFlexAlign="center" (click)="removingFromStaff = true">Remove From Shift
        </button>

        <button mat-button class="action-button" *ngIf="removingFromStaff" (click)="removeFromShift()"
                [disabled]="!cancelReason.control.valid" fxFlexAlign="center"
                [ngClass]="cancelReason.control.valid? 'complete' : 'disabled'">Remove From Shift
        </button>

        <button mat-button class="action-button cancel" *ngIf="removingFromStaff"
                fxFlexAlign="center" (click)="removingFromStaff = false">Cancel
        </button>
      </div>
    </div>

  </div>
</div>
