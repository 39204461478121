import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffCardExpandableComponent } from './staff-card-expandable.component';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared.module';
import { ChipIconModule } from '../chip-icon/chip-icon.module';
import { PointSeparatorModule } from '../point-separator/point-separator.module';

@NgModule({
  declarations: [StaffCardExpandableComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ChipIconModule,
    PointSeparatorModule
  ],
  exports: [
    StaffCardExpandableComponent
  ]
})
export class StaffCardExpandableModule { }
