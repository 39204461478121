import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestIntelyproComponent } from './request-intelypro.component';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../shared/material.module';
import { CardSystemTopHeaderModule } from '../card-system-top-header/card-system-top-header.module';
import { RequestIntelyproCardModule } from './components/request-intelypro-card/request-intelypro-card.module';

@NgModule({
  declarations: [RequestIntelyproComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CardSystemTopHeaderModule,
    RequestIntelyproCardModule
  ],
  exports: [
    RequestIntelyproComponent
  ]
})
export class RequestIntelyproModule {
}
