<h1 mat-dialog-title *ngIf="!!data.title">{{data.title}}</h1>

<div mat-dialog-content>
  <div fxLayout="row">
    <span class="content">{{data.content}}</span>
  </div>
</div>

<div mat-dialog-actions >
  <div class="dialog-buttons-container">
    <button mat-button class="btn complete" (click)="confirm()">
      <i class="fa fa-check-circle"></i>
     <span class="btn-span"> {{ data.confirmButtonText ? data.confirmButtonText : 'YES' }}</span>
    </button>
    <button mat-button class="btn partial" (click)="cancel()">
      <i class="fas fa-times-circle"></i>
      <span class="btn-span">  {{ !!data.cancelButtonText ? data.cancelButtonText : 'NO' }}</span>
    
    </button>
  </div>
</div>
