import { EmploymentType } from './EmploymentType';
import { StaffType } from './StaffType';
import { ShiftDaysTypeRelation } from './ShiftDaysTypeRelation';
import { Gender } from './Gender';
import { Unit } from './Unit';

export class AllStaff {
  id?: number;
  admin?: boolean;
  userid?: number;
  firstName?: string;
  lastName?: string;
  image?: string;
  employmentType?: EmploymentType;
  gender?: Gender;
  email?: string;
  phone?: string;
  formatedPhone?: string;
  hireDate?: string | Date | number;
  staffType?: StaffType;
  shiftDays?: ShiftDaysTypeRelation[];
  shiftDaysString?: string;
  unitsString?: string;
  notes?: string;
  rating?: number;
  birthDate?: string | Date | number;
  lastWorked?: Date;
  nextShift?: Date;
  lastWorkedString?: string;
  nextShiftString?: string;
  ssn?: string;
  bookMe?: boolean;
  location?: Unit[];
  agencyname?: string;
  isIntelypro?: boolean;
  hidden?: boolean;
  numShifts?: number;
  city?: string;
  state?: string;
  scheduler?: boolean;
  roleTypeId?: number;
  userPermissions?: { useraccessgroupid: number }[];
  favorite?:boolean
}

