import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseConfigService } from '@theme/services/config.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { monthlyViewType } from './config/monthly-view-type';
import { theme_config } from './config/theme_config';
import { JWTPayloadData } from './models/newModels/JWTPayloadData';
import { TemplateCustomConfig } from './models/TemplateCustomConfig';
import { ManageUsersAccountService } from './services/manage-users-account.service';
import { PerformanceSummaryShift } from './services/staff-dashboard/models';
import { StaffDashboardService } from './services/staff-dashboard/staff-dashboard.service';
import { OnBoardingService } from './on-boarding/services/on-boarding.service';
import { of } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'intelycare';
  uiconfig = theme_config;
  _RoutesWithLeftSideBar = [];
  _NavbarRoutes = ['/directory', '/daily', '/daily-new', '/settings', '/monthly', '/week', '/request-intelypro', '/dashboard', '/spreadsheet', '/timecards', '/home'];
  private isGAUserSet: boolean;
  enableApp: boolean;
  web: boolean;
  viewType = monthlyViewType;

  constructor (
    private _router: Router,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _domSan: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
    private _fuseConfigService: FuseConfigService,
    private _deviceService: DeviceDetectorService,
    private _onBoardingService: OnBoardingService,
    private _userService: ManageUsersAccountService,
    private _staffDashboardService: StaffDashboardService
  ) {

    this._route.queryParams.pipe(
      tap((params) => {
        if (environment.ALLOW_ANALYTICS) {
          gtag('js', new Date());
          gtag('config', 'UA-55670953-1', {
            'campaign': {
              'keyword': params['utm_term'],
              'name': params['utm_campaign'],
              'medium': params['utm_medium'],
              'source': params['utm_source'],
              'content': params['utm_content']
            },
            'custom_map': {
              'metric1': 'metric_facility_id',
              'dimension2': 'facility_id'
            }
          });
        }
      })
    ).subscribe();

    _router.events.subscribe(event => {
      const user = this._userService.currentUserValue;
      if (event instanceof NavigationEnd) {
        const customConfig: TemplateCustomConfig = { navbar: '', sidebar: '' };

        if (!this._RoutesWithLeftSideBar.includes(_router.url)) {
          this.uiconfig.layout.sidepanel.hidden = true;
        } else {
          customConfig.sidebar = 'DefaultSidebar';
          this.uiconfig.layout.sidepanel.hidden = false;
        }

        if (!this._NavbarRoutes.some(e => _router.url.includes(e))) {
          this.uiconfig.layout.toolbar.hidden = true;
        } else {
          customConfig.navbar = 'DefaultNavbar';
          this.uiconfig.layout.toolbar.hidden = false;
        }

        if (_router.url.includes('/onboard')) {
          this.uiconfig.layout.sidepanel.hidden = false;
          this.uiconfig.layout.toolbar.hidden = false;
          customConfig.navbar = 'OnBoardingNavbar';
          customConfig.sidebar = 'OnBoardingSidebar';

          if (_router.url === '/onboard/exists') {
            customConfig.navbar = 'OnlyNavbar';
            this.uiconfig.layout.sidepanel.hidden = true;
          }
        }

        this.uiconfig.layout.sidepanel.position = 'left';
        this._fuseConfigService.setConfig(this.uiconfig);
        this._fuseConfigService.setCustomConfig = customConfig;

        if (environment.ALLOW_ANALYTICS) {
          gtag('config', 'GA_MEASUREMENT_ID', {
            'campaign': {
              'keyword': event['snapshot']?.queryParams['utm_term'],
              'name': event['snapshot']?.queryParams['utm_campaign'],
              'medium': event['snapshot']?.queryParams['utm_medium'],
              'source': event['snapshot']?.queryParams['utm_source'],
              'content': event['snapshot']?.queryParams['utm_content']
            },
            'page_path': event.urlAfterRedirects,
            'facility_id': user ? user.activeusertype ? user.activeusertype.clientid : '' : '',
            'metric_facility_id': user ? user.activeusertype ? user.activeusertype.clientid : '' : ''
          });
        }

        this._setUserIdGoogleAnalytics();
      }
    });

    this._userService.userLoggedInSubject.pipe(
      filter(loggedIn => loggedIn),
      tap(() => this._getCancelledShiftsData())
    ).subscribe();

    this.web = this._deviceService.isDesktop();
    this.enableApp = true;
  }

  ngOnInit(): void {
    this._matIconRegistry.addSvgIcon(
      `intelypro`,
      this._domSan.bypassSecurityTrustResourceUrl(`assets/images/branding/intelycare-logo-icon-round-border-med.svg`)
    );
    this._matIconRegistry.addSvgIcon(
      `staff-icon`,
      this._domSan.bypassSecurityTrustResourceUrl(`assets/images/staff-icon.svg`)
    );
    this._matIconRegistry.addSvgIcon(
      `user-edit`,
      this._domSan.bypassSecurityTrustResourceUrl(`assets/images/user-edit.png`)
    );
    this._matIconRegistry.addSvgIcon(
      `user-edit-confirm`,
      this._domSan.bypassSecurityTrustResourceUrl(`assets/images/user-edit-confirm.png`)
    );
    this._matIconRegistry.addSvgIcon(
      `user-remove`,
      this._domSan.bypassSecurityTrustResourceUrl(`assets/images/trash.png`)
    );

    const user = this._userService.currentUserValue;
    if (user) {
      this._userService.initializePendo(user);
    }
  }

  private _setUserIdGoogleAnalytics() {
    const user = this._userService.currentUserValue;
    if (environment.ALLOW_ANALYTICS && user && user.active && !this.isGAUserSet) {
      gtag('set', { 'user_id': user.activeusertype.clientid });
      this.isGAUserSet = true;
    }
  }

  /**
   * Method to get data related to cancelledshifts
   */
  private _getCancelledShiftsData(): void {
    const user: JWTPayloadData = this._userService.currentUserValue;
    const clientId: number = user?.activeusertype.clientid;
    let cancelledShiftPercentage = 0;
    const highestCancelationCount = 15;
    const numberOfDays = 30;
    this._staffDashboardService.getLastCancelledShift(numberOfDays, clientId).pipe(
      first(),
      tap((performanceSummary: PerformanceSummaryShift) => cancelledShiftPercentage = performanceSummary.metrics.percentage),
      catchError(() => of<PerformanceSummaryShift>({
        shiftServiceData: [],
        metrics: { type: '', total: 0, used: 0, percentage: 0 }
      })),
      switchMap(() => this._onBoardingService.getOnboardingState()),
      catchError(() => of<string>('onboard/register')),
      tap(userState => {
        switch (this._userService.facilityType) {
          case (this.viewType.intelypro):
            user.team = 'Staffing';
            break;
          case (this.viewType.hybrid):
            user.team = 'Hybrid';
            break;
          default:
            user.team = 'Scheduling';
            break;
        }

        if (this._userService.facilityType !== this.viewType.internal && userState === 'done' || Number(userState) >= 23) {
          if (cancelledShiftPercentage >= highestCancelationCount) {
            user.highCancellation = true;

          } else {
            user.highCancellation = false;
          }
        }

        (window as any).Appcues?.identify(user.userid, user);
      })
    ).subscribe();
  }
}
