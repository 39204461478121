import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ManageUsersAccountService } from '../manage-users-account.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authenticationService: ManageUsersAccountService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authenticationService.currentUserSubject.pipe(
      map(e => {
        if (!!e) {
          if (!this.cookieService.get('IntelycareAuthCookie')) {
            this.authenticationService.clearLocalStorage();
            e = null;
          }
          return true;
        } else {
          this.router.navigateByUrl('/login');
        }
        return false;
      })
    );
  }
}
