import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../shared/material.module';
import { AddStaffComponent } from './add-staff.component';
import { SelectableButtonGroupModule } from '../../../shared/componets/selectable-button-group/selectable-button-group.module';
import { CustomOptionSetModule } from '../../../shared/componets/custom-option-set/custom-option-set.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ConfirmationDialogModule } from '../confirmation-dialog/confirmation-dialog.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IndividualStaffModule } from './individual-staff-form/individual-staff-form.module';
import { ConfirmFacilityScheduleDetailsModule } from './confirm-facility-schedule-details/confirm-facility-schedule-details.module';
import { DownloadTemplateUploadModule } from './download-template-upload/download-template-upload.module';
import { TemplateUploadModule } from './template-upload/template-upload.module';
export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AddStaffComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    SelectableButtonGroupModule,
    CustomOptionSetModule,
    NgxMaskModule.forRoot(options),
    MatSnackBarModule,
    ConfirmationDialogModule,
    IndividualStaffModule,
    ConfirmFacilityScheduleDetailsModule,
    DownloadTemplateUploadModule,
    TemplateUploadModule
  ],
  exports: [
    AddStaffComponent
  ],
})
export class AddStaffModule { }
