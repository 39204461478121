import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { BulkShift } from 'src/app/models/BoostModels';
import { BoostDataService } from './boost-data.service';

@Injectable({
  providedIn: 'root'
})
export class ModifyBoostService {

  modifyBoostRequests = new BehaviorSubject<number[]>([]);
  pending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private modifyDialogRef: MatDialogRef<any>;

  constructor(
    private boostDataService: BoostDataService,
    private dialog: MatDialog
  ) { }

  set processBoosting(value: boolean) {
    this.pending.next(value);
  }

  get processBoosting() {
    return this.pending.value;
  }

  openModifyDialog(
    component: any,
    data: { cid: number, uid: number, global?: boolean, block?: boolean, blockId?: number, boostData?: any, payRate?: number, costPlus?:boolean },
    toBottom: boolean) {
    this.closeModifyDialog();
    this.modifyDialogRef = this.dialog.open(component, {
      position: {
        bottom: toBottom ? '36px' : '',
        top: !toBottom ? '72px' : ''
      },
      panelClass: ['modify-boost-shifts'],
      hasBackdrop: false,
      data
    });
    return this.modifyDialogRef.afterClosed().pipe(take(1));
  }

  closeModifyDialog() {
    if (this.modifyDialogRef) {
      this.modifyDialogRef.close();
      this.modifyDialogRef = null;
    }
  }

  addToModify(reqdid: number, costPlus: boolean = false) {
    const requests = this.modifyBoostRequests.getValue().slice();
    if (requests.indexOf(reqdid) === -1) {
      if (!costPlus || requests.length === 0) {
        requests.push(reqdid);
        this.modifyBoostRequests.next(requests);
      }
    }
  }

  deleteFromModify(reqdid: number) {
    const requests = this.modifyBoostRequests.getValue().slice();
    const index = requests.indexOf(reqdid);
    if (index > -1) {
      requests.splice(index, 1);
      this.modifyBoostRequests.next(requests);
    }
  }

  toggleRequest(reqdid: number) {
    const requests = this.modifyBoostRequests.getValue().slice();
    if (requests.indexOf(reqdid) > -1) {
      this.deleteFromModify(reqdid);
    } else {
      this.addToModify(reqdid);
    }
  }

  addMultipleToModify(reqdids: number[]) {
    const currentRequests = this.modifyBoostRequests.getValue().slice();
    const combined = currentRequests.concat(reqdids);
    this.modifyBoostRequests.next(combined);
  }

  emptyRequests() {
    this.modifyBoostRequests.next([]);
  }

  boostAllRequests(cid: number, uid: number, requests: number[], percentage: string, overtime: string, sureShift: boolean, blockId?: number) {
    return this.boostDataService.getBoostDataForBulkShift(cid, uid, requests, percentage).pipe(
      mergeMap(response => response),
      map(data => data.boostDataBulkShift),
      map(boostInfo => {
        return boostInfo.map(shift => {
          return shift.rate.map(rating => {
            return {
              reqdid: shift.reqdid,
              staffType: rating.pType,
              newCharge: rating.newCharge,
              increaseValue: rating.increaseValue
            } as BulkShift;
          });
        }).reduce((previous, current) => {
          return previous.concat(current);
        }, []);
      }),
      toArray(),
      map(rates => {
        return rates.reduce((prev, curr) => {
          return prev.concat(curr);
        }, []);
      }),
      switchMap(rates => {
        return this.boostDataService.bulkBoostRequest(cid, uid, Number(percentage), rates, overtime, sureShift, blockId);
      })
    );
  }

  getBoostRange(reqdid: number, uid: number) {
    return this.boostDataService.getBoostDataForSingleShift(reqdid, uid).pipe(
      map(response => {
        return {
          boostData: response,
          boostRange: this.boostDataService.generateRates(
            Number(response.promotionRateMin),
            Number(response.promotionRateMax),
            response.billRate
          )
        };
      })
    );
  }

  getBlockBoostRange(cid: number, uid: number) {
    return this.boostDataService.getBlockBookingBoostRange(cid, uid).pipe(
      map(response => {
        return {
          overtimeSetting: response.otGlobalSetting,
          overtimeAllow: response.overtimeAllow,
          boostAllow: response.boostAllow,
          min: Number(response.minRange),
          max: Number(response.maxRange),
          adminAccess: response.adminAccess,
          requireApproval: response.requireApproval
        };
      })
    )
  }

  getAllPendingRequests(cid: number, uid: number) {
    return this.boostDataService.getBoostRangeForBulkShift(cid, uid).pipe(
      map(res => {
        return {
          overtimeSetting: res.otGlobalSetting,
          overtimeAllow: res.overtimeAllow,
          boostAllow: res.boostAllow,
          requests: res.requests.map(req => req.reqdid),
          min: Number(res.minRange),
          max: Number(res.maxRange),
          adminAccess: res.adminAccess,
          requireApproval: res.requireApproval
        };
      })
    );
  }

  editRate(newPrice: number, shiftId: number) {
    return this.boostDataService.editRate(newPrice, shiftId);
  }
}
