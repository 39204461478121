/**
 * This is Header component used to provide label and close button.
 *
 * @Input() title to show title.
 * @Input() closeEvent to close the opened Side nav.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-drawer-header',
  templateUrl: './custom-drawer-header.component.html',
  styleUrls: ['./custom-drawer-header.component.scss']
})
export class CustomDrawerHeaderComponent {
  @Input() title: string;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter();

  constructor() { }

  /**
   * To close the right drawer.
   */
  closeDrawer() {
    this.closeEvent.emit();
  }
}
