import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, flatMap, map, toArray } from 'rxjs/operators';
import { StaffModuleManagementService } from '../../../staff-module-management.service';
import { AllStaff } from '../../../../models/AllStaff';
import { Days } from '../../../../models/Days';
import { ShiftType } from '../../../../models/ShiftType';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Gender } from '../../../../models/Gender';
import { EmploymentType } from '../../../../models/EmploymentType';
import { StaffType } from '../../../../models/StaffType';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AditionalDataService } from '../../../../services/aditional-data.service';
import { StaffTypeData } from '../../../../models/newModels/StaffTypeData';
import { StaffService } from '../../../../services/staff.service';
import { Unit } from '../../../../models/Unit';
import { ConvertersService } from '../../../../services/utils/converters.service';
import * as moment from 'moment';
import { GoogleAnalyticsService } from '../../../../services/googleanalytics.service';
import { ShiftsService } from '../../../../services/apis/shifts.service';
import { ShiftToAdd } from 'src/app/models/shift-management-models/shift-to-add';
import { NoWhitespaceValidator } from 'src/app/helpers/custom-validators/NoWhiteSpaceValidator';
import { StaffManagementService } from 'src/app/services/staff-management.service';

@Component({
  selector: 'app-individual-staff-form',
  templateUrl: './individual-staff-form.component.html',
  styleUrls: ['./individual-staff-form.component.scss']
})
export class IndividualStaffFormComponent implements OnInit {


  @Input() staffMember: AllStaff;
  @Input() shifts: ShiftType[];
  @Input() days: Days[];
  processing = false;
  eachRowChosenData: any[];
  staffOptions: Observable<any>;
  employmentOptions: Observable<any>;
  genderOptions: Observable<any>;
  shiftOptions: Observable<any>;
  dayOptions: Observable<any>;
  unitOptions: Observable<any>;
  genderTypes: Gender[] = [];
  employmentTypes: EmploymentType[] = [];
  staffTypes: StaffType[] = [];
  unitTypes: Unit[] = [];
  staffTypesNewModel: StaffTypeData[] = [];
  chosenShiftName = 'Time';
  chosenDayName = 'Day';
  chosenUnitName = 'Location';
  submitted = false;
  staffMemberForm: FormGroup;
  selectedGender: number[];
  selectedStaff: number[];
  selectedEmpType: number[];

  constructor(
    public googleAnalyticsService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private additionalData: AditionalDataService,
    private staffService: StaffService,
    private _shiftJavaService: ShiftsService,
    private staffManagementService: StaffModuleManagementService,
    private _staffManagementService: StaffManagementService
  ) {
    this.eachRowChosenData = [];
    this.employmentTypes = [];
    this.employmentTypes = [];
    this.staffTypes = [];
    this.genderTypes = [];
  }

  ngOnInit() {

    this.addNewEmptyShiftDayUnit();

    this.staffMember = new AllStaff();
    this.staffMember.firstName = '';
    this.staffMember.lastName = '';
    this.staffMember.hireDate = '';
    this.staffMember.shiftDays = [];
    this.selectedGender = [];
    this.selectedStaff = [];
    this.selectedEmpType = [];

    this.staffMemberForm = this.formBuilder.group({
      name: [this.staffMember.firstName, [Validators.required, NoWhitespaceValidator.noWhitespaceValidator()]],
      surname: [this.staffMember.lastName, [Validators.required, NoWhitespaceValidator.noWhitespaceValidator()]],
      phone: [this.staffMember.phone, [Validators.required]],
      gender: [this.staffMember.gender?.id],
      employmentType: [this.staffMember.employmentType?.employmentTypeId !== 0 ?
        this.staffMember.employmentType?.employmentTypeId : null],
      staffType: [this.staffMember.staffType?.staffTypeId],
      hireDate: [this.staffMember.hireDate]
    });

    this.employmentOptions = this.additionalData.getEmploymentType().pipe(
      flatMap(e => e.data),
      filter(e => e.employmenttypeid !== 4),
      map(e => ({
        name: e.employmenttype,
        value: e.employmenttypeid
      })),
      toArray());

    this.employmentOptions.subscribe(emptypes => {
      emptypes.forEach(employmentType => {
        const employment: EmploymentType = {
          employmentTypeId: employmentType.value,
          employmentTypeName: employmentType.name
        };
        this.employmentTypes.push(employment);
      });
    });

    this.staffOptions = this.additionalData.getStaffTypes().pipe(
      flatMap(e => {
        this.staffTypesNewModel = e.data;
        return e.data;
      }),
      filter(e => e.canadd === true),
      map(e => ({
        name: e.abbreviation,
        value: e.stafftypeid
      })),
      toArray());

    this.staffOptions.subscribe(staffs => {
      staffs.forEach(item => {
        const staff: StaffType = {
          staffTypeId: item.value,
          staffTypeName: item.name
        };
        this.staffTypes.push(staff);
      });
    });

    this.unitOptions = this._shiftJavaService.getUnits().pipe(
      flatMap(e => e),
      map(e => ({
        name: e.floorName,
        value: e.id
      })),
      toArray());

    this.unitOptions.subscribe(units => {
      units.forEach(item => {
        const unit: Unit = {
          id: item.value,
          value: item.name,
          requiredStaff: '0'
        };
        this.unitTypes.push(unit);
      });
    });

    this.shiftOptions = this._shiftJavaService.getShiftDefs().pipe(
      flatMap(e => e),
      map(e => ({
        name: ConvertersService._datesToShiftHours(e),
        value: e.shiftDefId
      })),
      toArray());

    this.dayOptions = this.additionalData.getDays().pipe(
      flatMap(e => e.data),
      map(e => ({
        name: e.day,
        value: e.dayofweekid
      })),
      toArray());

    this.genderOptions = this.additionalData.getGenderTypes().pipe(
      flatMap(e => {
        return e.data.map((item, index) => {
          return {
            id: index + 1,
            name: item.gender
          };
        });
      }),
      map(e => ({
        name: e.name,
        value: e.id
      })),
      toArray());

    this.genderOptions.subscribe(genders => {
      genders.forEach(item => {
        const gender: Gender = {
          id: item.value,
          name: item.name
        };
        this.genderTypes.push(gender);
      });
    });
  }

  closeSidebar() {
    this.staffManagementService.closePanel();
  }

  get validateForm() { return this.staffMemberForm.controls; }

  saveUser() {
    this.processing = true;
    this.submitted = true;

    this.employmentTypes.forEach(item => {
      this.selectedEmpType.forEach(index => {
        if (index === item.employmentTypeId) {
          this.staffMemberForm.controls.employmentType.patchValue(item.employmentTypeId);
          this.staffMember.employmentType = item;
        }
      });
    });

    let staffTypeName = '';
    this.staffTypes.forEach(item => {
      this.selectedStaff.forEach(index => {
        if (index === item.staffTypeId) {
          this.staffMemberForm.controls.staffType.patchValue(item.staffTypeId);
          this.staffMember.staffType = item;
          staffTypeName = item.staffTypeName;
        }
      });
    });

    this.genderTypes.forEach(item => {
      this.selectedGender.forEach(index => {
        if (index === item.id) {
          this.staffMemberForm.controls.gender.patchValue(item.id);
          this.staffMember.gender = item;
        }
      });
    });

    if (this.staffMemberForm.invalid) {
      return;
    }

    const staff = new AllStaff();
    staff.firstName = this.staffMemberForm.controls.name.value;
    staff.lastName = this.staffMemberForm.controls.surname.value;
    staff.phone = this.staffMemberForm.controls.phone.value;
    staff.hireDate = this.staffMemberForm.controls.hireDate.value;
    staff.employmentType = this.employmentTypes.find(item => item.employmentTypeId === this.staffMemberForm.controls.employmentType.value);
    staff.staffType = this.staffTypes.find(item => item.staffTypeId === this.staffMemberForm.controls.staffType.value);
    staff.gender = this.genderTypes.find(item => item.id === this.staffMemberForm.controls.gender.value);
    staff.shiftDays = [];
    staff.staffType = this.staffTypes.find(item => item.staffTypeId === this.staffMemberForm.controls.staffType.value);

    const staffTypeNewModel: StaffTypeData = this.staffTypesNewModel.filter(function (each) {
      return each.abbreviation === staff?.staffType?.staffTypeName
    })[0];

    this.staffService.saveStaffMember(staff, staffTypeNewModel).pipe().subscribe(
      response => {
        if (!!String(response.code).match(/200|201/)) {
          this.googleAnalyticsService
            .eventEmitter(
              GoogleAnalyticsService._GetEvents().staff_management.category,
              GoogleAnalyticsService._GetEvents().staff_management.action.add_staff.name,
              GoogleAnalyticsService._GetEvents().staff_management.action.add_staff.label
            );

          const shiftToAdd: any[] = [];

          this.eachRowChosenData.forEach(row => {
            row.selectedDays.forEach(day => {
              if (row.selectedShifts !== 0 && row.selectedUnit !== 0) {
                let shiftToAddTemp: ShiftToAdd = {
                  'staffId': response.data.staffid,
                  'shiftId': row.selectedShifts,
                  'unitId': row.selectedUnit,
                  'dayOfTheWeek': day,
                  'weekNumber': null,
                  'startDate': moment(new Date()).format('YYYY-MM-DD'),
                  'endDate': null
                }
                shiftToAdd.push(shiftToAddTemp);
              }
            });
          });
          this._shiftJavaService.manageShifts(shiftToAdd).pipe().subscribe(resp => {
            this._snackBar.open(response.message, '', {
              duration: 2000,
            });
            this.staffManagementService.updateStaffTable.next(true);
            this.staffManagementService.closePanel();
          });
        } else {
          this._snackBar.open(response.message, '', {
            duration: 2000,
          });

          this.staffManagementService.updateStaffTable.next(true);
          this.staffManagementService.closePanel();
        }
        this.processing = false;
      },
      error => {
        this.processing = false;
        this.staffManagementService.updateStaffTable.next(true);
        this.staffManagementService.closePanel();
      }
    );
  }

  addNewEmptyShiftDayUnit() {
    const eachDayShiftChosenArray = {
      id: this.eachRowChosenData ? this.eachRowChosenData.length + 1 : 1,
      selectedDays: [],
      selectedShifts: 0,
      selectedUnit: 0,
      chosenDaysName: this.chosenDayName,
      chosenShiftName: this.chosenShiftName,
      chosenUnitName: this.chosenUnitName,
      showDayCard: false,
      showStaffCard: false,
      showUnitCard: false
    };

    this.eachRowChosenData.push(eachDayShiftChosenArray);
  }

  isValidUser() {

    this.employmentTypes.forEach(item => {
      this.selectedEmpType.forEach(index => {
        if (index === item.employmentTypeId) {
          this.staffMemberForm.controls.employmentType.patchValue(item.employmentTypeId);
        }
      });
    });

    this.staffTypes.forEach(item => {
      this.selectedStaff.forEach(index => {
        if (index === item.staffTypeId) {
          this.staffMemberForm.controls.staffType.patchValue(item.staffTypeId);
        }
      });
    });

    this.genderTypes.forEach(item => {
      this.selectedGender.forEach(index => {
        if (index === item.id) {
          this.staffMemberForm.controls.gender.patchValue(item.id);
        }
      });
    });

    if (this.staffMemberForm.invalid) {
      return false;
    }

    return true;
  }

  handleSelectableButtonValueChange(section: string, value: any) {
    switch (section) {
      case 'gender':
        this.selectedGender = value;

        if (!value || value?.length === 0) {
          this.staffMemberForm.controls.gender.patchValue(null);
        }
        break;
      case 'staff-type':
        this.selectedStaff = value;

        if (!value || value?.length === 0) {
          this.staffMemberForm.controls.staffType.patchValue(null);
        }
        break;
      case 'employment-type':
        this.selectedEmpType = value;

        if (!value || value?.length === 0) {
          this.staffMemberForm.controls.employmentType.patchValue(null);
        }
        break;
    }
  }

}
