import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuItemComponent } from './side-menu-item.component';

@NgModule({
  declarations: [SideMenuItemComponent],
  exports: [
    SideMenuItemComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SideMenuItemModule { }
