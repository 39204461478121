/**
 *   BoostApprovalQueue
 *   Retrieves the promoted requests from the Subject in BoostApprovalService and lists them in a sidebar
 *
 *   Inputs:
 *     N/A
 *
 *   Output:
 *     N/A
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BoostApprovalService } from '../../services/boost-approval.service';
import { delay, switchMap, tap, map } from 'rxjs/operators';
import { BoostRequestModel, RequestList } from '../../../models/BoostModels/BoostModels';
import { ManageUsersAccountService } from 'src/app/services/manage-users-account.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-boost-approval-queue',
  templateUrl: './boost-approval-queue.component.html',
  styleUrls: ['./boost-approval-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoostApprovalQueueComponent implements OnInit {


  activeMenu = true;
  pastCount = new BehaviorSubject<number>(0);
  activeCount = new BehaviorSubject<number>(0);

  constructor(
    private _boostApprovalService: BoostApprovalService,
    private _accountService: ManageUsersAccountService,
    private _cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  get requests$() {
    return this._boostApprovalService.boostRequests;
  }

  onRequestUpdate(request: BoostRequestModel, newStatus: string) {
    this._boostApprovalService.reviewRequest(
      request.reqdid || request.blockId,
      newStatus,
      this._accountService.currentUserValue.userid,
      !!request.blockId
    ).pipe(
      delay(10000),
      switchMap(() => {
        const req: any = {
          clientid: this._accountService.currentUserValue.activeusertype.clientid,
          userid: this._accountService.currentUserValue.userid
        };
        return this._boostApprovalService.getBoostRequests(req);
      })
    ).subscribe();
  }

  getTotalRequests(list: { date: Date, requests: BoostRequestModel[] }[]) {
    return list.map(req => req.requests).map(req => req.length).reduce((prev, curr) => curr + prev, 0);
  }

}
