import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillShiftResponsesComponent } from './fill-shift-responses.component';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../shared/material.module';
import { ShiftManagementFilterModule } from '../shift-management-filter/shift-management-filter.module';
import { ManagementTopCardModule } from '../management-top-card/management-top-card.module';
import { StaffCardExpandableModule } from '../../../shared/componets/staff-card-expandable/staff-card-expandable.module';
import { StaffCardConfirmationModule } from '../../../shared/componets/staff-card-confirmation/staff-card-confirmation.module';

@NgModule({
  declarations: [FillShiftResponsesComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ShiftManagementFilterModule,
    ManagementTopCardModule,
    StaffCardExpandableModule,
    StaffCardConfirmationModule
  ],
  exports: [
    FillShiftResponsesComponent
  ]
})
export class FillShiftResponsesModule {
}
