import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JavaPayload } from 'src/app/models/newModels/JavaPayload';
import { UtilsService } from '../utils/utils.service';
import { FacilityShiftType } from './models/facility-shift-type.model';

@Injectable({
  providedIn: 'root'
})

export class ShiftTypesService {
  shiftTypesReload$: Subject<void> = new Subject();
  private readonly _basedUri = `${UtilsService.getShiftJavaUri()}/shift`;

  constructor(
    private _http: HttpClient,
    private _snackbar: MatSnackBar
  ) { }

  /**
   * Emits an event to reload shift types list.
   */
  reloadShiftTypes(): void {
    this.shiftTypesReload$.next();
  }

  /***
   * Check if staff type is part of a shift type bucket
   * @param staffTypeId: given id of staff type to check
   */
  staffTypeExists(staffTypeId: number): Observable<boolean> {
    return this.getShiftTypes().pipe(
      map((shiftTypes) => {
        let exists = false;
        shiftTypes.forEach(shiftType => {
          shiftType.staffTypes.forEach(staffType => {
            if (staffType.staffTypeId === staffTypeId) {
              exists = true;
              return;
            }
          });
        });
        return exists;
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Get's list of all active shift types .
   */
  getShiftTypes(): Observable<FacilityShiftType[]> {
    const params: HttpParams = new HttpParams()
      .append('activeOnly', String(true));

    return this._http.get<JavaPayload<FacilityShiftType[]>>(`${this._basedUri}/shiftTypes`, { params }).pipe(
      map((response: JavaPayload<FacilityShiftType[]>) => response.data),
      catchError(error => {
        const errorMsg = error.error.errors[0]
          ? error.error.errors[0]
          : 'An error has occured. Please try again at a later time.';
        this._snackbar.open(errorMsg, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  getShiftTypesOnboarding(): Observable<FacilityShiftType[]> {
    return this._http.get<JavaPayload<FacilityShiftType[]>>(`${this._basedUri}/shiftTypes`).pipe(
      map((response: JavaPayload<FacilityShiftType[]>) => response.data),
      catchError(error => {
        const errorMsg = error.error.errors[0]
          ? error.error.errors[0]
          : 'An error has occured. Please try again at a later time.';
        this._snackbar.open(errorMsg, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Create's or Updates's shift type.
   * If shiftTypeId is not set, then new object/objects will be created.
   * if shiftTypeId is present, then existing object/objects will be updated.
   * @param updatedShiftType : Updated shift type object.
   */
  putShiftType(updatedShiftType: FacilityShiftType[]): Observable<FacilityShiftType[]> {
    return this._http.put<JavaPayload<FacilityShiftType[]>>(`${this._basedUri}/shiftTypes`, updatedShiftType).pipe(
      map((response: JavaPayload<FacilityShiftType[]>) => response.data),
      catchError(error => {
        const errorMsg = error.error.errors[0]
          ? error.error.errors[0]
          : 'An error has occured. Please try again at a later time.';
        this._snackbar.open(errorMsg, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * Puts the data back to the default, in case a change was made and would like reset it.
   * @param updatedShiftType : Updated shift type object.
   */
  resetShiftType(updatedShiftType: FacilityShiftType[]): Observable<FacilityShiftType[]> {
    return this._http.put<JavaPayload<FacilityShiftType[]>>(`${this._basedUri}/reset`, updatedShiftType).pipe(
      map((response: JavaPayload<FacilityShiftType[]>) => response.data),
      catchError(error => {
        const errorMsg = error.error.errors[0]
          ? error.error.errors[0]
          : 'An error has occured. Please try again at a later time.';
        this._snackbar.open(errorMsg, 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }
}
