import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestIntelyproCardComponent } from './request-intelypro-card.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { MaterialModule } from '../../../../../shared/material.module';
import { PointSeparatorModule } from '../../../../../shared/componets/point-separator/point-separator.module';

@NgModule({
  declarations: [RequestIntelyproCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    PointSeparatorModule
  ],
  exports: [
    RequestIntelyproCardComponent
  ]
})
export class RequestIntelyproCardModule { }
