import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewLiveUpdateService } from '../../../services/view-live-update.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'intelypro-checkbox',
  templateUrl: './intely-pro-checkbox.component.html',
  styleUrls: ['./intely-pro-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntelyProCheckboxComponent implements OnInit {

  @Input() checked = false;
  @Input() shiftTypeId: number;
  @Input() selectGender: boolean;
  @Output() selectionChange: EventEmitter<boolean> = new EventEmitter();
  @Output() staffTypeSelectionChange: BehaviorSubject<number> = new BehaviorSubject(null);
  @Output() genderSelectionChange: BehaviorSubject<string> = new BehaviorSubject('E');
  @Output() boostShiftSelections: BehaviorSubject<any> = new BehaviorSubject(null);
  expanded = false;
  subtypes: { name: string, value: number }[];
  defaultIntelyProStaffType: number;
  boostResponse: any;
  showBoostSummary: boolean;

  constructor(private _liveService: ViewLiveUpdateService) {
  }

  panelStatus(e) {
    this.showBoostSummary = e !== 'opened';
  }

  ngOnInit() {
    this.subtypes = this._liveService.getStaffSubTypes(this.shiftTypeId).intelyProStaffTypes.map(e => ({
      name: e.name,
      value: e.intelyProStaffTypeId
    }));

    this.subtypes.unshift(this.subtypes.splice(this.subtypes.findIndex(item => item.value === 4), 1)[0]);
    // display 'Nurse' as first radio button
    this.defaultIntelyProStaffType = this.subtypes[0].value;
    this.typeChange(this.defaultIntelyProStaffType);
  }

  changeSelection(checked: boolean) {
    this.checked = checked;
    this.expanded = checked && !!this.subtypes.length;
    this.selectionChange.next(this.checked);
    if (this.checked) {
      this.boostShiftSelections.next(this.boostResponse);
    }
  }

  getBoostResponse($event: any) {
    this.boostResponse = Object.assign({}, $event.boostResponse);
    if (this.checked) {
      this.boostShiftSelections.next(this.boostResponse);
    }
  }

  typeChange(value: number) {
    this.staffTypeSelectionChange.next(value);
  }

  changeGender(value: string) {
    this.genderSelectionChange.next(value);
  }
}
