import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MonthlyViewService } from 'src/app/monthly-view/services/monthly-view.service';

@Injectable({ providedIn: 'root' })
export class DeviceGuard implements CanActivate {

  handledUrl = ['home', 'dashboard', 'monthly', 'week', 'request-intelypro'];

  constructor(
    private _router: Router,
    private _deviceService: DeviceDetectorService,
    private _monthlyViewService: MonthlyViewService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let allow = true;
    let stateKeyUrl = '';

    this.handledUrl.forEach(item => {
      if (state.url.includes(item)) {
        stateKeyUrl = `/${item}`;
      }
    });

    switch (stateKeyUrl) {
      case '/monthly':
        if (this._isSmallDevice()) {
          let url;
          if (this._monthlyViewService.requestIp.value) {
            url = '/request-intelypro';
          } else {
            url = '/week';
          }
          this._router.navigateByUrl(url);
          allow = false;
        }
        break;
      case '/request-intelypro':
        if (!this._isSmallDevice()) {
          this._monthlyViewService.requestIp.next(true);
          this._router.navigateByUrl('/monthly');
          allow = false;
        }
        break;
      case '/week':
        if (!this._isSmallDevice()) {
          this._router.navigateByUrl('/monthly');
          allow = false;
        }
        break;
      case '/home':
        if (!this._isSmallDevice()) {
          this._router.navigateByUrl('/dashboard');
          allow = false;
        }
        break;
      case '/dashboard':
        if (this._isSmallDevice()) {
          this._router.navigateByUrl('/home');
          allow = false;
        }
        break;
    }
    return allow;
  }

  private _isSmallDevice(): boolean {
    const iFrameDetection = (window === window.parent) ? false : true;
    return this._deviceService.isMobile() || iFrameDetection;
  }
}
