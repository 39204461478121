<div class="shift-management">
  <div class="shift-management-wrapper" fxLayout="column" fxFlexFill>

    <div class="content" fxFlexFill fxFlex="0 1 auto" fxLayout="column" fxLayoutGap="16px">

      <div class="header" fxFlex="0 1 auto">
        <app-management-top-card
          [title]="!!replacing ? 'Fill Cancelled Shift' : 'Fill Open Shift'"
          [shiftDetails]="shiftDetails"
          [replacing]="replacing"
          [currentTab]="currentTab"
          [returnTo]="returnTo"
          (tabChange)="currentTab = $event"
          [callout]="shiftDetails?.statusID === shiftStatuses.callOut.id ? shiftDetails.callout?.description : ''">
        </app-management-top-card>
      </div>

      <div class="content-wrapper" fxLayout="column" fxFlex="0 1 auto">

        <!--############################################################################################################-->

        <div class="requests" fxFlex fxLayout="column" fxLayoutGap="12px"
             [ngClass]="{'tabbed-content': currentTab !== 'responses'}" fxFlexAlign="center center">

          <div class="responses-tab " fxFlex fxLayout="row">
            <button mat-button class="responses-tab-button accepted" fxFlex (click)="responsesTab = 'accepted'"
                    [ngClass]="{
            selected: responsesTab === 'accepted'
            }">accepted
            </button>
            <button mat-button class="responses-tab-button pending" fxFlex (click)="responsesTab = 'pending'"
                    [ngClass]="{
            selected: responsesTab === 'pending'
            }">pending
            </button>
            <button mat-button class="responses-tab-button rejected" fxFlex (click)="responsesTab = 'rejected'"
                    [ngClass]="{
            selected: responsesTab === 'rejected'
            }">rejected
            </button>
          </div>

          <div fxFlex="1 1 auto" *ngIf="shiftRequests[responsesTab] && shiftRequests[responsesTab].length > 0">
            <span class="total">{{shiftRequests[responsesTab].length}} Staff</span>
            <mat-divider></mat-divider>
          </div>

          <div class="content" fxLayout="column" fxLayoutGap="12px">
            <mat-list fxLayout="column" fxLayoutGap="12px">

              <app-staff-card-confirmation [staffMember]="getStaffMember(request.staffID)" [request]="request"
                                           [response]="responsesTab" (selectionChange)="setSelectedItem($event)"
                                           *ngFor="let request of shiftRequests[responsesTab]"></app-staff-card-confirmation>
            </mat-list>
          </div>

          <div *ngIf="(!shiftRequests[responsesTab] || !shiftRequests[responsesTab].length)">
            <div class="empty-message" *ngIf="responsesTab === 'accepted'" fxLayoutAlign="center center"
                 fxLayout="column">
              <span class="em-title">No One Has Accepted Your Shift Yet</span>
              <span class="em-subtitle">Staff who've accepted your shift request will appear here.</span>
              <span class="em-subtitle">Once they have, you can assign the shift to someone.</span>
            </div>

            <div class="empty-message" *ngIf="responsesTab === 'pending'" fxLayoutAlign="center center"
                 fxLayout="column">
              <span class="em-title">No Pending Requests</span>
              <span class="em-subtitle">
                Once you've requested staff they will appear until they accept or reject the tab.
              </span>
              <span class="em-subtitle">You can request staff from the Find Staff tab above.</span>
            </div>

            <div class="empty-message" *ngIf="responsesTab === 'rejected'" fxLayoutAlign="center center"
                 fxLayout="column">
              <span class="em-title">No Rejected Requests</span>
              <span class="em-subtitle">Staff who've rejected your shift request will appear here.</span>
            </div>
          </div>

        </div>

        <!--############################################################################################################-->
        <div class="user-search" [ngClass]="{'tabbed-content':currentTab !== 'search'}" fxLayoutGap="12px"
             fxLayout="column" fxFlex="0 1 auto">

          <div class="filter" fxFlex="0 1 auto">
            <shift-management-filter #ShiftManagementFilter (filterChange)="filter.next($event)"
                                     (blur)="showSuggestions()" (focus)="hideSuggestions()"
                                     (searchChange)="search.next($event)"
                                     [selectedFilter]="filterOptions"></shift-management-filter>
          </div>

          <div class="filter-options" fxLayout="column">

            <mat-chip-list fxLayout="row wrap" fxLayoutGap="10px" *ngIf="!searching && filterOptions && !searchCriteria.length && ((filterOptions.shift&&filterOptions.shift.length > 0) ||
               (filterOptions.employmentType&& filterOptions.employmentType.length > 0 ))">

              <mat-chip class="f-option" *ngFor="let s of filterOptions.shift" [selectable]="true" [removable]="true"
                        (removed)="removeFilterOpt('shift', s)">
                <span>{{shifts[s]}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>

              <mat-chip class="f-option" *ngFor="let u of filterOptions.employmentType" [selectable]="true"
                        [removable]="true" (removed)="removeFilterOpt('employmentType', u)">
                <span>{{employments[u]}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>

          </div>

          <div fxFlex="1 1 auto" *ngIf="!(searching && !searchCriteria)">
            <span class="total">{{total}} Staff
            </span>
          </div>

          <mat-divider></mat-divider>

          <div class="useful-options" fxFlex="0 1 auto" fxLayoutAlign="space-between center"
               *ngIf="unselectedStaff.length || selectedStaff.length">
            <mat-checkbox class="opt left" [checked]="areAllChecked" (change)="toggleAll($event.checked)">Select All
            </mat-checkbox>
            <button mat-button class="opt right" [matMenuTriggerFor]="sortBy" *ngIf="unselectedStaff.length">Sort By
              &nbsp;
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </div>

          <div class="results" fxFlex fxLayout="column" fxLayoutGap="12px">
<!-- HHP1-172 todo bring back for release 3 -->
<!--            <intelypro-checkbox [checked]="requestIntelyProSelected" [selectGender]="selectGender"-->
<!--                                *ngIf="showRequestIPOption()"-->
<!--                                [shiftTypeId]="shiftDetails.shiftTypeId"-->
<!--                                (selectionChange)="requestIntelyProSelected = $event"-->
<!--                                (staffTypeSelectionChange)="intelyproStaffType = $event"-->
<!--                                (genderSelectionChange)="selectedGender = $event"-->
<!--                                (boostShiftSelections)="shiftBoosts = $event"-->
<!--            ></intelypro-checkbox>-->

            <app-staff-card-expandable [staffMembers]="unselectedStaff" [control]="true"
                                       (selectionChange)="selectStaff($event)"></app-staff-card-expandable>
          </div>

          <div fxFlex *ngIf="!!searchCriteria && !unselectedStaff.length" class="no-results" fxLayoutGap="16px"
               fxLayoutAlign="center" fxLayout="column">
            <img src="assets/NoResult.svg">
            <span class="no-results-message">No Results</span>
          </div>

          <div fxFlex *ngIf="searching && !searchCriteria && !selectedStaff.length" class="no-results">
            <span class="start-typing-message" fxFlex fxLayoutAlign="center center">start typing to search all
              users...</span>
          </div>

        </div>

        <div [ngClass]="{'tabbed-content':currentTab !== 'addStaff'}">
          <app-add-new-staff (close)="onAddNewStaffClose($event)" [data]="manualStaffToAdd"></app-add-new-staff>
        </div>

      </div>
    </div>

    <div class="actions" fxFlex="84px" *ngIf="!(searching || currentTab === 'addStaff' ||
                                              (currentTab === 'responses' && responsesTab !== 'accepted') ||
                                              (currentTab === 'responses' && !!shiftRequests[responsesTab] && shiftRequests[responsesTab].length < 1)
                                              )">
      <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <button fxLayoutAlign="center center" class="shift-management-buttons shift-secondary-btn"
                [ngClass]="{ disabled: (!selectedStaff.length && !requestIntelyProSelected) || requestClicked }"
                [disabled]="(!selectedStaff.length && !requestIntelyProSelected) || requestClicked"
                *ngIf="currentTab === 'search'" (click)="sendRequestForShift()">
          <i class="far fa-comment-dots"></i>
          Send Text {{ !!(selectedStaff.length + (requestIntelyProSelected ? 1 : 0)) ?
          '(' + (selectedStaff.length + (requestIntelyProSelected ? 1 : 0)) + ')' : '' }}
        </button>

        <button fxLayoutAlign="center center" class="shift-management-buttons shift-primary-btn"
                [disabled]="selectedStaff.length !== 1 || isLoading"
                [ngClass]="{ disabled: selectedStaff.length !== 1 || isLoading }"
                *ngIf="currentTab === 'search' && !requestIntelyProSelected" (click)="assignStaffToShift()">
          <mat-icon>assignment_ind</mat-icon>
          Assign to This Shift
        </button>

        <button fxLayoutAlign="center center" class="shift-management-buttons shift-primary-btn"
                [ngClass]="{ disabled: !selectedRequest || isLoading }" [disabled]="!selectedRequest || isLoading"
                *ngIf="currentTab === 'responses'" (click)="confirmRequest()">
          <mat-icon>assignment_ind</mat-icon>
          Assign to This Shift
        </button>
      </div>
    </div>
  </div>
</div>


<mat-menu #sortBy>
  <button mat-menu-item (click)="sortByField('hours')"
          [ngClass]="{selected: sortCriteria === 'hours'}">Scheduled Hours
  </button>
  <button mat-menu-item (click)="sortByField('firstName')"
          [ngClass]="{selected: sortCriteria === 'firstName'}">First
    Name
  </button>
  <button mat-menu-item (click)="sortByField('lastName')"
          [ngClass]="{selected: sortCriteria === 'lastName'}">Last Name
  </button>
  <button mat-menu-item (click)="sortByField('staffType')"
          [ngClass]="{selected: sortCriteria === 'staffType'}">Staff
    Type
  </button>
</mat-menu>
