import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateShiftComponent } from './create-shift.component';
import { CustomDrawerHeaderModule } from 'src/app/shared/componets/custom-drawer-header/custom-drawer-header.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChipIconModule } from 'src/app/shared/componets/chip-icon/chip-icon.module';
import { CustomDrawerFooterModule } from 'src/app/shared/componets/custom-drawer-footer/custom-drawer-footer.module';
import { AmpmToggleModule } from 'src/app/shared/componets/ampm-toggle/ampm-toggle.module';
import { CustomShiftTimeDisplayModule } from 'src/app/shared/componets/custom-shift-time-display/custom-shift-time-display.module';



@NgModule({
  declarations: [CreateShiftComponent],
  imports: [
    CommonModule,
    CustomDrawerHeaderModule,
    CustomDrawerFooterModule,
    MaterialModule,
    SharedModule,
    ChipIconModule,
    AmpmToggleModule,
    CustomShiftTimeDisplayModule
  ],
  exports: [
    CreateShiftComponent
  ]
})
export class CreateShiftModule { }
