import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNewStaffComponent } from './add-new-staff.component';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../shared/material.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [AddNewStaffComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    NgxMaskModule.forRoot(options),
  ],
  exports: [
    AddNewStaffComponent
  ]
})
export class AddNewStaffModule { }
