import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestData } from '../../../models/newModels/RequestData';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';

@Component({
  selector: 'app-staff-card-confirmation',
  templateUrl: './staff-card-confirmation.component.html',
  styleUrls: ['./staff-card-confirmation.component.scss']
})
export class StaffCardConfirmationComponent implements OnInit {

  @Output() selectionChange: EventEmitter<RequestData> = new EventEmitter();
  @Input() staffMember: StaffMember;
  @Input() request: RequestData;
  @Input() response: 'accepted' | 'rejected' | 'pending';

  expanded = false;

  constructor() {
  }


  ngOnInit() {
  }

  formatNumber(number: string) {
    const phoneParts = [];
    phoneParts.push(number.substr(0, 3));
    phoneParts.push(number.substr(3, 3));
    phoneParts.push(number.substr(6, 4));

    return `(${phoneParts[0]}) ${phoneParts[1]}-${phoneParts[2]}`;
  }

}
