import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ShiftManagementSidebarService } from '../../shift-management-sidebar.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ViewLiveUpdateService } from '../../../services/view-live-update.service';
import * as moment from 'moment';
import { Constants } from '../../../helpers/Constants';
import { of, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, flatMap, takeUntil, tap } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../../shared/componets/confirmation-modal/confirmation-modal.component';
import { Shift } from '../../../models/shift-management-models/shift';
import { DatePipe } from '@angular/common';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';
import { ShiftsService } from '../../../services/apis/shifts.service';
/**
 * ManagementTopCardComponent has @Input() title which has the title of the component,
 * @Input() shiftDetails has details information of the shift.
 * @Input() callout has shift details callout.
 *  @Input() currentTab has the value of current tab.
 * When tab is changed @Output() tabChange emits the value changed ofthe tab.
 *
 */

@Component({
  selector: 'app-management-top-card',
  templateUrl: './management-top-card.component.html',
  styleUrls: ['./management-top-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapse', [
      state('void', style({
        height: '0px'
      })),
      state('*', style({
        height: '*'
      })),
      transition('void => *', animate('300ms ease-out')),
      transition('* => void', animate('100ms ease-in'))
    ])
  ]
})
export class ManagementTopCardComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() shiftDetails: Shift;
  @Input() replacing: StaffMember;
  @Input() callout: string;
  @Input() currentTab: 'search' | 'responses' | 'addStaff' = 'search';
  @Input() returnTo: 'requests' | 'allShifts';
  @Input() component: string;
  @Output() tabChange: EventEmitter<'search' | 'responses' | 'addStaff'> = new EventEmitter();

  // Private
  private _unsubscribeAll: Subject<any>;
  allOpenShifts: Shift[] = [];
  selectedIndex = -1;
  transformedData: {
    startHr: string,
    startMin: string,
    startAMPM: string,
    endHr: string,
    endMin: string,
    endAMPM: string,
  };

  constructor(
    private _shiftManagementService: ShiftManagementSidebarService,
    private _shiftJavaService: ShiftsService,
    private _liveUpdate: ViewLiveUpdateService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _cdr: ChangeDetectorRef,
    private datePipe: DatePipe) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._cdr.markForCheck();

    if (this.title !== 'Shift Details') {
      this._liveUpdate.updatedOpenShifts.pipe(
        tap(openedShifts => {
          this.allOpenShifts = openedShifts.sort((first, second) => {
            const firstTime = moment(first.shiftDate + ' ' + first.startTime).toDate().getTime();
            const secondTime = moment(second.shiftDate + ' ' + second.startTime).toDate().getTime();
            return firstTime > secondTime ? 1 : firstTime < secondTime ? -1 : 0;
          });
          this.allOpenShifts.forEach((a, i) => {
            if (a.shiftID === this.shiftDetails.shiftID) {
              this.selectedIndex = i;
            }
          });
          this._cdr.markForCheck();
        }),
        takeUntil(this._unsubscribeAll)
      ).subscribe();
    }
    this.transformDate(this.shiftDetails);
  }

  deleteShift() {
    const a = this.shiftDetails.statusID !== Constants.SHIFT_STATUS_DESCRIPTION.scheduled.id ? of(1) :
      this._dialog.open(ConfirmationModalComponent, {
        data: {
          title: null,
          content: `You are deleting this shift and removing ${this.shiftDetails.firstName + ' ' + this.shiftDetails.lastName}
           from the shift, they will be notified`,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Delete'
        }
      }).afterClosed().pipe(
        filter(e => !!e)
      );

    a.pipe(
      flatMap(e => this._shiftJavaService.deleteShift({ shiftId: String(this.shiftDetails.shiftID) })))
      .subscribe(e => {
        this._snackBar.open('Shift deleted successfully!', '', {
          duration: 2000
        }).afterDismissed().subscribe(res => {
          this._shiftManagementService.closePanel();
        });
      });
  }

  changeView(newView: 'search' | 'responses' | 'addStaff') {
    this.currentTab = newView;
    this.tabChange.emit(newView);
  }

  getStaffTypeName(shiftTypeId: number) {
    return this._liveUpdate.getStaffTypeName(shiftTypeId);
  }

  //Based on returnTo value the service will change the sidebar content.
  close() {
    switch (this.returnTo) {
      case 'requests':
        this._shiftManagementService.openRequests();
        break;
      case 'allShifts':
        this._shiftManagementService.openShiftsToFill();
        break;
      default:
        this._shiftManagementService.closePanel();
    }
  }
  //Changes the shift that is selected
  changeShift(shift: Shift) {
    this._shiftManagementService.toggleFillShiftPanel(shift);
  }

  goToPrevious() {
    this.selectedIndex = this.selectedIndex - 1;
    this._shiftManagementService.toggleFillShiftPanel(this.allOpenShifts[this.selectedIndex]);
  }

  goToNext() {
    this.selectedIndex = this.selectedIndex + 1;
    this._shiftManagementService.toggleFillShiftPanel(this.allOpenShifts[this.selectedIndex]);
  }

  changeShiftAfterAction() {
    if (this.allOpenShifts.length < 2) {
      this._shiftManagementService.closePanel();
    } else {
      let toGoIndex = -1;
      toGoIndex = this.selectedIndex === this.allOpenShifts.length - 1 ? this.selectedIndex - 1 : this.selectedIndex + 1;
      this._shiftManagementService.toggleFillShiftPanel(this.allOpenShifts[toGoIndex]);
    }
  }


  transformDate(shift: Shift) {
    const condition = shift.hasOwnProperty("isPartial") ? 'isPartial' : 'partial';
    const start = shift[condition] ? shift.shiftDate + " " + shift.partialShift.startTime : shift.shiftDate + " " + shift.startTime;
    const end = shift[condition] ? shift.shiftDate + " " + shift.partialShift.endTime : shift.shiftDate + " " + shift.endTime;

    this.transformedData = {
      startAMPM: this.datePipe.transform(new Date(start), 'a'),
      startHr: this.datePipe.transform(new Date(start), 'h'),
      startMin: this.datePipe.transform(new Date(start), 'mm'),
      endAMPM: this.datePipe.transform(new Date(end), 'a'),
      endHr: this.datePipe.transform(new Date(end), 'h'),
      endMin: this.datePipe.transform(new Date(end), 'mm')
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
