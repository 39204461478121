import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AllStaff } from '../../../models/AllStaff';
/**
 * AddNewStaffComponent @Input() data has the new staff to be added data.
 * @Output() close emits the added staff.
 */

@Component({
  selector: 'app-add-new-staff',
  templateUrl: './add-new-staff.component.html',
  styleUrls: ['./add-new-staff.component.scss']
})
export class AddNewStaffComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() data;

  addStaffForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    agencyName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    phoneNumber: new FormControl('')
  });

  constructor() {
  }

  addStaff() {
    const form = this.addStaffForm.getRawValue();
    const staff: AllStaff = {
      agencyname: form.agencyName,
      firstName: form.firstName,
      lastName: form.lastName,
      phone: form.phoneNumber
    };
    this.close.emit(staff);
  }

  ngOnInit() {
    this.addStaffForm.setValue(this.data);
  }

}
