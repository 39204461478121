import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  static getInternalBaseUri() {
    return environment.INTERNAL_URL.replace('?', location.host);
  }

  static getUserBaseUri() {
    return environment.USER_BASE_URL;
    // return this._isLocalhost() ? environment.USER_BASE_URL :
    //   environment.USER_BASE_URL.replace('?', location.host);
  }

  static getShiftBaseUri() {
    return environment.SHIFT_BASE_URL;
    // return this._isLocalhost() ? environment.SHIFT_BASE_URL :
    //   environment.SHIFT_BASE_URL.replace('?', location.host);
  }

  static getShiftJavaUri() {
    return environment.SHIFT_JAVA_URL;
  }

  static getShiftManagementJavaUri() {
    // return environment.SHIFT_MANAGEMENT_JAVA_URL;
    return environment.INTERNAL_URL + '/shift-management';
  }

  static getSchedulingBaseUri() {
    return environment.SCHEDULING_URL;
  }

  static getShiftUri() {
    return environment.SHIFT_URL;
  }

  static getICareUri() {
    return environment.ICARE_BASE_URL;
  }

  static getBackgroundCheckUri() {
    return environment.BACKGROUND_CHECK_URL;
  }

  static getBillingUri() {
    return `${environment.INTERNAL_URL}/billing`;
  }

  static getStaffBaseUri() {
    return environment.STAFF_BASE_URL;
    // return this._isLocalhost() ? environment.STAFF_BASE_URL :
    //   environment.STAFF_BASE_URL.replace('?', location.host);
  }

  static getAuthorizationBaseUri() {
    return environment.AUTH_BASE_URL;
    // return this._isLocalhost() ? environment.AUTH_BASE_URL :
    //   environment.AUTH_BASE_URL.replace('?', location.host);
  }

  static getTaskBaseUri() {
    return environment.TASK_BASE_URL;
    // return this._isLocalhost() ? environment.TASK_BASE_URL :
    //   environment.TASK_BASE_URL.replace('?', location.host);
  }

  static getSocketBaseUri() {
    return this._isLocalhost() ? environment.SOCKET_BASE_URL :
      environment.SOCKET_BASE_URL.replace('?', location.host);
  }

  static getISystemBaseUri() {
    return this._isLocalhost() ? environment.ISYSTEM_BASE_URL :
      environment.ISYSTEM_BASE_URL.replace('?', location.host);
  }

  static getISystemBypassGatewayBaseUri() {
    return environment.ISYSTEM_BYPASS_GATEWAY_BASE_URL;
  }

  static getInitialsFromName(name: string): string {
    if (!name) {
      return '';
    }
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  static getJobBoardUrl(token: string) {
    return `${environment.JOB_BOARD_URL}?token=${token}`;
  }

  static getPayrollUrl() {
    return environment.INTERNAL_URL + '/payroll';
  }

  private static _isLocalhost() {
    return false; // location.host === 'localhost:4222';
  }

}
