const minutes = Array.from(Array(60).keys());
const hours = Array.from({ length: 12 }, (_, i) => i + 1);
export const TimeConstants = {
  HOURS: hours.map(hour => String(hour)),
  MINUTES: minutes.map(minute => {
    if (minute < 10) {
      return '0' + minute;
    }
    return String(minute);
  }),
  FORMAT: [
    'AM', 'PM'
  ]
};
