import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffRequestsResponsesComponent } from './staff-requests-responses.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { MaterialModule } from '../../../../../shared/material.module';
import { StaffCardConfirmationModule } from '../../../../../shared/componets/staff-card-confirmation/staff-card-confirmation.module';

@NgModule({
  declarations: [StaffRequestsResponsesComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    StaffCardConfirmationModule
  ],
  exports: [StaffRequestsResponsesComponent]
})
export class StaffRequestsResponsesModule {
}
