import { Component } from '@angular/core';
import { StaffModuleManagementService } from '../../staff-module-management.service';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss']
})
export class AddStaffComponent {
  clickedConfirm = false;
  selectedFormDisplay: number = 1;
  radioButtonDisplay: boolean;

  constructor(
    private staffManagementService: StaffModuleManagementService
  ) { }

  /**
   * radio button set value display individual staff form.
   * @param val 1 or 2.
   */
  setStaffForm(val): void {
    this.selectedFormDisplay = val;
  }

  /**
   * close individual staff form.
   */
  closeSidebar() {
    this.staffManagementService.closePanel();
  }

  /**
   * Navigate to file upload page
   */
  navigatPage(page: number): void {
    this.selectedFormDisplay = page;
  }
}
