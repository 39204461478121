import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  header: string;
  message: string;
  firstLabel: string;
  firstValue: any;
  secondLabel: string;
  secondValue: any;
  globalSettings: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.header = data.header;
    this.message = data.message;
    this.firstLabel = data.firstLabel;
    this.firstValue = data.firstValue;
    this.secondLabel = data.secondLabel;
    this.secondValue = data.secondValue;
    this.globalSettings = data.globalSettings;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
