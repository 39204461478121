import { Injectable } from '@angular/core';
import { GoogleAnalyticsEvents } from '../models/GoogleAnalyticsEvents';
import { environment } from '../../environments/environment';
import { JWTPayloadData } from '../models/newModels/JWTPayloadData';
import { ManageUsersAccountService } from './manage-users-account.service';

declare let gtag: Function; // Declare ga as a function

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private _currentUser: JWTPayloadData;

  constructor(private _userService: ManageUsersAccountService) {

    this._userService.currentUserSubject.subscribe(e => this._currentUser = e);
  }

  static _GetEvents(): GoogleAnalyticsEvents {
    return new GoogleAnalyticsEvents();
  }

  public eventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (environment.ALLOW_ANALYTICS) {
      gtag('event', eventAction, {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'event_value': eventValue,
        'facility_id': this._currentUser ? this._currentUser.activeusertype ?
          String(this._currentUser.activeusertype.clientid) : '' : '',
        'metric_facility_id': this._currentUser ? this._currentUser.activeusertype ?
          String(this._currentUser.activeusertype.clientid) : '' : '',
        'custom_map': {
          'metric1': 'metric_facility_id',
          'dimension2': 'facility_id'
        }
      });
    }
  }
}
