<div fxLayout="column" *ngIf="requests$ | async as requests">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="110px">
        <div class="nav-item" [class.active]="activeMenu"
            [matBadge]="getTotalRequests(requests.active)"
            matBadgePosition="after" matBadgeColor="warn" matBadgeSize="small"
            (click)="activeMenu = true">
            Open
            <div [class.indicator]="activeMenu"></div>
        </div>
        <div class="nav-item" [class.active]="!activeMenu"
            [matBadge]="getTotalRequests(requests.past)"
            matBadgePosition="after" matBadgeColor="warn" matBadgeSize="small"
            (click)="activeMenu = false">
            Closed
            <div [class.indicator]="!activeMenu"></div>
        </div>
    </div>
    <div class="boost-information">
        <ng-container *ngIf="activeMenu; else pastRequests">
            <p class="sub-title">Review your notifications below</p>
            <p class="main-title">Pending Boosted Shifts</p>
        </ng-container>
        <ng-template #pastRequests>
            <p class="sub-title">Review all past requests below</p>
            <p class="main-title">Closed Requests</p>
        </ng-template>
    </div>
    <mat-divider></mat-divider>
    <div>
        <div *ngIf="activeMenu">
            <ng-container *ngIf="requests.active.length; else emptyList">
                <ng-container *ngFor="let requestTime of requests.active; let index = index;">
                    <p class="request-date">{{ requestTime.date | date: 'EEE, MMM dd, yyyy' }}</p>
                    <app-request-card *ngFor="let request of requestTime.requests; let i = index"
                        [active]="true" [request]="request" [index]="index + i"
                        (action)="onRequestUpdate(request, $event)">
                    </app-request-card>
                </ng-container>
                <ng-container *ngTemplateOutlet="endOfList"></ng-container>
            </ng-container>
        </div>
        <div *ngIf="!activeMenu">
            <ng-container *ngIf="requests.past.length; else emptyList">
                <ng-container *ngFor="let requestTime of requests.past; let index = index;">
                    <p class="request-date">{{ requestTime.date | date: 'EEE, MMM dd, yyyy' }}</p>
                    <app-request-card *ngFor="let request of requestTime.requests; let i = index"
                        [active]="false" [request]="request" [index]="index + i">
                    </app-request-card>
                </ng-container>
                <ng-container *ngTemplateOutlet="endOfList"></ng-container>
            </ng-container>
        </div>

        <ng-template #emptyList>
            <div class="text-center" style="margin-top: 45px;">
                <img src="/assets/images/happynotification.png" alt="Happy Notification" height="56px" width="88px">
                <p class="list-info">You have no new boosted shifts pending.</p>
            </div>
        </ng-template>

        <ng-template #endOfList>
            <div class="text-center" style="margin-top: 45px;">
                <img src="/assets/images/happynotification.png" alt="Happy Notification" height="56px" width="88px">
                <p class="list-info">You’re all caught up.</p>
            </div>
        </ng-template>
    </div>
</div>