import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { GoogleAnalyticsService } from '../../../../../services/googleanalytics.service';
import { GoogleAnalyticsEvents } from '../../../../../models/GoogleAnalyticsEvents';
import { FILE_UPLOAD_IMAGE_PATHS } from 'src/app/shared/constants/file-upload-image-constant';

@Component({
  selector: 'app-csv-file-upload',
  templateUrl: './csv-file-upload.component.html',
  styleUrls: ['./csv-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsvFileUploadComponent {
  @Input() usedInsideApp = false;
  @Input() isDisabled: boolean = false;
  @Output() file = new EventEmitter<any>();
  @Output() fileData = new EventEmitter<any>();
  @Output() fileName = new EventEmitter<string>();
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  currentPath = FILE_UPLOAD_IMAGE_PATHS.NORMAL_STATE_PATH;
  isInvalidFile: Boolean = false;

  private _analyticsEvents = new GoogleAnalyticsEvents();

  readonly FILE_UPLOAD_IMAGE_PATHS = FILE_UPLOAD_IMAGE_PATHS;

  constructor(private _cdr: ChangeDetectorRef, private _analyticsService: GoogleAnalyticsService) { }

  uploadFile(files: File[]) {
    this.isInvalidFile = false;
    const fileToUpload = files[0];
    if (!!fileToUpload && this.isCSVFile(fileToUpload)) {
      this.fileName.emit(fileToUpload.name);
      this.fileData.emit(fileToUpload);
      const reader = new FileReader();
      reader.readAsBinaryString(fileToUpload);
      reader.onload = (e) => {
        this.file.emit(reader.result);
      };
    } else {
      this.isInvalidFile = true;
      this.currentPath = this.FILE_UPLOAD_IMAGE_PATHS.ERROR_STATE_PATH;
      setTimeout(() => {
        this.currentPath = this.FILE_UPLOAD_IMAGE_PATHS.NORMAL_STATE_PATH;
        this._cdr.markForCheck();
      }, 3000);
    }
    this.fileInput.nativeElement.files = new DataTransfer().files;
    this.fileInput.nativeElement.value = [];
  }

  private isCSVFile(file: File) {
    // List of types allowed
    return [
      'text/csv',
      'application/csv',
      'text/x-csv',
      'application/x-csv',
      'text/x-comma-separated-values',
      'text/comma-separated-values',
      'application/vnd.ms-excel'
    ].includes(file.type);
  }

  updateGoogleAnalytics() {
    this._analyticsService.eventEmitter(this._analyticsEvents.onboarding.category,
      this._analyticsEvents.onboarding.action.download_csv_template.name,
      this._analyticsEvents.onboarding.action.download_csv_template.label);
  }

  /**
   * On browse click change file background image to browse file upload image
   */
  changeToBrowseImage(): void {
    if (!this.isDisabled) {
      this.currentPath = FILE_UPLOAD_IMAGE_PATHS.BROWSE_STATE_PATH;
    }
  }
}
