import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string;
    content: string;
    confirmButtonText: string;
    cancelButtonText: string;
  },
    private _dialogRef: MatDialogRef<ConfirmationModalComponent>) {
  }

  ngOnInit() {
  }

  confirm() {
    this._dialogRef.close(true);
  }

  cancel() {
    this._dialogRef.close(false);
  }

}
