import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ManageUsersAccountService } from '../manage-users-account.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: ManageUsersAccountService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authenticationService.currentUserSubject.pipe(
      map(user => {
        if (!!user) {
          this.router.navigateByUrl(this.authenticationService.navigateToLandingPage(user.activeusertype.usertypeid));
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
