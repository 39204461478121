import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfigService } from '@theme/services/config.service';
import { Router } from '@angular/router';
import { ManageUsersAccountService } from '../services/manage-users-account.service';
import { GoogleAnalyticsService } from '../services/googleanalytics.service';
import { ValidatePasswordComponent } from '../shared/componets/validate-password/validate-password.component';
import { UserTypes } from '../config/user-types';

@Component({
  selector: 'app-mobile-forgot-password',
  templateUrl: './mobile-forgot-password.component.html',
  styleUrls: ['./mobile-forgot-password.component.scss']
})
export class MobileForgotPasswordComponent implements OnInit {
  @Input() userTypeId = UserTypes.Staff;
  @ViewChild(ValidatePasswordComponent) child: ValidatePasswordComponent;
  emailSubmitted = false;
  recoverSubmitted = false;
  processing = false;
  emailForm: FormGroup;
  recoverForm: FormGroup;
  verificationCodeFocus = false;
  givenEmail = '';
  firstEmailSent = false;
  passMatch = false;
  hide = true;
  hideConfirm = true;

  constructor(
    public googleAnalyticsService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar,
    private emailFormBuilder: FormBuilder,
    private resetFormBuilder: FormBuilder,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _accountService: ManageUsersAccountService,
    private _cdr: ChangeDetectorRef
  ) {
    if (this._accountService.currentUserValue) {
      this.router.navigateByUrl(
        this._accountService.navigateToLandingPage(this._accountService.currentUserValue.activeusertype.usertypeid)
      );
    }

    const config = this._fuseConfigService.defaultConfig;
    config.layout.toolbar.hidden = true;
    this._fuseConfigService.setConfig(config);
  }

  ngOnInit() {
    this.emailForm = this.emailFormBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]]
    });

    this.recoverForm = this.resetFormBuilder.group({
      code: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}')
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}')
      ]]
    });
  }

  get validateEmailForm() { return this.emailForm.controls; }

  get validateRecoverForm() { return this.recoverForm.controls; }

  sendCode(email: string) {
    this.emailSubmitted = true;

    if (this.emailForm.invalid) {
      return;
    }

    this.processing = true;
    this._accountService.sendRecoveryCode(email).pipe().subscribe(response => {
      if (!!String(response.body.code).match(/200|201/)) {
        this.givenEmail = email;
        this.firstEmailSent = true;
        this.processing = false;
      } else {
        this._snackBar.open(response.body.message, '', {
          duration: 2000,
          panelClass: ['mobile-blue-snackbar']
        }).afterDismissed().subscribe(result => {
          this.processing = false;
          this._cdr.detectChanges();
        });
      }
    }, error => {
      this._snackBar.open(error.error.message, '', {
        duration: 2000,
        panelClass: ['mobile-blue-snackbar']
      }).afterDismissed().subscribe(result => {
        this.processing = false;
        this._cdr.detectChanges();
      });
    });
  }

  resetPassword() {
    this.recoverSubmitted = true;

    if (this.recoverForm.invalid || !this.passMatch) {
      return;
    }
    this.processing = true;
    this._accountService.verifyRecoveryCode(
      this.recoverForm.controls.code.value, this.givenEmail, this.recoverForm.controls.password.value
    ).pipe().subscribe(response => {
      this.googleAnalyticsService
        .eventEmitter(
          GoogleAnalyticsService._GetEvents().user_management.category,
          GoogleAnalyticsService._GetEvents().user_management.action.reset_password.name,
          GoogleAnalyticsService._GetEvents().user_management.action.reset_password.label
        );

      this._snackBar.open(response.body.message, '', {
        duration: 2000,
        panelClass: ['mobile-blue-snackbar']
      }).afterDismissed().pipe().subscribe(item => {
        if (!!String(response.body.code).match(/200|201/)) {
          this.router.navigateByUrl(this._accountService.getLoginUrlFromUserType(this.userTypeId));
        }

        this.processing = false;
        this._cdr.detectChanges();
      });
    }, error => {
      this._snackBar.open(error?.error?.message, '', {
        duration: 2000,
        panelClass: ['mobile-blue-snackbar']
      }).afterDismissed().pipe().subscribe(item => {
        this.processing = false;
        this._cdr.detectChanges();
      });
    });
  }

  isValidResetForm(): boolean {
    if (this.recoverForm.invalid || !this.passMatch) {
      return false;
    }

    return true;
  }

  checkButtonColor(form: string) {
    if (form === 'reset' && this.isValidResetForm() && !this.processing) {
      return '#1885B7';
    }

    if (form === 'email' && !this.emailForm.invalid && !this.processing) {
      return '#1885B7';
    }

    return '#A2A4A7';
  }
}
