import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntelyProCheckboxComponent } from './intely-pro-checkbox.component';
import { SharedModule } from '../../shared.module';
import { ChipIconModule } from '../chip-icon/chip-icon.module';
import { MaterialModule } from '../../material.module';

@NgModule({
  declarations: [IntelyProCheckboxComponent],
  imports: [
    CommonModule,
    SharedModule,
    ChipIconModule,
    MaterialModule
  ],
  exports: [
    IntelyProCheckboxComponent
  ]
})
export class IntelyProCheckboxModule { }
