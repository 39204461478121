<div class="intelypro-checkbox">
  <mat-expansion-panel fxLayout="column" (opened)="panelStatus('opened')" (closed)="panelStatus('closed')"
                       [expanded]="expanded" [disabled]="!subtypes.length && !selectGender">
    <mat-expansion-panel-header class="intelypro-checkbox-header"
                                (click)="!!subtypes.length || selectGender ? '' : changeSelection(!checked)"
                                [expandedHeight]="'100px'" [collapsedHeight]="'100px'">
      <div class="header-content" fxFlex="1 1 auto" fxLayoutAlign="start center">
        <div class="intelypro-checkbox-actions">
          <mat-checkbox [checked]="checked" (click)="$event.stopPropagation();"
                        (change)="changeSelection($event.checked)">
          </mat-checkbox>
        </div>

        <div class="intelypro-checkbox-chip">
          <app-chip-icon [iconMN]="'intelypro'" [customIcon]="true"></app-chip-icon>
        </div>

        <div class="intelypro-info" fxLayout="column" fxFlex="1 1 auto" fxLayoutAlign="start">
          <span class="bold-item title">Any IntelyPro</span>
        </div>
      </div>
      <div *ngIf="boostResponse" class="header-summary">
        <app-boost-summary [boostResponse]="boostResponse"></app-boost-summary>
      </div>
    </mat-expansion-panel-header>


    <div class="expansion-panel-body">
      <div class="staff-subtypes-values" fxLayout="column" fxLayoutGap="8px" *ngIf="!!subtypes.length || selectGender">

        <div *ngIf="!!subtypes.length" fxLayout="column" fxLayoutGap="8px">
          <span class="staff-subtypes-values-title">Role</span>

          <mat-radio-group [value]="defaultIntelyProStaffType" fxLayoutGap="16px" (change)="typeChange($event.value)">
            <mat-radio-button *ngFor="let a of subtypes" [value]="a.value">{{a.name}}</mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="selectGender" fxLayout="column" fxLayoutGap="8px">
          <span class="staff-subtypes-values-title">Gender</span>

          <mat-radio-group fxLayoutGap="16px" [value]="'E'" (change)="changeGender($event.value)">
            <mat-radio-button [value]="'E'">Male/Female</mat-radio-button>
            <mat-radio-button [value]="'F'">Female</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
    </div>


    <app-boost-shift [autoToggle]="true" (emitter)="getBoostResponse($event)"></app-boost-shift>

  </mat-expansion-panel>
</div>
