import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseResponseModel } from '../models/BaseResponseModel';
import { GenderData } from '../models/newModels/GenderData';
import { StaffTypeData } from '../models/newModels/StaffTypeData';
import { EmploymentTypeData } from '../models/newModels/EmploymentTypeData';
import { UnitData } from '../models/newModels/UnitData';
import { DaysData } from '../models/newModels/DaysData';
import { UtilsService } from './utils/utils.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

/**
 * Gets some aditional information from database
 */
@Injectable({
  providedIn: 'root'
})
export class AditionalDataService {

  private _baseurl = `${UtilsService.getStaffBaseUri()}`;
  private _request = {
    service: environment.SERVICE
  };

  constructor(private _http: HttpClient) {
  }

  getStaffTypes(): Observable<BaseResponseModel<StaffTypeData[]>> {
    return this._http.get<BaseResponseModel<StaffTypeData[]>>(this._baseurl + '/stafftype').pipe(
      map(e => {
        e.data = e.data.filter(item => item.hidden === false && item.canadd === true);
        return e;
      })
    );
  }

  getEmploymentType(): Observable<BaseResponseModel<EmploymentTypeData[]>> {
    return this._http.post<BaseResponseModel<EmploymentTypeData[]>>(`${this._baseurl}/get/employmenttype/all`, this._request);
  }

  getGenderTypes(): Observable<BaseResponseModel<GenderData[]>> {
    return this._http.post<BaseResponseModel<GenderData[]>>(`${this._baseurl}/get/gender/all`, {});
  }

  getUnits(): Observable<BaseResponseModel<UnitData[]>> {
    return this._http.post<BaseResponseModel<UnitData[]>>(`${this._baseurl}/get/unit/all`, this._request);
  }

  getDays(): Observable<BaseResponseModel<DaysData[]>> {
    const requestBody = {
      'service': environment.SERVICE
    };

    return this._http.post<BaseResponseModel<DaysData[]>>(`${this._baseurl}/get/dayofweek/all`, requestBody);
  }

  getEndDateOptions(): Observable<any> {
    return this._http.get('./assets/random-data/endDateOptions.json');
  }

}
