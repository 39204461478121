<div class="cart-sidebar" *ngIf="!isDevice; else mobileCartView">

  <div class="cart-sidebar-header" fxFlex="64px">
    <span class="title">Shift Request Summary</span>
    <button automation-id="shift-request-summary-close" (click)="close()" mat-icon-button>
      <i class="fal fa-times"></i>
    </button>
  </div>

  <div class="cart-sidebar-content">
    <div *ngIf="!cartItems.length" class="no-items-in-cart">
      No items in your cart
    </div>

    <div class="cart-items" *ngIf="cartItems.length">
      <div *ngFor="let item of cartItems" class="single-booking">
        <table>
          <thead>
          <tr class="title">
            <th class="custom">Shift Time & Floor</th>
            <th class="custom">IntelyPros</th>
            <th class="custom">Shift Dates</th>
            <th class="custom">
              <div class="actions">
                <button automation-id="update-item" (click)="updateItem(item)" class="update" mat-icon-button>
                  <i class="fal fa-user-edit"></i>
                </button>
                <button automation-id="delete-item" (click)="deleteItem(item)" class="delete" mat-icon-button>
                  <i class="fal fa-trash-alt"></i>
                </button>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td fxFlex="34%">
              <div class="cart-shift-time">
                <span class="bold">{{item.shiftTime.shiftName}}</span>
                <span>{{item.unit?.floorName}}</span>
              </div>
            </td>
            <td fxFlex="27%">
              <div class="item-staffTypes">
                <div *ngFor="let st of item.staffTypes || []" class="staff-type">
                  <span *ngIf="st.staffType.stateSpecificLabel?.length > 0">
                    <span *ngFor="let state of st.staffType?.stateSpecificLabel">
                      <span class="bold">{{st.count}} {{state.shiftTypeLabel}} </span>
                    </span>
                  </span>
                  <span *ngIf="!st.staffType.stateSpecificLabel?.length">
                    <span class="bold">{{st.count}} {{st.staffType.shiftTypeLabel}}</span>
                  </span>
                  <span class="small">{{st.gender !== 'F' ? 'Male or Female' : 'Female'}}</span>
                </div>
              </div>
            </td>
            <td fxFlex="auto">
              <div class="days">
                <span *ngFor="let day of item.days">{{day | date:'E LLL d'}}</span>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th>Total: {{getTotal(item)}}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="summary">
      <div class="summary-header">
        <span class="summary-title title">SUMMARY</span>
      </div>
      <div class="summary-content">
        <div class="total">
          <div class="total-header">
            <span class="total-title subtitle">Total Shifts Requested</span>
          </div>
          <div class="total-content content">
            <span class="title">{{totalIntelypros}}</span>
          </div>
        </div>
        <div class="total-per-staff-type">
          <div class="total-per-staff-type-header">
            <span class="total-per-staff-type-title subtitle">Total IntelyPros Requested</span>
          </div>
          <div class="total-per-staff-type-content content">
            <span *ngFor="let el of totalPerStaffType | keyvalue" class="title">
              {{el.value.total}}
              <span *ngIf="el.value.staffType?.stateSpecificLabel?.length">
                <span *ngFor="let state of el.value.staffType?.stateSpecificLabel">
                    {{state.shiftTypeLabel}}
                </span>
              </span>
              <span *ngIf="!el.value.staffType?.stateSpecificLabel?.length">
                {{el.value.staffType.shiftTypeLabel}}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-alert12-hour-notification></app-alert12-hour-notification>
  </div>

  <div class="cart-sidebar-actions" fxFlex="94px">
    <button [disabled]="!cartItems.length || requesting" [ngClass]="cartItems.length && !requesting ? 'complete' : 'disabled'"
            automation-id="submit-request" class="action-button complete" mat-button (click)="submitRequest()">
            <label *ngIf="!requesting">SUBMIT REQUESTS</label>
            <img *ngIf="requesting" src="/assets/images/loader.gif" height="30" width="30" />
    </button>
  </div>

</div>

<ng-template #mobileCartView>
  <div class="cart-sidebar">
    <app-mobile-cart-sidebar class="mobile-cart-summary" [cartItems]="cartItems" [totalPerStaffType]="totalPerStaffType"
      [totalIntelypros]="totalIntelypros" [requesting]="requesting" (submitRequest)="submitRequest()"
      (updateItem)="updateItem($event)" (deleteItem)="deleteItem($event)"></app-mobile-cart-sidebar>
  </div>
</ng-template>