<div class="drawer-header" fxLayout="column">
  <div class="drawer-header-title" fxLayout="row" fxLayoutAlign="start center">
    <div class="card-title-wrapper" fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between center">
        <button [attr.automation-id]="'close-create-shift-icon'" (click)="closeDrawer()" class="close-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>

        <div class="title">
          <span class="bold-item">{{title}}</span>
        </div>
    </div>
  </div>
</div>
