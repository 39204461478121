import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { StartOfWeek } from 'src/app/models/shift-management-models/StartOfWeek';
import { ShiftManagementService } from 'src/app/services/shift-management.service';
import { StaffModuleManagementService } from 'src/app/staff-management/staff-module-management.service';

@Component({
  selector: 'app-confirm-facility-schedule-details',
  templateUrl: './confirm-facility-schedule-details.component.html',
  styleUrls: ['./confirm-facility-schedule-details.component.scss']
})
export class ConfirmFacilityScheduleDetailsComponent implements OnInit {
  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter(false);
  clickedConfirm
  constructor(private shiftService: ShiftManagementService, private router: Router, private staffManagementService: StaffModuleManagementService) { }
  startWeek: StartOfWeek;

  ngOnInit(): void {
    this.shiftService.getWeekStartAndDOW().pipe(map(res => res.data)).subscribe(res => this.startWeek = res)
  }
  confirmAndContinue() {
    this.clickedConfirm = true;
    this.clicked.emit(true);
  }
  navigateToSettings() {
    this.router.navigateByUrl('/settings/start-day');
    this.staffManagementService.closePanel();
  }

}
