import { Injectable } from '@angular/core';
import { DailyViewConfigModel, DateRange } from '../models/daily-view-config-model';
import * as moment from 'moment';
import { ManageUsersAccountService } from './manage-users-account.service';
import { filter, } from 'rxjs/operators';
import { DailyViewService } from './daily-view.service';
/**
 * ViewsStateManagerService manages the actions when changing the date in calendar
 */

@Injectable({
  providedIn: 'root'
})
export class ViewsStateManagerService {
  public static DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

  dailyViewConfig: DailyViewConfigModel = {};
  startDayOfTheWeek = this._accountService.getDOW;

  constructor(
    private _dailyViewService: DailyViewService,
    private _accountService: ManageUsersAccountService
  ) {
    this._dailyViewService.dailyViewConfig.pipe(
      filter(e => !!e)
    ).subscribe(e => {
      this.dailyViewConfig = e;
    });
  }

  changeDateRange(dateRange: DateRange, from?: number, to?: number, current?: number) {
    if (!this.dailyViewConfig) {
      this.dailyViewConfig = {
        dateRange: 'daily',
        viewType: 'shift',
        date: {
          currentDate: 0,
          from: 0,
          to: 0
        }
      };
    }
    this.dailyViewConfig.dateRange = dateRange;
    dateRange === 'daily' ? this._setDaily() : dateRange === 'weekly' ? this._setWeekly() : dateRange === 'monthly' ?
      this._setMonthly() : this._setCustom(from, to, current);
  }

  goForward() {
    let newConfig;
    switch (this.dailyViewConfig.dateRange) {
      case 'daily':
        newConfig = this._calculateDailyConfig(
          moment(this.dailyViewConfig.date.currentDate + ViewsStateManagerService.DAY_IN_MILLIS).toDate()
        );
        break;
      case 'weekly':
        newConfig = {
          from: this.dailyViewConfig.date.from + 7 * ViewsStateManagerService.DAY_IN_MILLIS,
          to: this.dailyViewConfig.date.to + 7 * ViewsStateManagerService.DAY_IN_MILLIS,
          currentDate: this.dailyViewConfig.date.from + 7 * ViewsStateManagerService.DAY_IN_MILLIS
        };
        break;
      case 'monthly':
        const m = moment(this.dailyViewConfig.date.from).add(1, 'months');
        newConfig = {
          from: m.clone().startOf('month').toDate().getTime(),
          to: m.clone().endOf('month').toDate().getTime(),
          currentDate: m.clone().startOf('month').toDate().getTime()
        };
        break;
      case 'custom':
        const diff = this.dailyViewConfig.date.to - this.dailyViewConfig.date.from;
        newConfig = {
          from: this.dailyViewConfig.date.from + diff + ViewsStateManagerService.DAY_IN_MILLIS,
          to: this.dailyViewConfig.date.to + diff + ViewsStateManagerService.DAY_IN_MILLIS,
          currentDate: this.dailyViewConfig.date.from + diff + ViewsStateManagerService.DAY_IN_MILLIS
        };
        break;
      default:
        newConfig = this.dailyViewConfig.date;
    }
    this.dailyViewConfig.date = newConfig;
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  goBackwards() {
    let newConfig;
    switch (this.dailyViewConfig.dateRange) {
      case 'daily':
        newConfig = this._calculateDailyConfig(
          moment(this.dailyViewConfig.date.currentDate - ViewsStateManagerService.DAY_IN_MILLIS).toDate()
        );
        break;
      case 'weekly':
        newConfig = {
          from: this.dailyViewConfig.date.from - 7 * ViewsStateManagerService.DAY_IN_MILLIS,
          to: this.dailyViewConfig.date.to - 7 * ViewsStateManagerService.DAY_IN_MILLIS,
          currentDate: this.dailyViewConfig.date.from - 7 * ViewsStateManagerService.DAY_IN_MILLIS
        };
        break;
      case 'monthly':
        const m = moment(this.dailyViewConfig.date.from).add(-1, 'months');
        newConfig = {
          from: m.clone().startOf('month').toDate().getTime(),
          to: m.clone().endOf('month').toDate().getTime(),
          currentDate: m.clone().startOf('month').toDate().getTime()
        };
        break;
      case 'custom':
        const diff = this.dailyViewConfig.date.to - this.dailyViewConfig.date.from;
        newConfig = {
          from: this.dailyViewConfig.date.from - diff - ViewsStateManagerService.DAY_IN_MILLIS,
          to: this.dailyViewConfig.date.to - diff - ViewsStateManagerService.DAY_IN_MILLIS,
          currentDate: this.dailyViewConfig.date.from - diff + ViewsStateManagerService.DAY_IN_MILLIS
        };
        break;
      default:
        newConfig = this.dailyViewConfig.date;
    }
    this.dailyViewConfig.date = newConfig;
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  //When clicking to a day in calendar this method manages changing the screen for the specific selected date
  goToDate(date: moment.Moment, dateRangeParam?: DateRange) {
    this.dailyViewConfig.date = this.dailyViewConfig.date || {};
    this.dailyViewConfig.date.currentDate = date.clone().toDate().getTime();
    const dateRange = dateRangeParam || this.dailyViewConfig.dateRange;
    switch (dateRange) {
      case 'daily':
        this.dailyViewConfig.date = this._calculateDailyConfig(
          moment(this.dailyViewConfig.date.currentDate).toDate()
        );
        break;
      case 'weekly':
        this.dailyViewConfig.date.from = date.clone().startOf('week').toDate().getTime();
        this.dailyViewConfig.date.to = date.clone().endOf('week').toDate().getTime();
        break;
      case 'monthly':
        this.dailyViewConfig.date.from = date.clone().startOf('month').toDate().getTime();
        this.dailyViewConfig.date.to = date.clone().endOf('month').toDate().getTime();
        break;
    }
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  private _setDaily() {
    this.dailyViewConfig.date = this._calculateDailyConfig(moment().toDate());
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  private _setWeekly() {
    const current = moment();
    let first;
    let last;
    if (current.weekday() >= this.startDayOfTheWeek) {
      first = current.clone().weekday(this.startDayOfTheWeek).toDate().getTime();
      last = first + 6 * ViewsStateManagerService.DAY_IN_MILLIS;
    } else {
      first = current.clone().week(current.week() - 1).weekday(this.startDayOfTheWeek).toDate().getTime();
      last = first + 6 * ViewsStateManagerService.DAY_IN_MILLIS;
    }

    this.dailyViewConfig.date.from = first;
    this.dailyViewConfig.date.to = last;
    this.dailyViewConfig.date.currentDate = current.toDate().getTime();
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  private _setMonthly() {

    const current = moment();

    const monthStart = current.clone().startOf('month');
    const monthEnd = current.clone().endOf('month');

    const first = monthStart.toDate().getTime();
    const last = monthEnd.toDate().getTime();
    this.dailyViewConfig.date.from = first;
    this.dailyViewConfig.date.to = last;
    this.dailyViewConfig.date.currentDate = current.toDate().getTime();
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  private _setCustom(from: number, to: number, current: number) {
    this.dailyViewConfig.date.currentDate = current;
    this.dailyViewConfig.date.to = to;
    this.dailyViewConfig.date.from = from;
    this._dailyViewService.dailyViewConfig.next(this.dailyViewConfig);
  }

  private _calculateDailyConfig(currentDate: Date): {
    from: number;
    to: number;
    currentDate: number;
  } {
    this.startDayOfTheWeek = this._accountService.getDOW;
    let first;
    let last;
    const current = moment(currentDate);
    if (current.weekday() >= this.startDayOfTheWeek) {
      first = current.clone().weekday(this.startDayOfTheWeek).toDate().getTime();
      last = first + 6 * ViewsStateManagerService.DAY_IN_MILLIS;
    } else {
      first = current.clone().week(current.week() - 1).weekday(this.startDayOfTheWeek).toDate().getTime();
      last = first + 6 * ViewsStateManagerService.DAY_IN_MILLIS;
    }
    return {
      from: first,
      to: last,
      currentDate: current.toDate().getTime()
    };
  }
}
