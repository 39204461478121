import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-permission-denied',
  templateUrl: './account-permission-denied.component.html',
  styleUrls: ['./account-permission-denied.component.scss']
})
export class AccountPermissionDeniedComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public name: string) { }

  ngOnInit(): void {
  }

  goToLoginPage() {
    window.location.assign(environment.LEGACY_HUB_LOGIN);
  }

}
