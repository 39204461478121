<div class="management-top-card" fxLayout="column">

  <div class="management-top-card-title" fxLayout="row" fxLayoutAlign="start center">
    <div class="card-title-wrapper" fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between center">

      <div fxLayout="row" fxLayoutAlign="start center">
        <button (click)="close()" class="close-button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>

        <div class="title">
          <span class="bold-item">{{title}}</span>
        </div>
      </div>

      <div *ngIf="title !== 'Shift Details' && allOpenShifts.length > 0  && component!=='spreadsheet'"
        class="controls" fxLayout="row" fxFlex="19">
        <button (click)="goToPrevious()" [disabled]="selectedIndex <= 0" class="control-button left"
          fxLayoutAlign="center center" mat-button>
          <mat-icon class="outer-icon" fxLayoutAlign="center center">keyboard_arrow_left</mat-icon>
        </button>
        <button [matMenuTriggerFor]="shiftsMenu" class="control-button center" fxLayoutAlign="center center" mat-button>
          <mat-icon fxLayoutAlign="center center">arrow_drop_down_circle</mat-icon>
        </button>
        <button (click)="goToNext()" [disabled]="selectedIndex >= allOpenShifts.length -1" class="control-button right"
          fxLayoutAlign="center center" mat-button>
          <mat-icon class="outer-icon" fxLayoutAlign="center center">keyboard_arrow_right</mat-icon>
        </button>
      </div>

    </div>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div class="management-top-card-content">
    <div class="management-top-card-content-wrapper" [ngClass]="{'smallerHeight': title==='Assign Shift(s)'}" fxFlex
      fxLayout="column" fxLayoutGap="12px">

      <span *ngIf="title !== 'Shift Details'" class="info-header">Shift Details</span>
      <div *ngIf="component!=='spreadsheet' " fxLayout="row" fxLayoutAlign="end center" class="edit-shift">
        <i class="far fa-trash-alt"></i>
        <span matTooltip="Delete from Schedule" matTooltipClass="custom-delete-tooltip" matTooltipPosition="above"
          (click)="deleteShift()" class="edit-shift-text">Delete</span>
      </div>
      <div class="shift-date" [ngClass]="{'staffContainer':component==='spreadsheet'}">
        <span *ngIf="component!=='spreadsheet'" class="shift-date-text">{{shiftDetails?.shiftDate | date: ' EEE, MMM dd, yyyy'}}</span>
        <span *ngIf="component ==='spreadsheet'" class="shift-date-text">{{shiftDetails?.firstName}} {{shiftDetails?.lastName}}</span>
      </div>

      <div class="shift-hours">
        <span class="shift-hours-text">
          {{transformedData?.startHr}}:{{transformedData?.startMin}} <span
            class="ampm">{{transformedData?.startAMPM}}</span> -
          {{transformedData?.endHr}}:{{transformedData?.endMin}} <span class="ampm">{{transformedData?.endAMPM}}</span>
        </span>
        <mat-icon class="separator">fiber_manual_record</mat-icon>


        <span>{{shiftDetails?.floorName}}</span>

        <mat-icon class="separator">fiber_manual_record</mat-icon>


        <span>{{component==="spreadsheet" ? shiftDetails?.staffTypeName : getStaffTypeName(shiftDetails?.shiftTypeId)}}</span>
      </div>

      <div *ngIf="replacing" class="replacing-staff" fxLayoutAlign="start center">
        <span>{{replacing.firstName + ' ' + replacing.lastName}} was removed from shift ({{callout}}).</span>
      </div>
    </div>
  </div>

  <div *ngIf="title !== 'Shift Details' && component!=='spreadsheet' " class="tab-container" fxFlex
    fxLayoutAlign="center center">
    <button (click)="changeView('search')" [ngClass]="{
        selected: currentTab === 'search'}" fxFlex="1 1 auto" mat-button>Search Staff
    </button>
    <button (click)="changeView('responses')" [ngClass]="{
        selected: currentTab === 'responses'}" fxFlex="1 1 auto" mat-button>Confirm Staff
    </button>
    <button (click)="changeView('addStaff')" [ngClass]="{
        selected: currentTab === 'addStaff'}" fxFlex="1 1 auto" mat-button>
      <mat-icon>add</mat-icon>
      Other Staff
    </button>
  </div>

</div>

<mat-menu #shiftsMenu="matMenu" class="shifts-menu-sidebar">
  <div (click)="changeShift(shift)" *ngFor="let shift of allOpenShifts"
    [ngClass]="{selected: shift.shiftID === shiftDetails?.shiftID}" mat-menu-item>
    <div class="menu-item-shift" fxLayout="row">
      <div class="icon" fxLayoutAlign="center center">
        <mat-icon *ngIf="shift.shiftID === shiftDetails?.shiftID">done</mat-icon>
      </div>
      <div class="info" fxLayout="column">
        <span class="shift-title">{{shift.shiftDate | date : 'EEE, MMM d yyyy'}}</span>
        <div class="shift-subtitles" fxLayout="row wrap">
          <app-custom-shift-time-display *ngIf="!shift.isPartial" [startTime]="shift.startTime"
            [endTime]="shift.endTime" timeFontSize="16px" periodFontSize="12px">
          </app-custom-shift-time-display>
          <app-custom-shift-time-display *ngIf="shift.isPartial" [startTime]="shift.partialShift.start12Hr.timeString"
            [endTime]="shift.partialShift.end12Hr.timeString" timeFontSize="16px" periodFontSize="12px">
          </app-custom-shift-time-display>
          <mat-icon class="separator">fiber_manual_record</mat-icon>
          <span class="shift-subtitle">{{shift.floorName }}</span>
          <mat-icon *ngIf="getStaffTypeName(shift.shiftTypeId)" class="separator">
            fiber_manual_record</mat-icon>
          <span class="shift-subtitle">{{getStaffTypeName(shift.shiftTypeId)}}</span>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
