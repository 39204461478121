<div class="confirm-submit-wrapper" fxFlex="0 0 100%" fxLayout="column">
  <div class="confirm-submit-title" fxLayout="row" fxLayoutAlign="space-between">
    <label>Shift Request Summary</label>
    <mat-icon mat-dialog-close class="mat-dialog-close">close</mat-icon>
  </div>
  <div class="confirm-submit-body" fxLayout="column" fxLayoutGap="35px" fxLayoutGap.lt-sm="15px"
       fxFlexOffset="36px" fxFlexOffset.lt-sm="16px" fxLayoutAlign="start center">
    <div class="shift-request-info" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="25px">
      <div class="total-requested" fxFlex.gt-xs="0 0 50%" fxLayoutGap="20px" fxLayout="column">
        <label>Total Shifts Requested</label>
        <span>{{ totalShifts }}</span>
      </div>
      <div class="ip-requested" fxLayout="column">
        <label>IntelyPros Requested</label>
        <div fxLayout="column" fxFlexOffset="8px" fxLayoutGap="10px">
             <span *ngFor="let shift of shifts | keyvalue" class="title">
              {{shift.value.total}} {{shift.value.staffType.intelyProStaffTypeName}}
            </span>
        </div>
      </div>
    </div>
    <label>Are you sure you wish to continue with this request?</label>
  </div>
  <div class="confirm-submit-footer" fxLayout="column" fxFlexOffset="40px" fxFlexOffset.lt-sm="20px"
       fxLayoutAlign="end center" fxLayoutGap="20px" fxLayoutGap.lt-sm="10px">
    <button mat-button [mat-dialog-close]="true" class="confirm-action">Yes, continue</button>
    <button mat-button [mat-dialog-close]="false" class="cancel-action">No, cancel</button>
  </div>
</div>
