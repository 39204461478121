import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared.module';
import { AmpmToggleComponent } from './ampm-toggle.component';



@NgModule({
    declarations: [AmpmToggleComponent],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule
    ],
    exports: [AmpmToggleComponent]
})
export class AmpmToggleModule { }
