<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
  <vertical-layout-2></vertical-layout-2>
  <ng-chat *ngIf="showChat()" class="chat-wrapper" [title]="''" theme="custom-theme" [customTheme]="'custom-theme'"
           [adapter]="adapter" [userId]="clientId" [isCollapsed]="true" [browserNotificationsEnabled]="true"
           [persistWindowsState]="false" [maximizeWindowOnNewMessage]="true" (onMessagesSeen)="triggerSeen($event)"
           (onParticipantChatClosed)="windowClosed($event)"
           (onParticipantChatOpened)="windowOpened($event)"></ng-chat>
</ng-container>
<ng-container>
  <fuse-sidebar [invisibleOverlay]="false" [lockedOpen]="true" name="shiftManagement" position="right">
    <app-shift-management></app-shift-management>
  </fuse-sidebar>
  <fuse-sidebar [invisibleOverlay]="false" [lockedOpen]="true" name="staffManagement" position="right">
    <app-staff-management-sidebars></app-staff-management-sidebars>
  </fuse-sidebar>
  <fuse-sidebar [invisibleOverlay]="false" [lockedOpen]="true" name="intelyproCart" position="right">
    <app-cart-sidebar *ngIf="!!shoppingCartService.showCart.value"></app-cart-sidebar>
  </fuse-sidebar>
  <fuse-sidebar (openedChanged)="confirmSidebarClosed($event)" [invisibleOverlay]="false" [lockedOpen]="true" name="intelyproConfirmCart" position="right">
    <app-confirm-cart-sidebar *ngIf="!!shoppingCartService.totalCartInfo.value"></app-confirm-cart-sidebar>
  </fuse-sidebar>
  <fuse-sidebar [invisibleOverlay]="true" class="boost-approval-sidebar" name="boostApprovalQueue" position="left">
    <app-boost-approval-queue></app-boost-approval-queue>
  </fuse-sidebar>
</ng-container>
