import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { monthlyViewType } from '../config/monthly-view-type';
import { ManageUsersAccountService } from '../services/manage-users-account.service';
import { STORE_URL } from '../shared/constants';
import { JWTPayloadData } from '../models/newModels/JWTPayloadData';

@Component({
  selector: 'app-wrong-device',
  templateUrl: './wrong-device.component.html',
  styleUrls: ['./wrong-device.component.scss']
})
export class WrongDeviceComponent {

  isUserType: number;
  user: JWTPayloadData;
  public readonly monthlyViewType = monthlyViewType;
  public readonly STORE_URL = STORE_URL;

  constructor(
    private router: Router,
    public deviceService: DeviceDetectorService,
    private authenticationService: ManageUsersAccountService,
    public platform: Platform
  ) {
    this.user = this.authenticationService.currentUserValue;
    this.isUserType = this.authenticationService.facilityType === monthlyViewType.hybrid
      ? monthlyViewType.hybrid : (this.authenticationService.facilityType === monthlyViewType.intelypro ? monthlyViewType.intelypro : '');
    if (!!this.deviceService.isDesktop()) {
      this.router.navigateByUrl(this.authenticationService.getLoginUrlFromUserType(2));
    } else {
      // To Do: send email
    }
  }

  getProperLandingPage() {
    return this.authenticationService.navigateToLandingPage(this.user?.activeusertype?.usertypeid);
  }

}
