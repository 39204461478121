<div class="wrapper" fxLayout="column" fxLayoutGap="12px">

  <div class="staff-card-expandable" *ngFor="let staffMember of staffMembers; let i = index">
    <mat-expansion-panel #a [disabled]="control">
      <mat-expansion-panel-header collapsedHeight="72px" expandedHeight="72px"
                                  (click)="control ? selectItem(staffMember) : ''"
                                  [ngClass]="{selected: a.expanded, selectable: control}">
        <div class="header-content" fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">

          <div class="staff-card-expandable-checkbox" [ngClass]="{hidden: !control}" fxLayoutAlign="center center">
            <mat-checkbox (click)="$event.stopPropagation();" (change)="selectItem(staffMember)"
                          [checked]="getIndexOf(staffMember) > -1"></mat-checkbox>
          </div>

          <div class="staff-card-expandable-chip" fxLayoutAlign="center center">
            <app-chip-icon [name]="staffMember.firstName + ' ' + staffMember.lastName"></app-chip-icon>
          </div>

          <div class="staff-card-expendable-content" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center">
            <span class="bold-item title" [ngClass]="{'red-over-40':staffMember.hoursWorkedPerWeek > 40}">{{ staffMember.firstName + ' ' + staffMember.lastName | titlecase}}</span>
            <div class="info" fxLayout="row">
              <span class="subtitle">{{staffMember.abbreviation | staffType}}</span>
              <mat-icon class="separator">fiber_manual_record</mat-icon>

              <span class="subtitle">
                {{(staffMember.employmentTypeId !== 4 ? staffMember.employmentTypeName : staffMember.agencyName) | employmentType}}</span>
                <mat-icon  *ngIf="staffMember.employmentTypeName" class="separator">fiber_manual_record</mat-icon>
              <span class="subtitle" *ngIf="showHoursThisWeek" [ngClass]="{'red-over-40': staffMember.hoursWorkedPerWeek > 40}">{{ staffMember.hoursWorkedPerWeek}} 
                <span class="time">H </span> &nbsp; {{staffMember.minutesWorkedPerWeek}} 
                <span class="time"> MIN SCHEDULED</span>
               </span>
            </div>
          </div>

          
          <div class="icon-indicator" fxLayoutAlign="center center">
            <button mat-icon-button>
              <mat-icon *ngIf="!a.expanded" (click)="$event.stopPropagation();a.expanded = !a.expanded">
                keyboard_arrow_down
              </mat-icon>
              <mat-icon *ngIf="a.expanded" (click)="$event.stopPropagation();a.expanded = !a.expanded">
                keyboard_arrow_up
              </mat-icon>
            </button>
          </div>

        </div>
      </mat-expansion-panel-header>
      <div class="expansion-panel-body" fxFlex fxLayout="column" [ngClass]="{selectable: control}">


        <div class="user-info" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
          <span class="user-info-title">Gender</span>
          <span class="user-info-subtitle">{{staffMember.gender | titlecase}}</span>
        </div>
        <div class="user-info" fxLayout="row" fxLayoutGap="12px">
          <span class="user-info-title">Hire Date</span>
          <span class="user-info-subtitle">{{staffMember.hireDate | date : 'M/dd/yyyy'}}</span>
        </div>



        <div class="user-info last" fxLayout="row" fxLayoutGap="12px">
          <span class="user-info-title">Phone</span>
          <span class="user-info-subtitle">{{formatNumber(staffMember.phoneNumber)}}</span>
        </div>

      </div>
    </mat-expansion-panel>
  </div>
</div>
