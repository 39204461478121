<div class="move-shift-toggle" fxFlex fxLayout="column">

  <div class="move-shift-toggle-wrapper" expandedHeight="48px" fxLayoutAlign="center center"
       (click)="toggleChangeShift()">
    <div class="toggle-title" fxFlex="1 1 auto" fxLayoutAlign="start center">
      Change Shift Date And Time
    </div>
    <div class="toggle-actions">
      <mat-icon>edit</mat-icon>
    </div>
  </div>

  <div class="change-shift-panel" *ngIf="changeShift" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="16px">

    <div class="change-shift-panel-header" fxLayout="row">
      <div class="change-shift-panel-header-title" fxFlex="1 1 auto">
        Change Shift Date And Time
      </div>
      <mat-icon (click)="toggleChangeShift()">close</mat-icon>
    </div>

    <mat-divider></mat-divider>

    <div fxFlex fxLayout="column" fxLayoutGap="16px">
      <div fxFlex fxLayout="column">
        <span class="title"> Change Date </span>
        <mat-form-field fxFlex [appearance]="'outline'" class="shift-date-picker">
          <input matInput [matDatepicker]="myDatepicker" [min]="dateToday" [formControl]="dateFormControl">
          <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
          <mat-datepicker [startAt]="currentDate" #myDatepicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <span class="title"> Shift Time </span>
        <app-selectable-button-group #shiftTime [elements]="elements" [selectedFilters]="[currentShift]"
                                     [multiple]="false" [maximumSelections]="1" (valueChange)="currentShift = $event[0]"
        ></app-selectable-button-group>
      </div>

      <div class="actions">
        <div class="actions-wrapper" fxFlex fxLayoutAlign="center">
          <button mat-button class="action-button" (click)="moveShift()"
                  [disabled]="(!shiftTime.selected.length || !dateFormControl.valid) || !(shiftTime.control.touched || dateFormControl.touched)"
                  [ngClass]="(!shiftTime.selected.length || !dateFormControl.valid) || !(shiftTime.control.touched || dateFormControl.touched) ? 'disabled' : 'complete'">Move
          </button>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="change-shift-overlay" *ngIf="changeShift" (click)="toggleChangeShift()"></div>
