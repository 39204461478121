import { UserManagementEvents } from './UserManagementEvents';
import { ShiftManagementEvents } from './ShiftManagementEvents';
import { StaffManagementEvents } from './StaffManagementEvents';
import { OnboardingEvents } from './OnboardingEvents';

export class GoogleAnalyticsEvents {
  user_management = new UserManagementEvents();
  staff_management = new StaffManagementEvents();
  shift_management = new ShiftManagementEvents();
  onboarding = new OnboardingEvents();
}

