import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDrawerHeaderComponent } from './custom-drawer-header.component'
import { ChipIconModule } from '../chip-icon/chip-icon.module';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared.module';


@NgModule({
  declarations: [CustomDrawerHeaderComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ChipIconModule,
  ],
  exports: [
    CustomDrawerHeaderComponent
  ]
})
export class CustomDrawerHeaderModule { }
