import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestHelperData } from '../../../../../models/newModels/RequestHelperData';
import { RequestData } from '../../../../../models/newModels/RequestData';
import { ViewLiveUpdateService } from '../../../../../services/view-live-update.service';

@Component({
  selector: 'app-staff-requests-responses',
  templateUrl: './staff-requests-responses.component.html',
  styleUrls: ['./staff-requests-responses.component.scss']
})
export class StaffRequestsResponsesComponent implements OnInit {

  @Input() responses: RequestHelperData;
  @Output() confirm: EventEmitter<RequestData> = new EventEmitter();
  selectedRequest: RequestData;

  constructor(private _liveService: ViewLiveUpdateService) { }

  ngOnInit() {
  }

  getStaffMember(staffid: number) {
    return this._liveService.toStaffInfo(staffid);
  }


  setSelectedItem(req: RequestData) {
    this.selectedRequest = req;
  }

  confirmShift() {
    this.confirm.emit(this.selectedRequest);
  }
}
