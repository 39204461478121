import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, flatMap, map, toArray } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SelectableButtonGroupComponent } from '../../../shared/componets/selectable-button-group/selectable-button-group.component';
import { AditionalDataService } from '../../../services/aditional-data.service';
import { EmploymentTypeData } from '../../../models/newModels/EmploymentTypeData';
import { ShiftsService } from '../../../services/apis/shifts.service';
import { ShiftDefs } from '../../../models/shift-management-models/ShiftDefs';

@Component({
  selector: 'shift-management-filter',
  templateUrl: './shift-management-filter.component.html',
  styleUrls: ['./shift-management-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftManagementFilterComponent implements OnInit, AfterViewInit {

  @Output() filterChange: EventEmitter<{}> = new EventEmitter();
  @Output() searchChange: EventEmitter<{}> = new EventEmitter();
  @Output() blur: EventEmitter<{}> = new EventEmitter();
  @Output() focus: EventEmitter<{}> = new EventEmitter();
  @Input() selectedFilter: any = {};
  @ViewChild('shiftsOptions', { static: true }) shiftsOptions: SelectableButtonGroupComponent;
  @ViewChild('employmentTypeOptions', { static: true }) employmentTypeOptions: SelectableButtonGroupComponent;
  searchOpened = false;
  employmentType: Observable<any>;
  shifts: Observable<any>;
  control: FormControl = new FormControl('');
  subject: Subject<any[]> = new Subject();
  private _filter: {} = {};

  constructor(
    private _shiftJavaService: ShiftsService,
    private additionalData: AditionalDataService,
    private _cdr: ChangeDetectorRef
  ) { }

  private static _employmentTypeToFilterFormat(e: EmploymentTypeData) {
    return {
      name: e.employmenttype,
      value: e.employmenttypeid
    };
  }

  private static _shiftTimeToFilterFormat(e: ShiftDefs) {
    return {
      name: e.shiftName,
      value: e.shiftDefId
    };
  }

  ngOnInit() {

    this.employmentType = this.additionalData.getEmploymentType().pipe(
      flatMap(e => e.data),
      filter(e => e.employmenttypeid < 4),
      map(ShiftManagementFilterComponent._employmentTypeToFilterFormat),
      toArray()
    );

    this.shifts = this._shiftJavaService.getShiftDefs().pipe(
      flatMap(e => e),
      map(ShiftManagementFilterComponent._shiftTimeToFilterFormat),
      toArray()
    );

    this.subject.pipe(map(e => this._setFilterOption(e))).subscribe(e => this.filterChange.emit(e));
    this.control.valueChanges.subscribe(e => this.searchChange.next(e));
  }

  toggle() {
    this.searchOpened = !this.searchOpened;
    this._cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this._cdr.markForCheck();
  }

  forceSetFilters(newValues, pushEvent?: boolean) {
    this.selectedFilter = newValues;
    this.shiftsOptions.forceSetSelected(newValues.shift, pushEvent);
    this.employmentTypeOptions.forceSetSelected(newValues.employmentType, pushEvent);
    this._cdr.markForCheck();
  }

  private _setFilterOption(e: any) {
    this._filter[e[0]] = e[1];
    return this._filter;
  }

}
