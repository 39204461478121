/**
 *  Request Card
 *  Will display the data for a single shift promote request
 *
 *  Inputs:
 *   active: A boolean to used to determine if this is an active request or in the past
 *   request: The request data
 *   index: The current index of this request in the list. Used primarily for automation purposes
 *
 *  Outputs:
 *   action: When one of the action buttons is clicked, this will notify the parent that a request has been updated
 *
 */


import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BoostRequestModel } from '../../../../../models/BoostModels/BoostModels';
import * as moment from 'moment';
import { OVER_TIME } from '../over-time-constant';
import { OverTime } from '../over-time.enum';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestCardComponent implements OnInit {

  @Input() active: boolean;
  @Input() request: BoostRequestModel;
  @Input() index: number;
  @Output() action = new EventEmitter<string>();

  public readonly OVER_TIME = OVER_TIME;
  public readonly OverTime = OverTime;
  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.request.Approve = String(this.request.Approve);
    this.request.Reject = String(this.request.Reject);
    this.request.Cancel = String(this.request.Cancel);
  }

  // Does this request need to show the action buttons
  hasAccess() {
    return this.request.status === 'Pending' &&
      (this.request.Approve === '1' ||
        this.request.Reject === '1' ||
        this.request.Cancel === '1');
  }

  getStatusText(status: string) {
    switch (status) {
      case 'Rejected':
        return 'Rejected: Previous Billing Rate applied.';
        break;
      case 'Approved':
        return 'Boost request approved.';
        break;
      case 'Cancelled':
        return 'This request has been cancelled';
        break;
      case 'Expired':
        return 'Canceled: Previous billing rate applied';
        break;
      case 'Pending':
        return 'Request is awaiting approval';
        break;
      default:
        return '';
        break;
    }
  }

  getHours(hours: string) {
    return `${this._getTime(hours, 0)}-${this._getTime(hours, 1)}`;
  }

  changeStatus(newStatus: string) {
    this.request.status = newStatus;
    this.action.emit(newStatus);
    this._cdr.markForCheck();
  }

  private _getTime(hours: string, index: number) {
    return this._getDate(hours.split('-')[index]).format('h:mmA');
  }

  private _getDate(hour: string) {
    return moment(`${this.request.caredate} ${hour}`, 'YYYY-MM-DD hh:mm A');
  }

}
