import { Injectable } from '@angular/core';
import { ShiftManagementService } from '../../services/shift-management.service';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { filter, take, map, switchMap, toArray, tap, mergeMap, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ShiftDefs } from '../../models/shift-management-models/ShiftDefs';
import { ShiftsService } from '../../services/apis/shifts.service';
import { ShiftRequest } from 'src/app/models/java-models';
import { monthlyViewType } from '../../config/monthly-view-type';
import { dayViewState } from '../../models/dayView/dayViewState';
import { ShiftTypesService } from 'src/app/services/shift-types/shift-types.service';
import { AditionalDataService } from 'src/app/services/aditional-data.service';
import { StaffCategories } from 'src/app/enums/staff-categories';
import { ManageUsersAccountService } from 'src/app/services/manage-users-account.service';
import { ViewLiveUpdateService } from 'src/app/services/view-live-update.service';
import { DailyViewService } from 'src/app/services/daily-view.service';
import { StaffDashboardService } from 'src/app/services/staff-dashboard/staff-dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class DailyService {

  viewTypes: any = monthlyViewType;
  requestJustSent: Subject<ShiftRequest[]> = new Subject<ShiftRequest[]>();
  private _unsubscribe: Subject<any> = new Subject<any>();
  _state: BehaviorSubject<dayViewState>;
  readonly state$: Observable<dayViewState>;

  constructor (
    private _shiftJavaService: ShiftsService,
    private _shiftService: ShiftManagementService,
    private shiftTypesService: ShiftTypesService,
    private _dailyViewService: DailyViewService,
    private _additionalData: AditionalDataService,
    private _liveUpdate: ViewLiveUpdateService,
    private _userService: ManageUsersAccountService,
    private _staffDashboardService: StaffDashboardService,
  ) {
    this._state = new BehaviorSubject({
      locations: [],
      shiftTimes: [],
      staffTypes: [],
      shiftTypes: [],
      staffCategory: {},
      filters: { location: [], staffType: [], shiftTime: [], staffCategory: [] }
    });
    this.state$ = this._state.asObservable();
    this.loadDayViewData();
  }

  getState(): Observable<dayViewState> {
    return this.state$;
  }

  loadDayViewData() {
    this.getUnits();
    this.getShiftTimes();
    this.getStaffTypes();
    this.getShiftTypes();
    this.getStaffCategory();
  }

  shiftDataReload() {
    let currentDate = moment(this._dailyViewService.dailyViewConfig?.value?.date?.currentDate).format('yyyy-MM-DD');
    return this._liveUpdate.shiftDataReload.pipe(
      map(() => {
        const date = new Date(currentDate);
        return {
          start: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('yyyy-MM-DD'),
          end: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('yyyy-MM-DD')
        };
      }),
      switchMap(resp => forkJoin({
        openShifts: this._liveUpdate.getAllOpenShifts(),
        textMessages: this._staffDashboardService.getTextMessageCount(resp.start, resp.end),
        totalHours: this._liveUpdate.getTotalFilledHours(),
        totalFilledShifts: this._liveUpdate.getFilledShifts()
      })),
      takeUntil(this._unsubscribe))
  }


  // Returns Shift Times
  getShiftTimes() {
    this._shiftJavaService.getShiftDefs()
      .pipe(
        filter(shift => !!shift.length),
        take(1),
        map(shift => {
          return shift.sort((shift1, shift2) => {
            const firstStartTime: Date = moment(moment().format('yyyy/MM/DD') + ' ' + shift1.startTime).toDate();
            const firstEndTime: Date = moment(firstStartTime).add(shift1.hours, 'hours').toDate();

            const secondStartTime: Date = moment(moment().format('yyyy/MM/DD') + ' ' + shift2.startTime).toDate();
            const secondEndTime: Date = moment(secondStartTime).add(shift2.hours, 'hours').toDate();

            if (firstStartTime < secondStartTime) {
              return -1;
            } else if (firstStartTime.getTime() === secondStartTime.getTime()) {
              if (firstEndTime < secondEndTime) {
                return -1;
              } else {
                return 1;
              }
            } else {
              return 1;
            }
          });
        }),
        tap(shiftTimes => this._state.next({ ...this._state.getValue(), shiftTimes: shiftTimes })
        ))
      .subscribe()

  }

  // Returns Staff Types array
  getStaffTypes() {
    this._additionalData.getStaffTypes().pipe(
      mergeMap(staffType => staffType.data), toArray(),
      tap(staffTypes => this._state.next({ ...this._state.getValue(), staffTypes: staffTypes })),
      takeUntil(this._unsubscribe))
      .subscribe()
  }

  // Returns Shift Types
  getShiftTypes() {
    this.shiftTypesService.getShiftTypes().pipe(
      tap(shiftTypes => this._state.next({ ...this._state.getValue(), shiftTypes: shiftTypes })),
      takeUntil(this._unsubscribe))
      .subscribe();
  }

  // Return Locations
  getUnits() {
    this._shiftJavaService.getUnits().pipe(
      tap(location => this._state.next({ ...this._state.getValue(), locations: location })),
      takeUntil(this._unsubscribe))
      .subscribe();
  }

  // Returns Staff Category
  getStaffCategory() {
    let staffCategory = {};
    const staffCategories = StaffCategories;
    if (this._userService.facilityType === this.viewTypes.hybrid) {
      Object.keys(staffCategories).forEach(category => {
        staffCategory = { id: staffCategories[category].id, name: staffCategories[category].name, db: staffCategories[category].db }
      });
    } else {
      Object.keys(staffCategories).forEach(category => {
        if (category !== 'intelypro') {
          staffCategory = { id: staffCategories[category].id, name: staffCategories[category].name, db: staffCategories[category].db }
        }
      });
    }
    this._state.next({ ...this._state.getValue(), staffCategory: staffCategory });
  }

  // Returns call out reasons 
  getCallOutReasons(ipReason, clientType) {
    return this._shiftService.getCalloutReasons().pipe(
      filter(callouts => !!callouts.length),
      take(1),
      switchMap(callout => callout),
      filter(callout => ipReason ? (callout.ipReason === 1) : (callout.internalReason === 1 &&
        (clientType === this.viewTypes.internal ? callout.description !== 'IntelyPro' : true))), toArray()
    );
  }

  // Updates shift times in Settings
  updateShiftTimes(shiftTimes: ShiftDefs[]) {
    const currentState = this._state.getValue();
    this._state.next({ ...currentState, shiftTimes: shiftTimes });
  }

}
