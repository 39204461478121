import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneHandling } from '../timezone-handling';

@Pipe({
  name: 'localTimezone'
})
export class LocalTimezonePipe implements PipeTransform {

  transform(value: Date, timezone = 'America/New_York'): unknown {
    return TimezoneHandling.ConvertToLocalDateTime(value, timezone);
  }

}
