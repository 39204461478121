import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-processing-requests',
  templateUrl: './processing-requests.component.html',
  styleUrls: ['./processing-requests.component.scss']
})
export class ProcessingRequestsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProcessingRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      header: string;
      text: string;
    }
  ) { }

  ngOnInit(): void {
  }

}
