import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { JWTPayloadData } from 'src/app/models/newModels/JWTPayloadData';
import { UserTypeData } from 'src/app/models/newModels/UserTypeData';
import { ManageUsersAccountService } from 'src/app/services/manage-users-account.service';
import { SCROLL_CONSTANTS } from './constants/facility-constant';

export interface DialogData {
  facilities: UserTypeData[];
  userName: string;
  canClose: boolean;
}

@Component({
  selector: 'app-mutiple-facility',
  templateUrl: './mutiple-facility.component.html',
  styleUrls: ['./mutiple-facility.component.scss']
})
export class MutipleFacilityComponent implements OnInit, OnDestroy {
  @Output() selectFacility: EventEmitter<UserTypeData> = new EventEmitter();

  styleTopLine: boolean;
  styleTopShadow: boolean;
  styleScroll: boolean;
  canClose: boolean;
  firstName: string;
  facilities: UserTypeData[];
  user: JWTPayloadData;
  unsubscribe$: Subject<void> = new Subject();

  public readonly SCROLL_CONSTANTS = SCROLL_CONSTANTS;

  /* Form Control */
  facilityCont: FormControl = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<MutipleFacilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: ManageUsersAccountService,
    private cookieService: CookieService,
  ) { }


  /**
   * @method to handle the scrolling event
   */
  @HostListener('scroll', ['$event'])
  onScroll(): void {
    const element: Element = document.querySelector('.facility-section');
    const scrollCondition: number = element.scrollHeight - element.clientHeight;
    const scrollTop: number = element.scrollTop;
    const maxTop: number = scrollTop / scrollCondition;
    this.styleTopLine = false;
    this.styleTopShadow = false;
    this.styleScroll = false;
    if (element.scrollTop < this.SCROLL_CONSTANTS.scrollTopPosition) {
      this.styleTopLine = true;
      return;
    } else if (maxTop === this.SCROLL_CONSTANTS.maxTopValue || maxTop >
      this.SCROLL_CONSTANTS.minTopValue) {
      this.styleTopShadow = true;
      return;
    } else {
      this.styleScroll = true;
      return;
    }
  }

  /**
   * Method to initialize data and scrolling scenarios of popup
   */
  ngOnInit(): void {
    this.canClose = this.data.canClose;
    this.firstName = this.data.userName;
    this.facilities = this.data.facilities;
    this.user = this.userService.currentUserValue;

    if (this.data.facilities?.length > this.SCROLL_CONSTANTS.maxScrollHeight) {
      this.styleTopLine = true;
    }
    this.onSelectionChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @method to capture the selected facility data
   */
  onSelectionChange(): void {
    this.facilityCont.valueChanges.pipe(
      tap((facility: UserTypeData) => {
        if (this.canClose) {
          const clientId = facility[0].clientid;
          const token = this.cookieService.get('IntelycareAuthCookie');
          const userTypeId = facility[0].usertypeid;
          this.selectFacility.emit(facility);
          this.userService.loginAsFacility(clientId, token, userTypeId)
            .pipe(tap(() => {
              this.dialogRef.close({ isSwitched: true });
            }))
            .subscribe();
        } else {
          this.selectFacility.emit(facility);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  /**
   * Method to handle to select current facility
   * @param facility details related to current facility
   */
  OnSelect(facility: UserTypeData): void {
    this.user?.activeusertype.clientid === facility.clientid ? this.dialogRef.close(false) : null;
  }

}
