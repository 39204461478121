import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDrawerFooterComponent } from './custom-drawer-footer.component';
import { MaterialModule } from '../../material.module';
import { ChipIconModule } from '../chip-icon/chip-icon.module';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  declarations: [CustomDrawerFooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ChipIconModule
  ],
  exports: [
    CustomDrawerFooterComponent
  ]
})
export class CustomDrawerFooterModule { }
