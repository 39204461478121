import { OnBoardingNavigation } from '../models/newModels/OnBoardingNavigation';

export const onboardHubNavigation: OnBoardingNavigation[] = [
  {
    name: 'Register',
    uri: 'onboard/register',
    checked: true,
    next: 'onboard/welcome',
    previous: 'onboard/',
    isStep: false
  },
  {
    name: 'Welcome Back',
    uri: 'onboard/welcome',
    checked: true,
    next: 'onboard/facility/units',
    previous: 'onboard/register',
    isStep: true
  },
  {
    name: 'Add Facility',
    uri: 'onboard/facility/units',
    checked: false,
    next: 'onboard/shift-times',
    previous: 'onboard/welcome',
    isStep: true
  },
  {
    name: 'Define Shift Times',
    uri: 'onboard/shift-times',
    checked: false,
    next: 'onboard/shift-types',
    previous: 'onboard/facility/units',
    isStep: true
  },
  {
    name: 'Add Shift Types',
    uri: 'onboard/shift-types',
    checked: false,
    next: 'onboard/add-staff',
    previous: 'onboard/shift-times',
    isStep: true
  },
  {
    name: 'Add Staff',
    uri: 'onboard/add-staff',
    checked: false,
    next: 'onboard/confirm-staff',
    previous: 'onboard/shift-types',
    isStep: true
  },
  {
    name: 'Add Staff',
    uri: 'onboard/confirm-staff',
    checked: false,
    next: 'onboard/choose-experience',
    previous: 'onboard/add-staff',
    isStep: false
  },
  {
    name: 'Choose Experience',
    uri: 'onboard/choose-experience',
    checked: false,
    next: 'onboard/assign-floor',
    previous: 'onboard/confirm-staff',
    isStep: true
  },
  {
    name: 'Assign Location',
    uri: 'onboard/assign-floor',
    checked: false,
    next: 'onboard/assign-shift-time',
    previous: 'onboard/choose-experience',
    isStep: false
  },
  {
    name: 'Assign Shift Time',
    uri: 'onboard/assign-shift-time',
    checked: false,
    next: 'onboard/define-schedule',
    previous: 'onboard/assign-floor',
    isStep: false
  },
  {
    name: 'Define Schedule',
    uri: 'onboard/define-schedule',
    checked: false,
    next: 'onboard/assign-week-days',
    previous: 'onboard/assign-shift-time',
    isStep: false
  },
  {
    name: 'Assign Week Days',
    uri: 'onboard/assign-week-days',
    checked: false,
    next: 'onboard/confirm-schedule',
    previous: 'onboard/define-schedule',
    isStep: false
  },
  {
    name: 'Confirm Schedule',
    uri: 'onboard/confirm-schedule',
    checked: false,
    next: '/dashboard',
    previous: 'onboard/assign-week-days',
    isStep: false
  }
];


