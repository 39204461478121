<mat-expansion-panel class="card-system-shift-details" [expanded]="expanded"
                     [disabled]="!subtypes.length && !selectGender">
  <mat-expansion-panel-header class="card-system-shift-details-wrapper" [expandedHeight]="'84px'"
                              [collapsedHeight]="'84px'" fxLayoutAlign="start center">

    <div class="header" fxFlexAlign="start start">
      <mat-checkbox [checked]="selected" (change)="toggleSelection($event.checked)"
                    (click)="$event.stopPropagation()"></mat-checkbox>
    </div>

    <div class="content" fxLayout="column" fxLayoutGap="6px" fxLayoutAlign="center start">

      <span class="header-title">{{shiftDetails.shiftdate | date : 'EEE, MMM dd, yyyy'}}</span>

      <div class="shift-info" fxLayout="row wrap">

        <span>{{shiftDetails.starttime | toShiftHours : shiftDetails.endtime }}</span>

        <app-point-separator></app-point-separator>

        <span>{{shiftDetails.FloorName}}</span>

        <app-point-separator></app-point-separator>

        <span>{{shiftDetails.stafftypeabbreviation}}</span>


      </div>

    </div>
  </mat-expansion-panel-header>

  <div class="expansion-panel-body">
    <div class="staff-subtypes-values" fxLayout="column" fxLayoutGap="8px" *ngIf="!!subtypes.length || selectGender">

      <div *ngIf="!!subtypes.length" fxLayout="column" fxLayoutGap="8px">
        <span class="staff-subtypes-values-title">Role</span>

        <mat-radio-group fxLayoutGap="16px" [value]="subtype" (change)="changeSubtype($event.value)">
          <mat-radio-button [value]="null">{{getAllSubtypeValues()}}</mat-radio-button>
          <mat-radio-button *ngFor="let a of subtypes" [value]="a.value">{{a.name}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="selectGender" fxLayout="column" fxLayoutGap="8px">
        <span class="staff-subtypes-values-title">Gender</span>

        <mat-radio-group fxLayoutGap="16px" [value]="gender" (change)="changeGender($event.value)">
          <mat-radio-button [value]="'E'">Male/Female</mat-radio-button>
          <mat-radio-button [value]="'M'">Male</mat-radio-button>
          <mat-radio-button [value]="'F'">Female</mat-radio-button>
        </mat-radio-group>
      </div>

    </div>
  </div>

</mat-expansion-panel>
