<div class="addList">
    <div class="addListItem margin-bottom-40">
      <h4>1. Download the Spreadsheet Template and Help File</h4>
      <span class="download-file margin-bottom-10">
        <i class="fal fa-file-csv"></i>
        <a 
          href="assets/files/MultipleStaffUploadTemplate.csv" 
          download 
          matTooltipClass="custom-tool-tip" 
          matTooltip="Download" 
          matTooltipPosition="above"
          automation-id="staff-schedule-template-download">
          <label>Staff Schedule Template <br> (CSV Spreadsheet)</label>
        </a>
      </span>
      <span class="download-file">
        <i class="fal fa-file-pdf"></i>
        <a 
          href="https://www.intelycare.com/wp-content/uploads/2021/05/CSV-Upload-Help-Guide.pdf" 
          target="_blank"
          download
          matTooltipClass="custom-tool-tip" 
          matTooltip="Download" 
          matTooltipPosition="above"
          automation-id="help-guide-spreadsheet">
          <label>Spreadsheet <br> Help Guide (PDF)</label>
        </a>
      </span>
    </div>
    
    <div class="fill-template margin-bottom-40">
      <div>
        <h4>2. Fill in the template</h4>
        <i>
          Use the Help Guide Tips!
        </i>
      </div>
      <div class="img-class">
          <img src="assets/images/gcsv_graphic.png" />
      </div>
    </div>
  
    <div class="addListItem">
      <h4>3. Upload your completed spreadsheet here!</h4>
      <i>Drag & drop the file in the target</i><br>
      <i class="margin-top-23">or use the <strong>Browse</strong> File button below</i>
      <div class="file-container">
        <app-csv-file-upload (fileData)="uploadSpreadSheet($event)"></app-csv-file-upload>
      </div>
    </div>
    <div class="actions">
      <button 
       mat-button
       class="browse-button"
       automation-id="browse-button">
        <label class="button-label">
          <input 
            type="file" 
            hidden 
            (change)="uploadSpreadSheet($event.target.files[0])" 
            accept=".csv">
          Browse File...
        </label>
      </button>
    </div>
  </div>
