import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employmentType'
})
export class EmploymentTypePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    const emt = value.toLocaleLowerCase();
    return emt === 'full time' ? 'FT' : emt === 'part time' ? 'PT' : value;
  }

}
