<div id="sidebar-wrapper" [ngClass]="!show ? 'collapsednav' : 'expandnav'">
  <div class="intely-logo">
  <app-facility-icon [logo]="'blue'" [width]="'auto'"></app-facility-icon>
  </div>
  <div class="header-divider"></div>
  <div class="sidebar-body" fxFlexFill fxLayout="column" fxLayoutGap="22px">
    <div class="collapse">
      <button (click)="collapseSidenav()"
              [ngClass]="!show ? 'expanded' : 'collapsed'"
              [matTooltip]="!show ? 'HIDE MENU' : 'SHOW MENU'"
              matTooltipClass="edit-class"
              matTooltipPosition="above"
              class="collapse-button" >
           <i [ngClass]="!show ? 'fa-angle-left' : 'fa-angle-right'"
              class="far"></i>
      </button>
    </div>
    
<div *ngFor="let nav of navigation; let i = index"
      [ngClass]="{
        hidden: !nav.isStep,
        complete: nav.checked,
        disabled: !nav.checked,
        current: checkIfCurrent(nav.uri)
      }"
      class="navigation-item"
      disabled="true"
      fxLayout="row"
      (click)="navigateTo(nav.uri)"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      routerLinkActive="current">
      <div fxLayoutAlign="center center"
           class="step-number">

        <span *ngIf="i !== (navigation?.length-1)"
              class="step-number-value">{{ i + 1 }}</span>

            <i *ngIf="i === (navigation?.length-1)"
            class="far fa-star"></i>

        <mat-icon class="step-icon" *ngIf="i !== (navigation?.length-1)">done</mat-icon>
        
      </div>
      <div class="step-content" fxLayoutGap="start center">
        {{ nav.name }}
      </div>
    </div>
    
    <div id="need-help">
      <div  class="horizontal-divider"></div>
      <em>
        <span class="need-help-text"><i class="fas fa-question-square"></i>
          <strong>Need help? </strong></span>
        <a href="mailto:scheduling@intelycare.com?subject=Scheduling Support" class="need-help-link">Contact Us</a>
      </em>
    </div>
  </div>
</div>