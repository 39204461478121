import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { CartData } from '../../models/cart-data';
import { Subject } from 'rxjs';
import { IntelyproRequestData } from '../../../../../models/newModels/intelypro-request-data';
import { formatDate } from '@angular/common';
import { ManageUsersAccountService } from '../../../../../services/manage-users-account.service';
import { flatten } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { StaffJavaService } from 'src/app/services/apis/staff-java.service';
import { StaffingStaffTypes } from '../../../../../models/java-models/staffing-staff-typesmodel';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmSubmitComponent } from '../confirm-submit/confirm-submit.component';
import { ProcessingRequestsComponent } from '../processing-requests/processing-requests.component';
import { MonthlyViewService } from '../../../../services/monthly-view.service';
import { MobileService } from 'src/app/services/mobile.service';
import { Constants } from '../../../../../helpers/Constants';

@Component({
  selector: 'app-cart-sidebar',
  templateUrl: './cart-sidebar.component.html',
  styleUrls: ['./cart-sidebar.component.scss']
})
export class CartSidebarComponent implements OnInit, OnDestroy {

  cartItems: CartData[] = [];
  totalIntelypros = 0;
  totalPerStaffType: {
    [st: number]: {
      staffType: StaffingStaffTypes;
      total: number;
    }
  } = {};
  private _unsubscribe: Subject<any> = new Subject<any>();
  requesting = false;
  isDevice: boolean;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _cartService: ShoppingCartService,
    private _staffJavaService: StaffJavaService,
    private _userService: ManageUsersAccountService,
    private _monthlyViewService: MonthlyViewService,
    public mobileService: MobileService
  ) {
  }

  ngOnInit(): void {
    this.isDevice = this.mobileService.isDevice();
    this._cartService.cartState.pipe(
      takeUntil(this._unsubscribe),
      tap(cart => {
        cart.forEach(items => {
          items.days = items.days.sort((a, b) => {
            if (moment(a).isBefore(moment(b))) {
              return -1;
            }
            if (moment(a).isSame(moment(b))) {
              return 0;
            }
            if (moment(a).isAfter(moment(b))) {
              return 1;
            }
          });
        });
      })
    ).subscribe(e => {
      this.cartItems = e;
      this.calculateIntelypros();
    });
  }

  close() {
    this._cartService.closeShoppingCartSidebar();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  updateItem(item: CartData) {
    const itemCopy = { ...item };
    itemCopy.staffTypes = itemCopy.staffTypes.slice().map(e => ({ ...e }));
    this._cartService.updateItem(itemCopy);
    this.close();
  }

  deleteItem(item: CartData) {
    this._cartService.deleteItem(item);
  }

  getTotal(item?: CartData) {
    return item.staffTypes.reduce((c, el) => c += el.count, 0) * item.days.length;
  }

  submitRequest() {
    if (this.totalIntelypros > 25) {
      this._dialog.open(ConfirmSubmitComponent, {
        width: '562px',
        maxHeight: 'calc(100% - 20px)',
        height: '542px',
        panelClass: 'book-me-dialog',
        autoFocus: false,
        data: {
          totalRequests: this.totalIntelypros,
          shifts: this.totalPerStaffType
        }
      }).afterClosed()
        .pipe(
          filter(resp => resp),
          tap(() => this.save())
        )
        .subscribe();
    } else {
      this.save();
    }
  }

  save() {
    this.requesting = true;
    const unFlatten = this.cartItems.map(item => {
      return item.staffTypes.map(type => {
        return item.days.map(day => {
        const arr: IntelyproRequestData = {
          clientid: this._userService.currentUserSubject.value.activeusertype.clientid,
          gender: type.gender,
          reqdid: null,
          shiftdate: formatDate(day, 'yyyy-MM-dd', 'en-US'),
          staffshift: item.shiftTime.shiftDefId,
          staffunit: item.unit.id,
          shiftTypeId: type.staffType.shiftTypeId,
          shiftTypeLabel: type.staffType.shiftTypeLabel,
          shiftTypeName: type.staffType.shiftTypeName,
          costPlusPayRate: type.costPlusPayRate
        };

        if ((window !== window.parent)) {
          arr.source = Constants.SHIFT_CREATION_SOURCE.ukg;
        }

        return Array(type.count).fill(arr);
      });
      });
    });

    // this is a trick to detect the right type. '.map' only changes the type that the compiler detects does not transform the object
    const flatted = flatten(unFlatten).map((e: any) => e as IntelyproRequestData);

    this._cartService.totalCartInfo.next({
      cartItems: this.cartItems,
      totalIntelypros: this.totalIntelypros,
      totalPerStaffType: this.totalPerStaffType
    });

    const processingDialog = this._dialog.open(ProcessingRequestsComponent, {
      width: '624px',
      height: '429px',
      maxHeight: 'calc(100% - 20px)',
      autoFocus: false,
      panelClass: 'book-me-dialog',
      disableClose: true,
      data: { header: "Submitting IntelyPro Request", text: "Your Request is on its way!" }
    });
    //after click the button submit the cart will be emptied
    this._cartService.clearCart();

    this._staffJavaService.addIntelyProShiftsPassGateway(flatted)
      .pipe(
        tap(response => {
          processingDialog.close();
          this._monthlyViewService.forceReload.next();
          this._cartService.requestedShifts.next(response.data);
          this.requesting = false;
          this.close();
          this._monthlyViewService.requestIp.next(false);
          setTimeout(() => this._cartService.openConfirmationCartSidebar(), 500);
        })
      ).subscribe(response => { }, error => {
        processingDialog.close();
        this.requesting = false;
        this.close();
        this._snackBar.open('An error occurred when requesting IntelyPros!', '', {
          duration: 2000,
          panelClass: ['mobile-blue-snackbar']
        });
      });
  }

  private calculateIntelypros() {
    this.totalIntelypros = this.cartItems.map(e => e.staffTypes.reduce((c, el) => c += el.count, 0) * e.days.length)
      .reduce((c, el) => c += el, 0);
    this.totalPerStaffType = this.cartItems.reduce((coll, item) => {
      item.staffTypes.forEach(st => {
        if (!coll[st.staffType.shiftTypeId]) {
          coll[st.staffType.shiftTypeId] = {
            staffType: st.staffType,
            total: st.count * item.days.length
          };
        } else {
          coll[st.staffType.shiftTypeId].total += (st.count * item.days.length);
        }
      });
      return coll;
    }, {});
  }
}
