import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestsComponent } from './requests.component';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../shared/material.module';
import { CardSystemTopHeaderModule } from '../card-system-top-header/card-system-top-header.module';
import { CardSystemShiftDetailsModule } from '../card-system-shift-details/card-system-shift-details.module';
import { StaffRequestsResponsesModule } from './components/staff-requests-responses/staff-requests-responses.module';

@NgModule({
  declarations: [RequestsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CardSystemTopHeaderModule,
    CardSystemShiftDetailsModule,
    StaffRequestsResponsesModule
  ],
  exports: [
    RequestsComponent
  ]
})
export class RequestsModule { }
