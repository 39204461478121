import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { LastMinutesDetail } from 'src/app/models/LastMinuteDetailsModel';
import { JWTPayloadData } from 'src/app/models/newModels/JWTPayloadData';
import { environment } from 'src/environments/environment';
import { EventTypes } from '../../helpers/EventTypes';
import { MonthlyShiftData } from '../../models/newModels/MonthlyShiftData';
import { ManageUsersAccountService } from '../../services/manage-users-account.service';
import { ShiftManagementService } from '../../services/shift-management.service';
import { SocketIoService } from '../../services/socket-io.service';
import { JavaBaseResponse } from 'src/app/models/shift-management-models/java-base-response';

/**
 * MonthlyViewService manages monthly view actions
 */

@Injectable({
  providedIn: 'root'
})
export class MonthlyViewService {
  requestIp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openedDay: BehaviorSubject<Date> = new BehaviorSubject(null);
  shiftChanged: BehaviorSubject<any> = new BehaviorSubject('');
  forceReload: Subject<any> = new Subject<any>();
  hasBoostButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _baseUrl = environment.ISYSTEM_BASE_URL;
  public _shiftUrl = environment.SHIFT_URL;

  constructor(
    private _userService: ManageUsersAccountService,
    private _shiftService: ShiftManagementService,
    private _socket: SocketIoService,
    private _http: HttpClient,
    private _snackbar: MatSnackBar
  ) {
    this._userService.userLoggedInSubject.pipe(
      filter(user => !!user),
      tap(() => this._checkForShiftChanges())
    ).subscribe();
  }

  getMonthShifts(month: string, year: string): Observable<MonthlyShiftData[]> {
    return this._shiftService.getMonthlyShiftInfo(month, year).pipe(map(e => e.data));
  }

  getMonthShiftsInfo(startDate: string, endDate: string): Observable<MonthlyShiftData[]> {
    return this._shiftService.getMonthlyShiftsInfo(startDate, endDate).pipe(
      map(e => e.data),
      tap(shifts => shifts.forEach(this.adjustStafftypeForCalendarView))
    );
  }

  getCalendarShiftInfo(startDate: string, endDate: string): Observable<Array<MonthlyShiftData>> {
    const params = new HttpParams({fromObject: {startDate, endDate, cid: String(this._userService.currentUserValue.activeusertype.clientid)}});
    return this._http.get<JavaBaseResponse<Array<MonthlyShiftData>>>(`${this._shiftUrl}/calendar/`, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          this._snackbar.open("Could not retrieve data for this month. Please try a different month or contact us if this issue persists", "X", {
            duration: 2000
          });
          return of({data: new Array()} as JavaBaseResponse<Array<MonthlyShiftData>>);
        }
        throw error;
      }),
      map(response => response.data),
      tap(shifts => shifts.forEach(this.adjustStafftypeForCalendarView))
    );
  }


  /**
   * Function to show only staff types bigger than 0
   * @param item : Single staff type
   */
  checkIfHasStaffType(item) {
    return item[Object.keys(item)[0]] > 0;
  }

  /**
   * Convert staff types object to readable string
   * @param item : Single staff type
   */
  getStaffTypes(item) {
    return item[Object.keys(item)[0]] + ' ' + Object.keys(item)[0];
  }

  private _checkForShiftChanges() {
    return merge(
      this.forceReload,
      this._socket.onNewMessage(EventTypes.ADD_SHIFT),
      this._socket.onNewMessage(EventTypes.DELETE_SHIFT),
      this._socket.onNewMessage(EventTypes.SCHEDULE_SHIFT),
      this._socket.onNewMessage(EventTypes.MOVE_STAFF_TO_SHIFT),
      this._socket.onNewMessage(EventTypes.CONFIRM_SHIFT),
      this._socket.onNewMessage(EventTypes.ADD_CALLOUT),
      this._socket.onNewMessage(EventTypes.DELETE_STAFF),
      this._socket.onNewMessage(EventTypes.ADD_STAFF_TO_SHIFT),
      this._socket.onNewMessage(EventTypes.REMOVE_STAFF_TO_SHIFT)
    ).pipe(
      takeUntil(this._userService.userLoggedInSubject.pipe(filter(user => !user))),
      tap(() => this.shiftChanged.next(''))
    ).subscribe();
  }

  getLastMinuteDetails(user: JWTPayloadData ) {
    const params = { uid: user.userid, cid: user.activeusertype.clientid }
    return this._http.post<LastMinutesDetail>(`${this._baseUrl}/OPS/clientLastminDetails.php`, params ).pipe(
      map(response => response ? response : {}),
      catchError(error => {
        this._snackbar.open('An error has occured for getting last minute details.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  private adjustStafftypeForCalendarView(val: MonthlyShiftData) {
    // This should be changed to use an object map instead but right now this limits overall changes
    if (!Array.isArray(val.staffTypes)) {
      val.staffTypes = Object.keys(val.staffTypes).map(type => ({ [type]: val.staffTypes[type] }))
    }
  }
}
