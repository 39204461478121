<app-base-layout *ngIf="enableApp; else notSupporterdMessage"></app-base-layout>
<ng-template #notSupporterdMessage>
  <div class="ie-browser-wrapper" fxFlex="1 1 100%" fxLayout="column">
    <div fxFlexOffset="10" class="main-text" fxFlex="1 1 12%" fxLayout="row" fxFlexAlign="center">
      <b>Unsupported Browser</b>
    </div>
    <div class="subtitle" fxFlex="1 1 3%" fxLayout="row" fxFlexAlign="center">
      You're using a web browser we don't support yet.
    </div>
    <div class="subtitle" fxFlex="1 1 10%" fxLayout="row" fxFlexAlign="center">
      Please download Google Chrome to improve your experience.
    </div>
    <div fxFlex="1 1 75%" style="width: 70%" fxLayout="row" fxFlexAlign="center">
      <div fxFlex fxLayout="column">
        <img fxLayout="row" fxFlexAlign="center" src="../assets/images/chrome.png" width="100px" height="100px" />
        <div fxFlexOffset="2" fxLayout="row" fxFlexAlign="center">
          <a download href="https://www.google.com/chrome">Google Chrome</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
