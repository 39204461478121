import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FuseConfigService } from '@theme/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageUsersAccountService } from '../services/manage-users-account.service';
import { GoogleAnalyticsService } from '../services/googleanalytics.service';
import { ValidatePasswordComponent } from '../shared/componets/validate-password/validate-password.component';
import { UserTypes } from '../config/user-types';

@Component({
  selector: 'app-manage-mobile-user',
  templateUrl: './manage-mobile-user.component.html',
  styleUrls: ['./manage-mobile-user.component.scss']
})
export class ManageMobileUserComponent implements OnInit {
  @ViewChild(ValidatePasswordComponent) child: ValidatePasswordComponent;
  @Input() userTypeId = UserTypes.Staff;
  registerForm: FormGroup;
  submitted = false;
  processing = false;
  hide = true;
  hideConfirm = true;
  passMatch = false;
  givenEmail = '';
  customEmailError = '';
  registrationlinkid: number;
  facilityName = '';
  staffFirstName = '';
  staffLastName = '';

  constructor(
    public googleAnalyticsService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _accountService: ManageUsersAccountService
  ) {
    if (this._accountService.currentUserValue) {
      this.router.navigateByUrl(
        this._accountService.navigateToLandingPage(this._accountService.currentUserValue.activeusertype.usertypeid)
      );
    }

    const config = this._fuseConfigService.defaultConfig;
    config.layout.toolbar.hidden = true;
    this._fuseConfigService.setConfig(config);

    this.route
      .queryParams
      .subscribe(params => {
        this.registrationlinkid = params['registrationlinkid'];
      });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]],
      password: ['', [
        Validators.required,
        Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}')
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}')
      ]]
    });

    this.getStaffData();
  }

  get validateForm() { return this.registerForm.controls; }

  register() {
    this.submitted = true;

    if (!this.isValidUser() || this.processing) {
      return;
    }

    const requestBody = {
      'registrationlinkid': this.registrationlinkid,
      'email': this.registerForm.controls.email.value,
      'password': this.registerForm.controls.password.value,
      'frommobile': false,
      'fromemail': true
    };

    this.processing = true;
    this._accountService.saveStaffMemberAccount(requestBody).pipe().subscribe(
      response => {

        this.googleAnalyticsService
          .eventEmitter(
            GoogleAnalyticsService._GetEvents().user_management.category,
            GoogleAnalyticsService._GetEvents().user_management.action.sign_up.name,
            GoogleAnalyticsService._GetEvents().user_management.action.sign_up.label
          );

        if (String(response.body.code) === '422') {
          this.customEmailError = response.body.message;
          this.processing = false;
        } else {
          this._snackBar.open(response.body.message, '', {
            duration: 2000,
            panelClass: ['mobile-blue-snackbar']
          }).afterDismissed().subscribe(val => {
            if (!!String(response.body.code).match(/200|201/)) {
              this.router.navigateByUrl(this._accountService.navigateToRightActivationView(this.userTypeId));
            }
            this.processing = false;
          });
        }
      },
      error => {
        if (String(error?.error?.code) === '422') {
          this.customEmailError = error?.error?.message;
        } else {
          this._showMessage(error?.error?.message);
        }

        this.processing = false;
      }
    );
  }

  getStaffData() {
    if (this.registrationlinkid) {
      this._accountService.retrieveStaffMemberData(this.registrationlinkid).pipe().subscribe(
        response => {
          if (!!String(response.body.code).match(/200|201/)) {
            this.facilityName = response.body.data.clientname;
            this.staffFirstName = response.body.data.firstname;
            this.staffLastName = response.body.data.lastname;

            if (response.body.data.email) {
              this.givenEmail = response.body.data.email;
              this.registerForm.controls.email.patchValue(this.givenEmail);
            }

            // if (response.body.data.usertypeid !== this.userTypeId) {
            //   this._navigateToLogin('Please navigate to a valid registration link!');
            // }
          } else {
            this._navigateToLogin(response.body.message);
            this.registrationlinkid = null;
          }
        },
        error => {
          this._navigateToLogin(error.error.message);
        });
    } else {
      this._navigateToLogin('Please navigate to a valid registration link!');
    }
  }

  checkButtonColor() {
    if (this.isValidUser() && !this.processing) {
      return '#1885B7';
    }

    return '#A2A4A7';
  }

  isValidUser() {
    if (this.registerForm.invalid || !this.passMatch) {
      return false;
    }

    return true;
  }

  private _navigateToLogin(message: string) {
    this._accountService.registrationErrorMessage.next(message);
    this.router.navigateByUrl(this._accountService.getLoginUrlFromUserType(this.userTypeId));
  }

  private _showMessage(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['mobile-blue-snackbar']
    });
  }
}
