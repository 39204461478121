import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AddStaffService } from 'src/app/on-boarding/components/add-staff/services/add-staff.service';
import { StaffModuleManagementService } from 'src/app/staff-management/staff-module-management.service';

@Component({
  selector: 'app-download-template-upload',
  templateUrl: './download-template-upload.component.html',
  styleUrls: ['./download-template-upload.component.scss']
})
export class DownloadTemplateUploadComponent {
  @Output() manualStaff: EventEmitter<number> = new EventEmitter<number>();
  @Output() renderPage: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private _addStaffService: AddStaffService,
    private router: Router,
    private staffManagementService: StaffModuleManagementService
  ) { }

  /**
   * This function is to emit the selected file
   * Navigate to the handle staff upload and close panel
   */
  uploadSpreadSheet(file: File): void {
    this._addStaffService.fileUpload = file;
    this.router.navigate(['/directory/handle-staff-upload']);
    this.staffManagementService.closePanel();
  }
}
