/**
 * overtime setting service get overtimeValue from backend
 * set overtime option value  passing to api then save to DB.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResponseModel } from 'src/app/models/BaseResponseModel';
import { environment } from 'src/environments/environment';
import * as download from 'downloadjs';

@Injectable({
  providedIn: 'root'
})
export class OvertimeSettingsService {

  public _baseUri = environment.ISYSTEM_BASE_URL;

  constructor(
    private _http: HttpClient,
    private _snackbar: MatSnackBar
  ) { }

  /**
   * get overtime setting request to get data from backend.
   * @param cid :Client ID
   */
  getOvertimeSettingRequest(cid: number): Observable<string> {
    const params = new FormData();
    params.append('section', 'getOvertimeSettings');
    params.append('cid', String(cid));
    return this._http.post<BaseResponseModel<any>>(`${this._baseUri}/pages/partcare.php`, params).pipe(
      map((response: BaseResponseModel<any>) => response.data.OTOption),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  /**
   * set overtime setting save to DB.
   * @param cid :Client ID
   * @param uid : User ID
   * @param optionValue :Option
   */
  setOvertimeSettingRequest(cid: number, uid: number, optionValue: string): Observable<string> {
    const params = new FormData();
    params.append('section', 'setOvertimeSettings');
    params.append('cid', String(cid));
    params.append('uid', String(uid));
    params.append('option', optionValue);
    return this._http.post<any>(`${this._baseUri}/pages/partcare.php`, params).pipe(
      map((response: BaseResponseModel<null>) => response.message),
      catchError(error => {
        this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
          duration: 3000
        });
        return throwError(error);
      })
    );
  }

  getReport(uid: number, cid: number, fromDate: string, toDate: string, typereport: string, format: string): Observable<Blob> {
    const params = { uid: String(uid), cid: String(cid), fromDate, toDate, typereport, format };
    return this._http.get<Blob>(`${this._baseUri}/manage/reports.php`, {
      responseType: 'blob' as 'json',
      params: params
    });
  }

  getPbjXml(uid: number, cid: number, date: string, facID: string, identifier: string, medAid: number, medCare: number, other: number) {
    const formData = new FormData();
    formData.append('uid', String(uid));
    formData.append('cid', String(cid));
    formData.append('date', date);
    formData.append('facID', facID);
    formData.append('identifier', identifier);
    formData.append('medAid', String(medAid || ''));
    formData.append('medCare', String(medCare || ''));
    formData.append('other', String(other || ''));
    formData.append('format', 'xml');
    return this._http.post<{ client: string, code: number, data: string, message?: string }>(`${this._baseUri}/manage/PBJXML.php`, formData).pipe(
      map(response => {
        if (response.code === 200) {
          const xmlstring = '<?xml version="1.0"?>' + response.data;
          return download(xmlstring, `${response.client}_PBJ_Report.xml`);
        } else {
          this._snackbar.open(response.message, 'X', { duration: 5000 });
          return false;
        }
      })
    );
  }
}
