import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShiftData } from '../../../models/newModels/ShiftData';
import { ViewLiveUpdateService } from '../../../services/view-live-update.service';

@Component({
  selector: 'card-system-shift-details',
  templateUrl: './card-system-shift-details.component.html',
  styleUrls: ['./card-system-shift-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardSystemShiftDetailsComponent implements OnInit {
  @Output() selectionChange: EventEmitter<boolean> = new EventEmitter();
  @Input() shiftDetails: ShiftData;
  @Input() selectable = false;
  @Input() selected = false;

  constructor(private _liveUpdate: ViewLiveUpdateService) {
  }

  ngOnInit() {
  }

  toggleSelection(checked: boolean) {
    this.selected = checked;
    this.selectionChange.next(this.selected);
  }

  getStaffType(shiftTypeId: number) {
    return this._liveUpdate.getStaffTypeName(shiftTypeId);
  }
}
