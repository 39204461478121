<div class="popup">
  <header>
    <a *ngIf="canClose"
       matDialogClose 
       automation-id="close-popup-button"
       mat-button 
       class="close"><i class="fal fa-times"></i></a>
  </header>
  <div class="popup-section">
    <h1>Welcome {{firstName}}</h1>
    <h5>Select which facility you would like to manage</h5>
    <div [ngClass]="{ 'main-facility-initial': styleTopLine,
         'main-facility-final': styleTopShadow,
         'main-facility-scroll': styleScroll }"
         class="main-section">
      <div (scroll)="onScroll();" class="facility-section" >
        <mat-selection-list [formControl]="facilityCont" [multiple]="false" [disableRipple]="true">
          <mat-list-option  *ngFor="let facility of facilities; let i = index" 
                            [value]="facility"
                            [selected]="user?.activeusertype?.clientid === facility?.clientid" 
                            (click)="OnSelect(facility)"> 
            <div class="facility-options-section">
              <div class="facility-name-section">
                <span class="fa-stack">
                  <img src="assets/images/facility-image-hover.svg" class="hover-display">
                  <img src="assets/images/facility-image.svg" class="icon-style load-style">
                </span>
                <span>{{ facility?.clientname }}</span>
              </div>
              <a mat-button>Manage</a>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</div>
