import { Directive, EventEmitter, HostListener, Output, ElementRef, AfterContentInit } from '@angular/core';
import { FILE_UPLOAD_IMAGE_PATHS } from 'src/app/shared/constants/file-upload-image-constant';

@Directive({
  selector: '[appFileUpload]'
})
export class FileUploadDirective implements AfterContentInit {

  imageRef;

  readonly FILE_UPLOAD_IMAGE_PATHS = FILE_UPLOAD_IMAGE_PATHS;

  constructor(private elementRef: ElementRef) { }

  ngAfterContentInit() {
    this.imageRef = this.elementRef.nativeElement.querySelector('img');
  }

  @Output() onFileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.imageRef.src = FILE_UPLOAD_IMAGE_PATHS.UPLOADING_STATE_PATH;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.imageRef.src = FILE_UPLOAD_IMAGE_PATHS.BROWSE_STATE_PATH;
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
    this.imageRef.src = FILE_UPLOAD_IMAGE_PATHS.BROWSE_STATE_PATH;
  }


}
