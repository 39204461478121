/**
 *  BoostDataService
 *  This service is responsible get boost related data for single and multiple pending shifts.
 *  And boost single and bulk shifts.
 *
 *   Subject: removeShiftResponse$ - Take's care of removing a shift from the bulk boost array.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseResponseModel } from 'src/app/models/BaseResponseModel';
import { BlockShift } from 'src/app/models/BlockBooking/BlockBookingData';
import { BillRate, BoostDataBulkShiftByDate, BoostDataSingleShift, BoostRange, BoostRangeSingleShift, BulkShift } from 'src/app/models/BoostModels';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../services/utils/utils.service';
import { IntelyPro } from '../../models/IntelyPro';

const bypassGateway = { ...{ headers: { ...{ 'x-amzn-apigateway-api-id': '123' } } } };

@Injectable({
  providedIn: 'root'
})
export class BoostDataService {

  private readonly _baseUriBypassGateway = UtilsService.getISystemBypassGatewayBaseUri();
  private readonly _partcareUri = environment.ISYSTEM_BASE_URL + '/pages/partcare.php';
  private readonly _payrollUrl = UtilsService.getPayrollUrl();
  public readonly removeShiftResponse$: Subject<boolean> = new Subject<boolean>();
  public readonly blockShifts$: BehaviorSubject<BlockShift[]> = new BehaviorSubject<BlockShift[]>([]);

  constructor(
    private _http: HttpClient,
    private _snackbar: MatSnackBar
  ) { }

  private openSnackBar() {
    return this._snackbar.open('An error has occured. Please try again at a later time.', 'X', {
      duration: 3000
    });
  }

  /**
   * Gets boost data for a single pending shift.
   * Response will contain shift, admin access, overtime deetails.
   * Min and max promotion rate, bill rates.
   * @param reqdid : Request ID of the pending shift.
   * @param uid : User ID
   */
  getBoostDataForSingleShift(reqdid: number, uid: number): Observable<BoostDataSingleShift> {
    const params = new FormData();
    params.append('section', 'getBoostData');
    params.append('reqdid', String(reqdid));
    params.append('uid', String(uid));
    return this._http.post<BaseResponseModel<BoostDataSingleShift>>(this._partcareUri, params).pipe(
      map((response: BaseResponseModel<BoostDataSingleShift>) => response.data),
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }

  getBlockBookingBoostRange(cid: number, uid: number) {
    const params = new FormData();
    params.append('section', 'getBoostRangeBlock');
    params.append('uid', String(uid));
    params.append('cid', String(cid));
    return this._http.post<BoostRange>(this._partcareUri, params).pipe(
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }

  /**
   * Boost single shift.
   * @param reqdid : Request ID
   * @param uid : User ID
   * @param ratePercentage : Selected boost percentage
   * @param staffType : Staff type
   * @param newCharge : New charge
   * @param increaseValue : Increased value
   * @param overtimeOption : Overtime option
   */
  singleBoostRequest(
    reqdid: string, uid: number, ratePercentage: number, dataToBeBoosted: BillRate[], overtimeOption?: string
  ): Observable<string> {
    const params = new FormData();
    params.append('section', 'setBoostData');
    params.append('reqdid', reqdid);
    params.append('uid', String(uid));
    params.append('ratePercentage', String(ratePercentage));
    // NOUS TODO I believe you can provide just an array to the form data and it will be formatted
    dataToBeBoosted.forEach((element: BillRate, i: number) => {
      params.append(`boostVal[${i}][pType]`, element.pType);
      params.append(`boostVal[${i}][newCharge]`, String(element.newCharge));
      params.append(`boostVal[${i}][increaseValue]`, String(element.increaseValue));
    });
    // Format the boost data for the API call
    const boostData = dataToBeBoosted.map(
      ({ pType, newCharge, increaseValue }: BillRate) => ({
        pType,
        increaseValue,
        charge: newCharge,
      })
    );
    // Stringify it and append to the request params form data
    params.append('boostVal', JSON.stringify(boostData));
    params.append('option', overtimeOption);
    return this._http.post<BaseResponseModel<null>>(this._partcareUri, params).pipe(
      map((response: BaseResponseModel<null>) => response.message),
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }

  /**
   * Gets boost min and max range.
   * @param cid : Client ID.
   */
  getBoostRangeForBulkShift(cid: number, uid: number): Observable<BoostRange> {
    const params = new FormData();
    params.append('section', 'getBoostRange');
    params.append('cid', String(cid));
    params.append('uid', String(uid));
    return this._http.post<BoostRange>(this._partcareUri, params).pipe(
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }

  /**
   * Get calculated data including new rates for pending requestes by providing a boost percentage for bulk shifts.
   * @param cid : Client ID
   * @param reqdidBulk : Request ID array
   * @param ratePercentage : Selected boost percentage
   */
  getBoostDataForBulkShift(cid: number, uid: number, reqdidBulk: number[], ratePercentage: string): Observable<BoostDataBulkShiftByDate[]> {
    const params = new FormData();
    params.append('section', 'getPendingRequests');
    params.append('cid', String(cid));
    params.append('uid', String(uid));
    params.append('ratePercentage', ratePercentage);
    reqdidBulk.forEach((reqdid: number) => {
      params.append('reqdids[]', String(reqdid));
    });
    return this._http.post<BaseResponseModel<any>>(this._partcareUri, params).pipe(
      map(response => {
        if (response.data) {
          const boostDataBulkShiftByDate: BoostDataBulkShiftByDate[] = [];
          const keysArray: string[] = Object.keys(response.data).sort();
          keysArray.forEach((key: string) => {
            boostDataBulkShiftByDate.push(
              <BoostDataBulkShiftByDate>{
                date: key,
                displayDate: response.data[key][0]['formatDate'],
                boostDataBulkShift: response.data[key]
              }
            );
          });
          return boostDataBulkShiftByDate;
        } else {
          return [];
        }
      }),
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }

  /**
   * Boost shifts in bulk.
   * @param cid : Client ID
   * @param uid : User ID
   * @param ratePercentage : Selected boost percentage
   * @param dataToBeBoosted : Array of shifts to be boosted.
   */
  bulkBoostRequest(cid: number, uid: number, ratePercentage: number, dataToBeBoosted: BulkShift[], overtimeSetting?: string, sureShift?: boolean, blockId?: number) {
    const params = new FormData();
    params.append('section', 'setBulkBoostData');
    params.append('cid', String(cid));
    params.append('uid', String(uid));
    params.append('option', overtimeSetting);
    params.append('ratePercentage', String(ratePercentage));
    params.append('sureShift', String(sureShift));
    if (blockId) {
      params.append('blockId', String(blockId));
    }
    // Format the boost data for the API call
    const boostData = this.setBulkBoostData(dataToBeBoosted);
    // Stringify it and append to the request params form data
    params.append('boostData', JSON.stringify(boostData));
    return this._http.post<BaseResponseModel<null>>(`${this._baseUriBypassGateway}/pages/partcare.php`, params, bypassGateway).pipe(
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }

  private setBulkBoostData(blockShiftData) {
    let blockShiftResponse;
    this.blockShifts$.pipe(
      tap((blockShift: BlockShift[]) => {
        blockShiftResponse = blockShift;
      })
    ).subscribe();
    if (blockShiftResponse.length) {
      blockShiftData.map((blockShift) => blockShiftResponse.forEach(blockShiftRes => {
        if (+blockShift.reqdid === blockShiftRes.shiftId) {
          blockShift.reqdid = +blockShiftRes.shiftId;
          blockShift.blockId = +blockShiftRes?.blockId;
        } else {
          blockShift.reqdid = +blockShift.reqdid;
        }
        blockShift.rate = [{
          pType: blockShift.staffType,
          charge: blockShift.newCharge,
          increaseValue: blockShift.increaseValue
        }]
      }
      ));
    } else {
      blockShiftData = blockShiftData.map(
        ({ reqdid, staffType, newCharge, increaseValue }: BulkShift) => ({
          reqdid,
          rate: [{
            pType: staffType,
            charge: newCharge,
            increaseValue: increaseValue
          }]
        }));
    }
    return blockShiftData;
  }

  /**
   * Generates boost range.
   * Generates and returns boost range and boost percentage.
   * @param minRange : Min range
   * @param maxRange : Max range
   * @param billRate : Bill rate for shift
   */
  generateRates(minRange: number, maxRange: number, billRate: BillRate[]): BoostRangeSingleShift[] {
    const boostRange: BoostRangeSingleShift[] = [];
    for (let i = minRange; i <= maxRange; i += 5) {
      let newCharge: number, pType: string;
      const newBillRate: BillRate[] = [];
      const rateChange: number = 1 + (i / 100);
      for (let j = 0; j < billRate.length; j++) {
        newCharge = Number(billRate[j].charge) * rateChange;
        pType = billRate[j].pType;
        newBillRate.push(
          <BillRate>{
            pType: pType,
            newCharge: Number(newCharge.toFixed(2)),
            charge: billRate[j].charge,
            increaseValue: newCharge - Number(billRate[j].charge)
          }
        );
      }
      boostRange.push({ ratePercentage: i, billRate: newBillRate });
    }
    return boostRange;
  }

  /**
   * Checks if shift is already in the past and you can't do actions on it
   */
  isOldShift(rowData: IntelyPro) {
    if (!rowData.start_time) {
      return false;
    }
    return (rowData.start_time.getTime() < new Date().getTime());
  }

  boostShiftTooltip(rowData: IntelyPro) {
    let message = '';
    if (!!rowData.start_time && rowData.start_time.getTime() < new Date().getTime()) {
      message = 'Cannot boost the shift in the past';
    } else if (rowData.isBlockBooking) {
      message = 'Boost block bookings from the Dashboard';
    } else if (rowData.hasContractBlockBillingRate) {
      message = 'Cannot be boosted because billing rate has been updated';
    }
    return message;
  }

  editRate(newPrice: number, shiftId: number){
    return this._http.post<BaseResponseModel<null>>(`${this._payrollUrl}/pay-rate/edit`, {newPrice, shiftId}).pipe(
      catchError(error => {
        this.openSnackBar();
        return throwError(error);
      })
    );
  }
}
