import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartSidebarComponent } from './cart-sidebar.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { MaterialModule } from '../../../../../shared/material.module';
import { Alert12HourNotificationModule } from 'src/app/shared/componets/alert12-hour-notification/alert12-hour-notification.module';
import { MobileCartSidebarModule } from '../mobile-cart-sidebar/mobile-cart-sidebar/mobile-cart-sidebar.module';


@NgModule({
  declarations: [CartSidebarComponent],
  exports: [
    CartSidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    Alert12HourNotificationModule,
    MobileCartSidebarModule
  ]
})
export class CartSidebarModule {
}
