import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToShiftHoursPipe } from './to-shift-hours.pipe';
import { EmploymentTypePipe } from './employment-type.pipe';
import { StaffTypePipe } from './staff-type.pipe';
import { SafePipe } from './safe.pipe';
import { ToShiftHoursMobilePipe } from './to-shift-hours-mobile.pipe';
import { MinutesToHoursPipe } from './minutesToHours.pipe';
import { LocalTimezonePipe } from './local-timezone.pipe';
import { SecurePipe } from './secure.pipe';
import { BooleanToStringPipe } from './booleanToString.pipe';

@NgModule({
  declarations: [
    ToShiftHoursPipe,
    ToShiftHoursMobilePipe,
    EmploymentTypePipe,
    StaffTypePipe,
    SafePipe,
    MinutesToHoursPipe,
    LocalTimezonePipe,
    SecurePipe,
    BooleanToStringPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToShiftHoursPipe,
    ToShiftHoursMobilePipe,
    EmploymentTypePipe,
    StaffTypePipe,
    SafePipe,
    MinutesToHoursPipe,
    LocalTimezonePipe,
    SecurePipe,
    BooleanToStringPipe
  ]
})
export class PipesModule {
}
