import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IntelyproRequestData } from 'src/app/models/newModels/intelypro-request-data';
import { JavaBaseResponse } from 'src/app/models/shift-management-models/java-base-response';
import { UtilsService } from '../utils/utils.service';
import { RequestShiftsResponse } from '../../models/shift-management-models/RequestShiftsResponse';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';
import { BaseResponseModel } from 'src/app/models/BaseResponseModel';
import { FilterStaffData } from 'src/app/models/newModels/FilterStaffData';
import { JavaPayload } from '../../models/newModels/JavaPayload';
import { util } from 'prismjs';
import clone = util.clone;
import {ShiftType} from '../../models/ShiftType';
import { StaffTypeLabel } from 'src/app/models/shift-management-models/StaffTypeLabel';
import { ManageUsersAccountService } from '../manage-users-account.service';

@Injectable({
  providedIn: 'root'
})
export class StaffJavaService {

  private _basedUri = UtilsService.getShiftJavaUri();
  private _staffTypeUri = UtilsService.getShiftUri();
  private _scheduleUri = UtilsService.getSchedulingBaseUri();

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private _userService: ManageUsersAccountService) { }

  intelyProBookMe(bookMeData: any): Observable<JavaBaseResponse<any>> {
    return this.http.post<JavaBaseResponse<any>>(`${this._basedUri}/ip/book`, bookMeData).pipe(
      catchError(error => {
        this.snackbar.open(error.error.message || 'An error has occured', '', {
          duration: 3000
        });
        return of({ data: null, status: 400 });
      })
    );
  }

  getStaffTypes(qualifications = false): Observable<JavaPayload<StaffTypeLabel[]>> {
    const params = new HttpParams({fromObject: {
      clientId: this._userService.currentUserValue.activeusertype.clientid.toString(),
      qualifications: qualifications.toString()
    }});
    return this.http.get<JavaPayload<StaffTypeLabel[]>>(`${this._staffTypeUri}/management/shifttypes`, { params })
      .pipe(
        catchError(error => of({ data: [] as StaffTypeLabel[] } as JavaPayload<StaffTypeLabel[]>))
      );
  }

  getIntelyproShiftTypes(reqData): Observable<ShiftType[]> {
    return this.http.post<JavaPayload<ShiftType[]>>(`${this._staffTypeUri}/management/shifttypes/user`, reqData)
      .pipe(map(resp => resp.data['shiftTypes']))
      .pipe(
        catchError(error => {
          console.error(error);
          return of({ data: [] as ShiftType[] } as JavaPayload<ShiftType[]>);
        })
      );
  }

  addIntelyProShifts(req: IntelyproRequestData[]): Observable<JavaBaseResponse<RequestShiftsResponse[]>> {
    return this.http.post<JavaBaseResponse<RequestShiftsResponse[]>>(`${this._basedUri}/staffing/shift/create`, req);
  }

  addIntelyProShiftsPassGateway(req: IntelyproRequestData[]): Observable<JavaBaseResponse<RequestShiftsResponse[]>> {
    return this.http.post<JavaBaseResponse<RequestShiftsResponse[]>>(`${this._scheduleUri}/staffing/shift/create`, req);
  }

  getAllStaffFiltered(filters: FilterStaffData): Observable<BaseResponseModel<StaffMember[]>> {
    const filterObj = this._prepareFilterObjectForRequest(clone(filters));

    const reqparams = Object.keys(filterObj).reduce((c, e, i) => {
      if (filterObj[e] !== '') {
        return c + (i === 0 ? '' : '&') + e + '=' + filterObj[e];
      }
      return c;
    }, '?');
    return this.http.get<BaseResponseModel<StaffMember[]>>(`${this._basedUri}/staff${reqparams}`);
  }

  private _prepareFilterObjectForRequest(filters: FilterStaffData): FilterStaffData {
    switch (filters.accountStatus) {
      case '1':
        filters.isRegistered = true;
        delete filters.accountStatus;
        break;
      case '2':
        filters.isRegistered = false;
        delete filters.accountStatus;
        break;
      default:
        delete filters.accountStatus;
        break;
    }
    return filters;
  }

}

