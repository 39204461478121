import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadTemplateUploadComponent } from './download-template-upload.component';
import { CsvFileUploadModule } from 'src/app/on-boarding/components/add-staff/components/csv-file-upload/csv-file-upload.module';
import { TemplateUploadModule } from '../template-upload/template-upload.module';
import { MaterialModule } from 'src/app/shared/material.module';


@NgModule({
  declarations: [DownloadTemplateUploadComponent],
  imports: [
    CommonModule,
    CsvFileUploadModule,
    TemplateUploadModule,
    MaterialModule
  ],
  exports: [DownloadTemplateUploadComponent]
})
export class DownloadTemplateUploadModule { }
