import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { WrongDeviceRoutingModule } from './wrong-device-routing.module';
import { WrongDeviceComponent } from './wrong-device.component';



@NgModule({
  declarations: [WrongDeviceComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    WrongDeviceRoutingModule
  ]
})
export class WrongDeviceModule { }
