<div class="boost-shift-wrapper" *ngIf="boostVisibility?.sureShift || boostVisibility?.boostButton">
  <form [formGroup]="boostForm">
    <div class="boost-shift-title">
      <label>Boost Your Shift(s)</label>
      <button *ngIf="!autoToggle" automation-id="toogle-boost-options-button" (click)="toggleBoostOptions()">
        <i class="fas" [ngClass]="!showBoostOptions ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
      </button>
    </div>
    <div>
      <p class="optional-text" *ngIf="!isDevice">(OPTIONAL)</p>
    </div>
    <div class="boost-shift-subtitle">
      <label>Increase the likelihood of your shifts being filled by using our boost options.</label>
    </div>
    <hr *ngIf="showBoostOptions && !isDevice">
    <div *ngIf="!!showBoostOptions && boostVisibility" class="boost-details" id="boost-card">
      <ng-container *ngIf="!isDevice; else ukgBoostShift">
        <div class="boost-form" fxFlex="0 0 80%" fxLayout="column" fxLayoutGap="15px">
          <ng-container *ngIf="boostVisibility?.boostButton">
            <h2 class="boost-shift-heading">
              <i class="fal fa-rocket"></i>
              <span> Rate Boost </span>
              <i matTooltip="Increase the likelihood of your shift being filled by boosting the hourly rates."
                matTooltipPosition="above" matTooltipClass="tooltip short-tooltip"
                class="far fa-info-circle info-icon"></i>
            </h2>
            <ng-container *ngTemplateOutlet="boostAmountTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="boostVisibility?.boostButton">
            <h2 class="boost-shift-heading">
              <img src="../assets/images/overtime.svg" alt="Overtime Boost" />
              <span> Overtime Boost </span>
              <i matTooltip="Allow overtime to IntelyPros to expand this shift for more IntelyPros."
                matTooltipPosition="above" matTooltipClass="tooltip short-tooltip"
                class="far fa-info-circle info-icon"></i>
            </h2>
            <ng-container *ngTemplateOutlet="allowOvertimeTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="boostVisibility?.sureShift">
            <h2 class="boost-shift-heading">
              <i class="fas fa-shield-check"></i>
              <span> Sure Shift Boost </span>
              <i matTooltip="Reassure IntelyPros that you will not cancel this shift by applying this feature."
                matTooltipPosition="above" matTooltipClass="tooltip short-tooltip"
                class="far fa-info-circle info-icon"></i>
            </h2>
            <mat-checkbox formControlName="sureShift" class="checkbox-text">
              I understand by applying Sure Shift, I will not be able to cancel once an IntelyPro has accepted the
              shift(s).
            </mat-checkbox>
          </ng-container>
          <ng-container *ngTemplateOutlet="boostButtonsTemplate"></ng-container>
        </div>
      </ng-container>

      <ng-template #ukgBoostShift>
        <div class="boost-form">
          <ng-container *ngIf="boostVisibility?.boostButton">
            <div class="ukg-form">
              <div class="align-items">
                <i class="fal fa-rocket"></i>
                Rate
                <i matTooltip="Increase the likelihood of your shift being filled by boosting the hourly rates."
                  matTooltipPosition="above" matTooltipClass="tooltip short-tooltip"
                  class="far fa-info-circle info-icon"></i>
              </div>
              <ng-container *ngTemplateOutlet="boostAmountTemplate"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="boostVisibility?.boostButton">
            <div class="ukg-form">
              <div class="align-items">
                <img src="../assets/images/overtime.svg" alt="Overtime Boost" />
                <span> Overtime </span>
                <i matTooltip="Allow overtime to IntelyPros to expand this shift for more IntelyPros."
                  matTooltipPosition="above" matTooltipClass="tooltip short-tooltip"
                  class="far fa-info-circle info-icon"></i>
              </div>
              <ng-container *ngTemplateOutlet="allowOvertimeTemplate"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="boostVisibility?.sureShift">
            <div class="align-items sure-shift">
              <i class="fas fa-shield-check"></i>
              <span> Sure Shift </span>
              <i matTooltip="Reassure IntelyPros that you will not cancel this shift by applying this feature."
                matTooltipPosition="above" matTooltipClass="tooltip short-tooltip"
                class="far fa-info-circle info-icon"></i>
            </div>
            <mat-checkbox formControlName="sureShift" automation-id="sure-shift-check-box">
              I understand by applying Sure Shift, I will not be able to cancel once an IntelyPro has accepted the
              shift(s).
            </mat-checkbox>
          </ng-container>
          <div class="action-buttons">
            <ng-container *ngTemplateOutlet="boostButtonsTemplate"></ng-container>
            <button mat-button automation-id="close-button" (click)="toggleBoostOptions()">close</button>
          </div>
        </div>
      </ng-template>

      <ng-template #allowOvertimeTemplate>
        <mat-form-field class="modify-form-wrapper" appearance="outline" [floatLabel]="'always'">
          <mat-label>Allow Overtime</mat-label>
          <mat-select formControlName="allowOvertime" [disabled]="!boostForm.get('boostAmount').value"
            automation-id="confirm-cart-sidebar-allow-overtime-select" placeholder="Allow Overtime">
            <mat-select-trigger class="overtime-setting">
              {{ getSelectedText(boostForm.get('allowOvertime').value) }}
            </mat-select-trigger>
            <ng-container [ngSwitch]="overtimeSettingValue">
              <ng-container *ngSwitchCase="'allFac'">
                <mat-option automation-id="confirm-cart-sidebar-allow-overtime-option1" value="allFac">If working 40+
                  hours for IntelyCare</mat-option>
              </ng-container>
              <ng-container *ngSwitchCase="'myFac'">
                <mat-option automation-id="confirm-cart-sidebar-allow-overtime-option1" value="allFac">If working 40+
                  hours for IntelyCare</mat-option>
                <mat-option automation-id="confirm-cart-sidebar-allow-overtime-option2" value="myFac">If working 40+
                  hours at my facility</mat-option>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <mat-option automation-id="confirm-cart-sidebar-allow-overtime-option1" value="allFac">If working 40+
                  hours for IntelyCare</mat-option>
                <mat-option automation-id="confirm-cart-sidebar-allow-overtime-option2" value="myFac">If working 40+
                  hours at my facility</mat-option>
                <mat-option automation-id="confirm-cart-sidebar-allow-overtime-option3" value="No">Don't allow
                  overtime</mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-template>
      <ng-template #boostAmountTemplate>
        <mat-form-field appearance="outline" class="mat-form-field-wrapper">
          <mat-label>Boost Amount</mat-label>
          <mat-select formControlName="boostAmount" automation-id="confirm-cart-sidebar-boost-amount-select">
            <mat-option *ngFor="let boost of boostRangeList" [attr.automation-id]="'confirm-cart-sidebar-boost-amount-option-' + boost" [value]="boost">Boost {{ boost }}%</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
      <ng-template #boostButtonsTemplate>
        <button *ngIf="!autoToggle" mat-button (click)="boostShifts(request)" [disabled]="(!boostForm.controls.boostAmount.value &&
                (!boostForm.controls.allowOvertime.value || !boostForm.controls.allowOvertime.value.value) &&
                 !boostForm.controls?.sureShift.value) || _modifyBoostService.processBoosting"
          automation-id="confirm-cart-sidebar-boost-shifts-button">
          <ng-container *ngIf="_modifyBoostService.processBoosting else buttonLabels">
            <img src="/assets/images/loader.gif" height="30" width="30" />
          </ng-container>
          <ng-template #buttonLabels>
            Apply To All Shift(s)
          </ng-template>
        </button>
      </ng-template>
    </div>
  </form>
</div>