<div class="processing-request-wrapper">
  <label>{{ data.header }}</label>
  <div class="header-wrapper">
    <mat-icon>check_circle</mat-icon>
    <label>{{ data.text }}</label>
  </div>
  <div class="body-wrapper">
    <label>This may take a moment, please wait while we process your request.</label>
    <div class="loader-wrapper">
      <img src="assets/images/loader.svg" height="112" width="113"/>
      <label>Processing Request...</label>
    </div>
  </div>
</div>
