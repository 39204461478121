import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CartData } from '../../models/cart-data';
import { BoostDataService } from '../../../../services/boost-data.service';
import { ManageUsersAccountService } from '../../../../../services/manage-users-account.service';
import { JWTPayloadData } from '../../../../../models/newModels/JWTPayloadData';
import { MonthlyViewService } from '../../../../services/monthly-view.service';
import { ModifyBoostService } from '../../../../services/modify-boost.service';
import { Observable, Subject } from 'rxjs';
import { StaffTypeLabel } from 'src/app/models/shift-management-models/StaffTypeLabel';
import { MobileService } from 'src/app/services/mobile.service';
import { TotalCartInfo } from '../../models/total-cart-info-data';

@Component({
  selector: 'app-confirm-cart-sidebar',
  templateUrl: './confirm-cart-sidebar.component.html',
  styleUrls: ['./confirm-cart-sidebar.component.scss']
})
export class ConfirmCartSidebarComponent implements OnInit {

  sureShiftSelected = true;
  boostResponse: any = {};
  user: JWTPayloadData;
  boostRangeList: number[] = [];
  totalCartInfo:TotalCartInfo;
  isDevice: boolean;

  requestIds$: Observable<number[]>;
  hasBoost$: Observable<boolean>;

  constructor(
    private _manageUsersAccountService: ManageUsersAccountService,
    private _monthlyViewService: MonthlyViewService,
    private _cartService: ShoppingCartService,
    public mobileService: MobileService
  ) {
    this.user = this._manageUsersAccountService.currentUserValue;

  }

  ngOnInit(): void {
    this.isDevice = this.mobileService.isDevice();
    this._cartService.totalCartInfo?.pipe(
      filter(e => !!e)
    ).subscribe(e => {
      this.totalCartInfo = e;
    });
    this.setUpObservable();
  }

  close() {
    this.boostRangeList = [];
    this._cartService.totalCartInfo.next(null);
    this._cartService.closeConfirmationCartSidebar();
    this._cartService.isAlert12HourMsg.next(false);
    this._cartService.requestSelectedDates.next(null);
  }

  setUpObservable() {
    this.requestIds$ = this._cartService.requestedShifts.pipe(
      map(shiftResponse => shiftResponse.map(item => item.reqId))
    );
    this.hasBoost$ = this._monthlyViewService.hasBoostButton.asObservable();
  }

  getBoostResponse($event: any) {
    this.boostResponse = $event;
  }
}
