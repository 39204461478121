import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ManageUsersAccountService } from '../manage-users-account.service';
import { CookieService } from 'ngx-cookie-service';
import { FuseConfigService } from '@theme/services/config.service';
import { map } from 'rxjs/operators';
import { theme_config } from '../../config/theme_config';
import { UserTypes } from 'src/app/config/user-types';

@Injectable({ providedIn: 'root' })
export class StaffAuthGuard implements CanActivate {

  uiconfig = theme_config;

  constructor(
    private fuseConfigService: FuseConfigService,
    private cookieService: CookieService,
    private router: Router,
    private authenticationService: ManageUsersAccountService
  ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.authenticationService.currentUserSubject.pipe(
        map(e => {
          if (!!e) {
            switch (e.activeusertype.usertypeid) {
              case UserTypes.Staff:
                if (!this.cookieService.get('IntelycareAuthCookie')) {
                  this.authenticationService.clearLocalStorage();
                  e = null;
                }
                return true;
                break;
              case UserTypes.Scheduler:
              case UserTypes.HubUser:
                this.uiconfig.layout.sidepanel.hidden = true;
                this.uiconfig.layout.toolbar.hidden = true;
                this.fuseConfigService.setConfig(this.uiconfig);
                this.router.navigateByUrl(this.authenticationService.navigateToLandingPage(2));
                break;
            }
          } else {
            this.router.navigateByUrl('/login');
          }
          return false;
        })
      );
    }
}
