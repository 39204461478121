import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, flatMap, map, takeUntil, tap, toArray } from 'rxjs/operators';
import { ShiftData } from '../../../models/newModels/ShiftData';
import { ShiftManagementService } from '../../../services/shift-management.service';
import { ShiftManagementSidebarService } from '../../shift-management-sidebar.service';
import { ConvertersService } from '../../../services/utils/converters.service';
import { flatten } from '@angular/compiler';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { DailyViewService } from '../../../services/daily-view.service';
import { Subject } from 'rxjs';
import { DailyViewConfigModel } from '../../../models/daily-view-config-model';
import { GoogleAnalyticsService } from '../../../services/googleanalytics.service';
import { ShiftsService } from '../../../services/apis/shifts.service';
import { ShiftDefs } from '../../../models/shift-management-models/ShiftDefs';
import { UnitInfo } from 'src/app/models/shift-management-models/UnitInfo';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftDetailsComponent implements OnInit, OnDestroy {

  @Input() shiftDetails: ShiftData;
  @Input() staffMember: StaffMember;

  calloutReasons: { name: string, value: number }[];
  unitoptions: UnitInfo[];
  shifttypeoptions: { name: string, value: number }[];
  allshifttypes: ShiftDefs[];

  callOutReason: number;
  currentshiftdefid: number;
  currentunit: number;
  currentdate: moment.Moment;

  removingFromStaff = false;
  currentConfig: DailyViewConfigModel;
  private _unsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private _shiftJavaService: ShiftsService,
    private _shiftService: ShiftManagementService,
    private _dailyService: DailyViewService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _sidebar: ShiftManagementSidebarService
  ) {
    this._shiftService.getCalloutReasons().pipe(
      map(e => e.filter(reason => reason.ipReason !== 1)),
      flatMap(e => e),
      map((e) => ({ value: e.calloutReasonId, name: e.description })),
      toArray()).subscribe(e => {
        this.calloutReasons = e;
      });

    this._shiftJavaService.getShiftDefs().pipe(
      flatMap(e => e), toArray(),
      tap(e => this.allshifttypes = e),
      flatMap(e => e),
      filter(e => !!e.census),
      map((e: ShiftDefs) => ({ name: ConvertersService._datesToShiftHours(e), value: e.shiftDefId })),
      toArray()).subscribe(e => {
        this.shifttypeoptions = e;
        const currentshiftname = ConvertersService._datesToShiftHours({
          startTime: this.shiftDetails.starttime,
          endTime: this.shiftDetails.endtime
        });
        this.currentshiftdefid = e.filter(a => a.name === currentshiftname)[0].value;
      });

    this._shiftJavaService.getUnits().subscribe(e => {
      this.unitoptions = e;
      this.currentunit = this.shiftDetails.UnitId;
    });
  }

  ngOnInit() {
    this.currentdate = moment(this.shiftDetails.shiftdate);
    this._dailyService.dailyViewConfig.pipe(
      takeUntil(this._unsubscribe)
    ).subscribe(e => this.currentConfig = e);
  }

  removeFromShift() {
    this._shiftService.removeStaffWithReason(this.staffMember.staffId, this.shiftDetails.shiftid, this.callOutReason)
      .subscribe(e => {
        this._sendAnalyticsRemoveStaff();
        this._sidebar.closePanel();
      });
  }

  moveShift(newunit, newdate: moment.Moment, newshiftdefid) {
    const allcensus = flatten(this.allshifttypes.map(e => e.census));
    const currentday = newdate.isoWeekday();
    const currentcensus = allcensus.filter(e => e.unitID === newunit && e.shiftDefId === newshiftdefid && e.dayOfWeek === currentday)[0];

    this._shiftService.changeShiftTimeAndDate(currentcensus.censusId, this.shiftDetails.shiftid,
      formatDate(newdate.toDate(), 'yyyy-MM-dd', 'en-US')).subscribe(e => {
        this._sidebar.closePanel();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
  private _sendAnalyticsRemoveStaff() {
    this._googleAnalyticsService.eventEmitter(
      GoogleAnalyticsService._GetEvents().shift_management.category,
      GoogleAnalyticsService._GetEvents().shift_management.action.schedular_remove_with_reason.name,
      GoogleAnalyticsService._GetEvents().shift_management.action.schedular_remove_with_reason.label,
      1
    );
  }

}
