import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserWarningComponent } from './browser-warning.component';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared.module';



@NgModule({
  declarations: [BrowserWarningComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  exports: [BrowserWarningComponent]
})
export class BrowserWarningModule { }
