<div class="toolbar">
  <div class="toolbar-wrapper">
    <nav class="p-0 custom-toolbar">
      <ng-container *ngIf="_accountService.showAllItemsInToolbar$ | async">
        <label *ngIf="toolbarTheme === 'default'" [matMenuTriggerFor]="mobileMenu" class="hamburger-menu">
          <i class="fas fa-bars" automation-id="mobile-hamburger-menu"></i>
        </label>
      </ng-container>
      <div *ngIf="navbar === 'OnlyNavbar' && showFacilityName; else onlyNavbar" class="navbar-custom">
        <app-facility-icon [logo]="toolbarTheme == 'light' ? 'blue' : 'light'" [width]="'auto'">
        </app-facility-icon>
      </div>
      <ng-template #onlyNavbar>
        <app-facility-icon [logo]="toolbarTheme == 'light' ? 'blue' : 'light'" [width]="'180px'"
          *ngIf="showFacilityName">
        </app-facility-icon>
      </ng-template>
      <ng-container *ngIf="_accountService.showAllItemsInToolbar$ | async">
        <ul *ngIf="toolbarTheme == 'default'" class="navigation">
          <ng-container *ngIf="!isIFrame; else ukgMenu">
            <li *ngIf="_accountService.facilityType !== viewTypes.internal">
              <div class="navigation-item-wrapper" routerLink="/dashboard"
                   [class.active]="!isOpened && checkToHoverMenu(['/dashboard'])">
                <span class="navigation-item">Dashboard</span>
              </div>
            </li>
            <li>
              <div class="navigation-item-wrapper"
                   [class.active]="isOpened || checkToHoverMenu(['/monthly', '/daily', '/spreadsheet'])"
                   [matMenuTriggerFor]="menu" (menuOpened)="isOpened = true" (menuClosed)="isOpened = false">
                <span class="navigation-item"> Schedule </span>
                <div class="icon-wrapper">
                  <mat-icon>{{ isOpened ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                </div>
              </div>
            </li>
            <li *ngIf="_accountService.facilityType !== viewTypes.internal">
              <div class="navigation-item-wrapper" routerLink="/timecards" automation-id="timecard-toolbar"
                   [class.active]="!isOpened && checkToHoverMenu(['/timecards'])">
                <span class="navigation-item">Timecards</span>
              </div>
            </li>
            <li>
              <div class="navigation-item-wrapper" routerLink="/directory"
                   [class.active]="!isOpened && checkToHoverMenu(['/directory'])" automation-id="directory-toolbar">
                <span class="navigation-item">Directory</span>
              </div>
            </li>
            <li>
              <div class="navigation-item-wrapper" [routerLink]="_accountService.facilityType !== viewTypes.internal ?
            '/settings/manage-profile' : '/settings/locations'"
                   [class.active]="!isOpened && checkToHoverMenu(['/settings'])">
                <span class="navigation-item">Settings</span>
              </div>
            </li>
          </ng-container>
          <ng-template #ukgMenu>
            <li>
              <div class="navigation-item-wrapper" routerLink="/home" automation-id="ukg-home-toolbar"
                   [class.active]="!isOpened && checkToHoverMenu(['/home'])">
                <span class="navigation-item">Home</span>
              </div>
            </li>
            <li>
              <div class="navigation-item-wrapper" routerLink="/week" automation-id="ukg-week-toolbar"
                   [class.active]="!isOpened && checkToHoverMenu(['/week'])">
                <span class="navigation-item">Schedule (Week)</span>
              </div>
            </li>
          </ng-template>
        </ul>
        <div *ngIf="notLoggedIn(); else logedIn" class="toolbar-avatar" fxFlexLayout="row">
          <label class="existing-account-label">Have an existing account?</label>
          <button autofocus (click)="login()" mat-button [disableRipple]="true" style="min-width: fit-content;"
            [ngClass]="accountFormTouched ? 'not-focused-button' : 'custom-login-btn'">
            <label>Log in</label>
          </button>
        </div>
      </ng-container>
    </nav>
    <ng-template #logedIn>
      <div class="toolbar-avatar">
        <button *ngIf="isDevice; else loggedInUserInfo" mat-button class="primary-green-intelypro-btn request-intelypro-button"
          automation-id="request-intelyPros-btn" (click)="goToRequestIp()">
          <img src="/assets/images/white-logo.svg" class="logo" alt="intelycare-logo" />
          <span>request ip</span>
        </button>
        <ng-template #loggedInUserInfo>
          <label *ngIf="toolbarTheme == 'light'" fxShow="true" fxHide.lt-md class="logged-user-name">Welcome, {{
            currentUser.firstname }}
            {{ currentUser.lastname }}
          </label>
          <span *ngIf="toolbarTheme == 'default'" fxShow="true" fxHide.lt-md class="facilityname">
            {{ currentUser.activeusertype.clientname }}
          </span>
        </ng-template>
        <ng-container *ngIf="!isIFrame">
          <app-chip-icon [template]="toolbarTheme == 'light' ? 'light' : 'default'" name="{{ currentUser.firstname | titlecase }} {{
            currentUser.lastname | titlecase
          }}" [matMenuTriggerFor]="userMenu" #menuTrigger="matMenuTrigger">
          </app-chip-icon>
        </ng-container>
      </div>

      <mat-menu #userMenu="matMenu" class="user-menu" panelClass="user-menu">
        <div class="user-info" fxLayout="column" fxLayoutGap="4px" (click)="$event.stopPropagation()">
          <label class="status-header">
            {{currentUser.firstname}} {{currentUser.lastname}}
          </label>
          <span class="user-email">{{ currentUser.email }}</span>
          <div fxLayout="row" fxLayoutGap="13px">
            <button (click)="menuTrigger.closeMenu()" *ngIf="(
                _accountService.facilityType === viewTypes.hybrid
                || _accountService.facilityType === viewTypes.intelypro
                ) && isHubUser" class="pay-invoice-btn">
              <a [routerLink]="['/settings/manage-profile']" routerLinkActive="active">
                Manage profile
              </a>
            </button>
            <button class="pay-invoice-btn" [attr.automation-id]="'view-pay-invoices'"
              [hidden]="!billingSummaryData?.yayPayLink">
              <a [href]="billingSummaryData?.yayPayLink" target="_blank">
                View and pay invoices
              </a>
            </button>
          </div>
        </div>
        <div class="menu-sub-section">
          <div *ngIf="!onloadError && billingSummaryData" fxLayout="column" class="billing-sec">
            <span>
              <i class="fas fa-dollar-sign"></i>
              <label class="billing-header">Balance</label>
            </span>
            <label class="billing-value">{{
              billingSummaryData?.balance | currency: "USD"
              }}</label>
          </div>
          <!-- TO DO Finance requested 10/07 to remove diplaying credit limit until they've given all clients credit limits.-->
          <!-- <div *ngIf="!onloadError && billingSummaryData" fxLayout="column" class="billing-sec">
          <span>
            <i class="fas fa-dollar-sign"></i>
            <label class="billing-header">Facility Credit Limit</label>
          </span>
          <label class="billing-value" *ngIf="billingSummaryData?.balanceLimit">{{ billingSummaryData?.balanceLimit | currency: 'USD' }}</label>
          <label class="billing-value" *ngIf="!billingSummaryData?.balanceLimit">{{  0.00 | currency: 'USD' }}</label>
        </div>  -->
          <div *ngIf="!onloadError && billingSummaryData" fxLayout="column" class="billing-sec">
            <span>
              <i class="fas fa-arrow-right"></i>
              <label class="billing-header">Status</label>
            </span>
            <span>
              <label class="due-txt">{{
                billingSummaryData?.billingStatus == 'Override' ? 'Warning' : billingSummaryData?.billingStatus
                }}</label>
              <i *ngIf="billingSummaryData?.billingStatus !== ('Good' || 'Override')"
                [matTooltip]="billingSummaryData.statusReason ? billingSummaryData.statusReason : ''"
                class="fa fa-exclamation-circle" aria-hidden="true">
              </i>
            </span>
          </div>

          <div *ngIf="hasTwoRoles()" class="change-roles">
            <span class="roles-label">Change Roles</span>
            <app-radio-buttons [selectedOption]="selectedView" [options]="profileOptions"
              (radioOptionChanged)="changeExperience($event)" automation-id="change-roles"></app-radio-buttons>
          </div>

          <div *ngIf="showFacilitySection" class="facility-management">
            <p class="facility-heading">Facility Management</p>
            <div class="facility-main-section" (click)="openFacility()">
              <div class="facility-section">
                <img class="hospital-icon" src="assets/images/switch-facility-hospital-icon.svg">
                <div class="facility-name-section">
                  <p> {{currentUser.activeusertype.clientname}}</p>
                  <span>
                    <img src='assets/images/switch-facility.svg'>
                    <a>Switch Facility</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logout">
          <button automation-id="logout-button" (click)="logout()" class="logout-button" mat-button>
            <label class="logout-button-label">Logout</label>
          </button>
        </div>
      </mat-menu>
    </ng-template>
  </div>
  <div *ngIf="toolbarTheme == 'light'" class="header-divider"></div>
</div>

<mat-menu class="schedule-menu-panel" #menu="matMenu">
  <label>Available Views</label>
  <div class="menu-items">
    <button *ngIf="_accountService.facilityType !== viewTypes.intelypro" mat-menu-item
      [disabled]="route.url === '/daily'" (click)="navigateTo('daily')" automation-id="day-view-btn">
      <mat-icon>event</mat-icon>
      <span>Day View</span>
    </button>
    <button (click)="navigateTo('monthly')" [disabled]="route.url === '/monthly'" mat-menu-item
      automation-id="month-view-btn">
      <mat-icon>date_range</mat-icon>
      <span>Month View</span>
    </button>
    <button *ngIf="_accountService.facilityType !== viewTypes.intelypro" mat-menu-item
      automation-id="spreadsheet-view-btn" [disabled]="route.url === '/spreadsheet'"
      (click)="navigateTo('spreadsheet')">
      <mat-icon>list_alt</mat-icon>
      <span>Spreadsheet View</span>
    </button>
  </div>
  <ng-container *ngIf="_accountService.facilityType !== viewTypes.internal">
    <br />
    <label>Request IntelyPro</label>
    <div matTooltip="Status on Hold" matTooltipPosition="above" matTooltipClass="tooltip"
      [matTooltipDisabled]="canRequestIntelypro">
      <button mat-button class="ip-features request-ip  request-intelypro-button" automation-id="request-intelyPros-btn"
        (click)="goToRequestIp()" [disabled]="!canRequestIntelypro">
        <img src="/assets/images/white-logo.svg" class="logo" alt="intelycare-logo" />
        <label>Request Intelypros</label>
      </button>
    </div>
  </ng-container>
</mat-menu>

<mat-menu class="mobile-menu" routerLinkActive="active" #mobileMenu="matMenu">
  <span class="menu-item" routerLink="/home" automation-id="mobile-dashboard-link"
        routerLinkActive="active"> Home </span>
  <hr />
  <span routerLink="/week" automation-id="mobile-weekly-link" class="menu-item"
        routerLinkActive="active"> Schedule (WEEK) </span>
  <button class="request-btn" mat-button automation-id="mobile-request-intelypro-btn"
          (click)="goToRequestIp()" [disabled]="!canRequestIntelypro">
    <img src="/assets/images/white-logo.svg" class="logo" alt="intelycare-logo" />
    <label>Request Intelypros</label>
  </button>
</mat-menu>
