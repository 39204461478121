import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StaffMember } from 'src/app/models/shift-management-models/StaffMember.model';

@Component({
  selector: 'app-staff-card-expandable',
  templateUrl: './staff-card-expandable.component.html',
  styleUrls: ['./staff-card-expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaffCardExpandableComponent implements OnInit {

  @Input() control = false;
  @Input() showHoursThisWeek = true;
  @Input() staffMembers: StaffMember[];
  @Output() selectionChange: EventEmitter<StaffMember[]> = new EventEmitter();

  private _selectedItems: StaffMember[] = [];

  constructor(private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  selectItem(item: StaffMember) {
    const idx = this.getIndexOf(item);
    if (idx < 0) {
      this._selectedItems.push(item);
    } else {
      this._selectedItems.splice(idx, 1);
    }
    this.selectionChange.emit(this._selectedItems);
    this._cdr.detectChanges();
  }

  getIndexOf(item: StaffMember) {
    let a = -1;
    this._selectedItems.forEach((e, i) => {
      if (e.staffId === item.staffId) {
        a = i;
      }
    });
    return a;
  }

  hardsetSelected(selected: StaffMember[]) {
    this._selectedItems = [...selected];
    this._cdr.markForCheck();
  }

  formatNumber(number: string) {
    const phoneParts = [];
    phoneParts.push(number.substr(0, 3));
    phoneParts.push(number.substr(3, 3));
    phoneParts.push(number.substr(6, 4));

    return `(${phoneParts[0]}) ${phoneParts[1]}-${phoneParts[2]}`;
  }
}
