<div class="facility-schedule-wrapper">
    <div fxLayout="column" fxLayoutAlign="center center" class="details" fxLayoutGap="8%">
        <span class="title"> To accurately create your facility’s schedules,
            confirm the following schedule definitions:</span>
        <div class="body" fxLayout="column" fxLayoutGap="18%">

            <div fxLayout="column">
                <span class="sub-title">Start Day of the week</span>
                <mat-divider></mat-divider>
                <span class="bold-span-scnd">{{startWeek?.dayOfWeekName}}</span>
            </div>
            <div fxLayout="column">
                <span class="sub-title">Week You Are in Now</span>
                <mat-divider></mat-divider>
                <span class="bold-span-scnd">Week one </span>
                <span class="date">{{startWeek?.weekStartDate | date: 'EEEE, MMMM d, y'}}</span>
            </div>


        </div>
        <a (click)="navigateToSettings()" matTooltip="OPEN SETTINGS" matTooltipPosition="above"
            matTooltipClass="tooltip" class="edit-in-settings" automation-id="edit-in-settings-confirm-facility-schedule-details"> <i class="fal fa-edit"></i>
            Edit These IN SETTINGS</a>
        <span class="continue-text">Continue to the template download...</span>
        <button [ngClass]="{'selected':clickedConfirm}" (click)="confirmAndContinue()" class="continue-btn"  automation-id="continue-confirm-facility-schedule-details" mat-button>
            Confirm
            &
            Continue</button>
    </div>
</div>