import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsvFileUploadComponent } from './csv-file-upload.component';
import { FileUploadDirective } from './helpers/file-upload.directive';


@NgModule({
  declarations: [
    CsvFileUploadComponent,
    FileUploadDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CsvFileUploadComponent,
    FileUploadDirective
  ]
})
export class CsvFileUploadModule {
}
