import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Alert12HourNotificationComponent } from './alert12-hour-notification.component';
import { SharedModule } from '../../shared.module';
import { MaterialModule } from '../../material.module';



@NgModule({
  declarations: [Alert12HourNotificationComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  exports: [Alert12HourNotificationComponent]
})
export class Alert12HourNotificationModule { }
