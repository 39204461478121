<div class="shifts-to-fill">
  <div class="shifts-to-fill-wrapper" fxFlex fxFlexFill fxLayout="column" fxLayoutGap="16px">

    <div class="shifts-to-fill-header">
      <card-system-top-header [title]="'Open Shifts To Fill'"
                              [subtitle]="'Next 7 Days'"></card-system-top-header>
    </div>

    <div class="count-indicator" fxLayoutAlign="center center">
      <span>{{openedShifts.length}} open shifts</span>
    </div>

    <div class="content" fxFlex="0 1 auto" fxLayout="column">
      <div class="content-wrapper" fxLayout="column" fxLayoutGap="20px">

        <div class="shift-to-fill" *ngFor="let shift of openedShifts" fxLayout="column">
          <card-system-shift-details [shiftDetails]="shift" [selectable]="false"></card-system-shift-details>

          <div class="shift-actions" fxLayout="column">
            <button mat-button class="action-button complete">
              <mat-icon>person_add</mat-icon>
              Request Staff
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
