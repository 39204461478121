import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ManageUsersAccountService } from '../manage-users-account.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { OnBoardingService } from '../../on-boarding/services/on-boarding.service';
import { forkJoin, of } from 'rxjs';
import { UserTypes } from '../../config/user-types';

@Injectable({providedIn: 'root'})
export class ActivateGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _onBoardingService: OnBoardingService,
    private _authenticationService: ManageUsersAccountService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authenticationService.currentUserSubject.pipe(
      filter(e => !!e),
      switchMap(data => forkJoin({
          state: this._onBoardingService.getOnboardingState(),
          user: of(data)
        })
      ),
      map(resp => {
        // Check user type
        switch (resp.user.activeusertype.usertypeid) {
          case UserTypes.Staff:
            // if logged in user is a staff member
            if (resp.user.active) {
              return true;
            } else {
              // if not yet activated
              this._router.navigateByUrl('/activate');
            }
            break;
          case UserTypes.Scheduler:
          case UserTypes.HubUser:
            // if logged in user is a scheduler
            if ((resp.state === 'done' || Number(resp.state) >= 23)) {
              // Allow only if all onboarding steps are finished
              return true;
            }
            break;
        }
        return false;
      })
    );
  }
}
