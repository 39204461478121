<div class="card-system-shift-details" [ngClass]="{clickable: selectable}"
  (click)="selectable ? toggleSelection(!selected) : ''">
  <div class="card-system-shift-details-wrapper" fxLayoutAlign="start start" fxLayout="row" fxLayoutGap="16px">

    <div class="header" fxFlexAlign="start start">
      <mat-checkbox [checked]="selected" (change)="toggleSelection($event.checked)" *ngIf="selectable"
        (click)="$event.stopPropagation()"></mat-checkbox>
    </div>

    <div class="content" fxLayout="column" fxLayoutGap="6px">

      <span class="header-title">{{shiftDetails.shiftdate | date : 'EEE, MMM dd, yyyy'}}</span>

      <div class="shift-info" fxLayout="row wrap">

        <span>{{shiftDetails.starttime | toShiftHours : shiftDetails.endtime }}</span>

        <app-point-separator></app-point-separator>

        <span>{{shiftDetails.FloorName}}</span>

        <app-point-separator></app-point-separator>

        <span>{{getStaffType(shiftDetails.shiftTypeId)}}</span>


      </div>
  </div>
</div>