<div *ngIf="isUserType === monthlyViewType.internal; else hybridOrIntelypro"
     class="wrong-device-wrapper">
  <div class="wrong-device-header">
    <label>Awesome! You have setup your account.</label>
    <p>Please check your email from your desktop to log into the Scheduling Application.</p>
  </div>
  <img src="assets/images/clouds-wrong-device.png" height="1514" width="1125" />
</div>

<ng-template #hybridOrIntelypro>
  <div class="page-section">
    <div class="header-section">
      <img src="assets/images/branding/intelycare-logo-primary-tm-logo-full-color-rgb.svg" />
    </div>
    <div class="content-section">
      <h3>
        Are you looking to <b>request IntelyPros </b> to work at your facility? Install the app
      </h3>
      <div class="buttons-section">
        <a *ngIf="platform.IOS; else platformAndroid"
           mat-button
           href="{{ STORE_URL.APP_STORE_URL }}">
          <img src="assets/images/appStore_button.svg">
        </a>

        <ng-template #platformAndroid>
          <a mat-button
             href="{{ STORE_URL.PLAY_STORE_URL }}">
            <img src="assets/images/googleplay_button.svg">
          </a>
        </ng-template>
      </div>
      <p>Looking to do something else?</p>
      <a [routerLink]="getProperLandingPage()" class="navigation-link">Continue</a>
    </div>
  </div>
</ng-template>
