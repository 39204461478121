import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CartData } from '../../../models/cart-data';
import { ShoppingCartService } from '../../../services/shopping-cart.service';

@Component({
  selector: 'app-mobile-cart-sidebar',
  templateUrl: './mobile-cart-sidebar.component.html',
  styleUrls: ['./mobile-cart-sidebar.component.scss']
})
export class MobileCartSidebarComponent implements OnInit {

  @Input() cartItems: [] = [];
  @Input() totalPerStaffType: [] = [];
  @Input() totalIntelypros: number;
  @Input() requesting: boolean;
  @Output() submitRequest = new EventEmitter();
  @Output() deleteItem = new EventEmitter<CartData>();
  @Output() updateItem = new EventEmitter<CartData>();

  constructor(
    private _cartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this._cartService.closeShoppingCartSidebar();
  }

  update(item: CartData) {
    this.updateItem.emit(item)
  }

  delete(item: CartData) {
    this.deleteItem.emit(item)
  }

  submit() {
    this.submitRequest.emit();
  }

  clearCart() {
    this._cartService.clearCart();
  }

}