 export enum ShiftStatusType {
  Accepted = 'Accepted',
  AwaitingResponse = 'Awaiting Response',
  BookMe = 'BookMe',
  Completed = 'Completed',
  Pending_BlockBooking = 'Pending - Block Booking',
  Requested_BlockBooking = 'Requested - Block Booking',
  Deleted = 'Deleted',
  Pending = 'Pending',
  PNetwork = 'PNetwork'
}
