import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { MaterialModule } from '../../material.module';
import { RadioButtonsComponent } from './radio-buttons.component';

@NgModule({
    declarations: [
        RadioButtonsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule
    ],
    exports: [
        RadioButtonsComponent
    ]
})
export class RadioButtonsModule { }
